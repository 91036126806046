import { UserUpdatedEventArgs, User, UserUpdateReason } from '../user';
import { UserDescriptor } from '../userdescriptor';
import { UserDescriptors } from './userdescriptors';
import { Paginator } from '../interfaces/paginator';
import { Network } from '../services/network';
import { SyncClient } from 'twilio-sync';
import { Configuration } from '../configuration';
import { CommandExecutor } from '../commandexecutor';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type UsersEvents = {
  userUpdated: (data: {
    user: User;
    updateReasons: UserUpdateReason[];
  }) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
};

export interface UsersServices {
  network: Network;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

/**
 * @classdesc Container for known users
 * @fires Users#userUpdated
 */
class Users extends ReplayEventEmitter<UsersEvents> {

  private userDescriptors: UserDescriptors;
  private subscribedUsers: Map<string, User>;
  private fifoStack: any;
  public readonly myself: User;

  constructor(
    myself: User,
    private readonly configuration: Configuration,
    private readonly services: UsersServices
  ) {
    super();

    this.fifoStack = [];

    this.myself = myself;
    this.myself.on('updated', (args: UserUpdatedEventArgs) => this.emit('userUpdated', args));
    this.myself.on('userSubscribed', () => this.emit('userSubscribed', this.myself));
    this.myself.on('userUnsubscribed', () => {
      this.emit('userUnsubscribed', this.myself);
      this.myself._ensureFetched();
    });
    this.subscribedUsers = new Map<string, User>();
    this.userDescriptors = new UserDescriptors(this.configuration, {...this.services, users: this });
  }

  private handleUnsubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      this.subscribedUsers.delete(user.identity);
    }
    let foundItemIndex = -1;
    let foundItem = this.fifoStack.find((item, index) => {
      if (item == user.identity) {
        foundItemIndex = index;
        return true;
      }
      return false;
    });
    if (foundItem) {
      this.fifoStack.splice(foundItemIndex, 1);
    }
    this.emit('userUnsubscribed', user);
  }

  private handleSubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      return;
    }
    if (this.fifoStack.length >= this.configuration.userInfosToSubscribe) {
      this.subscribedUsers.get(this.fifoStack.shift()).unsubscribe();
    }
    this.fifoStack.push(user.identity);
    this.subscribedUsers.set(user.identity, user);
    this.emit('userSubscribed', user);
  }

  /**
   * Gets user, if it's in subscribed list - then return the user object from it,
   * if not - then subscribes and adds user to the FIFO stack
   * @returns {Promise<User>} Fully initialized user
   */
  public async getUser(identity: string, entityName: string = null): Promise<User> {
    await this.myself._ensureFetched();

    if (identity == this.myself.identity) {
      return this.myself;
    }

    let user = this.subscribedUsers.get(identity);
    if (!user) {
      if (!entityName) {
        let userDescriptor = await this.getUserDescriptor(identity);
        entityName = userDescriptor._getDescriptor().sync_objects.user_info_map;
      }

      user = new User(identity, entityName, this.configuration, this.services);
      user.on('updated', (args: UserUpdatedEventArgs) => this.emit('userUpdated', args));
      user.on('userSubscribed', () => this.handleSubscribeUser(user));
      user.on('userUnsubscribed', () => this.handleUnsubscribeUser(user));
      await user._ensureFetched();
    }

    return user;
  }

  /**
   * @returns {Promise<UserDescriptor>} User descriptor
   */
  public async getUserDescriptor(identity: string): Promise<UserDescriptor> {
    return this.userDescriptors.getUserDescriptor(identity);
  }

  /**
   * @returns {Promise<Paginator<UserDescriptor>>} Users descriptors page for given channel sid
   */
  public async getChannelUserDescriptors(channelSid: string): Promise<Paginator<UserDescriptor>> {
    return this.userDescriptors.getChannelUserDescriptors(channelSid);
  }

  /**
   * @returns {Promise<Array<User>>} returns list of subscribed User objects {@see User}
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    await this.myself._ensureFetched();

    const users = [this.myself];
    this.subscribedUsers.forEach((user) => users.push(user));

    return users;
  }
}

export { Users };
