import React from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UsersEnums } from "../../services/constant";
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import { InputText } from "../Admin/SharedComponents/inputText";
import { toast } from "react-toastify";



export default class GlobalChatList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setGroup: "",
            updatedGroupName: "",
            isUpdateNameModal: false,
            selectedChatId: "",
            loader: false,
        }
    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    componentDidUpdate = async (prevProps) => {

        // console.log("Set Val >>> ", this.props.setVal, " >>> Prev Props ", prevProps.setVal);
        if (prevProps.setVal !== this.props.setVal) {
            await this.loaderChange(true);
            this.setState({
                setGroup: this.props.setVal
            })
            await this.loaderChange(false);
        }
    }

    handleListItemClick = (data) => {
        // console.log(data)
        this.setState({
            setGroup: data.label
        });
        this.props.changeGroupValue(data.value)
    }

    deleteGroup = (value) => {
        // console.log("Delete Data >> ", value);
        this.props.deleteChatGroup(value)
    }

    openUpdateNameModal = (value) => {
        this.setState({
            selectedChatId: value._id,
            updatedGroupName: value.label,
            isUpdateNameModal: true
        })
    }

    closeUpdateNameModal = () => {
        this.setState({
            selectedChatId: "",
            updatedGroupName: "",
            isUpdateNameModal: false
        })
    }

    changeGroupName = (value) => {
        this.setState({
            updatedGroupName: value
        })
    }

    editGroup = () => {
        let errorCounter = 0;
        if (this.state.updatedGroupName === "") {
            errorCounter++;
            toast.error("Please Enter Group Name");
            return false;
        }
        if (errorCounter === 0) {
            let updateData = {
                id: this.state.selectedChatId,
                updatedGroupName: this.state.updatedGroupName
            }
            // console.log("Updated Chat Data >>>> ", updateData)
            this.props.editChatGroup(updateData);

            this.closeUpdateNameModal();
        }
    }
    render() {
        return (
            <>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <List component="nav" aria-label="main mailbox folders">
                        {this.props.value.map((data, i) => <>
                            <div className="chat_list_sec">
                                <Tooltip title={data.value.participantName.join(",")} placement="right-start">
                                    <ListItemButton
                                        selected={data.label === this.state.setGroup}
                                        onClick={() => this.handleListItemClick(data)}
                                    >
                                        <ListItemText primary={data.label} />
                                        <ListItemIcon>
                                            {/* <InfoOutlinedIcon /> */}
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Tooltip>
                            </div>
                            <div className="chat_list_delete_sec ">

                                {(this.props.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.props.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ? <>
                                    <Stack direction="row" spacing={2}>
                                        {this.props.isGroup === 1 ?
                                            <EditIcon onClick={() => this.openUpdateNameModal(data.value)} /> : <></>}
                                        <DeleteOutlineIcon onClick={() => this.deleteGroup(data.value)} />
                                    </Stack> </> : <></>}
                            </div>
                            {/* {this.props.value.length > i ?
                                <Divider /> : <></>
                            } */}
                        </>
                        )}
                    </List >
                </Box>

                <Modal show={this.state.isUpdateNameModal} >
                    <Modal.Header>
                        <Modal.Title>Update Group Name </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="web-form-bx">
                            <div className="frm-label">Group Name *</div>
                            <div className="bts-drop">
                                <InputText
                                    className="inputfield group_name_input"
                                    value={this.state.updatedGroupName}
                                    onTextChange={(value) => {
                                        this.changeGroupName(value);
                                    }}
                                ></InputText>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeUpdateNameModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.editGroup()}>
                            UPDATE
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}