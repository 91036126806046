import React, { Component } from "react";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import { ApiCall } from "../../../../../services/middleware";
import history from "../../../../../history";
import { InputText, MultiSelectBox, SelectBox } from "../../../SharedComponents/inputText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    departmentValidator,
    emailValidator,
    inputEmptyValidate,
    mobileNumberValidator,
    nameRegexValidator,
    numberValidator,
} from "../../../../../validators";
import { Decoder } from "../../../../../services/auth";
import {
    consoleLog,
    phoneNumberCheck,
    SetUSAdateFormat,
    getCountryList,
    SetDatabaseDateFormat
} from "../../../../../services/common-function";
import { ErrorCode } from "../../../../../services/constant";
import ReactLoader from "../../../../Loader";
import { IMAGE_PATH_ONLY, IMAGE_URL } from "../../../../../services/config/api_url";
import axios from "axios";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { AlertMessage, ImageName } from "../../../../../enums";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// .................mui switch...................................
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 68,
    height: 28,
    padding: 0,
    borderRadius: "15%/50%",
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 25,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(5px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(42px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#335b7b",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 16,
        height: 16,
        borderRadius: 8,
        color: "white",
        marginTop: 4,
        marginLeft: 2,
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#993921",
        boxSizing: "border-box",
    },
}));
// ..........................style for react select........................

const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
        borderRadius: "6px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "yellow";
        return {
            ...styles,
            color: isFocused ? "grey" : "white",
            color: "#000",
            cursor: isDisabled ? "not-allowed" : "default",
        };
    },
};
// .......................for react select icon.............................................

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img
                src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
                style={{ width: "17px" }}
            />
        </components.DropdownIndicator>
    );
};

const languageSkillArr = [
    {
        label: "Read",
        value: 1,
    },
    {
        label: "Write",
        value: 2,
    },
    {
        label: "Speak",
        value: 3,
    },
];


export default class View_LEI_Request extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: true,
            showPage: false,
            isSelected_language: "false",
            showBillingPage: false,
            isSelected: null,
            isLoad: true,
            role: "",

            checkStatus: true,
            fname: "",
            lname: "",
            email: "",
            phone: "",
            mainPhoneInput: "",
            countryCode: "1",
            countryCodeIso2: "us",
            contactName: "",
            contactPhone: "",
            contact_mainPhoneInput: "",
            contact_countryCodeIso2: "us",
            contact_country_code: "1",
            contactEmail: "",
            contactRelation: "",
            clientArr: [],
            clientId: "",
            clientData: {},
            industryArr: [],
            industryArrData: [],
            industryData: {},
            departmentArr: [],
            departmentData: [],
            supervisorDepartmentData: {},
            languageArr: [],
            languageData: {},
            departmentInfoArr: [],
            depMainArr: [],
            locationInfoArr: [],
            locArr: [],
            jobTitle: "",
            jobTypeArr: [],
            jobTypeData: {},
            roleArr: [],
            roleData: {},
            genderArr: [],
            genderData: {},
            countryData: {},
            preferedData: [],
            preferedArr: [],
            excludeData: [],
            excludeArr: [],
            password: "",
            cpassword: "",
            reference: "",
            additionalCountryCode: 1,
            amount: "",
            checked: "",
            adminPhoto: "",
            directLine: "",
            dobDate: "",
            outOfTown: "",
            imagePath: "images/profile-pic.png",
            allCountryOption: [],
            selectedCountry: {},
            language: "",

            countrySpecificDialect: false,
            specificDialectCode: false,
            userId: "",

            // language for lei 
            otherLanguageData: [],
            otherLanguageSet: [],

            primaryLanguageSkillArr: languageSkillArr,
            primaryLanguageData: {},
            primaryLanguageSkillData: [],
            primaryLanguageRating: "",
            userData: {},
            isSelected_merge: "false",
            leiListData: [],
            selectedLEIdata: {},
            declineMessage: "",
            showId: "",
            status: 0,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.load();
        var classInstance = this;
        var modal = document.getElementById("decline-model");

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modal) {
                classInstance.closeModal();
            }
        };
    }

    load = async () => {
        let mainData = this.props.location;
        let preData = mainData.state;
        let industryDataArr = [],
            location = [],
            deptArr = [],
            deptInfo = [],
            roleDataArr = [],
            allCountryList = [],
            roleObjData = {},
            clientDataArr = [],
            clientDataObj = {},
            industryResArr = [],
            genderDataArr = [],
            genderObjData = {},
            languageResArrData = [],
            clientContactInfo = [],
            jobTypeObjData = {},
            industryObjData = {},
            languageDataObj = {},
            deptDataInfo = [],
            languageInfo = [],
            deptObjData = [],
            deptMainData = [],
            deptArrMain = [],
            totalArr = [],
            languageArrData = [],
            imagePath = "",
            countryDataObj = {},
            language = "",
            allLeiList = [];

        this.setState({
            showId: preData.clientcontactid
        })

        let getDataObj = {
            clientcontactid: preData.clientcontactid,
        };

        /* ........... Fetch LEI Details ........... */

        let contactResData = await ApiCall("getapprovedclientcontactdetails", getDataObj);
        if (
            contactResData.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            contactResData.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(contactResData.data.payload);
            consoleLog("LEI DETAILS >>>>>>>> ", payload.data)
            clientContactInfo = payload.data.clientinfo;
            deptDataInfo = payload.data.deptInfo;
            languageInfo = payload.data.language

            for (let i = 0; i < deptDataInfo.length; i++) {
                deptMainData.push({
                    label: deptDataInfo[i].location,
                    value: deptDataInfo[i].id,
                });
            }
        }

        /* react-phone input format code start */

        let mainPhoneCountryCode = clientContactInfo.countrycode ? clientContactInfo.countrycode.toString() : "1";
        let phoneNumber = clientContactInfo.mobile ? clientContactInfo.mobile.toString() : "";

        let directCountryCode = clientContactInfo.directlinecountrycode ? clientContactInfo.directlinecountrycode.toString() : "1";
        let directPhone = clientContactInfo.directLine ? clientContactInfo.directLine.toString() : "";

        let contactCountryCode = clientContactInfo.additonalContactCountryCode ? clientContactInfo.additonalContactCountryCode.toString() : "1";
        let contactPhone = clientContactInfo.additonalContactPhone ? clientContactInfo.additonalContactPhone.toString() : "";

        let mainPhone = "+" + mainPhoneCountryCode + "" + phoneNumber;
        let directLinePhone = "+" + directCountryCode + "" + directPhone;
        let additionalContactPhone = "+" + contactCountryCode + "" + contactPhone;

        /* react-phone input format code end */

        // .................. Fetch Language List .............................

        let languageResData = await ApiCall("getlanguagelist");
        let languagePayload = Decoder.decode(languageResData.data.payload);
        languageResArrData = languagePayload.data.languagelist;
        for (let n = 0; n < languageResArrData.length; n++) {
            languageArrData.push({
                label: languageResArrData[n].language,
                value: languageResArrData[n].id,
            });

            if (clientContactInfo.prefLangId === null || clientContactInfo.prefLangId === undefined || clientContactInfo.prefLangId === "") {
                if (languageResArrData[n].language === "English") {
                    languageDataObj = {
                        label: languageResArrData[n].language,
                        value: languageResArrData[n].id,
                    };
                    language = languageResArrData[n].id;
                }
            } else {
                if (clientContactInfo.prefLangId === languageResArrData[n].id) {
                    languageDataObj = {
                        label: languageResArrData[n].language,
                        value: languageResArrData[n].id,
                    };
                    language = languageResArrData[n].id;
                }
            }
        }

        // ...................... Fetch All Clients List ............................

        let clientinfoRes = await ApiCall("getallclinetinfo");
        if (
            clientinfoRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            clientinfoRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let clientPayload = Decoder.decode(clientinfoRes.data.payload);
            let clientResData = clientPayload.data.clientlist;
            for (let i = 0; i < clientResData.length; i++) {
                clientDataArr.push({
                    label: clientResData[i].clientName,
                    value: clientResData[i].clientid,
                });
                if (clientContactInfo.clientId === clientResData[i].clientid) {
                    clientDataObj = {
                        label: clientResData[i].clientName,
                        value: clientResData[i].clientid,
                    };
                }
            }
            for (let j = 0; j < clientResData.length; j++) {
                industryResArr.push({
                    label: clientResData[j].industry,
                    value: clientResData[j].industryType,
                });
            }
        }

        /* ........... Fetch All General Data ("GENDER", "INDUSTRY TYPE", "ROLE") LIST ............ */
        let lookupres = await ApiCall("getLookUpData");
        if (
            lookupres.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            lookupres.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(lookupres.data.payload);
            let industryArr = payload.data.lookupdata.INDUSTRY_TYPE;
            let roleArr = payload.data.lookupdata.CLIENT_CONTACT_TYPE;
            let genderArr = payload.data.lookupdata.GENDER_TYPE;

            for (let i = 0; i < industryArr.length; i++) {
                industryDataArr.push({
                    label: industryArr[i].name,
                    value: industryArr[i].id,
                });
                if (clientContactInfo.jobTypeId === industryArr[i].id) {
                    jobTypeObjData = {
                        label: industryArr[i].name,
                        value: industryArr[i].id,
                    };
                }
                if (clientContactInfo.industryTypeId === industryArr[i].id) {
                    industryObjData = {
                        label: industryArr[i].name,
                        value: industryArr[i].id,
                    };
                }
            }
            for (let j = 0; j < roleArr.length; j++) {
                roleDataArr.push({
                    label: roleArr[j].name,
                    value: roleArr[j].id,
                });
                if (clientContactInfo.clientContactTypeId === roleArr[j].id) {
                    roleObjData = {
                        label: roleArr[j].name,
                        value: roleArr[j].id,
                    };
                }
            }
            for (let k = 0; k < genderArr.length; k++) {
                genderDataArr.push({
                    label: genderArr[k].name,
                    value: genderArr[k].id,
                });

                if (clientContactInfo.genderTypeId === genderArr[k].id) {
                    genderObjData = {
                        label: genderArr[k].name,
                        value: genderArr[k].id,
                    };
                }
            }
        }


        /* ...................LEI country List Data ............. */

        let resCountry = await getCountryList();
        resCountry.map((data) => {
            allCountryList.push({
                label: data.name,
                value: data.id,
            });
            if (clientContactInfo.countryOrigin == data.id) {
                countryDataObj = {
                    label: data.name,
                    value: data.id,
                }
            }
        });


        /* ..............other language data............. */
        let otherLangDataArr = [];
        let otherLangIdArr = [];

        if (languageInfo.length > 0) {

            languageInfo.map((langObj) => {
                let crr = [];
                languageArrData.map((obj) => {
                    if (obj.value == langObj.languageId) {
                        if (langObj.canRead == 1) {
                            crr.push({
                                label: "Read",
                                value: 1
                            })
                        }
                        if (langObj.canWrite == 1) {
                            crr.push({
                                label: "Write",
                                value: 2
                            })
                        }
                        if (langObj.canSpeak == 1) {
                            crr.push({
                                label: "Speak",
                                value: 3
                            })
                        }

                        otherLangDataArr.push({
                            id: langObj.languageId,
                            name: obj.label,
                            skill: crr,
                            rating: langObj.ratings
                        })

                        otherLangIdArr.push({
                            label: obj.label,
                            value: obj.value
                        })

                    }
                })
            })
        }

        /* ................. Get All Lei List .............*/
        let leiRes = await ApiCall("getLeiList", {});

        if (
            leiRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            leiRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let leiArr = Decoder.decode(leiRes.data.payload);
            leiArr.data.leiList.map((obj) => {
                allLeiList.push({
                    label: obj.name,
                    value: obj.userId
                })
            })

        }



        this.setState({
            fname: clientContactInfo.fName,
            lname: clientContactInfo.lName,
            clientId: clientContactInfo.clientId,
            email: clientContactInfo.email,
            jobTitle: clientContactInfo.jobTitle,
            jobTypeData: jobTypeObjData,
            amount: clientContactInfo.amount,
            industryData: industryObjData,
            isSelected: clientContactInfo.outOfTownAuth === 0 ? false : true,
            // ......................................
            roleArr: roleDataArr,
            roleData: roleObjData,
            departmentInfoArr: deptArr,
            dobDate: SetUSAdateFormat(clientContactInfo.createDate),
            departmentData: deptObjData,
            genderArr: genderDataArr,
            genderData: genderObjData,
            clientArr: clientDataArr,
            clientData: clientDataObj,
            industryArr: industryResArr,
            industryArrData: industryDataArr,
            languageData: languageDataObj,
            languageArr: languageArrData,
            adminPhoto: clientContactInfo.profilePic,
            directLine_countryCode_phone: clientContactInfo.directlinecountrycode ? clientContactInfo.directlinecountrycode : "1",
            directLine_countryIso_phone: clientContactInfo.directlinecountryIso2 ? clientContactInfo.directlinecountryIso2 : "us",
            directLine: clientContactInfo.directLine,
            directLine_mainPhoneInput: directLinePhone,
            phone: clientContactInfo.mobile,
            mainPhoneInput: mainPhone,
            countryCode: clientContactInfo.countrycode ? clientContactInfo.countrycode : "1",
            countryCodeIso2: clientContactInfo.countrycodeIso2 ? clientContactInfo.countrycodeIso2 : "us",
            contact_countryCodeIso2: clientContactInfo.additionalcountryIso2 ? clientContactInfo.additionalcountryIso2 : "us",
            contact_country_code: clientContactInfo.additonalContactCountryCode ? clientContactInfo.additonalContactCountryCode : "1",
            contactPhone: clientContactInfo.additonalContactPhone,
            contact_mainPhoneInput: additionalContactPhone,
            isLoad: false,
            depMainArr: deptInfo,
            imagePath: imagePath,
            language: language,
            checkStatus: clientContactInfo.status === 1 ? true : false,
            allCountryOption: allCountryList,
            selectedCountry: countryDataObj,
            specificDialectCode: clientContactInfo.specificDialectCode == "0" ? false : true,
            userId: clientContactInfo.userId,
            isSelected_language: clientContactInfo.proficiencyLanguage == 0 || clientContactInfo.proficiencyLanguage == null ? "false" : "true",
            otherLanguageSet: otherLangDataArr,
            otherLanguageData: otherLangIdArr,
            reference: clientContactInfo.reference,
            contactRelation: clientContactInfo.relationshipToUser == null || clientContactInfo.relationshipToUser == undefined ? "" : clientContactInfo.relationshipToUser,
            contactEmail: clientContactInfo.additonalContactEmail == null || clientContactInfo.additonalContactEmail == undefined ? "" : clientContactInfo.additonalContactEmail,
            contactPhone: clientContactInfo.additonalContactPhone == null || clientContactInfo.additonalContactPhone == undefined ? "+" + this.state.additionalCountryCode + " " : "+" + this.state.additionalCountryCode + " " + clientContactInfo.additonalContactPhone,
            contactName: clientContactInfo.additonalContactname == null || clientContactInfo.additonalContactname == undefined ? "" : clientContactInfo.additonalContactname,
            leiListData: allLeiList
        });

        if (
            clientContactInfo.profilePic === null ||
            clientContactInfo.profilePic === undefined ||
            clientContactInfo.profilePic === ""
        ) {
            this.setState({
                imagePath: ImageName.IMAGE_NAME.PROFILE_PIC,
            });
        } else {
            this.setState({
                imagePath: IMAGE_PATH_ONLY + clientContactInfo.profilePic,
            });
        }
    };

    // ...............for account info..........................
    onFNameChange = (value) => {
        let nameCheck = nameRegexValidator(value);
        this.setState({
            fname: nameCheck,
        });
    };
    onLNameChange = (value) => {
        let nameCheck = nameRegexValidator(value);
        this.setState({
            lname: nameCheck,
        });
    };
    onClientChange = async (data) => {
        this.setState({
            departmentData: [],
        });
        let indusObj = {},
            location = [],
            deptArr = [],
            deptInfo = [];

        let clientObjData = {
            clientid: data.value,
        };
        for (let i = 0; i < this.state.clientArr.length; i++) {
            if (data.value === this.state.clientArr[i].value) {
                indusObj = {
                    label: this.state.industryArr[i].label,
                    value: this.state.industryArr[i].value,
                };
                this.setState({
                    industryData: indusObj,
                });
            }
        }

        let deptRes = await ApiCall("fetchselectedclientdeptinfo", clientObjData);
        if (
            deptRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            deptRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = await Decoder.decode(deptRes.data.payload);
            deptInfo = payload.data.deptinfo;
            location = payload.data.location;

            if (deptInfo !== null || deptInfo !== undefined || deptInfo !== "") {
                for (let i = 0; i < deptInfo.length; i++) {
                    deptArr.push({
                        label: deptInfo[i].department,
                        value: deptInfo[i].id,
                    });
                }
            }
        }
        this.setState({
            clientData: data,
            departmentInfoArr: deptArr,
            locationInfoArr: location,
            depMainArr: deptInfo,
        });
    };
    onLanguageChange = (data) => {
        this.setState({
            languageData: data,
            language: data.value
        });
    };
    onGenderChange = (data) => {
        this.setState({
            genderData: data,
        });
    };

    onEmailChange = (value) => {
        this.setState({
            email: value,
        });
    };
    onPhoneChange = (value) => {
        if (numberValidator(value) === true) {
            if (mobileNumberValidator(value) === true) {
                let phoneCheck = phoneNumberCheck(value);
                if (phoneCheck) {
                    this.setState({
                        phone: phoneCheck,
                    });
                }
            }
        }
    };
    phoneChange = (value, data, event, formattedValue) => {
        this.setState({
            mainPhoneInput: value,
            countryCodeIso2: data.countryCode,
            countryCode: data.dialCode,
            phone: value.slice(data.dialCode.length),
        });
    };
    onContactNameChange = (value) => {
        let nameCheck = nameRegexValidator(value);
        this.setState({
            contactName: nameCheck,
        });
    };
    onContactPhoneChange = (value) => {
        if (numberValidator(value) === true) {
            if (mobileNumberValidator(value) === true) {
                let phoneCheck = phoneNumberCheck(value);
                if (phoneCheck) {
                    this.setState({
                        contactPhone: phoneCheck,
                    });
                }
            }
        }
    };
    contactPhoneChange = (value, data, event, formattedValue) => {
        this.setState({
            contact_mainPhoneInput: value,
            contact_countryCodeIso2: data.countryCode,
            contact_country_code: data.dialCode,
            contactPhone: value.slice(data.dialCode.length),
        });
    };
    onContactEmailChange = (value) => {
        this.setState({
            contactEmail: value,
        });
    };
    onContactRelationChange = (value) => {
        this.setState({
            contactRelation: value,
        });
    };
    onRoleChange = (data) => {
        this.setState({
            roleData: data,
        });
    };
    onReferenceChange = (data) => {
        this.setState({
            reference: data,
        });
    };

    leiCountryChange = (value) => {
        this.setState({
            selectedCountry: value
        })
    }
    onStatusChange = (e) => {
        this.setState({
            checkStatus: !this.state.checkStatus,
        });
    };
    dobDateChange = (date) => {
        this.setState({
            dobDate: SetUSAdateFormat(date),
        });
    };
    languageCheck = (e) => {
        this.setState({
            isSelected_language: e.target.value
        })
    }
    onProfileImage = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios.post(IMAGE_URL, formData).then((res) => {
            this.setState({
                imagePath: res.data.data.url,
                adminPhoto: res.data.data.path + res.data.data.filename,
            });
            let adminPhoto = res.data.data.path + res.data.data.filename;
            this.onUpdate(adminPhoto);
        });
    };
    onUpdate = async (adminPhoto) => {
        let mainData = this.props.location;
        let preData = mainData.state;

        let objData = {
            selectedUserId: preData.clientcontactid,
            photo: adminPhoto,
        };

        let res = await ApiCall("updateClientContactProfilePic", objData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.UPDATE.PROFILE_SUCCESS, {
                hideProgressBar: true,
            });
        }
    };

    onNext = async () => {
        window.scrollTo(0, 0);
        let mainData = this.props.location;
        let preData = mainData.state;
        // let mobileNo = this.state.phone.substring(3, 15);
        // let contactNo = this.state.contactPhone.substring(3, 15);
        // let directNo = this.state.directLine.substring(3, 15);
        let errorCount = 0;
        let validateFName = inputEmptyValidate(this.state.fname);
        let validateFNameLength = departmentValidator(this.state.fname);
        let validateLName = inputEmptyValidate(this.state.lname);
        let validateLNameLength = departmentValidator(this.state.lname);
        let validateClient = inputEmptyValidate(this.state.clientData);
        let validateCountryOrigin = inputEmptyValidate(this.state.selectedCountry.value);
        let validateLanguage = inputEmptyValidate(this.state.language);
        let validateEmail = emailValidator(this.state.email);
        let validatePhone = inputEmptyValidate(this.state.phone);
        let validateDob = inputEmptyValidate(this.state.dobDate)
        let validateotherLanguage = inputEmptyValidate(this.state.otherLanguageData)


        if (validateFName === false) {
            toast.error(AlertMessage.MESSAGE.CLIENT.FIRST_NAME_EMPTY, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateFNameLength === false) {
            toast.error(AlertMessage.MESSAGE.USER.MAX_NAME_LENGTH, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateLName === false) {
            toast.error(AlertMessage.MESSAGE.CLIENT.LAST_NAME_EMPTY, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateLNameLength === false) {
            toast.error(AlertMessage.MESSAGE.USER.MAX_NAME_LENGTH, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateClient === false) {
            toast.error(AlertMessage.MESSAGE.CLIENT.EMPTY_CLIENT_NAME, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateDob === false) {
            toast.error(AlertMessage.MESSAGE.CLIENT.EMPTY_DOB, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateLanguage === false) {
            toast.error(AlertMessage.MESSAGE.CLIENT.EMPTY_LANGUAGE, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validateotherLanguage === false && this.state.isSelected_language == "true") {
            toast.error("Please select other language");
            errorCount++;
        } else if (this.state.isSelected_language == "true") {

            this.state.otherLanguageSet.map((obj, i) => {
                if (obj.skill.length == 0 || obj.rating === "") {
                    toast.error(AlertMessage.MESSAGE.OTHER_LANGUAGE.ALL_FIELDS_REQUIRED + " at row " + Number(i + 1));
                    errorCount++;
                }
            })
        }
        if (this.state.email === "" && validatePhone === false) {
            toast.error("Please enter Email or Phone No !", {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validatePhone === false && validateEmail.status === false) {
            toast.error(validateEmail.message, { hideProgressBar: true });
            errorCount++;
        }
        else if (this.state.email.length > 100) {
            toast.error(AlertMessage.MESSAGE.EMAIL.MAX_EMAIL_LENGTH, {
                hideProgressBar: true,
            });
            errorCount++;
        } else if (validatePhone === false && validateEmail.status === false) {
            toast.error(AlertMessage.MESSAGE.MOBILE.MOBILE_EMPTY, {
                hideProgressBar: true,
            });
            errorCount++;
        }
        if (validateCountryOrigin === false) {
            toast.error("Please select LEI Country of Origin");
            errorCount++;
        }

        // .....................................................


        if (errorCount === 0) {
            let objData = {
                oherLanguageSet: this.state.otherLanguageSet,
            };
            var lang = [];
            if (objData.oherLanguageSet != undefined) {
                for (let j = 0; j < objData.oherLanguageSet.length; j++) {
                    var langInfo = objData.oherLanguageSet[j];
                    var langobj = {
                        "languageId": langInfo.id,
                        "targetLanguageId": langInfo.id,
                        "isPrimary": "0",
                        "canSpeak": "0",
                        "canRead": "0",
                        "canWrite": "0",
                        "ratings": langInfo.rating
                    };
                    for (let i = 0; i < langInfo.skill.length; i++) {
                        if (langInfo.skill[i].label == "Read") {
                            langobj.canRead = "1";
                        } else if (langInfo.skill[i].label == "Write") {
                            langobj.canWrite = "1";
                        } else if (langInfo.skill[i].label == "Speak") {
                            langobj.canSpeak = "1";
                        }
                    }
                    lang.push(langobj);
                }
            }
            let data = {
                clientcontacttype: this.state.roleData.value,
                fname: this.state.fname,
                lname: this.state.lname,
                dob: this.state.dobDate == "" ? "" : SetDatabaseDateFormat(this.state.dobDate),
                altcountrycode: this.state.countryCode,
                altmobile: "",
                department: this.state.departmentid,
                gender: this.state.genderData.value,
                countrycode: this.state.countryCode,
                countryCodeIso2: this.state.countryCodeIso2,
                mobile: this.state.phone,
                clientid: this.state.clientData.value,
                industrytype: this.state.industryData.value,
                jobtype: this.state.jobTypeData.value,
                jobtitle: this.state.jobTitle,
                language: this.state.language,
                profilepic: this.state.adminPhoto,
                directline: this.state.directLine,
                directlinecountrycode: this.state.directLine_countryCode_phone,
                directlinecountryIso2: this.state.directLine_countryIso_phone,
                outoftown: this.state.isSelected ? "1" : "0",
                amount: this.state.amount,
                referance: this.state.reference,
                additionalcontactname: this.state.contactName,
                additionalemail: this.state.contactEmail,
                additionalphone: this.state.contactPhone,
                additionalcountrycode: this.state.contact_country_code,
                additionalcountryIso2: this.state.contact_countryCodeIso2,
                relationship: this.state.contactRelation,
                status: this.state.checkStatus ? "1" : "0",
                userTypeId: this.state.roleData.value,
                clientcontactid: preData.clientcontactid,
                password: this.state.password,
                countryOrigin: this.state.selectedCountry.value,
                countrySpecificDialect: this.state.specificDialectCode ? "1" : "0",
                leiId: this.state.userId,
                otherLanguageCheck: this.state.isSelected_language == "true" ? "1" : "0",
                otherLanguageSet: lang,
                requesterId: this.state.selectedLEIdata.value == null || this.state.selectedLEIdata.value == undefined ? "" : this.state.selectedLEIdata.value,
                isMerge: this.state.isSelected_merge == "true" ? "1" : "0",
            };

            consoleLog("Request Merge LEI Data >>>> ", data)

            let res = await ApiCall("leiMerge", data);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                toast.success(AlertMessage.MESSAGE.CONTACT.UPDATE_SUCCESS, {
                    hideProgressBar: true,
                });
                return history.push("/adminContactsRequest");
            } else {
                if (
                    res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.EMAIL_EXIST
                ) {
                    toast.error(AlertMessage.MESSAGE.EMAIL.EMAIL_EXIST, {
                        hideProgressBar: true,
                    });
                } else if (
                    res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.PHONE_EXIST
                ) {
                    toast.error(AlertMessage.MESSAGE.MOBILE.MOBILE_EXIST, {
                        hideProgressBar: true,
                    });
                } else if (
                    res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.EMAIL_PHONE_EXIST
                ) {
                    toast.error(AlertMessage.MESSAGE.EMAIL_MOBILE.DUBLICATE, {
                        hideProgressBar: true,
                    });
                } else if (
                    res.error === ErrorCode.ERROR.ERROR.WITH_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.DUPLICATE_LEI
                ) {
                    toast.error(AlertMessage.MESSAGE.LEI.DUBLICATE_LEI, {
                        hideProgressBar: true,
                    });
                }
            }
        }
    };

    // .....................func for cancel btn......................

    onCancel = () => {
        window.scrollTo(0, 0);
        return history.push("/adminContactsRequest");
    };

    countrySpecific = (e) => {
        if (e.target.value === "yes") {
            this.setState({
                specificDialectCode: true,
            });
        } else {
            this.setState({
                specificDialectCode: false,
            });
        }
    };

    onOtherLanguageChange = (option) => {
        let arr = [];
        option.map((data) => {
            arr.push({
                id: data.value,
                name: data.label,
                skill: [],
                rating: "",
            });
        });
        this.setState({
            otherLanguageData: option,
            otherLanguageSet: arr,
        });
    };

    onOtherLanguageSkillChange = (option, pos) => {
        let arr = this.state.otherLanguageSet;
        arr[pos].skill = option;
        this.setState({
            otherLanguageSet: arr,
        });
    };

    otherLanguageRatingChange = (newRating, pos) => {
        let arr = this.state.otherLanguageSet;
        arr[pos].rating = newRating;
        this.setState({
            otherLanguageSet: arr,
        });
    };

    merge_check = (e) => {
        this.setState({
            isSelected_merge: e.target.value,
        });
    };

    onLEIchange = (value) => {
        this.setState({
            selectedLEIdata: value
        })
    }

    acceptRequest = () => {
        let data = {
            status: "1",
            clientcontactid: this.state.showId,
            reason: "",
        };
        // console.log("RequestData>>>", data)
        this.modifyStatus(data);
    };

    modifyStatus = async (data) => {
        let res = await ApiCall("modifyclientcontactstatus", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            if (data.status === "1") {
                toast.success(AlertMessage.MESSAGE.CLIENT_REQUEST.REQUEST_ACCEPTED, {
                    hideProgressBar: true,
                });
            } else if (data.status === "2") {
                toast.success(AlertMessage.MESSAGE.CLIENT_REQUEST.REQUEST_DECLINED, {
                    hideProgressBar: true,
                });
            }
            this.load(this.state.showId);
        }
    };

    openModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("decline-model").style.display = "block";
        document.getElementById("decline-model").classList.add("show");
    };

    closeModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("decline-model").style.display = "none";
        document.getElementById("decline-model").classList.remove("show");
    };

    declineModal = () => {
        // window.$("#decline-model").modal("show");
        window.scrollTo(0, 0);
        this.openModal();
    };

    declineClose = () => {
        this.setState({
            declineMessage: "",
        });
        // window.$("#decline-model").modal("hide");
        this.closeModal();
    };

    declineMessageChange = (e) => {
        this.setState({
            declineMessage: e.target.value,
        });
    };

    declineRequest = () => {
        let errorCount = 0;
        let validateReason = inputEmptyValidate(this.state.declineMessage);
        if (validateReason === false) {
            toast.error(AlertMessage.MESSAGE.DECLINE_MODAL.EMPTY_MESSAGE, {
                hideProgressBar: true,
            });
            errorCount++;
        }

        if (errorCount === 0) {
            let data = {
                status: "2",
                clientcontactid: this.state.showId,
                reason: this.state.declineMessage,
            };
            // console.log("data Decline", data)
            this.modifyStatus(data);
            this.declineClose();
        }
    };

    render() {
        return (
            <React.Fragment>
                <div class="component-wrapper" hidden={!this.state.isLoad}>
                    <ReactLoader />
                </div>
                <div className="component-wrapper" hidden={this.state.isLoad}>
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> /
                        <Link to="/adminContactsRequest">Contacts Request</Link> /
                        Contact Information
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="dcs">VIEW CONTACT DETAILS</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">

                            {/* <div className="my-form-rw _fl">
                                <div className="my-form-bx" style={{ display: "block" }}>
                                    <div className="web-form-bx selct">
                                        <div className="frm-label lblSize">
                                            Would you like to Merge with Existing LEI's ?
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input
                                                    type="radio"
                                                    value="true"
                                                    checked={this.state.isSelected_merge === "true"}
                                                    onChange={this.merge_check}
                                                />
                                                <span className="checkmark3"></span> Yes
                                            </label>
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input
                                                    type="radio"
                                                    value="false"
                                                    checked={this.state.isSelected_merge == "false"}
                                                    onChange={this.merge_check}
                                                />
                                                <span className="checkmark3"></span> No
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.isSelected_merge == "true" ? (
                                        <React.Fragment>
                                            <div className="web-form-bx">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="vn_frm">
                                                            {" "}
                                                            <span>LEI</span>
                                                            <div className="bts-drop">
                                                                <div className="dropdown bootstrap-select">
                                                                    <SelectBox
                                                                        value={this.state.selectedLEIdata}
                                                                        optionData={this.state.leiListData}
                                                                        onSelectChange={(value) => {
                                                                            this.onLEIchange(value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment />
                                    )}
                                </div>
                            </div> */}
                            <div className="department-component-app _fl sdw">
                                <h3>ACCOUNT INFORMATION</h3>

                                <div className="department-form">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_rbx">
                                                {" "}
                                                <span className="">Role *</span>
                                                <Select
                                                    styles={customStyles}
                                                    options={this.state.roleArr}
                                                    components={{
                                                        DropdownIndicator,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    isDisabled
                                                    value={this.state.roleData}
                                                    placeholder="Select"
                                                    onChange={(value) => {
                                                        this.onRoleChange(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="department-form">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">First Name *</span>
                                                    <InputText
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.fname}
                                                        onTextChange={(value) => {
                                                            this.onFNameChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">Last Name *</span>
                                                    <InputText
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.lname}
                                                        onTextChange={(value) => {
                                                            this.onLNameChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="department-form">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">Client *</span>
                                                    <Select
                                                        styles={customStyles}
                                                        options={this.state.clientArr}
                                                        components={{
                                                            DropdownIndicator,
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        value={this.state.clientData}
                                                        placeholder="Select"
                                                        onChange={(value) => {
                                                            this.onClientChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">Date Of Birth *</span>

                                                    <div className="input-group" style={{ width: "100%", borderRadius: "9px", height: "43px", border: "1px solid #ced4da", boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)" }}>
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            {this.state.dobDate}
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    onChange={(date) => this.dobDateChange(date)}
                                                                    customInput={(<Schedule />)}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="department-form">
                                        <div className="row">
                                            <div className="col-md-5">
                                                { }
                                                <div className="form_rbx">
                                                    <span className="">Gender</span>
                                                    <Select
                                                        styles={customStyles}
                                                        placeholder="Select"
                                                        components={{
                                                            DropdownIndicator,
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        value={this.state.genderData}
                                                        options={this.state.genderArr}
                                                        onChange={(value) => {
                                                            this.onGenderChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span className="">Preferred Language *</span>

                                                    <Select
                                                        styles={customStyles}
                                                        options={this.state.languageArr}
                                                        components={{
                                                            DropdownIndicator,
                                                            IndicatorSeparator: () => null,
                                                        }}
                                                        value={this.state.languageData}
                                                        placeholder="Select"
                                                        onChange={(value) => {
                                                            this.onLanguageChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment>
                                        <div className="department-form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_rbx">
                                                        {" "}
                                                        <span className="">
                                                            Proficient Other Languages? *
                                                        </span>
                                                        <div className="check-field">
                                                            <label className="checkbox_btn">
                                                                <input
                                                                    type="radio"

                                                                    value="true"
                                                                    checked={
                                                                        this.state.isSelected_language === "true"
                                                                    }
                                                                    onChange={this.languageCheck}
                                                                />
                                                                <span className="checkmark3"></span> Yes
                                                            </label>
                                                        </div>
                                                        <div className="check-field">
                                                            <label className="checkbox_btn">
                                                                <input
                                                                    type="radio"

                                                                    value="false"
                                                                    checked={
                                                                        this.state.isSelected_language === "false"

                                                                    }
                                                                    onChange={this.languageCheck}
                                                                />
                                                                <span className="checkmark3"></span> No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="department-form">
                                            {this.state.isSelected_language == "true" ? (
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form_rbx">
                                                                {" "}
                                                                <span className="">Other language[s]</span>
                                                                <div
                                                                    className="dropdwn"
                                                                    style={{
                                                                        width: "93%",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >

                                                                    <MultiSelectBox
                                                                        optionData={this.state.languageArr}
                                                                        value={this.state.otherLanguageData}
                                                                        placeholder="Select"
                                                                        onSelectChange={(value) => {
                                                                            this.onOtherLanguageChange(value);
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                        </div>
                                                        <div className="col-md-4">
                                                        </div>
                                                    </div>
                                                    {this.state.otherLanguageSet.length > 0 ? (
                                                        this.state.otherLanguageSet.map((oth, l) => (
                                                            <React.Fragment key={l}>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form_rbx">
                                                                            {" "}
                                                                            <span className="">
                                                                                Other language{" "}*
                                                                            </span>
                                                                            <div>{oth.name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form_rbx">
                                                                            {" "}
                                                                            <span className="">
                                                                                Other Language Proficiency Skills *
                                                                            </span>

                                                                            <MultiSelectBox
                                                                                optionData={this.state.primaryLanguageSkillArr}
                                                                                value={oth.skill}
                                                                                placeholder="Select"
                                                                                onSelectChange={(value) => {
                                                                                    this.onOtherLanguageSkillChange(value, l);
                                                                                }}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form_rbx">
                                                                            {" "}
                                                                            <span className="">
                                                                                Other Language Fluency Ratings *
                                                                            </span>
                                                                            <div className="rate">
                                                                                <ReactStars
                                                                                    count={5}
                                                                                    onChange={(value) => {
                                                                                        this.otherLanguageRatingChange(value, l);
                                                                                    }}
                                                                                    size={30}
                                                                                    // color="#fff"
                                                                                    activeColor="#009fe0"
                                                                                    value={oth.rating}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        <React.Fragment></React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                            )}

                                        </div>
                                    </React.Fragment>
                                    <div className="department-form">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span className="">Email *</span>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.email}
                                                        onChange={(value) => {
                                                            this.onEmailChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                {" "}
                                                <span className="phoneInputLabel">Mobile No *</span>
                                                {/* <InputText
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.phone}
                                                        onTextChange={(value) => {
                                                        this.onPhoneChange(value);
                                                        }}
                                                    /> */}
                                                <PhoneInput
                                                    country={"us"}
                                                    countryCodeEditable={true}
                                                    value={this.state.mainPhoneInput}
                                                    onChange={this.phoneChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment>
                                        <div className="department-form">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span className="">Reference (MRN,Client ID or Other)</span>
                                                        <InputText
                                                            type="text"
                                                            placeholder=""
                                                            className="in-field2"
                                                            value={this.state.reference}
                                                            onTextChange={(value) => {
                                                                this.onReferenceChange(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span>
                                                            Is there a country specific dialect required
                                                            ?
                                                        </span>
                                                        <div className="check-field">
                                                            <label className="checkbox_btn">
                                                                {this.state.specificDialectCode ? (
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        checked={true}
                                                                        value="yes"
                                                                        onChange={this.countrySpecific}
                                                                    // disabled
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        checked={false}
                                                                        value="yes"
                                                                        onChange={this.countrySpecific}
                                                                    // disabled
                                                                    />
                                                                )}
                                                                <span className="checkmark3"></span> Yes
                                                            </label>
                                                        </div>
                                                        <div className="check-field">
                                                            <label className="checkbox_btn">
                                                                {this.state.specificDialectCode ? (
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        checked={false}
                                                                        value="no"
                                                                        onChange={this.countrySpecific}
                                                                    // disabled
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        checked={true}
                                                                        value="no"
                                                                        onChange={this.countrySpecific}
                                                                    // disabled
                                                                    />
                                                                )}
                                                                <span className="checkmark3"></span> No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="department-form">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span>Country Of Origin</span>
                                                        <SelectBox
                                                            optionData={this.state.allCountryOption}
                                                            value={this.state.selectedCountry}
                                                            onSelectChange={(value) => this.leiCountryChange(value)}

                                                        ></SelectBox>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-1">
                                                    <div className="form_rbx">
                                                        {" "}
                                                        <span className="">Status</span>
                                                        <FormControl
                                                            component="fieldset"
                                                            variant="standard"
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                spacing={1}
                                                                alignItems="center"
                                                            >
                                                                <AntSwitch
                                                                    defaultChecked={this.state.checkStatus}
                                                                    inputProps={{
                                                                        "aria-label": "ant design",
                                                                    }}
                                                                    name="active"
                                                                    onClick={(e) => this.onStatusChange(e)}
                                                                />
                                                            </Stack>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 status-btn">
                                                    <div className="status_text">
                                                        {this.state.checkStatus
                                                            ? "Active"
                                                            : "Inactive"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <h3>Additional Contact Info</h3>
                                        <div className="department-form">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span className="">Contact Name</span>
                                                        <InputText
                                                            type="text"
                                                            placeholder=""
                                                            className="in-field2"
                                                            value={this.state.contactName}
                                                            onTextChange={(value) => {
                                                                this.onContactNameChange(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <span className="phoneInputLabel">Contact phone</span>
                                                    {/* <InputText
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.contactPhone}
                                                        onTextChange={(value) => {
                                                            this.onContactPhoneChange(value);
                                                        }}
                                                        /> */}
                                                    <PhoneInput
                                                        country={"us"}
                                                        countryCodeEditable={true}
                                                        value={this.state.contact_mainPhoneInput}
                                                        onChange={this.contactPhoneChange}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="department-form">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span className="">Contact Email</span>
                                                        <InputText
                                                            type="text"
                                                            placeholder=""
                                                            className="in-field2"
                                                            value={this.state.contactEmail}
                                                            onTextChange={(value) => {
                                                                this.onContactEmailChange(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        <span className="">Relationship to LEI</span>
                                                        <InputText
                                                            type="text"
                                                            placeholder=""
                                                            className="in-field2"
                                                            value={this.state.contactRelation}
                                                            onTextChange={(value) => {
                                                                this.onContactRelationChange(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>

                                {/* ..................................................................... */}

                                <div className="_button-style m30 _fl text-center">
                                    <a
                                        href="javascript:void(0)"
                                        className="white-btn"
                                        style={{ textDecoration: "none" }}
                                        onClick={this.onCancel}
                                    >
                                        Back
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        className="blue-btn"
                                        style={{ textDecoration: "none", color: "#fff" }}
                                        onClick={this.onNext}
                                    >
                                        Update
                                    </a>
                                    {this.state.status === 2 ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <a
                                            className="white-btn"
                                            style={{ textDecoration: "none", color: "red" }}
                                            onClick={this.declineModal}
                                        >
                                            Decline
                                        </a>
                                    )}
                                    {this.state.status === 1 ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <a
                                            className="blue-btn"
                                            style={{ textDecoration: "none", color: "#fff", backgroundColor: "#1AAF55" }}
                                            onClick={this.acceptRequest}
                                        >
                                            Accept
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="profile-pic-data">
                                <div className="c-logo">
                                    <img
                                        className="border_50_img"
                                        src={this.state.imagePath}
                                    />
                                    <button className="pht">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={this.onProfileImage}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ..................Decline modal................................. */}
                <div
                    id="decline-model"
                    className="modal fade modelwindow"
                    role="dialog"
                >
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content" style={{ width: "100%" }}>
                            <div className="cancel-job-head">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>
                                            REASON TO <span>DECLINE</span>
                                        </h2>
                                        <button className="close-page">
                                            <img
                                                src={ImageName.IMAGE_NAME.CLOSE_BTN_3}
                                                onClick={this.declineClose}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="model-info f-model">
                                    <div className="form-search-app">
                                        <textarea
                                            placeholder="Reason"
                                            className="in-textarea msg min"
                                            style={{ resize: "none" }}
                                            value={this.state.declineMessage}
                                            onChange={this.declineMessageChange}
                                        ></textarea>
                                        <div className="web-form-bx margin-top-20">
                                            <div className="_button-style _fl text-center">
                                                <a className="white-btn" onClick={this.declineClose}>
                                                    cancel
                                                </a>
                                                <a
                                                    className="blue-btn"
                                                    style={{ color: "#fff" }}
                                                    onClick={this.declineRequest}
                                                >
                                                    submit
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="modal-backdrop fade show"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment>
        );
    }
}

class Schedule extends React.Component {
    render() {
        const { onClick } = this.props;
        return (
            <img style={{ width: "35px", height: "37px", borderRadius: "4px", cursor: "pointer" }} src={ImageName.IMAGE_NAME.CALENDER4} onClick={onClick} />
        );
    }
}
