import React from "react";
import Modal from 'react-bootstrap/Modal';
import { COMMON } from "../../../../../services/constant/connpmData";
import { SelectBox } from "../../../../Admin/SharedComponents/inputText";
import { consoleLog } from "../../../../../services/common-function";
import { toast } from "react-toastify";

export default class RejectTrainingQuoteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            declineOptions: COMMON.DECLINE_QUOTE_BY_CLIENT,
            selectedOption: {},
            reason: "",
            isSubmit: false
        }
    }

    optionChange = (value) => {
        this.setState({
            selectedOption: value
        })
    }

    changeReason = (e) => {
        this.setState({
            reason: e.target.value
        })
    }

    closeModal = () => {
        this.props.closeRejectModal();
    }

    declineSubmit = () => {
        // console.log("Select Option >>> ", this.state.selectedOption)
        let errorCounter = 0;
        if (this.state.selectedOption.value === "") {
            toast.error("Please Select reason");
            errorCounter++;
        } else if (this.state.reason.trim() === "") {
            toast.error("Please enter description");
            errorCounter++;
        }

        if (errorCounter === 0) {
            this.setState({
                isSubmit: true
            })
            let obj = {
                reason: Object.keys(this.state.selectedOption).length > 0 ? this.state.selectedOption.value : "",
                description: this.state.reason
            }
            consoleLog("Submit Decline Reason : ", obj);
            this.props.submitDecline(obj);
            this.closeModal();
            this.setState({
                isSubmit: false,
                selectedOption: {
                    label: "",
                    value: ""
                },
                reason: ""
            })
        }
    }
    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={this.closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    centered
                >
                    <Modal.Header closeButton className="decline_mod_header">
                        <Modal.Title>Decline Quote</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 head_note">
                                <p>We are sorry to see you rejected our quote.</p>
                                <p>Please feel free to share your feedback.</p>
                            </div>
                            <div className="col-md-12 mt_10">
                                <label>Reason For Rejection <span className="imp">*</span></label>
                                <SelectBox
                                    optionData={this.state.declineOptions}
                                    value={this.state.selectedOption}
                                    onSelectChange={(value) =>
                                        this.optionChange(value)
                                    }
                                ></SelectBox>
                            </div>
                            <div className="col-md-12 mt_10">
                                <label>Please Enter Description <span className="imp">*</span></label>
                                <span className="tr_nl">
                                    <textarea
                                        rows="2"
                                        placeholder=""
                                        className="in-textarea msg min"
                                        value={this.state.reason}
                                        style={{
                                            height: "100px",
                                            color: "var(--grey)",
                                            borderRadius: "10px",
                                            boxShadow: "2px",
                                            resize: "none",
                                        }}
                                        onChange={(e) => this.changeReason(e)}
                                    ></textarea>
                                </span>
                            </div>
                            <div className="col-md-12">
                                <div className="_button-style m30 _fl text-center">
                                    <a
                                        href="javascript:void(0)"
                                        className="white-btn"
                                        style={{ textDecoration: "none" }}
                                        onClick={() => this.closeModal()}
                                    >
                                        Back
                                    </a>
                                    {!this.state.isSubmit ?
                                        <a
                                            href="javascript:void(0)"
                                            className="white-btn"
                                            style={{ textDecoration: "none", color: "red" }}
                                            onClick={() => this.declineSubmit()}
                                        >
                                            Decline
                                        </a> :
                                        <a
                                            href="javascript:void(0)"
                                            className="white-btn"
                                            style={{ textDecoration: "none", color: "red" }}
                                        >
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
