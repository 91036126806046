import { Logger } from './logger';
import { User } from './user';
import { Users } from './data/users';
import { parseAttributes } from './util';

const log = Logger.scope('UserDescriptor');

interface UserDescriptorServices {
  users: Users;
}

/**
 * Contains extended user information.
 */
class UserDescriptor {

  private services: UserDescriptorServices;
  private descriptor: any;

  /**
   * User identity.
   */
  public readonly identity: string;

  /**
   * Friendly name of the user, null if not set.
   */
  public readonly friendlyName: string;

  /**
   * Custom attributes of the user.
   */
  public readonly attributes: any;

  /**
   * Status of the real-time conversation connection of the user.
   *
   * Works only when the reachability functionality is enabled.
   * Refer to {@link Client.reachabilityEnabled}.
   */
  public readonly online: boolean;

  /**
   * User push notification registration status.
   *
   * Works only when the reachability functionality is enabled.
   * Refer to {@link Client.reachabilityEnabled}.
   */
  public readonly notifiable: boolean;

  /**
   * @internal
   */
  constructor(services: UserDescriptorServices, descriptor: any) {
    this.services = services;
    this.descriptor = descriptor;

    this.identity = descriptor.identity;
    this.friendlyName = descriptor.friendly_name;
    this.attributes = parseAttributes(descriptor.attributes, 'Failed to parse user attributes', log);
    this.online = descriptor.is_online;
    this.notifiable = descriptor.is_notifiable;
  }

  /**
   * Get the user object from the descriptor and subscribe to it.
   */
  subscribe(): Promise<User> {
    return this.services.users.getUser(this.identity, this.descriptor.sync_unique_name);
  }

  _getDescriptor() {
    return this.descriptor;
  }
}

export {
  UserDescriptor,
  UserDescriptorServices
};
