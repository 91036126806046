import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ApiCall } from "../../../../services/middleware";
import { consoleLog, SetDOBFormat } from "../../../../services/common-function";
import { Decoder } from "../../../../services/auth";
import { ErrorCode } from "../../../../services/constant";
import "../Manage_Reports.css";
import TableDynamicReports from "./TableDynamicReport";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImageName } from "../../../../enums";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FcSearch } from "react-icons/fc";
import { INVOICE_PATH_ONLY } from "../../../../services/config/api_url";

function DynamicReport(props) {
    let { id } = useParams();

    const [key, setKey] = useState("columns");
    const [dynamicData, setDynamicData] = useState({});
    const [listData, setListData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterText, setFilterText] = useState("");
    const [filePath, setFilePath] = useState("");
    useEffect(() => {
        getDynamicData(id);
    }, []);

    async function getDynamicData(id) {
        let payload = {
            id: id
        }
        let res = await ApiCall("reportdetails", payload);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            consoleLog("Report Details>>> ", payload.data);
            if (payload.data.details[0].fieldData !== null) {
                let obj = JSON.parse(payload.data.details[0].fieldData),
                    temp = {},
                    arr = [];
                obj.fields.map((item) => {
                    arr.push(item);
                });

                obj.fields = arr;
                consoleLog("New Dynamic OBJ >>> ", obj);
                setDynamicData(obj);
            }
            setListData(payload.data.list);
            if (payload.data.details[0].filePath !== null) {
                setFilePath(payload.data.details[0].filePath);
            }
        }
    }

    const loaderChange = async (type) => {
        setLoader(type);
    }

    async function changeShowQuestion(e, pos) {
        let dd = dynamicData;
        let data = dd.fields;
        if (e.target.checked) {
            data[pos].display = true;
        } else {
            data[pos].display = false
        }
        dd.fields = data;
        await loaderChange(true);
        setDynamicData(dd);
        await loaderChange(false);
    }

    async function changeFilterCheck(e, pos) {
        let dd = dynamicData;
        let data = dd.fields;
        if (e.target.checked) {
            data[pos].filterCheck = true;
            addFilter(data[pos].name, "");
        } else {
            data[pos].filterCheck = false;
            deleteFilter(data[pos].name);
        }
        dd.fields = data;
        await loaderChange(true);
        setDynamicData(dd);
        await loaderChange(false);
    }

    function renderingTableView(value) {
        return (
            <TableDynamicReports data={value} list={listData} />
        )
    }

    function appointmentdateChange(date) {
        setFilterDate(SetDOBFormat(date))
    }

    function filterTextChange(e, field) {
        console.log(e.target.value);
        setFilterText(e.target.value);
        // let temp = {},
        //     value = e.target.value
        // arr = [];

        // temp[field] = {
        //     op: "=",
        //     val: value
        // }

        // arr.push(temp);
        // consoleLog("fieldsetArray >>> ", arr)
    }

    async function addFilter(name, value) {
        let obj = dynamicData;

        obj.where[name] = {
            op: "=",
            val: value
        }
        await loaderChange(true);
        setDynamicData(obj);
        await loaderChange(false);
    }

    async function deleteFilter(name) {
        let obj = dynamicData;

        for (let key in obj.where) {
            console.log(name)
            if (key === name) {
                delete obj.where[key];
            }
        }
        await loaderChange(true);
        setDynamicData(obj);
        await loaderChange(false);
    }

    function onExport() {
        window.open(INVOICE_PATH_ONLY + filePath, "_blank");
    }
    return (<>
        <div className="component-wrapper">
            <div className="row">
                <div className="col-md-12">
                    <div className="report-top-part">
                        <div
                            className="vn_frm"
                            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                        >
                            {" "}
                            <Link to="/adminDashboard">Dashboard</Link> /
                            <Link to="/ManageReports"> Manage Reports </Link>
                            / View Reports
                        </div>
                        <div className="table-filter-app-b">
                        </div>

                    </div>
                </div>
                {filePath !== "" ?
                    <div className="col-md-12">
                        <div class="table-filter-app-b">
                            <div class="table-filter-box">
                                <div class="export-btn" >
                                    <a href="javascript:void(0)" onClick={() => onExport()}>
                                        Export{" "}
                                        <img
                                            src={ImageName.IMAGE_NAME.EXPORT_BTN}
                                            style={{ width: "25px", cursor: "pointer" }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
                }
                <div className="col-md-3" style={{ display: "none" }}>
                    <div className="col-filter-wrap mg-report">
                        <Tabs
                            defaultActiveKey="columns"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="columns" title="Columns">
                                <div className="Filters-wrap">
                                    <h2>Columns</h2>
                                    <p>Choose the columns to display list view</p>
                                    <ul>{Object.keys(dynamicData).length > 0 ? <>
                                        {dynamicData.fields.map((data, i) => <>
                                            <li key={i}>
                                                <input type="checkbox" id={data.name + "_" + i} name={data.name} value={data.name} defaultChecked={data.display} onChange={(e) => changeShowQuestion(e, i)} />
                                                <label for={data.name + "_" + i}> {data.label}</label>
                                            </li>
                                        </>
                                        )} </> : <></>}
                                    </ul>

                                    <div className="clear-bt-wrap">
                                        <a className="clear-bt white-btn">Cancel</a>
                                        <a className="apply-bt blue-btn">Save</a>
                                        <a className="reset-bt blue-btn">Reset to default</a>

                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="filter" title="Filter">
                                <div className="Filters-wrap">
                                    <h2>Filters</h2>
                                    <ul>
                                        {Object.keys(dynamicData).length > 0 ? <>
                                            {dynamicData.fields.map((data, i) => <>
                                                <li>
                                                    <input
                                                        type="checkbox"
                                                        id={data.name + "_" + i}
                                                        name={data.name}
                                                        value={data.name}
                                                        defaultChecked={data.filterCheck}
                                                        onChange={(e) => changeFilterCheck(e, i)} />
                                                    <label for={data.name + "_" + i}> {data.label}</label>
                                                </li>
                                                {data.filterCheck ? <>
                                                    {data.type === 'dropdown' ? <>
                                                        <Form.Select aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            {data.values.map((op, j) =>
                                                                <option value={op} key={j}>{op}</option>
                                                            )}
                                                        </Form.Select><br />
                                                    </> : <>
                                                        {data.type === 'text' ? <>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id={data.name + "_text_" + i}
                                                                    aria-describedby={data.name + "_text_" + i}
                                                                    onChange={(e) => filterTextChange(e)}
                                                                />
                                                                <Button variant="outline-secondary" id="button-addon2">
                                                                    <FcSearch size={30} />
                                                                </Button>
                                                            </InputGroup>

                                                            <br />
                                                        </> : <>
                                                            {data.type === 'datepicker' ? <>

                                                                <div className="frm-label">
                                                                    {data.label}
                                                                </div>

                                                                <div
                                                                    className="input-group"
                                                                    style={{
                                                                        width: "100%",
                                                                        borderRadius: "9px",
                                                                        height: "43px",
                                                                        border: "1px solid #ced4da",
                                                                        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                                    }}
                                                                >
                                                                    <div style={{ width: "80%", padding: "8px" }}>
                                                                        <span>{filterDate}</span>
                                                                    </div>
                                                                    <div style={{ width: "20%" }}>
                                                                        <a style={{ float: "right" }}>
                                                                            <DatePicker
                                                                                dropdownMode="select"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                adjustDateOnChange
                                                                                minDate={new Date()}
                                                                                onChange={(date) => appointmentdateChange(date)
                                                                                }
                                                                                customInput={<Schedule />}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </> : <></>}
                                                        </>}
                                                    </>}
                                                </> : <></>}
                                            </>)} </> : <></>
                                        }
                                    </ul>

                                    <div className="clear-bt-wrap">
                                        <a className="clear-bt white-btn">Clear</a>
                                        <a className="apply-bt blue-btn">Apply</a>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="manage-reports-container">
                        <div className="table-listing-app">
                            <div className="table-responsive">
                                {loader ? null :
                                    <React.Fragment>
                                        {renderingTableView(dynamicData)}</React.Fragment>}

                                {/* <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        {Object.keys(dynamicData).length > 0 ? <>
                                            {dynamicData.fields.map((data, i) =>
                                                <>
                                                    {consoleLog("Table Data Display >>> ", data.display)}
                                                    <th key={i} hidden={!data.display}>
                                                        <span style={{ fontSize: "13px" }}>{data.label}</span>
                                                    </th>
                                                </>
                                            )} </> : <></>
                                        }
                                    </tr>
                                    <tr>
                                        <td colspan="10">
                                            <div className="tble-row">
                                                <table
                                                    width="100%"
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                >
                                                    <tr>
                                                        <td style={{ width: "20%" }}>123</td>
                                                        <td style={{ width: "20%" }}>Report 1</td>

                                                        <td style={{ width: "20%" }}>Dummy Report Type</td>
                                                        <td style={{ width: "20%" }}>Dummy Data</td>
                                                        <td style={{ width: "10%" }}>Last 6 Months</td>
                                                        <td style={{ width: "10%" }}></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>

                                </table> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default DynamicReport;

function Schedule(props) {
    const { onClick } = props;
    return (
        <img
            style={{
                width: "35px",
                height: "37px",
                borderRadius: "4px",
                cursor: "pointer",
            }}
            src={ImageName.IMAGE_NAME.CALENDER4}
            onClick={onClick}
        />
    );
}