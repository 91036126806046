import { useState } from "react";
import Routes from "../Router/Routes";
import '../loader.js';
import { Decoder } from "../services/auth/index.js";
import { LOCAL_HOST, SOCKET_MUSIC, SOCKET_PATH } from "../services/config/api_url.js";
import { socket } from "../services/config/socket.js";
import Modal from 'react-bootstrap/Modal';
import { ApiCall } from "../services/middleware/index.js";
import { ErrorCode } from "../services/constant/index.js";


function App() {
  const [show, setShow] = useState(false);
  const [callJobId, setCallJobId] = useState("");
  const [audioSrc, setAudioSrc] = useState(SOCKET_MUSIC);
  socket.connect(SOCKET_PATH);

  // console.log(">>>>>>>socket> App JS >>>>>>>>>>>>", socket);

  // console.log(">>>>>>>>>>> Socket path Only >>>>>>>>>>>>>>>>>>>> ", SOCKET_PATH)
  let authData = localStorage.getItem("AuthToken");
  let authUser = Decoder.decode(authData);
  // console.log("Authuser >>>> ", authUser);
  let userId = "";
  if (authUser !== undefined) {
    userId = authUser.data.userid;
    // console.log(`ondemand/inCalBuzz/${userId}`)
    socket.on(`ondemand/inCalBuzz/${userId}`, (data) => {
      // console.log(" >>>>>>> Call data >>>>>>> ", data);
      setCallJobId(data.details.id);
      setShow(true);
      var audio = document.getElementById("myAudio");
      if (audio !== null) {
        audio.play();
      } else {
        console.error("Audio element not found or is null.");
      }
    });
  }

  function openModal() {
    setShow(true);
  }

  function closeModal() {
    setShow(false);
    var audio = document.getElementById("myAudio");
    audio.pause();
  }

  async function joinCall(data) {
    // console.log("join Call Data >>> ", data)
    let detailData = {
      requestId: data
    };
    let detailsRes = await ApiCall("getJobDetails", detailData);
    if (
      detailsRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      detailsRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let detailPayload = Decoder.decode(detailsRes.data.payload);
      let jobDetails = detailPayload.data.jobDetails;
      try {
        joinVideo(jobDetails.appointmentType, jobDetails.requestId, detailPayload.data);
        setShow(false);
        var audio = document.getElementById("myAudio");
        audio.pause();
      } catch (e) {
        console.log(e);
      }
    }
  }

  function joinVideo(appointmentType, jobId, jobData) {
    // consoleLog("AppointmentType: ", appointmentType);
    // consoleLog("Job Type::", jobId);
    localStorage.setItem("All_job_data", JSON.stringify(jobData));
    window.open(LOCAL_HOST + `/servicePage/${appointmentType}/${jobId}`);
  }

  return (
    <div>
      <Routes />
      <Modal show={show} onHide={() => closeModal()} dialogClassName="call_mod">
        <Modal.Header closeButton>
          <Modal.Title className="hdng">CALL COMING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 pt_15_iam hdng" >
              Are you available for attend this call ?
            </div>
            <div className="col-md-12 pt_15_iam" >
              <button className="join_btn" onClick={() => joinCall(callJobId)}>JOIN</button>
              <audio autoplay loop id="myAudio">
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
