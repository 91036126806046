import { Logger } from './logger';
import { SyncClient } from 'twilio-sync';
import { parseAttributes } from './util';
import { validateTypesAsync, literal } from '@twilio/declarative-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { EditUserRequest, EditUserResponse } from './interfaces/commands/edituser';
import isEqual from 'lodash.isequal';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type UserEvents = {
  updated: (data: {
    user: User,
    updateReasons: UserUpdateReason[]
  }) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
};

const log = Logger.scope('User');

interface UserState {
  identity: string;
  entityName: string;
  friendlyName: string;
  attributes: any;
  online: boolean;
  notifiable: boolean;
}

interface UserServices {
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

interface UserLinks {
  self: string;
}

type SubscriptionState =
  | 'initializing'
  | 'subscribed'
  | 'unsubscribed';

/**
 * The reason for the `updated` event being emitted by a user.
 */
type UserUpdateReason =
  | 'friendlyName'
  | 'attributes'
  | 'online'
  | 'notifiable';

interface UserUpdatedEventArgs {
  user: User;
  updateReasons: UserUpdateReason[];
}

/**
 * Extended user information.
 * Note that `isOnline` and `isNotifiable` properties are eligible
 * for use only if the reachability function is enabled.
 * You may check if it is enabled by reading the value of {@link Client.reachabilityEnabled}.
 */
class User extends ReplayEventEmitter<UserEvents> {

  private entity: any;
  private state: UserState;
  private promiseToFetch: Promise<User> | null = null;
  private subscribed: SubscriptionState;

  private links: UserLinks;
  private configuration: Configuration;
  private readonly services: UserServices;

  private _initializationPromise: Promise<void>;
  private _resolveInitializationPromise: any;

  /**
   * @internal
   */
  constructor(
    identity: string,
    entityName: string,
    configuration: Configuration | null,
    services: UserServices
  ) {
    super();

    this.services = services;

    this.subscribed = 'initializing';
    this.setMaxListeners(0);

    this.state = {
      identity,
      entityName,
      friendlyName: null,
      attributes: {},
      online: null,
      notifiable: null
    };

    this._initializationPromise = new Promise((resolve) => {
      this._resolveInitializationPromise = resolve;
    });

    if (configuration !== null) {
      this._resolveInitialization(
        configuration,
        identity,
        entityName,
        false
      );
    }
  }

  /**
   * Fired when the properties or the reachability status of the message have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link User} `user` - the user in question
   *     * {@link UserUpdateReason}[] `updateReasons` - array of reasons for the update
   * @event
   */
  public readonly updated = 'updated';

  /**
   * Fired when the client has subscribed to the user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public readonly userSubscribed = 'userSubscribed';

  /**
   * Fired when the client has unsubscribed from the user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public readonly userUnsubscribed = 'userUnsubscribed';

  /**
   * User identity.
   */
  public get identity(): string { return this.state.identity; }

  public set identity(identity: string) { this.state.identity = identity; }

  public set entityName(name: string) { this.state.entityName = name; }

  /**
   * Custom attributes of the user.
   */
  public get attributes() { return this.state.attributes; }

  /**
   * Friendly name of the user, null if not set.
   */
  public get friendlyName(): string { return this.state.friendlyName; }

  /**
   * Status of the real-time conversation connection of the user.
   */
  public get online(): boolean { return this.state.online; }

  /**
   * User push notification registration status.
   */
  public get notifiable(): boolean { return this.state.notifiable; }

  /**
   * True if this user is receiving real-time status updates.
   */
  public get isSubscribed(): boolean { return this.subscribed == 'subscribed'; }

  // Handles service updates
  private async _update(key: string, value: any) {
    await this._initializationPromise;

    let updateReasons: UserUpdateReason[] = [];
    log.debug('User for', this.state.identity, 'updated:', key, value);
    switch (key) {
      case 'friendlyName':
        if (this.state.friendlyName !== value.value) {
          updateReasons.push('friendlyName');
          this.state.friendlyName = value.value;
        }
        break;
      case 'attributes':
        const updateAttributes = parseAttributes(value.value, `Retrieved malformed attributes from the server for user: ${this.state.identity}`, log);
        if (!isEqual(this.state.attributes, updateAttributes)) {
          this.state.attributes = updateAttributes;
          updateReasons.push('attributes');
        }
        break;
      case 'reachability':
        if (this.state.online !== value.online) {
          this.state.online = value.online;
          updateReasons.push('online');
        }
        if (this.state.notifiable !== value.notifiable) {
          this.state.notifiable = value.notifiable;
          updateReasons.push('notifiable');
        }
        break;
      default:
        return;
    }
    if (updateReasons.length > 0) {
      this.emit('updated', { user: this, updateReasons: updateReasons });
    }
  }

  // Fetch reachability info
  private async _updateReachabilityInfo(map, update): Promise<void> {
    await this._initializationPromise;

    if (!this.configuration.reachabilityEnabled) {
      return;
    }

    return map.get('reachability')
      .then(update)
      .catch(err => { log.warn('Failed to get reachability info for ', this.state.identity, err); });
  }

  // Fetch user
  private async _fetch(): Promise<User> {
    await this._initializationPromise;

    if (!this.state.entityName) {
      return this;
    }

    this.promiseToFetch = this.services.syncClient.map({ id: this.state.entityName, mode: 'open_existing', includeItems: true })
                              .then(map => {
                                this.entity = map;
                                map.on('itemUpdated', args => {
                                  log.debug(`${this.state.entityName} (${this.state.identity}) itemUpdated: ${args.item.key}`);
                                  return this._update(args.item.key, args.item.data);
                                });
                                return Promise.all([
                                  map.get('friendlyName')
                                     .then(item => this._update(item.key, item.data)),
                                  map.get('attributes')
                                     .then(item => this._update(item.key, item.data)),
                                  this._updateReachabilityInfo(map,
                                    item => this._update(item.key, item.data))
                                ]);
                              })
                              .then(() => {
                                log.debug('Fetched for', this.identity);
                                this.subscribed = 'subscribed';
                                this.emit('userSubscribed', this);
                                return this;
                              })
                              .catch(err => {
                                this.promiseToFetch = null;
                                throw err;
                              });
    return this.promiseToFetch;
  }

  // Not private because it is accessed from Client constructor.
  async _ensureFetched(): Promise<User> {
    await this._initializationPromise;
    return this.promiseToFetch || this._fetch();
  }

  /**
   * Edit user attributes.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<User> {
    await this._initializationPromise;
    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.commandExecutor.mutateResource<EditUserRequest, EditUserResponse>(
      'post',
      this.links.self,
      {
        attributes: JSON.stringify(attributes)
      }
    );

    return this;
  }

  /**
   * Update the friendly name of the user.
   * @param friendlyName New friendly name.
   */
  @validateTypesAsync('string')
  public async updateFriendlyName(friendlyName): Promise<User> {
    await this._initializationPromise;

    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.commandExecutor.mutateResource<EditUserRequest, EditUserResponse>(
      'post',
      this.links.self,
      {
        friendly_name: friendlyName
      }
    );

    return this;
  }

  /**
   * Remove the user from the subscription list.
   * @return A promise of completion.
   */
  public async unsubscribe(): Promise<void> {
    await this._initializationPromise;

    if (this.promiseToFetch) {
      await this.promiseToFetch;
      this.entity.close();
      this.promiseToFetch = null;
      this.subscribed = 'unsubscribed';
      this.emit('userUnsubscribed', this);
    }
  }

  public _resolveInitialization(
    configuration: Configuration,
    identity: string,
    entityName: string,
    emitUpdated: boolean
  ): void {
    this.configuration = configuration;
    this.identity = identity;
    this.entityName = entityName;
    this.links = {
      self: `${this.configuration.links.users}/${this.identity}`
    };
    this._resolveInitializationPromise();

    if (emitUpdated) {
      this.emit('updated', {
        user: this,
        updateReasons: [
          'friendlyName',
          'attributes',
          'online',
          'notifiable'
        ]
      });
    }
  }
}

export {
  User,
  UserServices,
  SubscriptionState,
  UserUpdateReason,
  UserUpdatedEventArgs
};
