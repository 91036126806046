import { TransportResult as Result, Transport } from 'twilsock';
import { MutationConflictResponse } from './interfaces/commands/mutationconflict';
import { v4 as uuidv4 } from 'uuid';
import { AsyncRetrier } from '@twilio/operation-retrier';

export interface CommandExecutorServices {
  transport: Transport;
}

const trimSlashes = (url: string): string =>
  url.replace(/(^\/+|\/+$)/g, '');

const isMutationConflictResponse = (response: Result<unknown>): response is Result<MutationConflictResponse> =>
  response.status.code === 202;

class CommandExecutor {
  constructor(
    private _serviceUrl: string,
    private _services: CommandExecutorServices,
    private _productId?: string
  ) {}

  private _preProcessUrl(url: string): string {
    const trimmedUrl = trimSlashes(url);

    if (/^https?:\/\//.test(url)) {
      return trimmedUrl;
    }

    return `${trimSlashes(this._serviceUrl)}/${trimmedUrl}`;
  }

  private async _makeRequest<REQ = void, RESP = void>(
    method: 'get' | 'post' | 'delete',
    url: string,
    requestBody?: REQ,
    headers?: Record<string, string>
  ): Promise<Result<RESP>> {
    const preProcessedUrl = this._preProcessUrl(url);
    const finalHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      ...(headers || {})
    };
    let response: Result<RESP>;

    switch (method) {
      case 'get':
        let getUrl = preProcessedUrl;

        if (requestBody) {
          getUrl +=
            '?' +
            Object.entries(requestBody)
              .map((entry) => entry.map(encodeURIComponent).join('='))
              .join('&');
        }

        response = await this._services.transport.get(getUrl, finalHeaders, this._productId);
        break;
      case 'post':
        response = await this._services.transport.post(preProcessedUrl, finalHeaders, JSON.stringify(requestBody), this._productId);
        break;
      case 'delete':
        response = await this._services.transport.delete(preProcessedUrl, finalHeaders, null, this._productId);
        break;
    }

    if (response.status.code < 200 || response.status.code >= 300) {
      throw new Error(`Request responded with a non-success code ${response.status.code}`);
    }

    return response;
  }

  public async fetchResource<REQ = void, RESP = void>(
    url: string,
    requestBody?: REQ
  ): Promise<RESP> {
    const maxAttemptsCount = 6;
    let result: Result<RESP>;

    try {
      result = await new AsyncRetrier({ min: 50, max: 1600, maxAttemptsCount })
        .run(() => this._makeRequest<REQ, RESP>('get', url, requestBody));
    } catch {
      throw new Error(`Fetch resource from "${url}" failed.`);
    }

    return result.body;
  }

  public async mutateResource<REQ = void, RESP = void>(
    method: 'post' | 'delete',
    url: string,
    requestBody?: REQ
  ): Promise<RESP> {
    const result = await this._makeRequest<REQ, RESP>(method, url, requestBody, {
      'X-Twilio-Mutation-Id': uuidv4()
    });

    if (isMutationConflictResponse(result)) {
      return await this.fetchResource<undefined, RESP>(result.body.resource_url);
    }

    return result.body;
  }
}

export { CommandExecutor };
