import React from "react";
import "./viewTrainingDetails.css";

import { AlertMessage, ImageName } from "../../../../enums";
import { ApiCall } from "../../../../services/middleware";
import { Decoder } from "../../../../services/auth";
import { ErrorCode, UsersEnums } from "../../../../services/constant";
import { toast, ToastContainer } from "react-toastify";
import {
  inputEmptyValidate,
  zipValidate,
} from "../../../../validators";
import { Regex } from "../../../../services/config";
import history from "../../../../history";
import {
  consoleLog,
  SetDateFormat,
  SetDOBFormat,
  SetTimeFormat,
  SetUSAdateFormatV2,
} from "../../../../services/common-function";
import ReactLoader from "../../../Loader";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { IMAGE_PATH_ONLY } from "../../../../services/config/api_url";
import EditTrainingDetails from "./EditTrainingDetails";
import AvailableVendorListing from "./VendorListingTraining";
import { TiDelete } from "react-icons/ti";
import MainChatPage from "../../../ChatPages/Mainpage";
import { Stepper } from 'react-form-stepper';
import Modal from 'react-bootstrap/Modal';
import ChatGroupList from "../../Manage_Interpretation_jobs/JobDetails/ChatGroupList";
import { InputText, MultiSelectBox, SelectBox } from "../../SharedComponents/inputText";
import Button from '@mui/material/Button';
import moment from "moment";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { RiMailSendLine } from "react-icons/ri";
import { MdOutlineSms } from "react-icons/md";
import EmailModal from "../../Manage_Interpretation_jobs/EmailModal/EmailModal";
import SmsModal from "../../Manage_Interpretation_jobs/SmsModal/SmsModal";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
// .............................................................

export default class ViewTrainingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      isVendorLoad: true,
      requestId: "",
      clientId: "",
      clientFirstName: "",
      clientLastName: "",
      clientName: "",
      clientEmailId: "",
      clientPhone: "",
      clientIndustryType: "",
      //   .................jobDetails...........
      jobId: "",
      trainingFormat: "",
      trainingCategory: "",
      trainingCourse: "",
      budget: "",
      date: "",
      scheduleTime: "",
      serviceAddress: "",
      siteContant: "",
      consumer: "",
      notesByClient: "",
      notesBy7C: "",
      serviceProvider: "",

      // ................sendQuote............
      quoteId: "",
      quoteStatus: 0,
      targetAdience: "",
      location: "",
      dateTime: "",
      notesFromClient: "",
      deliveryType: "",
      duration: "",
      rushFee: 0,
      interpretationFee: 0,
      particularsArr: [],
      particular: "",
      particularAmount: [],
      totalParticularAmt: 0,
      particularAmt: 0,
      particularDataArr: [],
      particularAmountDataArr: [],
      totalAmount: 0,

      appointmentTypeArr: [],
      appointmentTypeData: [],
      languageArr: [],

      sourceLangData: {},
      targetLangData: {},
      listData: [],
      translationUnitCost: "",
      dtpUnitCost: "",
      translationRate: "",
      countryCode: "",

      allChecked: false,
      vendorAssigned: [],
      bidFee: 0,
      totalBidFee: 0,
      targetLanguage: "",
      bidFee: 0,
      totalBidFee: 0,
      bidVendorName: "",
      bidVendorId: "",

      completeSummary: {},
      mislleniousExpenses: [],
      miscTotal: 0.0,

      reqId: "",
      payable: [],
      receivable: [],
      pathCheck: false,

      alreadyOfferSent: [],
      isRushFee: false,
      quoteRemarks: "",
      assignedVendor: "",
      allJobNotes: [],
      notesText: "",
      progressLogData: [],
      addParticipantShow: false,
      chatParticipants: [],
      selectedChatParticipants: [],
      chatGroup: [],
      selectedChatGroupValue: '',
      allJobNotifications: [],
      chatGroupName: "",
      projectCancelReason: "",
      allEmailSmsLog: [],
      viewEmailModal: false,
      viewEmailModalData: {},
      sentEmailModal: false,
      sentSmsModal: false,
      loader: false,
      userType: 0,
      numJobId: 0,
      quoteRejectReason: "",
      quoteRejectDescription: "",
      viewNoteModal: false,
      viewNote: "",

      rejectModal: false,
      rejectReason: "",
      selectedRejectData: {},
      updatedAmount: "",
      updateDetails: false,
      updateBillId: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",
      loader: false,
      addGroupChatParticipant: [],
      selectedAddGroupChatParticipantValue: [],
      chatParticipantModal: false,
      groupChatParticipant: [],
      showTab: "project"
    };
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("backdrop").style.display = "none";
    let mainData = this.props.location,
      preData = mainData.state;
    if (mainData.from !== undefined) {
      if (mainData.from != 1) {
        this.setState({
          showTab: mainData.from
        })
      }
    } else {
      this.setState({
        showTab: "project"
      })
    }
    // consoleLog("preData:", preData);
    if (preData === undefined) {
      return history.push("/adminTrainingList");
    } else {
      this.setState({
        reqId: preData,
      });
      this.load();
    }
    // this.load();
    if (this.props.match.path === "/adminTrainingDetailsFromBillVerification") {
      this.setState({
        pathCheck: true,
      });
    } else {
      this.setState({
        pathCheck: false,
      });
    }


    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);

    this.setState({
      userType: authUser.data.usertypeid
    });

    var classInstance = this;

    var viewModal = document.getElementById("viewModal");
    var bidModal = document.getElementById("bid-modal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == viewModal) {
        classInstance.closeViewModal();
      } else if (event.target == bidModal) {
        classInstance.closeBidModal();
      }
    };
  }

  load = async () => {
    let mainData = this.props.location,
      preData = mainData.state;
    this.setState({
      requestId: preData,
    });
    let detailData = {
      requestId: preData,
    };

    let clientDetails = {},
      jobDetails = {},
      quoteInfo = {},
      quoteId = "N/A",
      particularsArr = [],
      totalAmount = 0,
      rushFee = 0,
      trainingFee = 0,
      completeSummary = {},
      mislleniousExpenses = [],
      miscTotal = 0.0,
      payable = [],
      receivable = [],
      quoteRemarks = "",
      isRushFee = false,
      quoteRejectReason = "",
      quoteRejectDescription = "";;


    //................ For all Vendor.................
    //Change on 16/09/2022 by skh
    // let res = await ApiCall("getVendorsWorkingStatusTraining", detailData);
    this.getAvailableVendorList(detailData);
    // ................Job details.................

    let detailsRes = await ApiCall("getJobDetails", detailData);
    if (
      detailsRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      detailsRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let detailPayload = Decoder.decode(detailsRes.data.payload);
      consoleLog("Project Details Training>>>", detailPayload.data)
      if (detailPayload.data.clientDetails) {
        clientDetails = detailPayload.data.clientDetails;
      }
      if (detailPayload.data.jobDetails) {
        jobDetails = detailPayload.data.jobDetails;
        this.fetchProgressData(jobDetails.id);
        this.fetchJobNotification(jobDetails.id);
        this.fetchNotes(jobDetails.id);
        this.fetchChatParticipants(jobDetails.id);
        await this.fetchGroupChatParticipants(jobDetails.id);
        this.fetchChatGroup(jobDetails.id);
        this.fetchEmailSmsLog(jobDetails.id);
      }
      if (Object.keys(detailPayload.data.quoteInfo).length > 0) {
        quoteInfo = detailPayload.data.quoteInfo;
        quoteId = detailPayload.data.quoteInfo.quoteId;
        totalAmount = detailPayload.data.quoteInfo.total;
        rushFee = detailPayload.data.quoteInfo.rushFee;
        trainingFee = detailPayload.data.quoteInfo.fee;
        particularsArr = JSON.parse(detailPayload.data.quoteInfo.additionalFee);
        isRushFee = Number(detailPayload.data.quoteInfo.rushFee) > 0 ? true : false;

        quoteRemarks = detailPayload.data.quoteInfo.remarks;
        quoteRejectReason = quoteInfo.declinedType ? quoteInfo.declinedType : "";
        quoteRejectDescription = quoteInfo.declinedReason ? quoteInfo.declinedReason : "";
      }

      if (jobDetails.quoteStatus === 10) {
        //...............Get complete summury............

        // let summuryRes = await ApiCall("getJobCompleteSummary", { requestId: 20 });
        let summuryRes = await ApiCall("getJobCompleteSummary", detailData);
        if (
          summuryRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
          summuryRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
          let payload = Decoder.decode(summuryRes.data.payload);
          // consoleLog("Conmplete Summry for training::", payload.data);
          if (payload.data.jobSummary) {
            completeSummary = payload.data.jobSummary;
          }
          if (payload.data.miscJobExpense.length > 0) {
            mislleniousExpenses = payload.data.miscJobExpense;
            payload.data.miscJobExpense.map((data) => {
              miscTotal = parseFloat(miscTotal) + parseFloat(data.amount);
            });
          }
        }
        //...............Get Payable and Receivable Data............

        let payableRes = await ApiCall("fetchPayableTraining", detailData);
        if (
          payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
          payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
          let payload = Decoder.decode(payableRes.data.payload);
          // consoleLog("Payable List:::", payload.data);
          if (payload.data.details.length > 0) {
            payable = payload.data.details;
          }
        }

        let receivableRes = await ApiCall("fetchReceivabaleTraining", detailData);
        if (
          receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
          receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
          let payload = Decoder.decode(receivableRes.data.payload);
          // consoleLog("Receivable List:::", payload.data);
          if (payload.data.details.length > 0) {
            receivable = payload.data.details;
          }
        }
      }

      // ...........................................

      this.setState({
        //.............Client Details Set............
        clientId: jobDetails.clientId,
        clientFirstName: clientDetails.fName,
        clientLastName: clientDetails.lName,
        clientEmailId: clientDetails.businessEmail,
        clientName: clientDetails.clientName,
        clientIndustryType: clientDetails.industtryType,
        clientPhone: clientDetails.adminPhone,
        //..............Job Details Set..............
        quoteStatus: jobDetails.quoteStatus,
        jobId: jobDetails.jobId,
        numJobId: jobDetails.id,
        trainingFormat: jobDetails.trainingFormat,
        trainingCategory: jobDetails.trainingCategory,
        trainingCourse: jobDetails.trainingCourse,
        budget: jobDetails.budget,
        date: jobDetails.scheduleDate,
        scheduleTime: jobDetails.scheduleTime,
        countryCode: clientDetails.adminCountryCode,
        serviceAddress:
          jobDetails.location === null ||
            jobDetails.location === undefined ||
            jobDetails.location === ""
            ? "N/A"
            : jobDetails.location,
        siteContant:
          jobDetails.siteContact === null ||
            jobDetails.siteContact === undefined ||
            jobDetails.siteContact === ""
            ? "N/A"
            : jobDetails.siteContact,
        consumer: jobDetails.consumer,
        serviceProvider: jobDetails.noOfserviceProvider,
        notesByClient: jobDetails.noteByClient,
        notesBy7C: jobDetails.noteFor7C,
        quoteId: quoteId,
        targetAdience:
          jobDetails.targetAudience === null ||
            jobDetails.targetAudience === undefined ||
            jobDetails.targetAudience === ""
            ? "N/A"
            : jobDetails.targetAudience,
        location:
          jobDetails.location === null ||
            jobDetails.location === undefined ||
            jobDetails.location === ""
            ? "N/A"
            : jobDetails.location,
        dateTime: jobDetails.scheduleDate,
        notesFromClient: jobDetails.noteByClient,
        deliveryType:
          clientDetails.isOutOfTown === 1 ? "OUT OF TOWN" : "IN TOWN",
        duration: jobDetails.duration,
        particularsArr: particularsArr,
        totalAmount: totalAmount,
        rushFee: rushFee,
        interpretationFee: trainingFee,
        clientIndustryType: clientDetails.industtryType,
        completeSummary: completeSummary,
        mislleniousExpenses: mislleniousExpenses,
        miscTotal: miscTotal,
        payable: payable,
        receivable: receivable,
        isRushFee: isRushFee,
        quoteRemarks: quoteRemarks,
        assignedVendor: jobDetails.vendorName,
        projectCancelReason: jobDetails.rejectReason ? jobDetails.rejectReason : "",
        quoteRejectReason: quoteRejectReason,
        quoteRejectDescription: quoteRejectDescription,
        isLoad: false,
      });
    }
  };

  fetchProgressData = async (id) => {
    let obj = {
      jobId: id
    }
    let res = await ApiCall("fetchProgressLog", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Progress Log Data  >>>>>", payload.data);
      if (payload.data.progressData.length > 0) {
        let arr = [],
          notiArr = [];
        payload.data.progressData.map((item) => {
          arr.push({
            label: (
              <div>
                <span>{item.progressType}</span><br />
                <span>{SetDOBFormat(item.createDate)}&nbsp;{SetTimeFormat(item.createDate)}</span>
              </div>
            )
          })
          notiArr.push({
            body: item.progressType,
            time: moment(item.createDate).fromNow()
          })
        })
        // let newNotiArr = notiArr.reverse();
        this.setState({
          progressLogData: arr,
          // allJobNotifications: newNotiArr
        })
      } else {
        this.setState({
          progressLogData: [],
          // allJobNotifications: []
        })
      }
    }

  }

  /* Fetch job notification */
  fetchJobNotification = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getUserNotificationByJobid", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Job Notification Data  >>>>>", payload.data);
      if (payload.data.length > 0) {
        let arr = [];
        arr = payload.data;
        this.setState({
          allJobNotifications: arr
        })
      } else {
        this.setState({
          allJobNotifications: []
        })
      }
    }
  }

  getAvailableVendorList = async (reqData) => {
    this.setState({
      isVendorLoad: true
    })
    let res = await ApiCall("getAvailableVendors", reqData);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("All Vendor List for training::", payload.data)
      let arr = payload.data.vendorList,
        brr = [],
        alreadyOfferSent = [],
        scount = 0,
        totalPage = Math.ceil(payload.data.length / this.state.limit),
        vendorList = [];

      arr.map((list) => {
        let item = list.vendorlist;
        let tCategory = [],
          tCourse = [];
        if (item.trainingCategory !== undefined) {
          item.trainingCategory.map((tc) => {
            tCategory.push(tc.trainingCategory);
            tc.courseList.map((tcr) => {
              tCourse.push(tcr);
            })
          })
        }
        vendorList.push({
          userId: item.vendorid,
          fName: item.fName,
          lName: item.lName,
          ratings: item.ratings,
          agencyName: item.agencyName,
          email: item.email,
          countryCode: item.countryCode,
          mobile: item.mobile,
          isQuoteSent: item.isQuoteSent,
          qstatus: item.qstatus,
          trainingCategory: tCategory.join(","),
          trainingCourse: tCourse.join(","),
          bidFee: item.bidFee,
          totalBidFee: item.totalBidFee,
          rejectReason: item.rejectReason
        })
      })

      vendorList.map((aa) => {
        if (aa.isQuoteSent === 1) {
          brr.push(aa.userId);
          alreadyOfferSent.push(aa.userId);
        }
        if (aa.status === 2) {
          scount++;
        }
      });
      if (scount > 0) {
        this.setState({
          isVendorAssigned: true,
        });
      }

      this.setState({
        listData: vendorList,
        total_page: totalPage,
        vendorAssigned: brr,
        alreadyOfferSent: alreadyOfferSent,
        isVendorLoad: false
      });
    }
  }

  // listApi = async (data) => {
  //   const res = await ApiCall("fetchapprovedclientcontactlist", data);
  //   console.log("resData::::", res);
  //   if (
  //     res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
  //     res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  //   ) {
  //     const decodeData = Decoder.decode(res.data.payload);
  //     console.log("Payload data>>>", decodeData);
  //     let listDetails = decodeData.data.clientContactDetailsList;
  //     let totalPage = Math.ceil(decodeData.data.totalCount / this.state.limit);
  //     console.log("Total Page>>>", listDetails);
  //     this.setState({
  //       listData: decodeData.data.clientContactDetailsList,
  //       total_page: totalPage,
  //     });
  //   }
  // };

  onTranslationUnitCostChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationUnitCost: val,
      });
    }
  };
  onTranslationRateChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationRate: val,
      });
    }
  };

  onAppointmentChange = (data) => {
    this.setState({
      appointmentTypeData: data,
    });
  };

  onSourceLangChange = (data) => {
    this.setState({
      sourceLangData: data,
    });
  };

  onTargetLangChange = (data) => {
    this.setState({
      targetLangData: data,
    });
  };
  onParticularChange = (e, index) => {
    let amt = this.state.particularsArr,
      particularData = [],
      particularDataArr = [];
    for (let i = 0; i < this.state.particularsArr.length; i++) {
      if (i === index) {
        amt[i].title = e.target.value;
        particularDataArr.push(amt[i].title);
      }
      particularData.push(amt[i].title);
    }

    this.setState({
      particularsArr: amt,
      particularDataArr: particularData,
    });
  };

  onParticularAmountChange = (e, index) => {
    let data = e.target.value,
      particularAmtDataArr = [];
    var valid = !isNaN(data);

    let amount = e.target.value === "" ? 0 : parseFloat(e.target.value);

    let amt = this.state.particularsArr,
      rushfee = this.state.rushFee,
      interpretationFee = this.state.interpretationFee,
      totalAmt = 0,
      sum = 0;
    if (valid) {
      for (let i = 0; i < amt.length; i++) {
        if (i === index) {
          amt[i].amt = amount.toString();
          particularAmtDataArr.push(amt[i].amt);
        }

        sum = sum + parseFloat(amt[i].amt);
      }
      totalAmt = sum + parseFloat(rushfee) + parseFloat(interpretationFee);
    }

    this.setState({
      particularsArr: amt,
      totalParticularAmt: sum,
      totalAmount: totalAmt,
      // particularAmountDataArr: particularAmtDataArr,
    });
  };
  onRushFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) +
        parseFloat(this.state.interpretationFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        rushFee: parseFloat(e.target.value),
        totalAmount: totalAmt,
      });
    } else {
      totalAmt =
        totalAmt +
        parseFloat(this.state.interpretationFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        rushFee: 0,
        totalAmount: totalAmt,
      });
    }
  };
  onInterpretationFeeChange = (e) => {
    let totalAmt = 0;

    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      totalAmt =
        totalAmt +
        parseFloat(e.target.value) +
        parseFloat(this.state.rushFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        interpretationFee: parseFloat(e.target.value),
        totalAmount: totalAmt,
      });
    } else {
      totalAmt =
        totalAmt +
        parseFloat(this.state.rushFee) +
        parseFloat(this.state.totalParticularAmt);
      this.setState({
        interpretationFee: 0,
        totalAmount: totalAmt,
      });
    }
  };
  addParticularField = () => {
    let arr = this.state.particularsArr;
    // particularItem = "",
    // particularAmnt = 0;
    // for (let i = 0; i < this.state.particularsArr.length; i++) {
    arr.push({
      title: this.state.particular,
      // particularAmt: parseInt(this.state.particularAmt),
      amt: this.state.particularAmt,
    });

    this.setState({
      particularsArr: arr,
    });
  };
  onDeleteParticulars = (index) => {
    let particularArray = this.state.particularsArr;

    let arr = [],
      amt = 0,
      particularAmt = 0;
    for (let i = 0; i < particularArray.length; i++) {
      if (i != index) {
        particularAmt = particularAmt + parseFloat(particularArray[i].amt);
        arr.push(particularArray[i]);
      }
    }
    amt =
      amt +
      particularAmt +
      parseFloat(this.state.rushFee) +
      parseFloat(this.state.interpretationFee);


    this.setState({
      particularsArr: arr,
      totalAmount: amt,
      totalParticularAmt: particularAmt,
    });
  };
  onCreateQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    let validateInterpretationFee = inputEmptyValidate(
      this.state.interpretationFee
    );

    if (errorCount === 0) {
      let data = {
        requestId: preData,
        fee: this.state.interpretationFee.toString(),
        total: this.state.totalAmount.toString(),
        rushFee: this.state.rushFee.toString(),
        additionalFee: this.state.particularsArr,
        remarks: this.state.quoteRemarks
      };
      let res = await ApiCall("createQuote", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SEND, {
          hideProgressBar: true,
        });
        return history.push("/adminTrainingList");
      }
    }
  };

  onSaveQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    // let validateInterpretationFee = inputEmptyValidate(
    //   this.state.interpretationFee
    // );

    if (this.state.interpretationFee === 0) {
      toast.error(AlertMessage.MESSAGE.JOB.EMPTY_FEE, {
        hideProgressBar: true,
      });
      errorCount++;
    }

    if (errorCount === 0) {
      let data = {
        requestId: preData,
        fee: this.state.interpretationFee.toString(),
        total: this.state.totalAmount.toString(),
        rushFee: this.state.rushFee.toString(),
        additionalFee: this.state.particularsArr,
        quoteId: this.state.quoteId,
        approvalStatus: this.state.quoteStatus.toString(),
        remarks: this.state.quoteRemarks
      };
      consoleLog("Save Quote Req >>>> ", data)
      let res = await ApiCall("saveQuote", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SAVE);
        return history.push("/adminTrainingList");
      }
    }
  };

  allVendorCheck = (e) => {
    let arr = this.state.listData;
    let brr = [];
    if (e.target.checked) {
      arr.map((data) => {
        data.isQuoteSent = 1;
        brr.push(data.userId);
      });
      this.setState({
        allChecked: true,
        listData: arr,
        vendorAssigned: brr,
      });
    } else {
      arr.map((data) => {
        data.isQuoteSent = 0;
      });
      brr = [];
      this.setState({
        allChecked: false,
        listData: arr,
        vendorAssigned: brr,
      });
    }
  };

  listChecked = (e, i) => {
    let arr = this.state.listData,
      brr = this.state.vendorAssigned;
    if (e.target.checked) {
      arr[i].isQuoteSent = 1;
      brr.push(arr[i].userId);
      this.setState({
        vendorAssigned: brr,
      });
    } else {
      arr[i].isQuoteSent = 0;
      brr.map((data, k) => {
        if (data === arr[i].userId) {
          brr.splice(k, 1);
        }
      });
      this.setState({
        vendorAssigned: brr,
      });
    }
  };

  handleVendorAssign = async () => {
    // consoleLog("Vendor List Data:", this.state.listData);
    // let count = 0;
    // this.state.vendorAssigned.map((ll) => {
    //   this.state.alreadyOfferSent.map((al) => {
    //     console.log(al,ll)
    //     if (al != ll) {
    //       count++;
    //     }
    //   })
    // });
    // if (count > 0) {
    let data = {
      requestId: this.state.requestId,
      vendorId: this.state.vendorAssigned,
    };
    // consoleLog("Send vendor offer request data::", data)
    let res = await ApiCall("sentOfferToVendorFrmTraining", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.OFFER_SENT_SUCCESS);
      return history.push("/adminTrainingList");
    } else {
      toast.error(res.message);
    }
  };

  blankValueCheck = (data) => {
    if (
      data === null ||
      data === undefined ||
      data === "" ||
      data.length === 0
    ) {
      data = "N/A";
      return data;
    } else {
      return data;
    }
  };

  handleViewModal = () => {
    this.openViewModal();
  };
  openViewModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("viewModal").style.display = "block";
    document.getElementById("viewModal").classList.add("show");
  };
  closeViewModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("viewModal").style.display = "none";
    document.getElementById("viewModal").classList.remove("show");
  };

  handleBidModal = async (vid) => {
    let arr = this.state.listData;
    arr.map((data) => {
      if (data.userId === vid) {
        this.setState({
          bidVendorName: data.fName + " " + data.lName,
          bidFee: data.bidFee.toFixed(2),
          totalBidFee: data.totalBidFee.toFixed(2),
          bidVendorId: vid,
        });
      }
    });
    this.openBidModal();
  };
  openBidModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("bid-modal").style.display = "block";
    document.getElementById("bid-modal").classList.add("show");
  };
  closeBidModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("bid-modal").style.display = "none";
    document.getElementById("bid-modal").classList.remove("show");
  };

  vendoeAssigned = async (vid) => {
    let apiObj = {
      requestId: this.state.requestId,
      vendorId: vid,
    };
    let res = await ApiCall("assignTraningInterpretion", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_ASSIGNED_SUCCESS);
      this.closeBidModal();
      setTimeout(
        function () {
          return history.push("/adminTrainingList");
        }.bind(this),
        1000
      );
    }
  };

  acceptClient = async () => {
    let data = {
      clientId: this.state.clientId,
      requestId: this.state.requestId
    }
    let res = await ApiCall("acceptClientQuote", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_ACCEPTED_BY_CLIENT);
      setTimeout(
        function () {
          return history.push("/adminTrainingList")
        }
          .bind(this),
        1000
      );
    }
  }

  onDownloadMisc = (pos) => {
    window.open(
      IMAGE_PATH_ONLY + this.state.mislleniousExpenses[pos].incidentals,
      "_blank"
    );
  };



  approvePayable = async (id, status) => {
    let obj = {
      id: id,
      status: status,
    };
    let res = await ApiCall("changeStatusAccountPayable", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Verified successfullly");
      let apidata = {
        requestId: this.state.requestId,
      };

      let payable = [],
        receivable = [];

      let payableRes = await ApiCall(
        "fetchPayableTraining",
        apidata
      );
      if (
        payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(payableRes.data.payload);
        // consoleLog("Payable List:::", payload.data);
        if (payload.data.details.length > 0) {
          payable = payload.data.details;
        }
      }

      let receivableRes = await ApiCall(
        "fetchReceivabaleTraining",
        apidata
      );
      if (
        receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(receivableRes.data.payload);
        // consoleLog("Receivable List:::", payload.data);
        if (payload.data.details.length > 0) {
          receivable = payload.data.details;
        }
      }

      this.setState({
        payable: payable,
        receivable: receivable,
      });
    }
  };

  viewQuoteTab = () => {
    let data = this.state.quoteStatus;
    if (data === 1 || data === 2 || data === 6 || data === 9 || data === 10) {
      return true;
    } else {
      return false;
    }
  }

  addRushFee = () => {
    this.setState({
      isRushFee: true
    })
  }

  removeRushFee = () => {
    this.setState({
      isRushFee: false
    })
  }

  changeQuoteRemarks = (e) => {
    this.setState({
      quoteRemarks: e.target.value
    })
  }

  changeInternalNotes = (e) => {
    this.setState({
      notesText: e.target.value
    })
  }

  addNotes = async () => {
    let errorCounter = 0;
    if (this.state.notesText.trim() === "") {
      toast.error("Please input some text");
      errorCounter++;
      return false;
    }

    if (errorCounter === 0) {
      let apiObj = {
        requestId: this.state.requestId,
        notes: this.state.notesText,
      };
      // consoleLog("ADD NOTES REQ >>> ", apiObj)
      let res = await ApiCall("addInternalNotes", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchNotes(this.state.requestId);
        toast.success(AlertMessage.MESSAGE.NOTES.ADD_SUCCESS);
        this.setState({
          notesText: ""
        })
      }
    }
  }

  fetchNotes = async (id) => {
    let apiObj = {
      requestId: id
    };
    let res = await ApiCall("fetchInternalNotes", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      this.setState({
        allJobNotes: payload.data.notesData
      })
    }
  }

  fetchChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);

      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })
      payload.data.staffList.map((st) => {
        arr.push({
          label: st.name + " (" + st.userId + " - Staff)",
          value: st.userId,
          userTypeId: st.userTypeId,
          id: st.id
        });
      });


      this.setState({
        chatParticipants: arr
      })
    }
  }

  getUserType = (id) => {
    let str = "";
    if (id == 3) {
      str = "Client";
    } else if (id == 4) {
      str = "vendor";
    } else {
      str = "Client Requester"
    }

    return str;
  }

  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }

  closeParticipantModal = () => {
    this.setState({
      addParticipantShow: false
    })
  }

  onSelectChatParticipants = (value) => {
    this.setState({
      selectedChatParticipants: value
    })
  }

  changeChatGroupName = (e) => {
    this.setState({
      chatGroupName: e.target.value
    })
  }

  submitParticipants = async () => {
    let errorCounter = 0;
    if (this.state.chatGroupName === "") {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_GROUP_NAME);
      return false;
    } else if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: this.state.chatGroupName,
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("submitChatJoinRequest", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Group initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }

  changeChatGroup = async (value) => {
    let data = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(data);
    consoleLog("Group Value >>> ", value);
    let temp = [];
    for (let i = 0; i < value.participantId.length; i++) {
      if (authUser.data.userid !== value.participantId[i])
        temp.push({
          label: value.participantName[i],
          value: value.participantId[i],
          actualId: value.participantActulaId[i]
        })
    }
    await this.loaderChange(true);
    this.setState({
      selectedChatGroupValue: value,
      groupChatParticipant: temp
    })
    await this.loaderChange(false);
    this.showChatPage(value)
  }

  fetchChatGroup = async (id) => {
    let apiObj = {
      jobId: id
    };

    let res = await ApiCall("fetchChatJoinRequest", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("All Chat Group", payload.data);
      let arr = [];
      if (payload.data.length > 0) {
        payload.data.map((item, i) => {
          let obj = {
            label: item.jobId + " - " + item.groupName,
            value: item
          }
          arr.push(obj)

        })
      }
      await this.loaderChange(true);
      this.setState({
        chatGroup: arr
      })
      await this.loaderChange(false);
    }
  }

  showChatPage = (value) => {
    return value !== "" ? <>
      <div className="prticipants-area _fl">
        <div className="row">
          <div className="col-md-6">
            <h3><span style={{ cursor: "pointer" }} onClick={() => this.openChatParticipantModal()}>{Number(value.participantId.length) - 1} Participant</span></h3>
          </div>

          <div className="col-md-6">
            <a href="javascript:void(0)" className="add-part-btn" onClick={this.openAddGroupChatParticipantModal}>
              + Add Participants
            </a>
          </div>
        </div>
      </div>
      <MainChatPage jobId={value._id} jobAlphaId={this.state.jobId} />
    </> : <></>
  }

  openChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: true
    })
  }

  closeChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: false
    })
  }

  removeParticipants = async (value) => {
    if (this.state.groupChatParticipant.length > 1) {
      let apiObj = {
        id: this.state.selectedChatGroupValue._id,
        participantName: value.label,
        participantId: value.value,
        participantActualId: value.actualId
      };
      // consoleLog("Remove Participants data req >>> ", apiObj)
      let res = await ApiCall("removeParticipantFromGroup", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        this.fetchChatGroup(this.state.requestId);
        this.changeChatGroup(this.state.selectedChatGroupValue);
        this.closeChatParticipantModal();
        toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_PARTICIPANTS);
      }
    } else {
      toast.error(AlertMessage.MESSAGE.CHAT.NOT_REMOVE_PARTICIPANTS)
    }

  }

  openAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: true
    })
  }

  closeAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: false
    })
  }

  selectAddGroupParticipants = async (value) => {
    await this.loaderChange(true);
    this.setState({
      selectedAddGroupChatParticipantValue: value
    })
    await this.loaderChange(true);
  }

  submitParticipantsInGroup = async () => {

    // consoleLog("Add Group Particpat >>> ", this.state.selectedAddGroupChatParticipantValue);
    let participantId = [],
      participantName = [],
      participantActualId = [];

    if (this.state.selectedAddGroupChatParticipantValue.length > 0) {
      this.state.selectedAddGroupChatParticipantValue.map((data) => {
        participantName.push(data.name);
        participantId.push(data.value);
        participantActualId.push(data.id);
      })
    }

    let reqObj = {
      id: this.state.selectedChatGroupValue._id,
      participantName: participantName,
      participantId: participantId,
      participantActualId: participantActualId
    }

    // consoleLog("Add Participnat in exist group req >> ", reqObj)
    let res = await ApiCall("addParticipantFromGroup", reqObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      this.closeAddGroupChatParticipantModal();
      await this.fetchChatGroup(this.state.requestId);
      toast.success(AlertMessage.MESSAGE.CHAT.ADD_PARTICIPANTS);
      await this.fetchChatParticipants(this.state.requestId);
      this.changeChatGroup(this.state.chatGroup[0].value);
      this.setState({
        selectedAddGroupChatParticipantValue: []
      })
    } else {
      toast.error(res.message)
    }


  }

  fetchGroupChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    // let res = await ApiCall("getSpecialChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);
      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          name: pp.name,
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })


      if (payload.data.staffList) {
        payload.data.staffList.map((st) => {
          arr.push({
            label: st.name + " (" + st.userId + " - Staff)",
            name: st.name,
            value: st.userId,
            userTypeId: st.userTypeId,
            id: st.id
          });
        });
      }


      this.setState({
        addGroupChatParticipant: arr
      })
    }
  }

  /* Single chat initiate function start */

  onSelectChatParticipantsForSingle = (value) => {
    let arr = [];
    arr.push(value);
    this.setState({
      selectedChatParticipants: arr
    })
  }

  submitParticipantsForSingle = async () => {
    let errorCounter = 0;
    if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: '',
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("singleJobChatInitiate", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Chat initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }
  /* Single chat initiate function End */

  /* Fetch Email & SMS Log */

  fetchEmailSmsLog = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getEmailSmsLogByJobId", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Email SMS Log Data  >>>>>", payload);
      if (payload.data.log.length > 0) {
        let arr = [];
        this.setState({
          allEmailSmsLog: payload.data.log
        })
      } else {
        this.setState({
          allEmailSmsLog: []
        })
      }
    }

  }

  openViewEmailModal = async (data) => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: true,
      viewEmailModalData: data
    });
    await this.loaderChange(false);
  }

  closeViewEmailModal = async () => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: false,
      viewEmailModalData: {}
    });
    await this.loaderChange(false);
  }

  openEmailSendModal = async () => {

    await this.loaderChange(true);
    this.setState({
      sentEmailModal: true
    })
    await this.loaderChange(false);
  }

  closeEmailSendModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentEmailModal: false
    })
    await this.loaderChange(false);
  }

  openSmsModal = async () => {
    await this.loaderChange(true);
    this.setState({
      sentSmsModal: true
    })
    await this.loaderChange(false);
  }

  closeSmsModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentSmsModal: false
    })
    await this.loaderChange(false);
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }


  openRejectModal = (data) => {
    this.setState({
      selectedRejectData: data,
      rejectModal: true
    })
  }

  closeRejectModal = () => {
    this.setState({
      selectedRejectData: {},
      rejectReason: "",
      rejectModal: false
    })
  }

  rejectReasonChange = (e) => {
    this.setState({
      rejectReason: e.target.value
    })
  }

  submitReject = () => {
    this.approvePayable(this.state.selectedRejectData.id, "3")
  }

  openViewNoteModal = (data) => {
    this.setState({
      updateBillId: data.id,
      viewNote: data.description,
      updatedAmount: data.totalAmount,
      billQty: data.quantity,
      billUnitPrice: data.unitPrice,
      viewNoteModal: true
    })
  }

  closeViewNoteModal = () => {
    this.setState({
      viewNote: "",
      updatedAmount: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",
      viewNoteModal: false,
      updateDetails: false
    })
  }
  changeViewDescription = (e) => {
    this.setState({
      viewNote: e.target.value
    })
  }

  changeUpdateDetails = () => {
    this.setState({
      updateDetails: true
    })
  }

  changeBillQty = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billQty: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billUnitPrice) > 0) {
            amt = newValue * Number(this.state.billUnitPrice);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billQty: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeUnitPrice = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billUnitPrice: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billQty) > 0) {
            amt = newValue * Number(this.state.billQty);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billUnitPrice: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeBillRemarks = (e) => {
    this.setState({
      billRemarks: e.target.value
    })
  }

  updateBillDetails = async () => {
    let errorCount = 0;
    if (this.state.billQty == 0 || this.state.billQty === "") {
      toast.error("Quantity cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billUnitPrice == 0 || this.state.billUnitPrice === "") {
      toast.error("Unit price cannot be 0 or blank");
      errorCount++;
    } else if (this.state.updatedAmount == 0 || this.state.updatedAmount === "") {
      toast.error("Total amount cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billRemarks === "") {
      toast.error("Please enter remarks");
      errorCount++;
    }
    if (errorCount == 0) {
      let reqObj = {
        id: this.state.updateBillId,
        totalAmount: this.state.updatedAmount,
        quantity: this.state.billQty,
        unitPrice: this.state.billUnitPrice,
        description: this.state.viewNote,
        remarks: this.state.billRemarks
      }

      // consoleLog("Updated BillDetails >>>> ", reqObj);
      let res = await ApiCall("updateExistingBill", reqObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Bill Updated Successfully");
        this.closeViewNoteModal();
        this.load();
      } else {
        toast.error(res.message)
      }
    }
  }

  render() {
    // const open = Boolean(this.state.anchorEl); //used in MenuButton open
    // const open1 = Boolean(this.state.anchorEl1);
    return (
      <React.Fragment>
        <ToastContainer hideProgressBar theme="colored" />
        <div class="component-wrapper" hidden={!this.state.isLoad}>
          <ReactLoader />
        </div>
        <div className="component-wrapper" hidden={this.state.isLoad}>
          <div
            className="vn_frm"
            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
          >
            {" "}
            <Link to="/adminDashboard">Dashboard</Link> /{" "}
            {this.state.pathCheck == true ? (
              <Link to="/adminMainBillUnderVerification">Bills Under Verification</Link>
            ) : (
              <Link to="/adminTrainingList">Training</Link>
            )}
            / Training Details
          </div>
          <div className="job-details-tab jobdltste _fl sdw">
            <ul className="nav nav-tabs start_tabs" style={{ justifyContent: "left" }}>
              <li className="nav-item">
                {" "}
                <a
                  className={this.state.showTab === "project" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#jobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Details
                  </div>
                </a>{" "}
              </li>
              {this.state.quoteStatus === 7 ||
                this.state.quoteStatus === 9 ||
                this.state.quoteStatus === 10 ||
                this.state.quoteStatus === 11 ? (
                <React.Fragment></React.Fragment>
              ) : (
                <React.Fragment>
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#editjobdetails"
                    >
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                        </figure>{" "}
                        Edit Training
                      </div>
                    </a>{" "}
                  </li>
                </React.Fragment>
              )}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#clientdetails">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                    </figure>
                    Client Details
                  </div>
                </a>{" "}
              </li>
              {/* {this.viewQuoteTab() ? */}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#sendqute">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TABBAR} />
                    </figure>
                    {this.state.quoteStatus > 8 || this.state.quoteStatus === 2 ? "View Quote" : "Sent Quote"}
                  </div>
                </a>{" "}
              </li>
              {/* : <></>
               } */}
              {
                // this.state.quoteStatus === 2 ||
                // this.state.quoteStatus === 3 ||
                // this.state.quoteStatus === 6 ||
                this.state.quoteStatus <= 8 ? (
                  <li className="nav-item">
                    {" "}
                    <a className="nav-link" data-toggle="tab" href="#vendoroff">
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.DOCUMENTICON} />
                        </figure>
                        {this.state.quoteStatus === 8
                          ? "Vendors Offered"
                          : "Available vendors"}
                      </div>
                    </a>{" "}
                  </li>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              {this.state.quoteStatus === 10 &&
                this.state.completeSummary.postJobStat > 0 ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#comp_sum">
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.TABBAR} />
                        </figure>
                        Completion Summary
                      </div>
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              {this.state.quoteStatus === 10 &&
                this.state.completeSummary.postJobStat > 1 ? (
                <>
                  <li className="nav-item">
                    <a
                      className={this.state.showTab === "bills" ? "nav-link active" : "nav-link"}
                      data-toggle="tab"
                      href="#payablerecavable"
                    >
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.CHAT_ICON} />
                        </figure>
                        Payables & Receivables
                      </div>
                    </a>
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#notetab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Notes{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#emailsms">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.CHARSMSICON} />
                    </figure>
                    Email & SMS
                  </div>
                </a>{" "}
              </li>
            </ul>

            <div className="tab-content">
              <div className={this.state.showTab === "project" ? "tab-pane  active" : "tab-pane"} id="jobdetails">
                <div className="job-section-tab">
                  <table
                    width="100%"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td width="50%" align="left">
                          Project ID
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobId}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Training Format
                        </td>
                        <th width="50%" align="right">
                          {this.state.trainingFormat}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          No. of Participants
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceProvider}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Category
                        </td>
                        <th width="50%" align="right">
                          {this.state.trainingCategory}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Course
                        </td>
                        <th width="50%" align="right">
                          {this.state.trainingCourse}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Date & Time
                        </td>
                        <th width="50%" align="right">
                          {SetDateFormat(this.state.date)}{" "}
                          {this.state.scheduleTime}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Training Location Address
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceAddress}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Site Contact
                        </td>
                        <th width="50%" align="right">
                          {this.state.siteContant}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Customer
                        </td>
                        <th width="50%" align="right">
                          {this.state.consumer}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Notes by Client
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesByClient}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          <p class="notes">
                            Notes From <span>7C Lingo</span>
                          </p>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr>
                      {this.state.quoteStatus >= 8 ?
                        <tr>
                          <td width="50%" align="left">
                            Assigned Vendor
                          </td>
                          <th width="50%" align="right">
                            {this.state.assignedVendor}
                          </th>
                        </tr> : <></>
                      }

                      {this.state.quoteStatus == 11 ? <>
                        {this.state.projectCancelReason !== "" ?
                          <div className="col-md-12">
                            <textarea className="cancelTranslationReason" value={"Cancel Reason : " + this.state.projectCancelReason} disabled={true} ></textarea>
                          </div> : <></>}
                      </> : <></>}
                      {this.state.quoteStatus == 7 ? <>
                        {this.state.quoteRejectReason !== "" ? <>
                          <div className="col-md-12">
                            <textarea className="cancelTranslationReason" value={"Quote Decline Reason : " + this.state.quoteRejectReason + "\n" + "Quote Decline Description : " + this.state.quoteRejectDescription} disabled={true} ></textarea>
                          </div>
                        </> : <></>}
                      </> : <></>}
                    </tbody>
                  </table>

                  {this.state.progressLogData.length > 0 ? <>
                    <Stepper
                      steps={this.state.progressLogData}
                      activeStep={this.state.progressLogData.length}
                      styleConfig={{ completedBgColor: "#00a0df" }}
                    />
                  </> : <></>}
                </div>
              </div>

              {/* ................for edit job details page................. */}
              <div className="tab-pane" id="editjobdetails">
                <div className="job-section-tab">
                  {/* {consoleLog("Html adata", this.state.reqId)} */}
                  <EditTrainingDetails mainData={this.state.reqId} />
                </div>
              </div>

              {/* .............................................................*/}

              <div className="tab-pane" id="sendqute">
                <div className="job-section-tab">
                  <div className="view-quote-details-wrap">
                    <h3>view quote details</h3>
                    <p>
                      <span>Quote Id</span> {this.state.quoteId}
                    </p>
                    <p>
                      <span>Training Format</span> {this.state.trainingFormat}
                    </p>
                    <p>
                      <span>Location</span> {this.state.serviceAddress}
                    </p>
                    <p>
                      <span>Training Category</span>{" "}
                      {this.state.trainingCategory}
                    </p>
                    <p>
                      <span>Training Course</span> {this.state.trainingCourse}
                    </p>
                    <p>
                      <span>Date & Time</span>{" "}
                      {SetDateFormat(this.state.dateTime)}{" "}
                      {this.state.scheduleTime}
                    </p>
                    <p>
                      <span>Notes from Client</span>
                      {this.state.notesFromClient}
                    </p>
                  </div>
                  <div className="add-more-field">
                    <div className="table-listing-app create">
                      <div className="creat_quot">
                        <h3>View quote</h3>
                        <div className="depr_table">
                          <div className="table-responsive_mb">

                            <div class="addmore_service text-right" hidden={this.state.quoteStatus > 8}>
                              <a href="javascript:void(0)" onClick={() => this.addParticularField()}>
                                <div style={{ marginTop: "8px" }} >
                                  Add Additional Field
                                </div>
                              </a>
                            </div>

                            {/* {(this.state.quoteStatus == 6 && !this.state.isRushFee) ? */}

                            {(!this.state.isRushFee) ?
                              <div class="addmore_service text-right mr_0 " hidden={this.state.quoteStatus > 8}>
                                <a href="javascript:void(0)" onClick={() => this.addRushFee()}>
                                  <div style={{ marginTop: "8px" }}>
                                    Add Rush Fee
                                  </div>
                                </a>
                              </div> : <></>
                            }

                            <table
                              width="100%"
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="50%" align="left">
                                    No. of Participants
                                  </td>
                                  <td width="50%" align="right">
                                    {this.state.serviceProvider}
                                  </td>
                                </tr>

                                <tr>
                                  <td width="50%" align="left">
                                    Duration
                                  </td>
                                  <td width="50%" align="right">
                                    {this.state.duration}
                                  </td>
                                </tr>

                                <tr hidden={!this.state.isRushFee}>
                                  <td width="50%" align="left">
                                    Rush Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "right" }}
                                    >
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        className="inputfield flr"
                                        type="number"
                                        value={this.state.rushFee}
                                        onChange={this.onRushFeeChange}
                                        readOnly={this.state.quoteStatus > 8 ? true : false}
                                      />
                                      {this.state.quoteStatus < 2 || this.state.quoteStatus == 6 ?
                                        <TiDelete
                                          size={40}
                                          style={{ cursor: 'pointer', color: "red", float: "right" }}
                                          onClick={() => this.removeRushFee()} /> :
                                        <></>
                                      }
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td width="50%" align="left">
                                    Training Fee
                                  </td>
                                  <td width="50%" align="right">
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "right" }}
                                    >
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        className="inputfield flr"
                                        type="number"
                                        value={this.state.interpretationFee}
                                        onChange={(e) =>
                                          this.onInterpretationFeeChange(e)
                                        }
                                        readOnly={this.state.quoteStatus > 8 ? true : false}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                {this.state.particularsArr.map((item, key) => (
                                  <tr>
                                    <td width="50%" align="left">
                                      <input
                                        className="inputfield flr"
                                        type="text"
                                        placeholder="Enter Details"
                                        value={item.title}
                                        onChange={(e) =>
                                          this.onParticularChange(e, key)
                                        }
                                      // readOnly
                                      />
                                    </td>
                                    <td width="50%" align="right">
                                      <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-md-5">
                                          <div
                                            className="input-group"
                                            style={{ justifyContent: "end" }}
                                          >
                                            <div class="input-group-prepend">
                                              <span
                                                class="input-group-text dollar"
                                                id="basic-addon1"
                                              >
                                                $
                                              </span>
                                            </div>
                                            <input
                                              className="inputfield flr"
                                              type="number"
                                              placeholder="Enter Amount"
                                              value={item.amt}
                                              style={{ width: "75%" }}
                                              onChange={(e) =>
                                                this.onParticularAmountChange(e, key)
                                              }
                                            // readOnly
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-1 delete-btn">
                                          <img
                                            src={ImageName.IMAGE_NAME.TRASH_BTN}
                                            onClick={() =>
                                              this.onDeleteParticulars(key)
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}


                                <tr>
                                  <td width="50%" align="left">
                                    Remarks (In 500 characters)
                                  </td>
                                  <td width="50%" align="right">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <textarea
                                          rows="4"
                                          disabled={
                                            this.state.quoteStatus > 8
                                              ? true
                                              : false
                                          }
                                          className="remarks_textarea"
                                          type="text"
                                          value={this.state.quoteRemarks}
                                          onChange={(e) =>
                                            this.changeQuoteRemarks(e)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    width="50%"
                                    align="left"
                                    style={{
                                      color: "#5ea076",
                                      fontSize: "22px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Total Payable
                                  </td>
                                  <td
                                    width="50%"
                                    style={{
                                      color: "#5ea076",
                                      textAlign: "center",
                                      fontSize: "22px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    $ {parseFloat(this.state.totalAmount)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="_button-style m30 _fl text-center">
                    <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        return history.push("/adminTrainingList");
                      }}
                    >
                      Back
                    </a>
                    {this.state.quoteStatus < 8 ? <>
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none" }}
                        onClick={this.onCreateQuote}
                      >
                        Send Quote
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none" }}
                        onClick={this.onSaveQuote}
                      >
                        Save Quote
                      </a></> : <></>
                    }
                    {this.state.quoteStatus === 1 ? <>
                      <a href="javascript:void(0)" class="blue-btn" onClick={this.acceptClient}>Accept</a> <span style={{ color: "gray" }}>(On behalf of client)</span>
                    </> : <></>
                    }
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="vendoroff">
                <div className="job-section-tab">
                  <AvailableVendorListing
                    value={this.state}
                    allVendorCheck={this.allVendorCheck}
                    listChecked={(e, i) => this.listChecked(e, i)}
                    handleBidModal={(id) => this.handleBidModal(id)}
                    handleVendorAssign={() => this.handleVendorAssign()}
                    getList={(req) => this.getAvailableVendorList(req)}
                  />
                  {/* <div className="table-listing-app">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            {this.state.quoteStatus === 8 ? (
                              <React.Fragment></React.Fragment>
                            ) : (
                              <React.Fragment>
                                <th style={{ width: "5%" }}>
                                  <label className="custom_check2">
                                    <input
                                      type="checkbox"
                                      checked={this.state.allChecked}
                                      onClick={this.allVendorCheck}
                                    />
                                    <span className="checkmark2"></span>
                                  </label>
                                </th>
                              </React.Fragment>
                            )}
                            <th style={{ width: "30%" }}>Name / Agency</th>
                            <th style={{ width: "20%" }}>Email ID</th>
                            <th style={{ width: "20%" }}>Phone Number</th>
                            <th style={{ width: "10%" }}>Ratings</th>
                            {this.state.quoteStatus === 8 ? (
                              <th style={{ width: "15%" }}>Bid</th>
                            ) : (
                              <th style={{ width: "15%" }}>Status</th>
                            )}
                          </tr>
                        </tbody>
                        <tbody>
                          {this.state.listData.map((item, key) => (
                            <tr key={key}>
                              {this.state.quoteStatus === 8 ? (
                                <React.Fragment></React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td style={{ width: "5%" }}>
                                    <label className="custom_check2">
                                      <input
                                        type="checkbox"
                                        defaultChecked={
                                          item.isQuoteSent === 1 ? true : false
                                        }
                                        onChange={(e) =>
                                          this.listChecked(e, key)
                                        }
                                      />

                                      <span className="checkmark2"></span>
                                    </label>
                                  </td>
                                </React.Fragment>
                              )}
                              <td style={{ width: "30%" }}>
                                {item.agencyName === ""
                                  ? item.fName + " " + item.lName
                                  : item.fName +
                                  " " +
                                  item.lName +
                                  " (" +
                                  item.agencyName +
                                  ")"}
                              </td>
                              <td style={{ width: "20%" }}>
                                <a href="" className="viewlink">
                                  {item.email}
                                </a>
                              </td>
                              <td style={{ width: "20%" }}>+1 {item.mobile}</td>
                              <td style={{ width: "10%" }}>
                                <img src={ImageName.IMAGE_NAME.STARYELLOW} />
                                <span className="rat_txt">{item.ratings}</span>
                              </td>

                              {this.state.quoteStatus === 8 ? (
                                <React.Fragment>
                                  <td style={{ width: "15%" }}>
                                    {item.status === 2 ? (
                                      <>
                                        <span
                                          style={{
                                            color: "green",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Assigned
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td style={{ width: "15%" }}>
                                    {item.status === 1 ? (
                                      <a href="javascript:void(0)">
                                        <img
                                          src={ImageName.IMAGE_NAME.EYE_BTN}
                                          onClick={() => {
                                            this.handleBidModal(item.userId);
                                          }}
                                        />
                                      </a>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </React.Fragment>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {this.state.quoteStatus === 8 ? (
                    <React.Fragment></React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="_button-style _fl text-center margin-top-30">
                        <a
                          href="javascript:void(0)"
                          className="grey-btn"
                          style={{ textDecoration: "none" }}
                        >
                          Reset
                        </a>
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none" }}
                          onClick={this.handleVendorAssign}
                        >
                          Send Offer
                        </a>
                      </div>
                    </React.Fragment>
                  )} */}
                </div>
              </div>
              <div className={this.state.showTab === "bills" ? "tab-pane  active" : "tab-pane"} id="payablerecavable">
                <div className="acc_payable">
                  <h3>Account Payables </h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Training ID</th>
                          <th style={{ width: "20%" }}>Course</th>
                          <th style={{ width: "15%" }}>Vendor[S]</th>
                          <th style={{ width: "15%" }}>Completed On</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "15%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.payable.length > 0 ? (
                          <React.Fragment>
                            {this.state.payable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  <td>
                                    {data.trainingCourse}
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    {SetDateFormat(data.endTime)} |{" "}
                                    {SetTimeFormat(data.endTime)}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <span className="progress-btn yellow">
                                          Pending
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            <span className="progress-btn sky" >
                                              Verified
                                            </span>
                                          </React.Fragment>
                                        ) : data.status === 2 ? (
                                          <React.Fragment>
                                            <span className="progress-btn blue">
                                              Invoice Created
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <span className="progress-btn red">
                                              Rejected
                                            </span>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <FiEdit size={20} onClick={() => this.openViewNoteModal(data)} style={{ cursor: "pointer" }} />
                                    {data.status === 0 || data.status === 3 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id, "1")
                                            }
                                          />
                                        </a>
                                        {data.status === 0 ?
                                          <a href="javascript:void(0)" style={{ paddingLeft: "10px" }}>
                                            <img
                                              src={
                                                ImageName.IMAGE_NAME.CROSS_BTN
                                              }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              onClick={() =>
                                                this.openRejectModal(data)
                                              }
                                            />
                                          </a> : <></>
                                        }
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="acc_payable">
                  <h3>Account Receivables</h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Training ID</th>
                          <th style={{ width: "20%" }}>Course</th>
                          <th style={{ width: "15%" }}>Client[S]</th>
                          <th style={{ width: "15%" }}>Completed On</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "15%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.receivable.length > 0 ? (
                          <React.Fragment>
                            {this.state.receivable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  <td>
                                    {data.trainingCourse}
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    {SetDateFormat(data.endTime)} |{" "}
                                    {SetTimeFormat(data.endTime)}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <span className="progress-btn yellow">
                                          Pending
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            <span className="progress-btn sky" >
                                              Verified
                                            </span>
                                          </React.Fragment>
                                        ) : data.status === 2 ? (
                                          <React.Fragment>
                                            <span className="progress-btn blue">
                                              Invoice Created
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <span className="progress-btn red">
                                              Rejected
                                            </span>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <FiEdit size={20} onClick={() => this.openViewNoteModal(data)} style={{ cursor: "pointer" }} />
                                    {data.status === 0 || data.status === 3 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id, "1")
                                            }
                                          />
                                        </a>
                                        {data.status === 0 ?
                                          <a href="javascript:void(0)" style={{ paddingLeft: "10px" }}>
                                            <img
                                              src={
                                                ImageName.IMAGE_NAME.CROSS_BTN
                                              }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              onClick={() =>
                                                this.openRejectModal(data)
                                              }
                                            />
                                          </a> : <></>
                                        }
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                show={this.state.rejectModal}
                onHide={this.closeRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Modal.Header className="reject_payables_details" closeButton>
                  <Modal.Title>Are you sure want to reject ? </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="">
                    <span className="tr_nl">
                      <label>Enter Reject Reason</label>
                      <textarea
                        rows="2"
                        placeholder="Please type anything..."
                        className="in-textarea msg min"
                        value={this.state.rejectReason}
                        style={{
                          height: "100px",
                          color: "var(--grey)",
                          borderRadius: "10px",
                          boxShadow: "2px",
                          resize: "none",
                        }}
                        onChange={(e) => this.rejectReasonChange(e)}
                      ></textarea>
                      {/* {this.state.viewNote} */}
                    </span>
                    <Button onClick={this.closeRejectModal} color="primary">
                      Close
                    </Button>
                    <Button onClick={this.submitReject} color="primary">
                      Reject
                    </Button>

                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.viewNoteModal}
                onHide={this.closeViewNoteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Modal.Body>
                  {/* <Box sx={styleCancelModal}> */}
                  <div className="doc_action_modal">
                    <div className="edit_details" hidden={this.state.updateDetails} >
                      <FiEdit size={25} onClick={this.changeUpdateDetails} />
                    </div>
                    <div className="vn_frm">
                      <span
                        style={{
                          width: "35%",
                          fontSize: "15px",
                        }}
                      >
                        Quantity
                      </span>
                      <input
                        placeholder="Quantity"
                        className="inputfield"
                        type="number"
                        value={this.state.billQty}
                        onChange={(e) =>
                          this.changeBillQty(e)
                        }
                        disabled={!this.state.updateDetails}
                      />
                    </div>
                    <div className="vn_frm">
                      <span
                        style={{
                          width: "35%",
                          fontSize: "15px",
                        }}
                      >
                        Unit Price
                      </span>

                      <input
                        placeholder="unitPrice"
                        className="inputfield"
                        type="number"
                        value={this.state.billUnitPrice}
                        onChange={(e) =>
                          this.changeUnitPrice(e)
                        }
                        disabled={!this.state.updateDetails}
                      />
                    </div>
                    <div className="vn_frm">
                      <span
                        style={{
                          width: "35%",
                          fontSize: "15px",
                        }}
                      >
                        Total Amount
                      </span>
                      <InputText
                        placeholder="amount"
                        className="inputfield"
                        value={this.state.updatedAmount}
                        // onTextChange={(value) => {
                        //   this.changeUpdateAmount(value);
                        // }}
                        disabled={true}
                      />
                    </div>
                    <span className="tr_nl">
                      <label>Description</label>
                      <textarea
                        rows="2"
                        placeholder="Enter Descriptions ..."
                        className="in-textarea msg min"
                        value={this.state.viewNote}
                        style={{
                          height: "100px",
                          color: "var(--grey)",
                          borderRadius: "10px",
                          boxShadow: "2px",
                          resize: "none",
                        }}
                        onChange={this.changeViewDescription}
                        disabled={!this.state.updateDetails}
                      ></textarea>
                    </span>

                    <div hidden={!this.state.updateDetails}>
                      <label>Remarks</label>
                      <textarea
                        rows="2"
                        placeholder="Enter Remarks ..."
                        className="in-textarea msg min"
                        value={this.state.billRemarks}
                        style={{
                          height: "100px",
                          color: "var(--grey)",
                          borderRadius: "10px",
                          boxShadow: "2px",
                          resize: "none",
                        }}
                        onChange={this.changeBillRemarks}
                        disabled={!this.state.updateDetails}
                      ></textarea>
                      {/* {this.state.viewNote} */}
                    </div>
                    <div className="edit_details_btn_sec" hidden={!this.state.updateDetails}>
                      <Button onClick={this.closeViewNoteModal} color="primary">
                        Close
                      </Button>
                      <Button onClick={this.updateBillDetails} color="primary">
                        Update
                      </Button>
                    </div>
                  </div>
                  {/* </Box> */}

                </Modal.Body>
              </Modal>

              <div className="tab-pane" id="comp_sum">
                <div className="job-section-tab">
                  {Object.keys(this.state.completeSummary).length > 0 ? (
                    <React.Fragment>
                      <h2>CLIENT</h2>
                      <div className="tbl-iformation">
                        <div className="table-responsive">
                          <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                          >
                            <tbody>
                              {/* <tr>
                                <td width="33%" align="left">
                                  Trainer Arrival Date
                                </td>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.arrivalTime}
                                </th>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.isArrivalAprroved}
                                </th>
                              </tr> */}
                              <tr>
                                <td width="33%" align="left">
                                  Trainer Start Time
                                </td>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.startTime}
                                </th>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.isStartApproved}
                                </th>
                              </tr>
                              <tr>
                                <td width="33%" align="left">
                                  Trainer End Time
                                </td>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.endTime}
                                </th>
                                <th width="33%" align="center">
                                  {this.state.completeSummary.isEndApproved}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p> Rate Your Trainer</p>
                          </div>
                          <div className="col-md-6">
                            <div className="rt-rating">
                              {/* <figure><img src={ImageName.IMAGE_NAME.RATTINGSTAR} /></figure> */}
                              <ReactStars
                                count={5}
                                // onChange={ratingChanged}
                                size={44}
                                isHalf={true}
                                activeColor="#009fe0"
                                value={this.state.completeSummary.clientRatings}
                                edit={false}
                              />
                              <a href="javascript:void(0)" className="poor">
                                Very Poor
                              </a>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Would you use the same Trainer?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">
                              {this.state.completeSummary.isPrefVendor}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Reason</p>
                          </div>
                          <div className="col-md-6">
                            <p>{this.state.completeSummary.prefferedReason}</p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Is there any follow up appointments?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">
                              {this.state.completeSummary.followUp}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-12">
                            <h2>VENDOR</h2>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="tbl-iformation ">
                          <div className="table-responsive">
                            <table
                              className="mn"
                              width="100%"
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                            >
                              <tbody>
                                {/* <tr>
                                  <td width="50%" align="left">
                                    Trainer Arrival Date
                                  </td>
                                  <th width="50%" align="center">
                                    {this.state.completeSummary.arrivalTime}
                                  </th>
                                  <th width="33%" align="center">{this.state.completeSummary.isArrivalAprroved}</th>  // Blockable code
                                </tr> */}
                                <tr>
                                  <td width="50%" align="left">
                                    Trainer Start Time
                                  </td>
                                  <th width="50%" align="center">
                                    {this.state.completeSummary.startTime}
                                  </th>
                                  {/* <th width="33%" align="center">{this.state.completeSummary.isStartApproved}</th> */}
                                </tr>
                                <tr>
                                  <td width="50%" align="left">
                                    Trainer End Time
                                  </td>
                                  <th width="50%" align="center">
                                    {this.state.completeSummary.endTime}
                                  </th>
                                  {/* <th width="33%" align="center">{this.state.completeSummary.isEndApproved}</th> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Provide Follow up details? (if any)</p>
                          </div>
                          <div className="col-md-6">
                            <p>
                              {this.state.completeSummary.followUpDate} |{" "}
                              {this.state.completeSummary.followUpTimeRange}
                            </p>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>Are you available for follow up?</p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">yes</p>
                          </div>
                        </div>
                      </div> */}
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-12">
                            <h2>MISCELLANEOUS EXPENSES</h2>
                          </div>
                        </div>
                      </div>
                      <div className="_fl margin-top-30 br-bx">
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              Are the miscellaneous expences approved by{" "}
                              <span style={{ color: "#00a0df" }}>7C Lingo</span>
                              ?
                            </p>
                          </div>
                          <div className="col-md-6">
                            <p className="np">
                              {
                                this.state.completeSummary
                                  .expensesApproveByAdmin
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      {this.state.completeSummary.expensesApproveByAdmin ===
                        "Yes" ? (
                        <div className="_fl margin-top-30 br-bx">
                          <div className="tbl-iformation ">
                            <div className="table-responsive">
                              <table
                                className="mn"
                                width="100%"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Expense Type
                                    </th>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Incidentals
                                    </th>
                                    <th
                                      width="33%"
                                      align="center"
                                      style={{ color: "#b4b4b4" }}
                                    >
                                      Receipt Amount
                                    </th>
                                  </tr>
                                  {this.state.mislleniousExpenses.length > 0 ? (
                                    <React.Fragment>
                                      {this.state.mislleniousExpenses.map(
                                        (data, i) => (
                                          <React.Fragment key={i}>
                                            <tr>
                                              <th width="33%" align="center">
                                                {data.expenseType}
                                              </th>
                                              <th width="33%" align="center">
                                                <p>
                                                  <a href="javascript:void(0)">
                                                    <img
                                                      src={
                                                        ImageName.IMAGE_NAME
                                                          .DOWNLOADSHEET
                                                      }
                                                      onClick={() =>
                                                        this.onDownloadMisc(i)
                                                      }
                                                    />
                                                  </a>
                                                </p>
                                              </th>
                                              <th width="33%" align="center">
                                                $ {data.amount}
                                              </th>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment></React.Fragment>
                                  )}
                                  <tr>
                                    <th colspan="2">Total Amount : </th>
                                    <th width="33%" style={{ color: "green" }}>
                                      ${" "}
                                      {parseFloat(this.state.miscTotal).toFixed(
                                        2
                                      )}{" "}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ReactLoader />
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="tab-pane" id="clientdetails">
                <div className="job-section-tab">
                  <h3>Client Information</h3>
                  <div className="c-l-s _fl">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>First Name</h4>
                        <p>{this.state.clientFirstName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Last Name</h4>
                        <p>{this.state.clientLastName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Client</h4>
                        <p>
                          <span className="blue">{this.state.clientName}</span>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Email ID</h4>
                        <p>{this.state.clientEmailId}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Phone Number</h4>
                        <p>
                          +{this.state.countryCode} {this.state.clientPhone}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Industry Type</h4>
                        <p>{this.state.clientIndustryType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* .................. Note Tab View .......................  */}
              <div className="tab-pane" id="notetab">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotes.map((data, i) =>
                        <div className="participants-chat-row" key={i}>
                          <figure>
                            <img src={ImageName.IMAGE_NAME.LOGO1} />
                          </figure>
                          <div className="chatinfo-bx">
                            <p className="cht-user">
                              <b>{data.userName}</b> <span>{SetUSAdateFormatV2(data.createDate) + " " + SetTimeFormat(data.createDate)}</span>
                            </p>
                            <p className="chat-text">
                              {data.notes}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>


                  </div>
                  <div className="chat-app-type-area">
                    <input
                      type="text"
                      value={this.state.notesText}
                      name="notes"
                      className="chat-field-bx"
                      onChange={(e) => this.changeInternalNotes(e)}
                    />
                    <button type="submit" className="send-btn-app" onClick={() => this.addNotes()}>
                      send
                    </button>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="prticipants-area _fl">


                    <div className="row">
                      <div className="col-md-6">
                        {/* <h3>3 Participants</h3> */}
                        <button type="button" className="new_chat_btn" onClick={() => this.openParticipantModal()}>
                          <img src={ImageName.IMAGE_NAME.MENU.CHAT_ICON_BLUE} />
                          <span>New Chat</span>
                        </button>
                      </div>

                      {/* <div className="col-md-6">
                        <a href="javascript:void(0)" className="add-part-btn" onClick={this.openParticipantModal}>
                          + Create Group
                        </a>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.chatGroup.length > 0 ?
                          <ChatGroupList
                            value={this.state.chatGroup}
                            changeGroupValue={(value) => this.changeChatGroup(value)}
                          /> :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                  {this.showChatPage(this.state.selectedChatGroupValue)}
                </div>
              </div>

              <Modal show={this.state.addGrouoChatParticipantModal} >
                <Modal.Header>
                  <Modal.Title>Add Participants </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <MultiSelectBox
                        optionData={this.state.addGroupChatParticipant}
                        value={this.state.selectedAddGroupChatParticipantValue}
                        onSelectChange={(value) =>
                          this.selectAddGroupParticipants(value)
                        }
                      ></MultiSelectBox>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeAddGroupChatParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsInGroup}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.chatParticipantModal} onHide={this.closeChatParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Participants List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants List</div>
                    <div className="row">
                      {this.state.groupChatParticipant.map((data, i) => <>
                        <div className="col-md-10" key={i}>
                          {data.label}
                        </div>
                        {this.state.groupChatParticipant.length > 1 ?
                          <div className="col-md-2">
                            <MdDelete style={{ cursor: "pointer" }} onClick={() => this.removeParticipants(data)} />
                          </div> : <></>}
                      </>)}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeChatParticipantModal}>
                    Close
                  </Button>
                  {/* <Button variant="primary" onClick={this.submitParticipants}>
      Add
    </Button> */}
                </Modal.Footer>
              </Modal>


              <Modal show={this.state.addParticipantShow} onHide={this.closeParticipantModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Participants</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="web-form-bx">
                    <div className="frm-label">Participants *</div>
                    <div className="bts-drop">
                      <SelectBox
                        optionData={this.state.chatParticipants}
                        value={this.state.selectedChatParticipants}
                        onSelectChange={(value) =>
                          this.onSelectChatParticipantsForSingle(value)
                        }
                      ></SelectBox>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.closeParticipantModal}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.submitParticipantsForSingle}>
                    Send Requests
                  </Button>
                </Modal.Footer>
              </Modal>


              <Modal
                show={this.state.viewEmailModal}
                onHide={this.closeViewEmailModal}
                size="lg"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                centered
              >
                <Modal.Header className="email_details" closeButton>
                  <Modal.Title>{this.state.viewEmailModalData.logType === 'Email' ? 'Email Data' : 'SMS Data'} </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="row">
                    {this.state.viewEmailModalData.logType === 'Email' ?
                      <div className="col-md-12">
                        <label>Sender : &nbsp;</label>
                        {this.state.viewEmailModalData.sender}
                      </div> : <></>
                    }
                    <div className="col-md-12">
                      <label>Sent To : &nbsp;</label>
                      {this.state.viewEmailModalData.receiver}
                    </div>
                    {this.state.viewEmailModalData.logType === 'Email' ?
                      <div className="col-md-12">
                        <label>Subject : &nbsp;</label>
                        {this.state.viewEmailModalData.subject}
                      </div> : <></>
                    }
                    <div className="col-md-12">
                      <span className="tr_nl">
                        {ReactHtmlParser(this.state.viewEmailModalData.content)}
                      </span>
                    </div>
                    <Button onClick={this.closeViewEmailModal} color="primary">
                      Close
                    </Button>

                  </div>
                </Modal.Body>
              </Modal>

              <div className="tab-pane" id="nofifications">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotifications.map((data, key) => <>
                        <div class="accordion" id={"accordionExample" + key} >
                          <div class="card notify_card">
                            <div
                              class="card-header"
                              id={"headingOne" + key}
                              type="button"
                              style={{
                                borderRadius: "20px ",
                                padding: "20px 0px",
                                backgroundColor: "white",
                              }}
                              data-toggle="collapse"
                              data-target={"#collapseOne" + key}
                              aria-expanded="false"
                              aria-controls={"collapseOne" + key}
                            // onClick={() => this._onCheckNotification(item,key)}
                            >
                              <div>
                                <div className="row notify">
                                  <div className="col-md-2">
                                    <React.Fragment>
                                      <img
                                        src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                        style={{ width: "40px" }}
                                      ></img>
                                    </React.Fragment>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="job_details">
                                      {data.title}<br />
                                      {data.body}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="notify_time">
                                      {moment(data.createDate).format("LLL")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* ................ Email & SMS data tab ................. */}
              <div className="tab-pane" id="emailsms">
                <div className="job-section-tab">
                  {/* <EditJob mainData={this.state.reqId} /> */}
                  <h2>Email & SMS Log Details</h2>
                  {(this.state.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.state.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ?
                    <div className="mailSmsBtn">
                      <RiMailSendLine size={30}
                        style={{ cursor: 'pointer' }} onClick={() => this.openEmailSendModal()} />
                      {this.state.sentEmailModal == true ? <React.Fragment><EmailModal showShare={this.state.sentEmailModal} jobId={this.state.numJobId} closeMailModal={() => this.closeEmailSendModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}
                      <MdOutlineSms size={30} style={{ cursor: 'pointer' }} onClick={() => this.openSmsModal()} />
                      {this.state.sentSmsModal == true ? <React.Fragment><SmsModal showsms={this.state.sentSmsModal} jobId={this.state.numJobId} closeSms={() => this.closeSmsModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}

                    </div> :
                    <></>
                  }
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allEmailSmsLog.length > 0 ? <React.Fragment>
                        {this.state.allEmailSmsLog.map((data, key) => <React.Fragment key={key}>
                          <div class="accordion" id={"accordionExample" + key} >
                            <div class="card notify_card">
                              <div
                                class="card-header"
                                id={"headingOne" + key}
                                type="button"
                                style={{
                                  borderRadius: "20px ",
                                  padding: "20px 0px",
                                  backgroundColor: "white",
                                }}
                                data-toggle="collapse"
                                data-target={"#collapseOne" + key}
                                aria-expanded="false"
                                aria-controls={"collapseOne" + key}
                              // onClick={() => this._onCheckNotification(item,key)}
                              >
                                <div>
                                  <div className="row notify" onClick={() => this.openViewEmailModal(data)}>
                                    <div className="col-md-2">
                                      <React.Fragment>
                                        <img
                                          src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                          style={{ width: "40px" }}
                                        ></img>
                                      </React.Fragment>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="job_details">
                                        {data.logType} : {data.subject}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        {data.receiver}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        {SetDateFormat(data.receivedDate)} {SetTimeFormat(data.receivedDate)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>)}
                      </React.Fragment> : <React.Fragment></React.Fragment>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="viewModal" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="filter-head _fl document-hd">
                <h3 className="text-center center-text">
                  Trainer Availability
                </h3>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.closeViewModal()}
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                <div className="table-listing-app card">
                  <div className="table-responsive">
                    <table
                      width="100%"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <tr>
                        <th style={{ width: "16%" }}>Monday</th>
                        <th style={{ width: "16%" }}>Tuesday</th>
                        <th style={{ width: "16%" }}>Wednesday</th>
                        <th style={{ width: "16%" }}>Thursday</th>
                        <th style={{ width: "16%" }}>Friday</th>
                        <th style={{ width: "16%" }}>Saturday</th>
                      </tr>
                      <tr>
                        <td>
                          <div className="f2f_rate">F2F</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">OPI</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">F2F</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>NA</td>
                        <td>NA</td>
                        <td>NA</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="f2f_rate">VRI</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">F2F</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">F2F</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>NA</td>
                        <td>NA</td>
                        <td>NA</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="f2f_rate">VRI</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">VRI</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>
                          <div className="f2f_rate">OPI</div> 12:00 AM- 5:00 PM
                        </td>
                        <td>NA</td>
                        <td>NA</td>
                        <td>NA</td>
                      </tr>
                    </table>
                  </div>
                </div>
                {/* <div className="b-i-s _fl text-right _button-style m30">
                            <a href="#" className="blue-btn">Add language Pair</a>
                        </div> */}
                <div className="_button-style _fl text-center">
                  {/* <a className="grey-btn" onClick={() => this.closeModal()}>cancel</a> */}
                  {/* <a className="blue-btn">save</a> */}
                  {/* <a href="#" className="buledark-btn">save & reverse</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //...................... For Bid Modal....................... */}
        <div
          id="bid-modal"
          className="modal fade modelwindow largewindow"
          role="dialog"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p30">
                <div className="mtch-top-text">
                  <p>
                    <span>Project ID</span> {this.state.jobId}
                  </p>
                  <p>
                    <span>Category</span> {this.state.trainingCategory}
                  </p>
                  <p>
                    <span>Course</span> {this.state.trainingCourse}
                  </p>
                  <p>
                    <span>Client Name</span> {this.state.clientName}
                  </p>
                </div>

                <div className="mv-text _fl">
                  <h2>Vendor Bid Details</h2>
                </div>

                <div className="matching-vendor-table _fl sdw">
                  <div className="depr_table p10">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th colspan="2" style={{ width: "60%" }}>
                              Vendor Name :{" "}
                              <span className="th_1">
                                {this.state.bidVendorName}
                              </span>
                            </th>
                            {/* <th style={{ width: "10%" }} className="th_1">{this.state.bidVendorName}</th> */}
                          </tr>
                          <tr>
                            <td>Rate / Hour</td>
                            <td>$ {this.state.bidFee}</td>
                          </tr>

                          <tr className="tt-count">
                            <td className="f1">Total Bid</td>
                            <td style={{ color: "green" }}>
                              {" "}
                              $ {this.state.totalBidFee}
                            </td>
                          </tr>
                          {/* <tr>
                    <td>&nbsp;</td>
                    <td  ><a className="bidAssignBtn">Assign</a></td>
                  </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                      {this.state.quoteStatus === 2 ?
                        <a
                          href="javascript:void(0)"
                          className="bidAssignBtn"
                          onClick={() => {
                            this.vendoeAssigned(this.state.bidVendorId);
                          }}
                        >
                          Assign
                        </a>
                        : <></>
                      }
                      <a
                        href="javascript:void(0)"
                        className="bidAssignBtn"
                        onClick={() => {
                          this.closeBidModal();
                        }}
                      >
                        Close
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-backdrop fade show"
          id="backdrop"
          style={{ display: "none" }}
        ></div>
      </React.Fragment>
    );
  }
}
