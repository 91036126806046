import React, { Component } from "react";
import "./video.css";
import Track from "./Track";
import { Decoder } from "../../services/auth";
import { ImageName } from "../../enums";

class Participant extends Component {
  constructor(props) {
    super(props);

    const existingPublications = Array.from(
      this.props.participant.tracks.values()
    );
    const existingTracks = existingPublications.map(
      (publication) => publication.track
    );
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    this.state = {
      tracks: nonNullTracks,
      isVideoOff: false,
    };

    // this.eventClick = this.eventClick.bind(this);
  }

  componentDidMount() {
    if (!this.props.localParticipant) {
      this.props.participant.on("trackSubscribed", (track) => {
        console.log("trackSubscribed", track.sid);
        this.setState({
          isVideoOff: false
        })
        this.addTrack(track)
      });
      // this.props.participant.on("trackUnsubscribed", (track) => {
      //   console.log("trackUnsubscribed", track);
      // });
    }
  }
  eventuClick() {
    this.props.getParticipant();
  }
  addTrack(track) {
    this.setState({
      tracks: [...this.state.tracks, track],
    });
  }

  disableVideo() {
    this.setState({
      isVideoOff: true
    })
  }

  enableVideo() {
    this.setState({
      isVideoOff: false
    })
  }

  render() {
    return (
      <>
        {this.props.dominantParticpant === "true" ? (
          <>
            <div className="participant" id={this.props.participant.identity}>
              <div className="identity-text">
                {this.props.participant.identity}
              </div>

              {this.state.tracks.map((track) => (
                <Track key={track} filter={this.state.filter} track={track}
                  isVideoOn={this.props.isVideoOn} disableVideo={this.disableVideo.bind(this)} enableVideo={this.enableVideo.bind(this)} />
              ))}
            </div>
          </>
        ) : (
          <>
            <div
              className="participant-each"
              id={this.props.participant.identity}
              onClick={() => this.eventuClick()}
            >
              <div className="identity-text">
                {this.props.participant.identity}
              </div>
              {/* {this.state.isVideoOff ? <div className="track">
                <img src={ImageName.IMAGE_NAME.INTERPRETATION_JOB} alt="Avatar" className="avatar" style={{ width: "63%", margin: "20px 30px" }} />
              </div> : */}
              {this.state.tracks.map((track) => (
                <Track
                  key={track}
                  filter={this.state.filter}
                  track={track}
                  identity={this.props.participant.identity}
                  disableVideo={this.disableVideo.bind(this)}
                  enableVideo={this.enableVideo.bind(this)}
                />
              ))
              }
              {/* } */}
            </div>
          </>
        )}
      </>
    );
  }
}

export default Participant;
