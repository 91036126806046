import React from 'react';
import VideoPageVRI from '../VideoPagesVRI/VideoPageVRI';
import VideoPage from '../VideoPages/VideoPage';

export default class TwilioCalling extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.match.params.appointmentType == "VRI" ? <>
                    <VideoPageVRI appointmentType={this.props.match.params.appointmentType} jobId={this.props.match.params.jobId} email={this.props.match.params.email}/>
                </> : <>
                    <VideoPage appointmentType={this.props.match.params.appointmentType} jobId={this.props.match.params.jobId} email={this.props.match.params.email} />
                </>}
            </>
        )
    }
}