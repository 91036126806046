import React from "react";
import { styled } from "@mui/system"; //imported for modal
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { passwordValidator } from "../../../../validators";
import { toast } from "react-toastify";
import { ApiCall } from "../../../../services/middleware";
import { AlertMessage, ImageName } from "../../../../enums";
import { passWordRandomGenerate } from "../ClientContactList/function";
import { ErrorCode } from "../../../../services/constant";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 10,
        marginTop: 5,
        minWidth: 100,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
    },
}));

export default class ContactMenuPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null, //menu button
            resetPasswordData: ""
        }
    }

    componentDidMount() {

        document.getElementById("backdrop").style.display = "none";
        var classInstance = this;
        var passwordModal = document.getElementById("password-model-cc");
        var deleteModal = document.getElementById("delete-model-cc");
        window.onclick = function (event) {
            if (event.target == passwordModal) {
                classInstance.closeModal();
            } else if (event.target == deleteModal) {
                classInstance.closeModal();
            }
        };
    }

    //..............function for MenuButton close..............
    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };
    openPasswordModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("password-model-cc").style.display = "block";
        document.getElementById("password-model-cc").classList.add("show");
    };
    openDeleteModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("delete-model-cc").style.display = "block";
        document.getElementById("delete-model-cc").classList.add("show");
    };

    closeModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("password-model-cc").style.display = "none";
        document.getElementById("password-model-cc").classList.remove("show");
        document.getElementById("delete-model-cc").style.display = "none";
        document.getElementById("delete-model-cc").classList.remove("show");
    };


    //............Reset Password...........

    onResetPassword = async () => {
        let errorCount = 0;
        let validatePassword = passwordValidator(this.state.resetPasswordData)

        if (validatePassword.status === false) {
            toast.error(validatePassword.message, {
                hideProgressBar: true,
            });
            errorCount++;
        }
        // let pass = this.randomString(10, "aA#!");

        if (errorCount === 0) {

            let data = {
                staffid: this.props.value.clientcontactid,
                password: this.state.resetPasswordData,
            };

            let status = await ApiCall("userpasswordreset", data);
            if (
                status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                this.closeModal();
                toast.success(AlertMessage.MESSAGE.PASSWORD.RESET_SUCCESS);
            }
        }
    }

    onResetPassChange = (e) => {
        this.setState({
            resetPasswordData: e.target.value,
        });
    };

    //................funct for menuBtn on click................
    menuBtnhandleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleReset = () => {
        let mainPass = passWordRandomGenerate();
        this.setState({
            resetPasswordData: mainPass
        })
        this.handleMenuClose();
        this.openPasswordModal();
    };

    handleDelete = () => {
        this.handleMenuClose();
        this.openDeleteModal();
    };

    onCancel = () => {
        this.closeModal();
    };

    deleteItem = async () => {
        let data = {
            clientcontactid: this.props.value.clientcontactid,
            status: 2,
        };
        // console.log(">>>>>>>>>>dataaaa::", JSON.stringify(data));
        let status = await ApiCall("modifyapprovedclientcontactstatus", data);
        // console.log(">>>>>>>>>status:", status);
        this.closeModal();
        if (
            status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.DELETE.DELETED);
        }
    };

    cancelDelete = () => {
        // window.$("#status-model").modal("hide");
        this.closeModal();
    };


    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        return (
            <React.Fragment>

                {/* <img
                    src={ImageName.IMAGE_NAME.MENU_VERTICAL}
                    style={{ cursor: "pointer" }}
                    id={"basic-button"}
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(e)
                    }
                /> */}

                <button
                    className='btn btn-primary'
                    style={{ cursor: "pointer" }}
                    id={"basic-button"}
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(e)
                    }
                >Go To</button>
                <StyledMenu
                    id={"basic-menu"}
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem onClick={this.handleReset}>
                        Reset Password
                    </MenuItem>
                    <MenuItem onClick={this.handleDelete}>
                        Delete
                    </MenuItem>
                </StyledMenu>


                {/* ..............................delete modal............................. */}
                <div id="delete-model-cc" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content" style={{ padding: "10px" }}>
                            <div className="delete-head">Delete contact</div>
                            <div className="modal-body">
                                <div className="body-txt">
                                    Are You Sure to delete the contact?
                                </div>

                                <div className="_button-style m30 _fl text-center">
                                    <a
                                        href="javascript:void(0)"
                                        className="white-btn"
                                        style={{ textDecoration: "none", color: "grey" }}
                                        onClick={this.onCancel}
                                    >
                                        NO
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        className="blue-btn"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            width: "10%",
                                        }}
                                        onClick={() => this.deleteItem()}
                                    >
                                        Yes
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ..................Reset Password modal................................. */}
                <div
                    id="password-model-cc"
                    className="modal fade modelwindow"
                    role="dialog"
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you really want to reset the password for selected
                                            user?{" "}
                                        </h6>
                                    </center>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <center>
                                            <div className="col-md-6">
                                                <input

                                                    className="inputfield"
                                                    value={this.state.resetPasswordData}
                                                    onChange={(e) =>
                                                        this.onResetPassChange(e)
                                                    }
                                                />
                                            </div>
                                        </center>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "2%" }}
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.cancelDelete}
                                            >
                                                No
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="blue-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.onResetPassword}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal-backdrop fade show"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment>
        )
    }
}