import { EventEmitter } from 'events';
import { UserDescriptor } from './userdescriptor';
import { Users } from './data/users';
import { User } from './user';
import { parseTime, parseAttributes } from './util';
import { Logger } from './logger';
import { validateTypesAsync, literal } from '@twilio/declarative-type-validator';
import { Channel } from './channel';
import { CommandExecutor } from './commandexecutor';
import { EditMemberRequest, EditMemberResponse } from './interfaces/commands/editmember';
import isEqual from 'lodash.isequal';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type MemberEvents = {
  typingEnded: (member: Member) => void;
  typingStarted: (member: Member) => void;
  updated: (data: {
    member: Member;
    updateReasons: MemberUpdateReason[];
  }) => void;
};

const log = Logger.scope('Member');

interface MemberDescriptor {
  attributes?: Object;
  dateCreated: any;
  dateUpdated: any;
  identity: string;
  roleSid?: string;
  lastConsumedMessageIndex: number;
  lastConsumptionTimestamp: number;
  type?: MemberType;
  userInfo: string;
}

interface MemberState {
  attributes: any;
  dateCreated: Date;
  dateUpdated: Date;
  identity: string;
  isTyping: boolean;
  lastConsumedMessageIndex: number | null;
  lastConsumptionTimestamp: Date;
  roleSid: string;
  sid: string;
  type: MemberType;
  typingTimeout: any;
  userInfo: string;
}

interface MemberServices {
  users: Users;
  commandExecutor: CommandExecutor;
}

interface MemberLinks {
  self: string;
}

/**
 * The reason for the `updated` event being emitted by a member.
 */
type MemberUpdateReason =
  | 'attributes'
  | 'dateCreated'
  | 'dateUpdated'
  | 'roleSid'
  | 'lastConsumedMessageIndex'
  | 'lastConsumptionTimestamp';

/**
 * Push notification type of a member.
 */
type MemberType = 'chat' | 'sms' | 'whatsapp';

interface MemberUpdatedEventArgs {
  member: Member;
  updateReasons: MemberUpdateReason[];
}

/**
 * A member represents a remote client in a channel.
 */
class Member extends ReplayEventEmitter<MemberEvents> {

  private state: MemberState;

  /**
   * Channel that the remote client is a member of.
   */
  public readonly channel: Channel;

  private readonly links: MemberLinks;
  private readonly services: MemberServices;

  /**
   * @internal
   */
  constructor(
    data: MemberDescriptor,
    sid: string,
    channel: Channel,
    links: MemberLinks,
    services: MemberServices
  ) {
    super();

    this.channel = channel;
    this.links = links;
    this.services = services;

    this.state = {
      attributes: parseAttributes(data.attributes,
        'Retrieved malformed attributes from the server for member: ' + sid,
        log),
      dateCreated: data.dateCreated ? parseTime(data.dateCreated) : null,
      dateUpdated: data.dateCreated ? parseTime(data.dateUpdated) : null,
      sid: sid,
      typingTimeout: null,
      isTyping: false,
      identity: data.identity || null,
      roleSid: data.roleSid || null,
      lastConsumedMessageIndex: Number.isInteger(data.lastConsumedMessageIndex) ? data.lastConsumedMessageIndex : null,
      lastConsumptionTimestamp: data.lastConsumptionTimestamp ? parseTime(data.lastConsumptionTimestamp) : null,
      type: data.type || 'chat',
      userInfo: data.userInfo
    };

    if (!data.identity && !data.type) {
      throw new Error('Received invalid Member object from server: Missing identity or type of Member.');
    }
  }

  /**
   * Fired when the member has started typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly typingStarted = 'typingStarted';

  /**
   * Fired when the member has stopped typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member in question
   * @event
   */
  static readonly typingEnded = 'typingEnded';

  /**
   * Fired when the fields of the member have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Member} member - the member in question
   *     * {@link MemberUpdateReason}[] updateReasons - array of reasons for the update
   * @event
   */
  static readonly updated = 'updated';

  /**
   * The server-assigned unique identifier for the member.
   */
  public get sid(): string { return this.state.sid; }

  /**
   * Custom attributes of the member.
   */
  public get attributes(): Object { return this.state.attributes; }

  /**
   * Date this member was created on.
   */
  public get dateCreated(): Date { return this.state.dateCreated; }

  /**
   * Date this member was last updated on.
   */
  public get dateUpdated(): Date { return this.state.dateUpdated; }

  /**
   * Identity of the member.
   */
  public get identity(): string { return this.state.identity; }

  /**
   * Indicates whether the member is currently typing.
   */
  public get isTyping(): boolean { return this.state.isTyping; }

  /**
   * The index of the last consumed message by the member.
   * Note that retrieving messages on a client endpoint does not mean that messages are read,
   * please consider reading about the [Read Horizon feature](https://www.twilio.com/docs/api/chat/guides/consumption-horizon)
   * to find out about the proper way to mark messages as read.
   */
  public get lastConsumedMessageIndex(): number | null { return this.state.lastConsumedMessageIndex; }

  /**
   * Date of the most recent consumption horizon update.
   */
  public get lastConsumptionTimestamp(): Date { return this.state.lastConsumptionTimestamp; }

  public get roleSid(): string { return this.state.roleSid; }

  /**
   * Message type of the member.
   */
  public get type(): MemberType { return this.state.type; }

  /**
   * Internal method used to start or reset the typing indicator timeout (with event emitting).
   * @internal
   */
  _startTyping(timeout) {
    clearTimeout(this.state.typingTimeout);

    this.state.isTyping = true;
    this.emit('typingStarted', this);
    this.channel.emit('typingStarted', this);

    this.state.typingTimeout = setTimeout(() => this._endTyping(), timeout);
    return this;
  }

  /**
   * Internal method function used to stop typing indicator timeout (with event emitting).
   * @internal
   */
  _endTyping() {
    if (!this.state.typingTimeout) { return; }

    this.state.isTyping = false;
    this.emit('typingEnded', this);
    this.channel.emit('typingEnded', this);

    clearInterval(this.state.typingTimeout);
    this.state.typingTimeout = null;
  }

  /**
   * Internal method function used update local object's property roleSid with a new value.
   * @internal
   */
  _update(data) {
    let updateReasons: MemberUpdateReason[] = [];

    const updateAttributes =
      parseAttributes(
        data.attributes,
        'Retrieved malformed attributes from the server for member: ' + this.state.sid,
        log);

    if (data.attributes && !isEqual(this.state.attributes, updateAttributes)) {
      this.state.attributes = updateAttributes;
      updateReasons.push('attributes');
    }

    const updatedDateUpdated = parseTime(data.dateUpdated);
    if (data.dateUpdated && (updatedDateUpdated?.getTime() !== this.state.dateUpdated?.getTime())) {
      this.state.dateUpdated = updatedDateUpdated;
      updateReasons.push('dateUpdated');
    }

    const updatedDateCreated = parseTime(data.dateCreated);
    if (data.dateCreated && (updatedDateCreated?.getTime() !== this.state.dateCreated?.getTime())) {
      this.state.dateCreated = updatedDateCreated;
      updateReasons.push('dateCreated');
    }

    if (data.roleSid && this.state.roleSid !== data.roleSid) {
      this.state.roleSid = data.roleSid;
      updateReasons.push('roleSid');
    }

    const indexIsValid = Number.isInteger(data.lastConsumedMessageIndex) || data.lastConsumedMessageIndex === null;
    if (indexIsValid && (this.state.lastConsumedMessageIndex !== data.lastConsumedMessageIndex)) {
      this.state.lastConsumedMessageIndex = data.lastConsumedMessageIndex;
      updateReasons.push('lastConsumedMessageIndex');
    }

    const updatedTimestamp = parseTime(data.lastConsumptionTimestamp);
    if (data.lastConsumptionTimestamp && (updatedTimestamp?.getTime() !== this.state.lastConsumptionTimestamp?.getTime())) {
      this.state.lastConsumptionTimestamp = updatedTimestamp;
      updateReasons.push('lastConsumptionTimestamp');
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { member: this, updateReasons: updateReasons });
    }

    return this;
  }

  /**
   * Get the user descriptor for this member. Supported only for members of type `chat`.
   */
  public async getUserDescriptor(): Promise<UserDescriptor> {
    if (this.type != 'chat') {
      throw new Error('Getting User Descriptor is not supported for this Member type: ' + this.type);
    }

    return this.services.users.getUserDescriptor(this.state.identity);
  }

  /**
   * Get the user for this member and subscribes to it. Supported only for members of type `chat`.
   */
  public async getUser(): Promise<User> {
    if (this.type != 'chat') {
      throw new Error('Getting User is not supported for this Member type: ' + this.type);
    }

    return this.services.users.getUser(this.state.identity, this.state.userInfo);
  }

  /**
   * Remove the member from the channel.
   */
  public async remove(): Promise<void> {
    return this.channel.removeMember(this);
  }

  /**
   * Update the attributes of the member.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<Member> {
    await this.services.commandExecutor.mutateResource<EditMemberRequest, EditMemberResponse>(
      'post',
      this.links.self,
      {
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );

    return this;
  }
}

export {
  MemberDescriptor,
  MemberServices,
  Member,
  MemberUpdateReason,
  MemberType,
  MemberUpdatedEventArgs
};
