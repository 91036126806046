import React from "react";


export default class EditServiceInfoModal extends React.Component {
    constructor(props) {
        super(props);
    }




    openServiceInfoEditModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("editServiceInfoModal").style.display = "block";
        document.getElementById("editServiceInfoModal").classList.add("show");
    };
    closeServiceInfoEditModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("editServiceInfoModal").style.display = "none";
        document.getElementById("editServiceInfoModal").classList.remove("show");
    };

    render() {
        return (
            <React.Fragment>
                {/* <div id="editServiceInfoModal" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-xl modal-dialog-centered decline-modal-width">
                        <div className="modal-content" style={{ width: "100%" }}>
                            <div className="cancel-job-head edit-service_info-head">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Edit Service Info</h2>
                                        <button className="close-page">
                                            <CgCloseO size={25} style={{ color: "#fff" }} onClick={this.closeServiceInfoEditModal} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="create-jeneral-wrap _fl">
                                    <div className="create-row-app">
                                        <div className="row" style={{ marginBottom: "15px" }}>
                                            <div className="col-md-12">
                                                <table className="dataTable_project">
                                                    <tr>
                                                        <th style={{ width: "14%" }}>Service Type</th>
                                                        <th style={{ width: "20%", paddingLeft: "2%" }}>
                                                            Source Language
                                                        </th>
                                                        <th style={{ width: "20%", paddingLeft: "2%" }}>
                                                            Target Language
                                                        </th>
                                                        <th style={{ width: "15%", paddingLeft: "2%" }}>
                                                            Document Name
                                                        </th>
                                                        <th style={{ width: "15%", paddingLeft: "2%" }}>Add Notes</th>
                                                        <th style={{ width: "8%", paddingLeft: "2%" }}>
                                                            Upload Document
                                                        </th>
                                                        <th style={{ width: "8%", paddingLeft: "2%" }}></th>
                                                    </tr>
                                                    {this.state.translationProjectArr.map((data, i) => (
                                                        <tr key={i}>
                                                            <td style={{ width: "14%" }}>
                                                                <SelectBox
                                                                    optionData={this.state.allTranslationService}
                                                                    value={data.selectedServiceType}
                                                                    onSelectChange={(value) => this.serviceChange(value, i)}
                                                                ></SelectBox>
                                                            </td>
                                                            <td style={{ width: "20%", paddingLeft: "2%" }}>
                                                                <SelectBox
                                                                    optionData={this.state.allLanguageArr}
                                                                    value={data.selectedSourceLanguage}
                                                                    onSelectChange={(value) =>
                                                                        this.sourceLanguageChange(value, i)
                                                                    }
                                                                ></SelectBox>
                                                            </td>
                                                            <td style={{ width: "20%", paddingLeft: "2%" }}>
                                                                <MultiSelectBox
                                                                    optionData={this.state.allLanguageArr}
                                                                    value={data.selectedTargetLanguage}
                                                                    onSelectChange={(value) =>
                                                                        this.targetLanguageChange(value, i)
                                                                    }
                                                                ></MultiSelectBox>
                                                            </td>
                                                            <td style={{ width: "15%", paddingLeft: "2%" }}>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="Enter Name"
                                                                    onChange={(e) => {
                                                                        this.documentNameChange(e, i);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ width: "15%", paddingLeft: "2%" }}>
                                                                <input
                                                                    type="text"
                                                                    className="in-field3"
                                                                    placeholder="Enter Notes"
                                                                    onChange={(e) => {
                                                                        this.noteChange(e, i);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "8%",
                                                                    paddingLeft: "2%",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                {data.uploadName.length > 0 ? (
                                                                    <React.Fragment>
                                                                        <img
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                margin: "auto",
                                                                                width: "25px",
                                                                            }}
                                                                            src={ImageName.IMAGE_NAME.UPLOADED_FILE}
                                                                            onClick={() => this.showDocument(i)}
                                                                        />
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        <div className="upload-profile">
                                                                            <label
                                                                                for={"profile_image" + i}
                                                                                data-toggle="tooltip"
                                                                                data-placement="top"
                                                                                title={data.uploadName}
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        margin: "auto",
                                                                                    }}
                                                                                    src={ImageName.IMAGE_NAME.ADD_WITH_CIRCLE_BG}
                                                                                />
                                                                                <input
                                                                                    type="file"
                                                                                    id={"profile_image" + i}
                                                                                    style={{ display: "none" }}
                                                                                    onChange={(e) => {
                                                                                        this.onUploadDocument(e, i);
                                                                                    }}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "8%",
                                                                    paddingLeft: "2%",
                                                                    paddingTop: "1px",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                {i > 0 ? (
                                                                    <img
                                                                        src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                                        style={{ cursor: "pointer" }}
                                                                        alt=""
                                                                        onClick={() => this.removeObject(i)}
                                                                    />
                                                                ) : (
                                                                    <React.Fragment></React.Fragment>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </table>

                                                <div className="_fl">
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <button
                                                                className="add_more_project_btn"
                                                                onClick={this.addTranslationArr}
                                                            >
                                                                ADD MORE
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="web-form-bx selct">
                                                    <div className="_button-style _fl text-center">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="white-btn"
                                                            onClick={this.closeServiceInfoEditModal}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            cancel
                                                        </a>
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="blue-btn"
                                                            style={{ textDecoration: "none" }}
                                                            onClick={this.closeServiceInfoEditModal}
                                                        >
                                                            Update
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </React.Fragment>
        )
    }
}