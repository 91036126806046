import React from "react";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

export default class ChatGroupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setGroup: ""
        }
    }

    handleListItemClick = (data) => {
        this.setState({
            setGroup: data.label
        });
        this.props.changeGroupValue(data.value)
    }
    render() {
        return (
            <>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <List component="nav" aria-label="main mailbox folders">
                        {this.props.value.map((data, i) => <>
                            <Tooltip title={data.value.participantName.join(",")} placement="right-start">
                                <ListItemButton
                                    selected={data.label === this.state.setGroup}
                                    onClick={() => this.handleListItemClick(data)}
                                >
                                    <ListItemText primary={data.label} />
                                    <ListItemIcon>
                                            <InfoOutlinedIcon />
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                            {/* {this.props.value.length > i ?
                                <Divider /> : <></>
                            } */}
                        </>
                        )}
                    </List >
                </Box>
            </>
        )
    }
}