import React from "react";
import { Decoder } from "../../services/auth";
import "./datapreview.css";

export default class DataPreviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            requestPayload: "",
            responsePayload: ""
        }
    }

    changeRequestPayload = (e) => {
        this.setState({
            requestPayload: e.target.value
        })
    }

    showDecriptedPaylod = () => {
        let value = "";
        if (this.state.requestPayload !== "") {
            value = Decoder.decode(this.state.requestPayload);
            // console.log("Show Response >>>> ", value);
            this.setState({
                responsePayload: JSON.stringify(value.data)
            })
        } else {
            alert("Request Canot be blank!")
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="enc_dec_div">
                    <div className="row" style={{ paddingBottom: "35px" }}>
                        <div className="col-md-8">
                            <h4>Request Payload (encrypted)</h4>
                            <textarea
                                // style={{ height: "100px", resize: "none", width: "90%" }}
                                className="dataView_area"
                                value={this.state.requestPayload}
                                onChange={this.changeRequestPayload}></textarea>
                        </div>
                        <div className="col-md-8">
                            <button className="dec_res_btn" onClick={this.showDecriptedPaylod}>Submit</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Response Payload (Decrypted)</h4>
                            <textarea
                                // style={{ height: "100px", resize: "none", width: "90%" }}
                                className="dataView_area"
                                value={this.state.responsePayload}
                                disabled={true}></textarea>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}