import React, { Component } from "react";
import Avatar from "react-avatar";
import "./video.css";
import { ImageName } from "../../enums";

class Track extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      trackDisabled: false
    };
    this.getAvatar = this.getAvatar.bind(this);
  }

  componentDidMount() {
    if (
      this.props.track !== null &&
      typeof this.props.track.attach !== "undefined"
    ) {
      console.log("Track type >>> ", this.props.track)
      var child = this.props.track.attach();
      child.style.transform = "scale(1, 1)";
      child.style["-webkit-transform"] = "scale(1, 1)";
      this.ref.current.classList.add(this.props.track.kind);
      this.ref.current.appendChild(child);
      var redd = this;
      this.props.track.on("disabled", () => {
        console.log(
          "Hide the associated <video> element and show an avatar image."
        );
        this.props.disableVideo();
        // if (this.props.track.kind === 'video') {
        //   this.setState({ trackDisabled: true });
        // }
        // redd.ref.current.appendChild(redd.getAvatar(redd.props.identity));
      });
      this.props.track.on("enabled", () => {
        console.log(
          "Show the associated <video> element and hide the avatar image."
        );
        this.props.enableVideo();
        // if (this.props.track.kind === 'video') {
        //   this.setState({ trackDisabled: false });
        // }
      });
    }
  }
  getAvatar(name) {
    return (<>
      <Avatar
        color={Avatar.getRandomColor("sitebase", ["red", "green", "blue"])}
        name={name}
      />

    </>
    );
  }
  render() {
    // const { trackDisabled } = this.state;
    // const { identity } = this.props;
    return <div className="track" ref={this.ref}>
      {/* {trackDisabled && this.getAvatar(identity)} */}

    </div>;
  }
}

export default Track;
