import React from "react";
import { ListItem } from "@material-ui/core";
import { ImageName } from "../../enums";
import moment from "moment";

class ChatItemView extends React.Component {


    onDownload = (pos) => {
        window.open(pos, "_blank");
        // var file_path = pos;
        // var a = document.createElement('a');
        // a.href = file_path;
        // a.download = "Chat_Document";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
    };
    render() {
        const { message, email } = this.props;
        const isOwnMessage = message.author === email;


        return (<>
            <ListItem style={styles.listItem(isOwnMessage)}>
                <div style={styles.author}>{message.author}</div>
                <div style={styles.container(isOwnMessage)}>
                    {urlType(message.body).isChatData ?
                        message.body :
                        <>
                            {urlType(message.body).type == "doc" ? <>
                                <img src={ImageName.IMAGE_NAME.UPLOADED_FILE} alt="body" style={{ cursor: "pointer", }} onClick={() => this.onDownload(message.body)} /> </> :
                                <img src={message.body} alt="body" style={{ width: "100px", height: "100px" }} />
                            }
                        </>
                    }
                    <div style={styles.timestamp}>
                        {/* {new Date(message.timestamp.toISOString()).toLocaleString()} */}
                        {message.timestamp ? moment(message.timestamp).format('MM/DD/YYYY') + " " + moment(message.timestamp).format("LT") : ""}
                    </div>
                </div>
            </ListItem>

            {/* <div className={isOwnMessage ? "participants-chat-row reply" :  "participants-chat-row"} style={styles.listItem(isOwnMessage)} >
            <div style={styles.author}>{message.author}</div>
                <div style={styles.container(isOwnMessage)}>
                    {urlType(message.body).isChatData ?
                        message.body :
                        <>
                            {urlType(message.body).type == "doc" ? <>
                                <img src={ImageName.IMAGE_NAME.UPLOADED_FILE} alt="body" style={{ cursor: "pointer", }} onClick={() => this.onDownload(message.body)} /> </> :
                                <img src={message.body} alt="body" style={{ width: "100px", height: "100px" }} />
                            }
                        </>
                    }
                    <div style={styles.timestamp}>
                        {new Date(message.dateCreated.toISOString()).toLocaleString()}
                    </div>
                </div>
            </div> */}
        </>
        );
    }
}

// ====================

// check the url
function isValidHttpUrl(link) {
    const regex = new RegExp(WEB_URI_REGEX);
    return regex.test(link);
}


// check the url is image or file
function urlType(link) {
    let validateCheckUrl = false;
    if (link) {
        validateCheckUrl = isValidHttpUrl(link);
    }

    let resData = { type: "", name: "", isChatData: true };
    if (validateCheckUrl) {
        let splitData = link.split("/");

        let docName = splitData[splitData.length - 1];

        let splitName = docName.split(".");
        let docType = splitName[splitName.length - 1].toLowerCase();

        if (DOC_TYPE[docType] !== undefined) {
            resData.name = docName;
            resData.type = DOC_TYPE[docType];
            resData.isChatData = false;
        }
    }
    // console.log("Res data from url check", resData)
    return resData;
}

const WEB_URI_REGEX = '((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)';

const DOC_TYPE = {
    "jpg": "img",
    "jpeg": "img",
    "png": "img",
    "jfif": "img",
    "pjpeg": "img",
    "pjp": "img",
    "pdf": "doc",
    "doc": "doc",
    "docx": "doc",
    "xls": "doc",
    "xlsx": "doc",
    "ppt": "doc",
    "pptx": "doc",
    "txt": "doc",
}

const styles = {
    listItem: (isOwnMessage) => ({
        flexDirection: "column",
        alignItems: isOwnMessage ? "flex-end" : "flex-start",
    }),
    container: (isOwnMessage) => ({
        maxWidth: "75%",
        borderRadius: 12,
        padding: 16,
        color: "black",
        fontSize: 14,
        backgroundColor: isOwnMessage ? "#2698c5" : "#f1f1f1",
    }),
    author: { fontSize: 14, color: "gray" },
    timestamp: {
        fontSize: 10,
        color: "black",
        textAlign: "right",
        paddingTop: 4,
    },
};

export default ChatItemView;
