import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AlertMessage, ImageName } from "../../../../enums";
import history from "../../../../history";
import { Decoder } from "../../../../services/auth";
import {
    consoleLog,
    getLookUpDataFromAPI,
} from "../../../../services/common-function";
import { ErrorCode } from "../../../../services/constant";
import { ApiCall } from "../../../../services/middleware";
import { styled, Box } from "@mui/system"; //imported for modal
import Switch from "@mui/material/Switch";

import "./configuration.css";
import { InputText, SelectBox } from "../../SharedComponents/inputText";
import { Link } from "react-router-dom";
// import { Draggable } from "react-drag-reorder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LotteLoader from "../../../Loader/LotteLoader";
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import { emailValidator } from "../../../../validators";


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    borderRadius: "30px/50px",
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 30,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 3,
        "&.Mui-checked": {
            transform: "translateX(22px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#335b7b",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 14,
        height: 14,
        borderRadius: 7,
        // transition: theme.transitions.create(["width"], {
        //   duration: 200,
        // }
        // ),
    },
    "& .MuiSwitch-track": {
        borderRadius: 10 / 50,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#993921",
        boxSizing: "border-box",
    },
}));

const hourArr = [
    {
        label: "Hour",
        value: 0,
    },
    {
        label: "Minutes",
        value: 1,
    },
];
const radiusArr = [];
const outRadius = [];
const timeArr = [];
// const shortlistArr = ["Total Cost", "Distance", "Relationship", "gender"];
// const shortlistArrInterpretors = ["Total Cost", "Relationship"];
// fake data generator
const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#00a0df" : "",

    // styles we need to apply on draggables
    ...draggableStyle,


    width: "300px",

    padding: "10px",
    border: "1px solid hsl(0, 0%, 80%)",
    marginBottom: "5px",
    borderRadius: "10px",
    boxShadow:
        "0px 0px 4px 0px rgb(0 0 0 / 28%)",
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
});

export default class ConfigurationPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: true,
            userType: "4",
            confId: "2",
            broadcastStatus: 0,
            broadcastStatusTranslator: 0,
            confirmStatus: 0,
            interpreterNo: "",
            totalTranslator: "",
            notifyArr: [],
            notifyData: "",
            timeData: {},
            areaArr: [],
            areaData: "",
            outAreaArr: [],
            outAreaData: "",
            shortlistArr: [],
            shortlistData: "",
            newCriteriaList: [],
            // newCriteriaListForInterpretors: ["Total Cost", "Relationship"],
            newCriteriaListForInterpretors: [],

            allBillingEmailArr: [],
            billingEmailText: "",
            errorJoiningName: false,
            valueType: 1
        };
    }

    componentWillMount() {
        this.load();

    }

    componentDidMount() {
        let mainData = this.props.location;
        let preData = mainData.state;
        // console.log("______", preData);
        window.scrollTo(0, 0);
        // this.load();
    }

    onTabClick = (type) => {
        if (type === 4) {
            this.setState({
                isLoad: true,
                userType: 4,
                confId: 2,
                valueType: 1,
                allBillingEmailArr: [],
                billingEmailText: "",
                errorJoiningName: false
            })
        } else {
            this.setState({
                isLoad: true,
                userType: 3,
                confId: 1,
                valueType: 0,
                allBillingEmailArr: [],
                billingEmailText: "",
                errorJoiningName: false
            })
        }
        this.listApi();
    }

    load = async () => {
        // consoleLog(">>>>>>>>>>>>", detailData);
        this.listApi();
    };

    listApi = async () => {
        let notifyObj = {},
            radiusObj = {},
            outRadiusObj = {},
            shortlistArrData = [],
            shortlistObj = {},
            timeObj = {};
        let val = {
            lookuptype: "SHORTLIST_CRITERIA_TYPE",
        };
        let lookres = await ApiCall("getlookuplistbylookuptype", val);
        const res = await ApiCall("getConfigurationDetails");
        let lookUpRes = await getLookUpDataFromAPI();

        // console.log("resData::::", Decoder.decode(lookres.data.payload));
        if (
            lookres.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            lookres.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let lookupdecodeData = Decoder.decode(lookres.data.payload);
            // console.log("Payload data>>>", lookupdecodeData.data.lookupdata);

            for (let h = 0; h < lookupdecodeData.data.lookupdata.length; h++) {
                // shortlistArr.push({
                //   label: lookupdecodeData.data.lookupdata[h].name,
                //   value: lookupdecodeData.data.lookupdata[h].id,
                // })

                shortlistArrData.push({
                    label: lookupdecodeData.data.lookupdata[h].name,
                    value: lookupdecodeData.data.lookupdata[h].id,
                });
            }

            // consoleLog("%%%%5", shortlistArrData);

            this.setState({
                shortlistArr: shortlistArrData,
            });
        }

        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            const decodeData = Decoder.decode(res.data.payload);
            consoleLog("Configuration DAta >>>>>>>>", decodeData);

            for (var i = 50; i <= 1000; i += 50) {
                outRadius.push({
                    label: i.toString(),
                    value: i
                });
            }

            for (let m = 0; m < 60; m++) {
                radiusArr.push({
                    label: (m + 1).toString(),
                    value: m + 1,
                });
                timeArr.push({
                    label: (m + 1).toString(),
                    value: m + 1,
                });
            }

            for (let i = 0; i < timeArr.length; i++) {
                if (decodeData.data[1].notifyTimeBeforeJob === timeArr[i].label) {
                    notifyObj = {
                        label: timeArr[i].label,
                        value: timeArr[i].value,
                    };
                }
            }
            for (let j = 0; j < hourArr.length; j++) {
                if (decodeData.data[1].notifyTimeUnit === hourArr[j].label) {
                    timeObj = {
                        label: hourArr[j].label,
                        value: hourArr[j].value,
                    };
                }
            }

            for (let k = 0; k < radiusArr.length; k++) {
                if (decodeData.data[1].radius === radiusArr[k].value) {
                    radiusObj = {
                        label: radiusArr[k].label,
                        value: radiusArr[k].value,
                    };
                }
            }

            for (let m = 0; m < outRadius.length; m++) {
                if (decodeData.data[1].outRadius === outRadius[m].value) {
                    outRadiusObj = {
                        label: outRadius[m].label,
                        value: outRadius[m].value,
                    };
                }
            }

            for (let l = 0; l < shortlistArrData.length; l++) {
                if (decodeData.data[1].criteria === shortlistArrData[l].value) {
                    shortlistObj = {
                        label: shortlistArrData[l].label,
                        value: shortlistArrData[l].value,
                    };
                }
            }

            let translatorCriteriaArray = decodeData.data[1].TransletersCriteria.split(",");
            let interpretorCriteriaArray = decodeData.data[1].InterpretersCriteria.split(",");
            // consoleLog("translator criteria:", translatorCriteriaArray);
            // consoleLog("Interpretor Criteria:", interpretorCriteriaArray);

            let billEmails = [];
            if (decodeData.data[this.state.valueType].billingEmails !== "" && decodeData.data[this.state.valueType].billingEmails !== null) {
                billEmails = decodeData.data[this.state.valueType].billingEmails.split(",");
            }

            this.setState({
                interpreterNo: decodeData.data[1].noOfInterpreter,
                totalTranslator: decodeData.data[1].NoOfTranslaters,
                broadcastStatus: decodeData.data[1].autoBroadcastInterpreter,
                broadcastStatusTranslator: decodeData.data[1].AutoBroadcastTranslater,
                confirmStatus: decodeData.data[1].autoConfirm,
                notifyData: notifyObj,
                timeData: timeObj,
                areaData: radiusObj,
                outAreaData: outRadiusObj,
                shortlistData: shortlistObj,
                newCriteriaListForInterpretors: interpretorCriteriaArray,
                newCriteriaList: translatorCriteriaArray,
                allBillingEmailArr: billEmails,
                isLoad: false
            });
        }
    };

    onInterpreterNoChange = (data) => {
        this.setState({
            interpreterNo: data,
        });
    };
    onTranslatorNoChange = (data) => {
        this.setState({
            totalTranslator: data,
        });
    };
    onNotifyInerpreterChange = (data) => {
        let obj = { value: data.value, label: data.label };
        this.setState({ notifyData: obj }); //  convert to obj
    };
    onInterpreterAreaChange = (data) => {
        let obj = { value: data.value, label: data.label };
        this.setState({ areaData: obj }); //  convert to obj
    };
    onInterpreterOutAreaChange = (data) => {
        let obj = { value: data.value, label: data.label };
        this.setState({ outAreaData: obj }); //  convert to obj
    };
    onTimeFormatChange = (data) => {
        this.setState({ timeData: data }); //  convert to obj
    };
    onShortlistChange = (data) => {
        let obj = { value: data.value, label: data.label };
        this.setState({ shortlistData: obj }); //  convert to obj
    };
    onBroadcastStatusChange = async (index) => {
        let stat = 0;
        if (this.state.broadcastStatus === 0) {
            stat = 1;
            toast.success("Auto Broadcasting Enabled");
        } else {
            stat = 0;
            toast.error("Auto Broadcasting Disabled");
        }

        this.setState({
            broadcastStatus: stat,
        });

        // let data = {
        //   staffid: arrData[index].userId,
        //   status: stat.toString(),
        //   staffusertypetd: arrData[index].userTypeId,
        // };

        // let status = await ApiCall("adminstaffstatuschange", data);
        // if (
        //   status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        //   status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        // ) {

        //   toast.success(AlertMessage.MESSAGE.UPDATE_STATUS.SUCCESS);
        // }
    };
    onBroadcastStatusTranslatorChange = async (index) => {
        let stat = 0;
        if (this.state.broadcastStatusTranslator === 0) {
            stat = 1;
            toast.success("Auto Broadcasting for Translator Enabled");
        } else {
            stat = 0;
            toast.error("Auto Broadcasting for Translator Disabled");
        }

        this.setState({
            broadcastStatusTranslator: stat,
        });
    };
    onConfirmStatusChange = async (index) => {
        let stat = 0;
        if (this.state.confirmStatus === 0) {
            stat = 1;
            toast.success("Auto Confirmation Enabled");
        } else {
            stat = 0;
            toast.error("Auto Confirmation Disabled");
        }

        this.setState({
            confirmStatus: stat,
        });

        // let arrData = this.state.listData;
        // let stat = 0;
        // if (arrData[index].status === 0) {
        //   stat = 1;
        // } else {
        //   stat = 0;
        // }
        // arrData[index].status = stat;
        // this.setState({
        //   listData: arrData,
        // });

        // let data = {
        //   staffid: arrData[index].userId,
        //   status: stat.toString(),
        //   staffusertypetd: arrData[index].userTypeId,
        // };

        // let status = await ApiCall("adminstaffstatuschange", data);
        // if (
        //   status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        //   status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        // ) {

        //   toast.success(AlertMessage.MESSAGE.UPDATE_STATUS.SUCCESS);
        // }
    };

    getChangedPos = (currentPos, newPos) => {
        console.log(currentPos, newPos);
        let arr = [];
        //  shortlistArr.map((obj) => {

        //  })
        // shortlistArr[currentPos]
        const newItems = this.state.newCriteriaList;
        // newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]).forEach((item,index)=>{
        //   item.order = index;
        // });
        newItems
            .splice(newPos, 0, newItems.splice(currentPos, 1)[0])
            .forEach((item, index) => {
                consoleLog("item", item);
                item.order = index;
            });

        consoleLog("newarr", newItems);
        this.setState({
            newCriteriaList: newItems,
        });
    };

    getChangedPosInterpreters = (currentPos, newPos) => {
        console.log(currentPos, newPos);
        const newItems = this.state.newCriteriaListForInterpretors;
        newItems
            .splice(newPos, 0, newItems.splice(currentPos, 1)[0])
            .forEach((item, index) => {
                consoleLog("item", item);
                item.order = index;
            });

        // consoleLog("newarrInterpretors", newItems);
        this.setState({
            newCriteriaListForInterpretors: newItems,
        });
    };

    onCancel = () => {
        return history.push("/adminDashboard");
    };

    onUpdate = async () => {
        let data = {
            AutoBroadcastTranslater: this.state.broadcastStatusTranslator.toString(),
            autoBroadcastInterpreter: this.state.broadcastStatus.toString(),
            autoConfirm: this.state.confirmStatus.toString(),
            noOfInterpreter: this.state.interpreterNo,
            NoOfTranslaters: this.state.totalTranslator,
            notifyTimeBeforeJob: this.state.notifyData.label,
            notifyTimeUnit:
                this.state.timeData.label == null ||
                    this.state.timeData.label == undefined
                    ? ""
                    : this.state.timeData.label,
            radius: this.state.areaData.label,
            outRadius: this.state.outAreaData.label,
            TransletersCriteria: this.state.newCriteriaList.join(","),
            InterpretersCriteria: this.state.newCriteriaListForInterpretors.join(","),
            userType: this.state.userType,
            id: this.state.confId,
            billingEmails: this.state.allBillingEmailArr.length > 0 ? this.state.allBillingEmailArr.join(",") : ""
        };

        consoleLog("update request payload", data);
        let res = await ApiCall("updateConfigurationDetails", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            window.scrollTo(0, 0);
            toast.success(AlertMessage.MESSAGE.CONFIGURATION.UPDATE_VENDOR_SUCCESS);
        } else {
            toast.success(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR);
        }
    };

    dragEnter = (currentPos, newPos) => {
        console.log("current", currentPos);
        console.log("new", newPos);
        //  dragOverItem.current = position;
        //console.log(e.target.innerHTML);
    };

    //react-beautiful DND function.......

    onDragEndInterpreters = (result) => {
        consoleLog("Item State::", this.state.newCriteriaListForInterpretors)
        // dropped outside the list
        if (!result.destination) {
            return;
        }


        const newCriteriaListForInterpretors = reorder(
            this.state.newCriteriaListForInterpretors,
            result.source.index,
            result.destination.index
        );

        this.setState({
            newCriteriaListForInterpretors
        });
    }

    onDragEndTranslators = (result) => {
        consoleLog("Item State::", this.state.newCriteriaList)
        // dropped outside the list
        if (!result.destination) {
            return;
        }


        const newCriteriaList = reorder(
            this.state.newCriteriaList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            newCriteriaList
        });
    }




    deleteEmail = (pos) => {
        let arr = this.state.allBillingEmailArr,
            brr = [];
        arr.map((data, i) => {
            if (i != pos) {
                brr.push(data)
            }
        });
        // console.log("Length>>", brr.length)
        this.setState({
            allBillingEmailArr: brr
        })
    }

    onChangeEmail = (e) => {

        if (e.charCode == 13 || e.charCode == 32) {
            // alert('Enter... (KeyPress, use charCode)');
            // if(emailValidator(e.target.value)){

            // }
            // console.log("ASD>>", emailValidator(e.target.value));
            let validateEmail = emailValidator(e.target.value);

            if (validateEmail.status) {
                let arr = this.state.allBillingEmailArr;
                arr.push(e.target.value);
                this.setState({
                    allBillingEmailArr: arr,
                    billingEmailText: ''
                })
            } else {
                this.setState({
                    errorJoiningName: true
                })
            }
        } else {
            this.setState({
                billingEmailText: e.target.value
            })
        }
    }

    changeEmailText = (e) => {
        let val = e.target.value;
        let temp = val.split(',');
        if (temp.length > 1) {
            let arr = this.state.allBillingEmailArr;
            for (let i = 0; i < temp.length - 1; i++) {
                if (temp[i] != "") {
                    arr.push(temp[i]);

                }
            }
            this.setState({
                allBillingEmailArr: arr,
            })
        }
        this.setState({
            billingEmailText: temp[temp.length - 1],
            errorJoiningName: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" autoClose={1000} />
                <div className="component-wrapper activ_project_rqst_clnt cofigpanl">
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> / Configuration Panel
                    </div>{" "}
                    <h2>Configration Panel</h2>
                    <div className="job-details-tab _fl sdw cofigpanl_wid">
                        <div className="cofigpanl_top">
                            <div className="row">
                                <div class="col-md-8">
                                    <ul className="nav nav-tabs" style={{ marginTop: "2px" }}>
                                        <li className="nav-item">
                                            {" "}
                                            <a
                                                className="nav-link configuration_tab active"
                                                data-toggle="tab"
                                                href="#vendorsetting"
                                                onClick={() => this.onTabClick(4)}
                                            >
                                                <div className="taber" style={{ paddingTop: "9px" }}>
                                                    <figure>
                                                        <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                                                    </figure>{" "}
                                                    Vendor Settings
                                                </div>
                                            </a>{" "}
                                        </li>
                                        <li className="nav-item">
                                            {" "}
                                            <a
                                                className="nav-link configuration_tab"
                                                data-toggle="tab"
                                                href="#clientsetting"
                                                onClick={() => this.onTabClick(3)}
                                            >
                                                <div className="taber" style={{ paddingTop: "9px" }}>
                                                    <figure>
                                                        <img src={ImageName.IMAGE_NAME.TABUSERICON} />
                                                    </figure>
                                                    Client Settings
                                                </div>
                                            </a>{" "}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-4">
                                    <div class="web_btn f-right">
                                        <a href="javascript:void(0)" onClick={this.onCancel}>
                                            Cancel
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            class="blue"
                                            onClick={this.onUpdate}
                                        >
                                            Update
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-content">
                            <div className="tab-pane" id="clientsetting-disabled">
                                <div className="job-section-tab">
                                    <div className="job-section-tab">
                                        <div className="my-form-hd _fl">
                                            <h3 className="open">Job Automation </h3>

                                            <div className="swize" style={{ display: "flex" }}>
                                                <label>Auto Broadcasting</label>
                                                {this.state.broadcastStatus === 1 ? (
                                                    <AntSwitch
                                                        checked={true}
                                                        inputProps={{
                                                            "aria-label": "ant design",
                                                        }}
                                                        name="active"
                                                        onClick={() => this.onBroadcastStatusChange()}
                                                    />
                                                ) : (
                                                    <AntSwitch
                                                        checked={false}
                                                        inputProps={{
                                                            "aria-label": "ant design",
                                                        }}
                                                        name="active"
                                                        onClick={() => this.onBroadcastStatusChange()}
                                                    />
                                                )}
                                            </div>
                                            <div className="swize" style={{ display: "flex" }}>
                                                <label>Auto Confirmation</label>
                                                {this.state.confirmStatus === 1 ? (
                                                    <AntSwitch
                                                        checked={true}
                                                        inputProps={{
                                                            "aria-label": "ant design",
                                                        }}
                                                        name="active"
                                                        onClick={() => this.onConfirmStatusChange()}
                                                    />
                                                ) : (
                                                    <AntSwitch
                                                        checked={false}
                                                        inputProps={{
                                                            "aria-label": "ant design",
                                                        }}
                                                        name="active"
                                                        onClick={() => this.onConfirmStatusChange()}
                                                    />
                                                )}
                                            </div>

                                            <div
                                                className="my-form-bx-bo"
                                                style={{ display: "block" }}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span className="">
                                                                Application should send notification to how many
                                                                Interpreters?
                                                            </span>
                                                            <InputText
                                                                placeholder=""
                                                                className="in-field2"
                                                                value={this.state.interpreterNo}
                                                                onTextChange={(value) => {
                                                                    this.onInterpreterNoChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span className="lbl">
                                                                Notify Interpreters before start of Job
                                                            </span>
                                                            <SelectBox
                                                                optionData={timeArr}
                                                                value={this.state.notifyData}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onNotifyInerpreterChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span
                                                                className="lbl"
                                                                style={{ marginBottom: "24px" }}
                                                            >
                                                                Hr/Min
                                                            </span>
                                                            <SelectBox
                                                                optionData={hourArr}
                                                                value={this.state.timeData}
                                                                placeholder="hr/min"
                                                                onSelectChange={(value) => {
                                                                    this.onTimeFormatChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span>
                                                                Local Interpreters should be available within
                                                            </span>
                                                            <SelectBox
                                                                optionData={radiusArr}
                                                                value={this.state.areaData}
                                                                placeholder="Enter Radius (mi)"
                                                                onSelectChange={(value) => {
                                                                    this.onInterpreterAreaChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span>Criteria to shortlist Translators</span>
                                                            <SelectBox
                                                                optionData={this.state.shortlistArr}
                                                                value={this.state.shortlistData}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onShortlistChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane active" id="vendorsetting">
                                {/* <ReactFragment> */}
                                <div hidden={!this.state.isLoad}>
                                    <LotteLoader />
                                </div>
                                {/* </ReactFragment> */}
                                <div className="job-section-tab" hidden={this.state.isLoad}>
                                    <div className="job-section-tab">
                                        <div className="my-form-hd _fl">
                                            <h3 className="open">Job Automation </h3>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="swize" style={{ display: "flex" }}>
                                                        <label>Auto Broadcasting</label>
                                                        {this.state.broadcastStatus === 1 ? (
                                                            <AntSwitch
                                                                checked={true}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onBroadcastStatusChange()}
                                                            />
                                                        ) : (
                                                            <AntSwitch
                                                                checked={false}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onBroadcastStatusChange()}
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="swize" style={{ display: "flex" }}>
                                                        <label>Auto Confirmation</label>
                                                        {this.state.confirmStatus === 1 ? (
                                                            <AntSwitch
                                                                checked={true}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onConfirmStatusChange()}
                                                            />
                                                        ) : (
                                                            <AntSwitch
                                                                checked={false}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onConfirmStatusChange()}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <div className="swize" style={{ display: "flex" }}>
                                                        <label>Auto Broadcasting for Translator</label>
                                                        {this.state.broadcastStatusTranslator === 1 ? (
                                                            <AntSwitch
                                                                checked={true}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onBroadcastStatusTranslatorChange()}
                                                            />
                                                        ) : (
                                                            <AntSwitch
                                                                checked={false}
                                                                inputProps={{
                                                                    "aria-label": "ant design",
                                                                }}
                                                                name="active"
                                                                onClick={() => this.onBroadcastStatusTranslatorChange()}
                                                            />
                                                        )}
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        {" "}
                                                        <span className="">Billing Emails *</span>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter emails"
                                                            className="in-field2"
                                                            onChange={this.changeEmailText}
                                                            value={this.state.billingEmailText}
                                                            onKeyPress={this.onChangeEmail}
                                                        />
                                                        <span className='billingFieldErrorConf' hidden={!this.state.errorJoiningName}>*Please enter valid email</span>
                                                        <div className="row">
                                                            {this.state.allBillingEmailArr.map((em, i) =>
                                                                <div className="col-md-10" style={{ paddingTop: '10px' }}>
                                                                    <Card>
                                                                        <Card.Body className="share_card_body">
                                                                            {em}
                                                                            <CloseButton style={{ float: 'right' }} onClick={() => this.deleteEmail(i)} />
                                                                        </Card.Body>
                                                                    </Card>

                                                                </div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="my-form-bx-bo"
                                                style={{ display: "block" }}
                                            >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span className="lbl">
                                                                Application should send notification to how many
                                                                Interpreters?
                                                            </span>
                                                            <InputText
                                                                placeholder=""
                                                                className="in-field2"
                                                                value={this.state.interpreterNo}
                                                                onTextChange={(value) => {
                                                                    this.onInterpreterNoChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span className="lbl">
                                                                Notify before start of job
                                                            </span>
                                                            <SelectBox
                                                                optionData={timeArr}
                                                                value={this.state.notifyData}
                                                                placeholder="Select"
                                                                onSelectChange={(value) => {
                                                                    this.onNotifyInerpreterChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span
                                                                className="lbl"
                                                            >
                                                                Hr/Min
                                                            </span>
                                                            <SelectBox
                                                                optionData={hourArr}
                                                                value={this.state.timeData}
                                                                placeholder="hr/min"
                                                                onSelectChange={(value) => {
                                                                    this.onTimeFormatChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span className="lbl">
                                                                Application should send notification to how many
                                                                Translators?
                                                            </span>
                                                            <InputText
                                                                placeholder=""
                                                                className="in-field2"
                                                                value={this.state.totalTranslator}
                                                                onTextChange={(value) => {
                                                                    this.onTranslatorNoChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">

                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span>
                                                                Local Interpreters should be available within (in mile)
                                                            </span>
                                                            <SelectBox
                                                                optionData={radiusArr}
                                                                value={this.state.areaData}
                                                                placeholder="Enter Radius (mile)"
                                                                onSelectChange={(value) => {
                                                                    this.onInterpreterAreaChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form_rbx">
                                                            {" "}
                                                            <span>
                                                                Out of Town Interpreters should be available within (in mile)
                                                            </span>
                                                            <SelectBox
                                                                optionData={outRadius}
                                                                value={this.state.outAreaData}
                                                                placeholder="Enter Radius (mile)"
                                                                onSelectChange={(value) => {
                                                                    this.onInterpreterOutAreaChange(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {/* <div className="form_rbx"> */}
                                                        <span
                                                            style={{
                                                                color: "grey",
                                                                fontSize: "13px",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            Criteria to shortlist Interpretors
                                                        </span>
                                                        <div className="flex-container">
                                                            <div className="row">

                                                                <DragDropContext onDragEnd={this.onDragEndInterpreters}>
                                                                    <Droppable droppableId="droppable">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                                style={getListStyle(snapshot.isDraggingOver)}
                                                                            >
                                                                                {this.state.newCriteriaListForInterpretors.map((item, index) => (
                                                                                    <Draggable key={item} draggableId={item} index={index}>
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps.style
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {/* <div className="form_rbx"> */}
                                                        <span
                                                            style={{
                                                                color: "grey",
                                                                fontSize: "13px",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            Criteria to shortlist Translators
                                                        </span>
                                                        <div className="flex-container">
                                                            <div className="row">

                                                                <DragDropContext onDragEnd={this.onDragEndTranslators}>
                                                                    <Droppable droppableId="droppable">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                                style={getListStyle(snapshot.isDraggingOver)}
                                                                            >
                                                                                {this.state.newCriteriaList.map((item, index) => (
                                                                                    <Draggable key={item} draggableId={item} index={index}>
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                style={getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps.style
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane" id="clientsetting">
                                <div className="job-section-tab">
                                    <div className="job-section-tab">
                                        <div className="my-form-hd _fl">

                                            <h3>Client Settings</h3>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="form_rbx">
                                                        {" "}
                                                        <span className="">Billing Emails *</span>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter emails"
                                                            className="in-field2"
                                                            onChange={this.changeEmailText}
                                                            value={this.state.billingEmailText}
                                                            onKeyPress={this.onChangeEmail}
                                                        />
                                                        <span className='billingFieldErrorConf' hidden={!this.state.errorJoiningName}>*Please enter valid email</span>
                                                        <div className="row">
                                                            {this.state.allBillingEmailArr.map((em, i) =>
                                                                <div className="col-md-10" style={{ paddingTop: '10px' }}>
                                                                    <Card>
                                                                        <Card.Body className="share_card_body">
                                                                            {em}
                                                                            <CloseButton style={{ float: 'right' }} onClick={() => this.deleteEmail(i)} />
                                                                        </Card.Body>
                                                                    </Card>

                                                                </div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


class DragView extends React.Component {
    constructor(props) {
        super(props);
    }

    getChangedPos = (currentPos, newPos) => {
        this.props.posChage(currentPos, newPos);
    };

    render() {
        return (
            <React.Fragment>
                <Draggable onPosChange={this.getChangedPos}>
                    {consoleLog("Dragable value", this.props.value)}
                    {this.props.value.map((obj, idx) => {
                        return (
                            <div
                                key={idx}
                                style={{
                                    width: "300px",

                                    padding: "10px",
                                    border: "1px solid hsl(0, 0%, 80%)",
                                    marginBottom: "5px",
                                    borderRadius: "10px",
                                    boxShadow:
                                        "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                }}
                                // onDragEnter={(e) => this.dragEnter(e, idx)}
                                // onPosChange={this.dragEnter}
                                className="flex-item"
                            // draggable
                            >
                                {obj}
                            </div>
                        );
                    })}
                </Draggable>

            </React.Fragment>
        )
    }
}