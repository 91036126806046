import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from "react-toastify";
import { ImageName } from "../../../../enums";
import history from "../../../../history";
import { Decoder } from "../../../../services/auth";
import { consoleLog } from "../../../../services/common-function";
import { ErrorCode, UsersEnums } from "../../../../services/constant";
import { ApiCall } from "../../../../services/middleware";
import './OutofTown.css';

export default class OutOfTownRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.value.isInTown,
            isSelect: false
        }
    }

    handleClose = () => {
        this.props.toggle()
    }

    clickChangeYes = async (e) => {
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);

        this.setState({
            isSelect: !this.state.isSelect
        });
        // consoleLog("Job Request Id :", this.props.value.jobRequestId);
        if (e.target.checked) {
            let data = {
                requestId: this.props.value.jobRequestId
            }
            let res = await ApiCall("outOfTownConfirmation", data);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                    return history.push("/adminViewAllJobs");
                } else {
                    return history.push("/clientAllJobs");
                }
            } else {
                toast.error(res.message)
            }
        } else {
            if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                return history.push("/adminViewAllJobs");
            } else {
                return history.push("/clientAllJobs");
            }
        }
    }

    clickChangeNo = (e) => {
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);
        if (e.target.checked) {
            if (authUser.data.usertypeid === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN) {
                return history.push("/adminViewAllJobs");
            } else {
                return history.push("/clientAllJobs");
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" />

                <Modal show={this.props.value.isInTown} onHide={this.handleClose} dialogClassName="my-modal_out border-radius-2" size="md" centered>
                    {/* <Modal.Header>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div class="filter-head-gg _fl document-hdr">
                            <button type="button" class="close" data-dismiss="modal" onClick={this.handleClose}>
                                <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                            </button>
                        </div>
                        <div className="pop_hd">
                            <img src={ImageName.IMAGE_NAME.ALRT_ICON} />
                            <p>In the event that our local interpreters are not available, <br />what would you like <span>7C</span> to do</p>
                        </div>
                        <div className="row">
                            <div className="mod-ppop-inr">
                                <div className="mod-ppop-inr-und">
                                    <div className="web-form-bx">
                                        <div className="frm-label">&nbsp;</div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input type="radio" name="radio" checked={this.state.isSelect} onChange={this.clickChangeYes} />
                                                <span className="checkmark3"></span>
                                                Yes
                                            </label>
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                <input type="radio" name="radio" checked={!this.state.isSelect} onChange={this.clickChangeNo} />
                                                <span className="checkmark3"></span>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6 book_se">
                                <button>Book Out town interpreter</button>
                                <p>Additional Fees Applies</p>
                            </div>
                            <div className="col-md-6 book_se">
                                <button>Reshedule to a different date</button>
                                <p>Choose a differentAppointment Date</p>
                            </div> */}
                            {/* <div className="pops_undr_pop mod-ppop-inr">
                                <div class="filter-head-gg _fl document-hdr">

                                    <button type="button" class="close" data-dismiss="modal"><img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} /></button>
                                </div>
                                <div className="mod-ppop-inr-und">
                                    <p>It looks like you are not approved for Out of <br />Town interpreter. Would you like us to reach out <br /> To the authorised person?</p>
                                    <div className="web-form-bx">
                                        <div className="frm-label">&nbsp;</div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                {this.state.countrySpecificDialect ?
                                                    <input type="radio" name="radio" checked={true} value="yes" onChange={this.countrySpecific} disabled /> :
                                                    <input type="radio" name="radio" checked={false} value="yes" onChange={this.countrySpecific} disabled />}
                                                <span className="checkmark3"></span> Yes</label>
                                        </div>
                                        <div className="check-field">
                                            <label className="checkbox_btn">
                                                {this.state.countrySpecificDialect ?
                                                    <input type="radio" name="radio" checked={false} value="no" onChange={this.countrySpecific} disabled /> :
                                                    <input type="radio" name="radio" checked={true} value="no" onChange={this.countrySpecific} disabled />}
                                                <span className="checkmark3"></span> No</label>
                                        </div>


                                        <div className="pops_undr_pop mod-ppop-inr">
                                            <div className="filter-head-gg _fl document-hdr">
                                                <button type="button" className="close" data-dismiss="modal">
                                                    <img src={ImageName.IMAGE_NAME.CLOS_BIG_ICON} />
                                                </button>
                                            </div>
                                            <p>
                                                <strong>
                                                    Oopps! No Supervisior was assigned to you
                                                </strong>
                                            </p>
                                            <button type="button" className="blue">Redirect to the authorised person</button>
                                            <button type="button" className="blue">Go back to thr previous page</button>

                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}