import { combineReducers } from "redux";
import DashboardReducer from "../../app/Admin/Dashboard/Reducer/DashboardReducer";
import HeaderReducer from "../../app/Admin/Header/Reducer/HeaderReducer";
import MainReducer from "./reducers/MainReducer";
import ReportReducer from "./reducers/reportReducer";

const RootReducer = combineReducers({
  dashboardData: DashboardReducer,
  headerData: HeaderReducer,
  mainData: MainReducer,
  reportData : ReportReducer
});
export default RootReducer;
