import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./VendorListingTraining.css";
import { ImageName } from "../../../../enums";
import { InputText, SelectBox } from "../../SharedComponents/inputText";
import { ApiCall } from "../../../../services/middleware";
import { ErrorCode } from "../../../../services/constant";
import { Decoder } from "../../../../services/auth";
import { consoleLog } from "../../../../services/common-function";
import LotteLoader from "../../../Loader/LotteLoader";

const allRatingArr = [
    {
        label: "All",
        value: "",
    },
    {
        label: "0",
        value: "0",
    },
    {
        label: "1",
        value: "1",
    },
    {
        label: "2",
        value: "2",
    },
    {
        label: "3",
        value: "3",
    },
    {
        label: "4",
        value: "4",
    },
    {
        label: "5",
        value: "5",
    },
]

export default class AvailableVendorListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allRating: allRatingArr,
            selectedFilterRating: {},
            filterRating: "",
            allTrainingData: [],
            allTrainingCourse: [],
            selectedTrainingCourse: {
                label: "All",
                value: ""
            },
            trainingCourse: "",
            allTrainingCategory: [],
            selectedTrainingCategory: {
                label: "All",
                value: ""
            },
            trainingCategory: "",
            searchVendorName: "",
            searchVendorEmail: "",
            searchVendorPhone: "",
            viewReasonModal: false,
            rejectReasonData: ""
        };

        this.load();
    }

    load = async () => {

        let allCategoryType = [
            {
                label: "All",
                value: "",
            }
        ],
            allTrainingData = [];

        let res = await ApiCall("getCourseWithCategory");
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // consoleLog("Training Course response Data:", payload);
            allTrainingData = payload.data;
            if (payload.data.length > 0) {
                payload.data.map((data) => {
                    allCategoryType.push({
                        label: data.name,
                        value: data.id,
                    });
                });
            }
        }

        this.setState({
            allTrainingCategory: allCategoryType,
            allTrainingData: allTrainingData
        })
        this.getTrainingCourseList();
    }
    getTrainingCourseList = async () => {

        let allTrainingCourse = [
            {
                label: "All",
                value: "",
            }
        ];

        let res = await ApiCall("fetchTrainingCourseName");
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // consoleLog("Training Course response Data:", payload);
            // allTrainingCourse = payload.data;
            if (payload.data.length > 0) {
                payload.data.map((data) => {
                    allTrainingCourse.push({
                        label: data.name,
                        value: data.id,
                    });
                });
            }
        }

        this.setState({
            allTrainingCourse: allTrainingCourse
        })
    }



    allVendorCheck = () => {
        this.props.allVendorCheck();
    }

    listChecked = (e, i) => {
        this.props.listChecked(e, i);
    }

    handleBidModal = (id) => {
        this.props.handleBidModal(id);
    }
    handleVendorAssign = () => {
        this.props.handleVendorAssign();
    }

    filterRatingChange = (value) => {
        this.setState({
            filterRating: value.value,
            selectedFilterRating: value
        });


        let reqdata = {
            requestId: this.props.value.requestId,
            name: this.state.searchVendorName,
            email: this.state.searchVendorEmail,
            phone: this.state.searchVendorPhone,
            course: this.state.trainingCourse,
            category: this.state.trainingCategory,
            rating: value.value
        }

        this.props.getList(reqdata);
    };



    trainingCategoryChange = (value) => {
        // console.log("selected Client",value);
        let allCourse = [],
            trainingCourses = [];
        this.state.allTrainingData.map((data) => {
            if (data.id === value.value) {
                allCourse = data.courses;
                trainingCourses = [{
                    label: "All",
                    value: "",
                }];
                if (allCourse.length > 0) {
                    allCourse.map((cd) => {
                        trainingCourses.push({
                            label: cd.name,
                            value: cd.id,
                        });
                    });
                }
            }
        });

        this.setState({
            selectedTrainingCategory: value,
            allTrainingCourse: trainingCourses,
            trainingCategory: value.value
        });

        let reqdata = {
            requestId: this.props.value.requestId,
            name: this.state.searchVendorName,
            email: this.state.searchVendorEmail,
            phone: this.state.searchVendorPhone,
            course: this.state.trainingCourse,
            category: value.value,
            rating: this.state.filterRating
        }

        this.props.getList(reqdata);
    };



    trainingCourseTypeChange = (value) => {
        this.setState({
            selectedTrainingCourse: value,
            trainingCourse: value.value
        });

        let reqdata = {
            requestId: this.props.value.requestId,
            name: this.state.searchVendorName,
            email: this.state.searchVendorEmail,
            phone: this.state.searchVendorPhone,
            course: value.value,
            category: this.state.trainingCategory,
            rating: this.state.filterRating
        }

        this.props.getList(reqdata);
    };

    onVendorNameSearch = (value) => {
        this.setState({
            searchVendorName: value
        });

        let reqdata = {
            requestId: this.props.value.requestId,
            name: value,
            email: this.state.searchVendorEmail,
            phone: this.state.searchVendorPhone,
            course: this.state.trainingCourse,
            category: this.state.trainingCategory,
            rating: this.state.filterRating
        }

        this.props.getList(reqdata);
    }

    onVendorMailSearch = (value) => {
        this.setState({
            searchVendorEmail: value
        })

        let reqdata = {
            requestId: this.props.value.requestId,
            name: this.state.searchVendorName,
            email: value,
            phone: this.state.searchVendorPhone,
            course: this.state.trainingCourse,
            category: this.state.trainingCategory,
            rating: this.state.filterRating
        }

        this.props.getList(reqdata);
    }

    onVendorPhoneSearch = (value) => {
        this.setState({
            searchVendorPhone: value
        })

        let reqdata = {
            requestId: this.props.value.requestId,
            name: this.state.searchVendorName,
            email: this.state.searchVendorEmail,
            phone: value,
            course: this.state.trainingCourse,
            category: this.state.trainingCategory,
            rating: this.state.filterRating
        }

        this.props.getList(reqdata);
    }

    openViewreasonModal = (value) => {
        this.setState({
            viewReasonModal: true,
            rejectReasonData: value
        })
    }

    closeViewreasonModal = () => {
        this.setState({
            viewReasonModal: false,
            rejectReasonData: ""
        })
    }

    render() {
        return (
            <React.Fragment>

                {/* <div
                    className="vendor-info p-10 _fl sdw tr_dt"
                    style={{ paddingBottom: "10px" }}
                >
                    <div className="vn-form _fl">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="web-form-bx">
                                    <div className="frm-label">
                                        Name
                                    </div>
                                    <div className="">
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.searchVendorName}
                                            onTextChange={(value) => {
                                                this.onVendorNameSearch(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="web-form-bx">
                                    <div className="frm-label">
                                        Email
                                    </div>
                                    <div className="">
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.searchVendorEmail}
                                            onTextChange={(value) => {
                                                this.onVendorMailSearch(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="web-form-bx">
                                    <div className="frm-label">
                                        Rating
                                    </div>
                                    <div className="bts-drop">
                                        <SelectBox
                                            optionData={this.state.allRating}
                                            value={this.state.selectedFilterRating}
                                            onSelectChange={(value) =>
                                                this.filterRatingChange(value)
                                            }
                                        ></SelectBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vn-form _fl">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="web-form-bx md4">
                                    <div className="frm-label">
                                        Training Category
                                    </div>
                                    <div className="bts-drop">
                                        <SelectBox
                                            optionData={
                                                this.state.allTrainingCategory
                                            }
                                            onSelectChange={(value) =>
                                                this.trainingCategoryChange(value)
                                            }
                                        ></SelectBox>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="web-form-bx">
                                    <div className="frm-label">Course</div>
                                    <div className="bts-drop">
                                        <SelectBox
                                            optionData={
                                                this.state.allTrainingCourse
                                            }
                                            value={
                                                this.state.selectedTrainingCourse
                                            }
                                            onSelectChange={(value) =>
                                                this.trainingCourseTypeChange(value)
                                            }
                                        ></SelectBox>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="web-form-bx">
                                    <div className="_button-style _fl mt-20">
                                        <a
                                            href="javascript:void(0)"
                                            className="grey-btn"
                                            style={{ textDecoration: "none" }}
                                        >
                                            Reset
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="blue-btn"
                                            style={{ textDecoration: "none" }}
                                        // onClick={this.handleVendorAssign}
                                        >
                                            Apply
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="table-listing-app">
                    <div className="table-responsive">
                        <table
                            cellspacing="0"
                            cellpadding="0"
                            className="av_training_table"
                        >
                            <tbody>
                                <tr>
                                    {this.props.value.quoteStatus === 8 ? (
                                        <React.Fragment></React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <th>
                                                {/* <label className="custom_check2">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.props.value.allChecked}
                                                        onClick={this.allVendorCheck}
                                                    />
                                                    <span className="checkmark2"></span>
                                                </label> */}
                                            </th>
                                        </React.Fragment>
                                    )}
                                    <th >Name / Agency</th>
                                    <th >Email ID</th>
                                    <th >Phone Number</th>
                                    <th>Training Category</th>
                                    <th >Training Course</th>
                                    <th >Ratings</th>
                                    {this.props.value.quoteStatus === 8 ? (
                                        <th >Bid</th>
                                    ) : (
                                        <th >Status</th>
                                    )}
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    {this.props.value.quoteStatus === 8 ?
                                        <></> : <><td ></td></>
                                    }
                                    <td >
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.searchVendorName}
                                            onTextChange={(value) => {
                                                this.onVendorNameSearch(value);
                                            }}
                                        />
                                    </td>
                                    <td >
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.searchVendorEmail}
                                            onTextChange={(value) => {
                                                this.onVendorMailSearch(value);
                                            }}
                                        />
                                    </td>
                                    <td >
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.searchVendorPhone}
                                            onTextChange={(value) => {
                                                this.onVendorPhoneSearch(value);
                                            }}
                                        />
                                    </td>
                                    <td >
                                        <div className="bts-drop">
                                            <SelectBox
                                                optionData={
                                                    this.state.allTrainingCategory
                                                }
                                                value={this.state.selectedTrainingCategory}
                                                onSelectChange={(value) =>
                                                    this.trainingCategoryChange(value)
                                                }
                                            ></SelectBox>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="bts-drop">
                                            <SelectBox
                                                optionData={
                                                    this.state.allTrainingCourse
                                                }
                                                value={
                                                    this.state.selectedTrainingCourse
                                                }
                                                onSelectChange={(value) =>
                                                    this.trainingCourseTypeChange(value)
                                                }
                                            ></SelectBox>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="bts-drop rating_stats">
                                            <SelectBox
                                                optionData={this.state.allRating}
                                                value={this.state.selectedFilterRating}
                                                onSelectChange={(value) =>
                                                    this.filterRatingChange(value)
                                                }
                                            ></SelectBox>
                                        </div>
                                    </td>
                                    <td ></td>
                                </tr>
                            </tbody>
                            <tbody hidden={!this.props.value.isVendorLoad}>
                                <tr >
                                    {/* <td style={{width:"40%"}} colSpan="4"></td> */}
                                    <td style={{ width: "100%" }} colSpan="8">
                                        <div hidden={!this.props.value.isVendorLoad}>
                                            <LotteLoader />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody hidden={this.props.value.isVendorLoad} >
                                {this.props.value.listData.length > 0 ? <>
                                    {this.props.value.listData.map((item, key) => (
                                        <tr key={key}>
                                            {this.props.value.quoteStatus === 8 ? (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td >
                                                        {Number(item.qstatus) === 0 ? <></> :
                                                            <label className="custom_check2">
                                                                <input
                                                                    type="checkbox"
                                                                    defaultChecked={
                                                                        item.isQuoteSent === 1 ? true : false
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.listChecked(e, key)
                                                                    }
                                                                />

                                                                <span className="checkmark2"></span>
                                                            </label>
                                                        }
                                                    </td>
                                                </React.Fragment>
                                            )}
                                            <td >
                                                {item.agencyName === ""
                                                    ? item.fName + " " + item.lName
                                                    : item.fName +
                                                    " " +
                                                    item.lName +
                                                    " (" +
                                                    item.agencyName +
                                                    ")"}
                                            </td>
                                            <td >
                                                <span className="viewlink">
                                                    {item.email}
                                                </span>
                                            </td>
                                            <td >+1 {item.mobile}</td>
                                            <td >
                                                {item.trainingCategory == undefined ? "N/A" : item.trainingCategory}
                                            </td>
                                            <td >
                                                {item.trainingCourse == undefined ? "N/A" : item.trainingCourse}
                                            </td>
                                            <td >
                                                <img src={ImageName.IMAGE_NAME.STARYELLOW} />
                                                <span className="rat_txt">{item.ratings}</span>
                                            </td>

                                            {this.props.value.quoteStatus >= 8 ? (
                                                <React.Fragment>
                                                    <td>
                                                        {item.qstatus == 2 ? (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        color: "green",
                                                                        fontSize: "12px",
                                                                    }}
                                                                >
                                                                    Assigned
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {item.qstatus}
                                                            </>
                                                        )}
                                                    </td>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <td>
                                                        {item.qstatus == 1 ? (
                                                            <a href="javascript:void(0)">
                                                                <img
                                                                    src={ImageName.IMAGE_NAME.EYE_BTN}
                                                                    onClick={() => {
                                                                        this.handleBidModal(item.userId);
                                                                    }}
                                                                />
                                                            </a>
                                                        ) : (
                                                            <React.Fragment>
                                                                {item.qstatus == 0 ? <>
                                                                    <span
                                                                        style={{
                                                                            color: "#00a0df",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        Offer Sent
                                                                    </span>
                                                                </> : item.qstatus == 5 ? <span
                                                                    style={{
                                                                        color: "red",
                                                                        fontSize: "12px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={() => {
                                                                        this.openViewreasonModal(item.rejectReason);
                                                                    }}
                                                                >
                                                                    Offer Rejected
                                                                </span> :
                                                                    <>
                                                                        {item.qstatus}
                                                                    </>}
                                                            </React.Fragment>
                                                        )}
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    ))}</> : <>
                                    <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                            <center style={{ fontSize: "20px" }}>
                                                No data found !!!
                                            </center>
                                        </td>
                                    </tr>
                                </>}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.props.value.quoteStatus === 8 ? (
                    <React.Fragment></React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="_button-style _fl text-center margin-top-30">
                            <a
                                href="javascript:void(0)"
                                className="grey-btn"
                                style={{ textDecoration: "none" }}
                            >
                                Cancel
                            </a>
                            <a
                                href="javascript:void(0)"
                                className="blue-btn"
                                style={{ textDecoration: "none" }}
                                onClick={this.handleVendorAssign}
                            >
                                Send Offer
                            </a>
                        </div>
                    </React.Fragment>
                )}

                <Modal show={this.state.viewReasonModal} onHide={this.closeViewreasonModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Offer Reject Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <textarea className="cancelTranslationReason" value={"Offer Reject Reason : " + this.state.rejectReasonData} disabled={true} ></textarea>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}