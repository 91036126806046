import React from 'react';
import { toast } from 'react-toastify';
import { AlertMessage, ImageName } from '../../../../enums';
import { consoleLog } from '../../../../services/common-function';
import { ErrorCode } from '../../../../services/constant';
import { ApiCall } from '../../../../services/middleware';
import { passwordValidator } from '../../../../validators';
import { passWordRandomGenerate } from '../ClientList/function';
import { styled } from "@mui/system"; //imported for modal
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import history from '../../../../history';



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 10,
        marginTop: 5,
        minWidth: 100,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
    },
}));

export default class ClientMenuPages extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            anchorEl: null,
            curIndex: 0,
            resetPasswordData: "",
        }
    }

    componentDidMount() {

        var classInstance = this;
        var passwordModal = document.getElementById("password-model-1");
        window.addEventListener("click", function (event) {
            if (event.target == passwordModal) {
                classInstance.closeResetModal();
            }
        });
    }

    //................funct for menuBtn on click................
    menuBtnhandleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    //..............function for MenuButton close..............
    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    goEdit = () => {
        this.props.history.push({
            pathname: "/adminClientEdit",
            state: this.props.value
        })
    };

    handleReset = () => {
        // consoleLog("Props Data:", this.props.value)
        let mainPass = passWordRandomGenerate();
        // consoleLog("mainpass",mainPass)
        this.setState({
            resetPasswordData: mainPass
        })
        this.handleMenuClose();
        this.openResetModal();
    };

    openResetModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("password-model-1").style.display = "block";
        document.getElementById("password-model-1").classList.add("show");
    };

    closeResetModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("password-model-1").style.display = "none";
        document.getElementById("password-model-1").classList.remove("show");
    };

    onResetPassChange = (e) => {
        this.setState({
            resetPasswordData: e.target.value,
        });
    };
    //............Reset Password...........

    onResetPassword = async () => {
        // console.log(this.state);
        let errorCount = 0;
        let validatePassword = passwordValidator(this.state.resetPasswordData);
        if (validatePassword.status === false) {
            toast.error(validatePassword.message, {
                hideProgressBar: true,
            });
            errorCount++;
        }

        if (errorCount === 0) {
            let data = {
                staffid: this.props.value.userId,
                password: this.state.resetPasswordData,
            };

            let status = await ApiCall("userpasswordreset", data);
            if (
                status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                this.closeResetModal();
                toast.success(AlertMessage.MESSAGE.PASSWORD.RESET_SUCCESS);
            } else {
                toast.error(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR)
            }
        }

    };



    openJob = () => {
        this.props.history.push({
            pathname: "/adminJobDetailsOfClient",
            state: this.props.value,
        });
    };

    openProject = () => {
        this.props.history.push({
            pathname: "/adminProjectDetailsOfClient",
            state: this.props.value,
        });
    };

    goRateCard = () => {
        this.props.history.push({
            pathname: "/adminClientRateCard",
            state: this.props.value,
        });
    };
    openInvoice = () => {
        this.props.history.push({
            pathname: "adminClientInvoiceList",
            state: this.props.value
        })
    }

    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        return (
            <React.Fragment>

                {/* <img
                    src={ImageName.IMAGE_NAME.MENU_VERTICAL}
                    style={{ cursor: "pointer" }}
                    id={"basic-button"}
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(e)
                    }
                /> */}

                <button
                    className='btn btn-primary'
                    style={{ cursor: "pointer" }}
                    id={"basic-button"}
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(e)
                    }
                >Go To</button>
                <StyledMenu
                    id={"basic-menu"}
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    {/* <MenuItem onClick={() => this.goEdit()}>
                        Edit
                    </MenuItem> */}
                    <MenuItem onClick={this.handleReset}>
                        Reset Password
                    </MenuItem>

                    <MenuItem onClick={() => this.openJob()}>
                        View Jobs
                    </MenuItem>
                    <MenuItem
                        onClick={() => this.openProject()}
                    >
                        View Projects
                    </MenuItem>
                    <MenuItem
                        onClick={() => this.goRateCard()}
                    >
                        View Rate Card
                    </MenuItem>
                    <MenuItem onClick={() => this.openInvoice()}>Invoices</MenuItem>
                    {/* <MenuItem
                                    onClick={() => this.onStatusModal()}
                                  >
                                    De-Activate or Activate
                                  </MenuItem> */}
                </StyledMenu>



                {/* ..................Reset Password modal................................. */}
                <div
                    id="password-model-1"
                    className="modal fade modelwindow"
                    role="dialog"
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you really want to reset the password for selected
                                            user?{" "}
                                        </h6>
                                    </center>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <center>
                                            <div className="col-md-8">
                                                <input

                                                    className="inputfield"
                                                    value={this.state.resetPasswordData}
                                                    onChange={(e) =>
                                                        this.onResetPassChange(e)
                                                    }
                                                />
                                            </div>
                                        </center>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "2%" }}
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.closeResetModal}
                                            >
                                                No
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="blue-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.onResetPassword}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="modal-backdrop fade show"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment>
        )
    }
}