import { parseAttributes } from './util';
import { Logger } from './logger';

import { Channel } from './channel';
import { McsClient } from '@twilio/mcs-client';
import { Media } from './media';
import { Member } from './member';
import { validateTypesAsync, literal } from '@twilio/declarative-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { EditMessageRequest } from './interfaces/commands/editmessage';
import { MessageResponse } from './interfaces/commands/messageresponse';
import isEqual from 'lodash.isequal';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type MessageEvents = {
  updated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[]
  }) => void;
};

const log = Logger.scope('Message');

interface MessageState {
  sid: string;
  index: number;
  author?: string;
  body: string;
  dateUpdated: Date;
  lastUpdatedBy: string;
  attributes: Object;
  timestamp: Date;
  type: MessageType;
  media?: Media;
  memberSid?: string;
}

interface MessageServices {
  mcsClient: McsClient;
  commandExecutor: CommandExecutor;
}

interface MessageLinks {
  self: string;
  conversation: string;
  messages_receipts: string;
}

/**
 * The reason for the `updated` event being emitted by a message.
 */
type MessageUpdateReason =
  | 'body'
  | 'lastUpdatedBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'attributes'
  | 'author';

/**
 * Push notification type of a message.
 */
type MessageType = 'text' | 'media';

interface MessageUpdatedEventArgs {
  message: Message;
  updateReasons: MessageUpdateReason[];
}

/**
 * A message in a channel.
 */
class Message extends ReplayEventEmitter<MessageEvents> {
  private state: MessageState;

  /**
   * Channel that the message is in.
   */
  public readonly channel: Channel;
  private readonly links: MessageLinks;
  private readonly configuration: Configuration;
  private readonly services: MessageServices;

  /**
   * @internal
   */
  constructor(
    index: number,
    data: any,
    channel: Channel,
    links: MessageLinks,
    configuration: Configuration,
    services: MessageServices
  ) {
    super();

    this.channel = channel;
    this.links = links;
    this.configuration = configuration;
    this.services = services;

    this.state = {
      sid: data.sid,
      index: index,
      author: data.author == null ? null : data.author,
      body: data.text,
      timestamp: data.timestamp ? new Date(data.timestamp) : null,
      dateUpdated: data.dateUpdated ? new Date(data.dateUpdated) : null,
      lastUpdatedBy: data.lastUpdatedBy ? data.lastUpdatedBy : null,
      attributes: parseAttributes(data.attributes, `Got malformed attributes for the message ${data.sid}`, log),
      type: data.type ? data.type : 'text',
      media: (data.type && data.type === 'media' && data.media)
        ? new Media(data.media, this.services) : null,
      memberSid: data.memberSid == null ? null : data.memberSid
    };
  }

  /**
   * Fired when the properties or the body of the message have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} message - the message in question
   *     * {@link MessageUpdateReason}[] updateReasons - array of reasons for the update
   */
  static readonly updated = 'updated';

  /**
   * The server-assigned unique identifier for the message.
   */
  public get sid(): string { return this.state.sid; }

  /**
   * Name of the user that sent the message.
   */
  public get author(): string { return this.state.author; }

  /**
   * Body of the message. Null if the message is a media message.
   */
  public get body(): string {
    if (this.type === 'media') {
      return null;
    }

    return this.state.body;
  }

  /**
   * Date this message was last updated on.
   */
  public get dateUpdated(): Date { return this.state.dateUpdated; }

  /**
   * Index of the message in the channel's messages list.
   * By design of the Programmable Chat system, the message indices may have arbitrary gaps between them,
   * that does not necessarily mean they were deleted or otherwise modified - just that
   * messages may have some non-contiguous indices even if they are being sent immediately one after another.
   *
   * Trying to use indices for some calculations is going to be unreliable.
   *
   * To calculate the number of unread messages it is better to use the read horizon API.
   * See {@link Channel.getUnconsumedMessagesCount} for details.
   */
  public get index(): number { return this.state.index; }

  /**
   * Identity of the last user that updated the message.
   */
  public get lastUpdatedBy(): string { return this.state.lastUpdatedBy; }

  /**
   * Date this message was created on.
   */
  public get dateCreated(): Date { return this.state.timestamp; }

  /**
   * Custom attributes of the message.
   */
  public get attributes(): Object { return this.state.attributes; }

  /**
   * Push notification type of the message.
   */
  public get type(): MessageType { return this.state.type; }

  /**
   * Media information (if present).
   */
  public get media(): Media { return this.state.media; }

  /**
   * The server-assigned unique identifier of the authoring member.
   */
  public get memberSid(): string { return this.state.memberSid; }

  _update(data) {
    let updateReasons: MessageUpdateReason[] = [];

    if ((data.text || ((typeof data.text) === 'string')) && data.text !== this.state.body) {
      this.state.body = data.text;
      updateReasons.push('body');
    }

    if (data.lastUpdatedBy && data.lastUpdatedBy !== this.state.lastUpdatedBy) {
      this.state.lastUpdatedBy = data.lastUpdatedBy;
      updateReasons.push('lastUpdatedBy');
    }

    if (data.author && data.author !== this.state.author) {
      this.state.author = data.author;
      updateReasons.push('author');
    }

    if (data.dateUpdated &&
      new Date(data.dateUpdated).getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = new Date(data.dateUpdated);
      updateReasons.push('dateUpdated');
    }

    if (data.timestamp &&
      new Date(data.timestamp).getTime() !== (this.state.timestamp && this.state.timestamp.getTime())) {
      this.state.timestamp = new Date(data.timestamp);
      updateReasons.push('dateCreated');
    }

    let updatedAttributes = parseAttributes(data.attributes, `Got malformed attributes for the message ${this.sid}`, log);
    if (!isEqual(this.state.attributes, updatedAttributes)) {
      this.state.attributes = updatedAttributes;
      updateReasons.push('attributes');
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { message: this, updateReasons: updateReasons });
    }
  }

  /**
   * Get the member who is the author of the message.
   */
  public async getMember(): Promise<Member> {
    let member: Member = null;
    if (this.state.memberSid) {
      member = await this.channel.getMemberBySid(this.memberSid)
                         .catch(() => {
                           log.debug('Member with sid "' + this.memberSid + '" not found for message ' + this.sid);
                           return null;
                         });
    }
    if (!member && this.state.author) {
      member = await this.channel.getMemberByIdentity(this.state.author)
                         .catch(() => {
                           log.debug('Member with identity "' + this.author + '" not found for message ' + this.sid);
                           return null;
                         });
    }
    if (member) {
      return member;
    }
    let errorMesage = 'Member with ';
    if (this.state.memberSid) {
      errorMesage += 'SID \'' + this.state.memberSid + '\' ';
    }
    if (this.state.author) {
      if (this.state.memberSid) {
        errorMesage += 'or ';
      }
      errorMesage += 'identity \'' + this.state.author + '\' ';
    }
    if (errorMesage === 'Member with ') {
      errorMesage = 'Member ';
    }
    errorMesage += 'was not found';
    throw new Error(errorMesage);
  }

  /**
   * Remove the message.
   */
  public async remove(): Promise<Message> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      this.links.self,
    );

    return this;
  }

  /**
   * Edit the message body.
   * @param body New body of the message.
   */
  @validateTypesAsync('string')
  public async updateBody(body: string): Promise<Message> {
    await this.services.commandExecutor.mutateResource<EditMessageRequest, MessageResponse>(
      'post',
      this.links.self,
      {
        body
      }
    );

    return this;
  }

  /**
   * Edit the message attributes.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<Message> {
    await this.services.commandExecutor.mutateResource<EditMessageRequest, MessageResponse>(
      'post',
      this.links.self,
      {
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );

    return this;
  }
}

export {
  Message,
  MessageServices,
  MessageType,
  MessageUpdateReason,
  MessageUpdatedEventArgs
};
