import React from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import ReactHtmlParser from 'react-html-parser';
import Lottie from "lottie-react";
import NO_Data from "./No_Data.json";
import { ApiCall } from "../../../services/middleware";
import { ErrorCode } from "../../../services/constant";
import { Decoder } from "../../../services/auth";
import { consoleLog, SetDateFormat, SetTimeFormat } from "../../../services/common-function";
import ReactLoader from "../../Loader";
import { ImageName } from "../../../enums";

const style = {
    height: 400,
    width : 800
};
export default class LogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            loader: false,
            viewEmailModal: false,
            allEmailSmsLog: [],
            viewEmailModalData: {}
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();
    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    load = () => {
        this.fetchEmailSmsLog("0");
    }

    fetchEmailSmsLog = async (id) => {
        let obj = {
            requestId: id
        }
        let res = await ApiCall("getEmailSmsLogByJobId", obj);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            consoleLog("Email SMS Log Data  >>>>>", payload);
            if (payload.data.log.length > 0) {
                let arr = [];
                this.setState({
                    allEmailSmsLog: payload.data.log
                })
            } else {
                this.setState({
                    allEmailSmsLog: []
                })
            }
        }

    }

    openViewEmailModal = async (data) => {
        await this.loaderChange(true);
        this.setState({
            viewEmailModal: true,
            viewEmailModalData: data
        });
        await this.loaderChange(false);
    }

    closeViewEmailModal = async () => {
        await this.loaderChange(true);
        this.setState({
            viewEmailModal: false,
            viewEmailModalData: {}
        });
        await this.loaderChange(false);
    }

    render() {
        return (
            <>
                <div class="component-wrapper" hidden={!this.state.isLoad}>
                    <ReactLoader />
                </div>
                <div className="component-wrapper" hidden={this.state.isLoad}>
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> /{" "}
                        All Log
                    </div>


                    <div className="chat-app-information-component">
                        <div className="chat-app-component">
                            {this.state.allEmailSmsLog.length > 0 ? <React.Fragment>
                                {this.state.allEmailSmsLog.map((data, key) => <React.Fragment key={key}>
                                    <div class="accordion" id={"accordionExample" + key} >
                                        <div class="card notify_card">
                                            <div
                                                class="card-header"
                                                id={"headingOne" + key}
                                                type="button"
                                                style={{
                                                    borderRadius: "20px ",
                                                    padding: "20px 0px",
                                                    backgroundColor: "white",
                                                }}
                                                data-toggle="collapse"
                                                data-target={"#collapseOne" + key}
                                                aria-expanded="false"
                                                aria-controls={"collapseOne" + key}
                                            >
                                                <div>
                                                    <div className="row notify" onClick={() => this.openViewEmailModal(data)}>
                                                        <div className="col-md-2">
                                                            <React.Fragment>
                                                                <img
                                                                    src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                                                    style={{ width: "40px" }}
                                                                ></img>
                                                            </React.Fragment>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="job_details">
                                                                {data.logType} : {data.subject}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="notify_time">
                                                                {data.receiver}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="notify_time">
                                                                {SetDateFormat(data.receivedDate)} {SetTimeFormat(data.receivedDate)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)}
                            </React.Fragment> : <React.Fragment>
                                <center><Lottie animationData={NO_Data} loop={true} style={style} /></center>
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.viewEmailModal}
                    onHide={this.closeViewEmailModal}
                    size="lg"
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    centered
                >
                    <Modal.Header className="email_details" closeButton>
                        <Modal.Title>{this.state.viewEmailModalData.logType === 'Email' ? 'Email Data' : 'SMS Data'} </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="row">
                            {this.state.viewEmailModalData.logType === 'Email' ?
                                <div className="col-md-12">
                                    <label>Sender : &nbsp;</label>
                                    {this.state.viewEmailModalData.sender}
                                </div> : <></>
                            }
                            <div className="col-md-12">
                                <label>Sent To : &nbsp;</label>
                                {this.state.viewEmailModalData.receiver}
                            </div>
                            {this.state.viewEmailModalData.logType === 'Email' ?
                                <div className="col-md-12">
                                    <label>Subject : &nbsp;</label>
                                    {this.state.viewEmailModalData.subject}
                                </div> : <></>
                            }
                            <div className="col-md-12">
                                <span className="tr_nl">
                                    {ReactHtmlParser(this.state.viewEmailModalData.content)}
                                </span>
                            </div>
                            <Button onClick={this.closeViewEmailModal} color="primary">
                                Close
                            </Button>

                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}