import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ApiCall } from "../../../../services/middleware";
import history from "../../../../history";
import { InputText, SelectBox } from "../../SharedComponents/inputText";
import { AlertMessage, ImageName } from "../../../../enums";
// import "./CreateInternalProject.css";
import {
    inputEmptyValidate,
} from "../../../../validators";
import { Decoder } from "../../../../services/auth";
import {
    consoleLog,
    SetDatabaseDateFormat,
    SetUSAdateFormat,
    SetUSAdateFormatV2,
} from "../../../../services/common-function";
import { ErrorCode } from "../../../../services/constant";
import ReactLoader from "../../../Loader";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LotteLoader from "../../../Loader/LotteLoader";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FiEdit } from "react-icons/fi";


const taskStatusArr = [
    {
        label: "Open",
        value: "0",
    },
    {
        label: "close",
        value: "1",
    },
];

export default class EditInternalProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: true,
            editId: "",
            projectId: "",
            isAddMilestone: false,
            projectTitle: "",
            expectedEndDate: "",
            projectDescription: "",
            note: "",
            //   ......for mileStone modal,,,,,,,,,,,,,
            mileStoneName: "",
            milestoneStartDate: "",
            milestoneEndDate: "",

            //   .............task list.....
            milestoneArr: [],
            // ............for task modal,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
            milestoneTitle: "",
            taskName: "",
            taskDescription: "",
            taskOwnerArr: [],
            selectedTaskOwner: {},
            taskStartDate: "",
            taskEndDate: "",
            taskStatusArr: taskStatusArr,
            selectedTaskStatus: {},
            taskNotes: "",
            curIndex: 0,
            SelectedJob: {},
            allJobs: [],
            isUpdate: false,
            updateTaskId: "",
            milestoneModal: "",
            mileStoneEditPos: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();

        // var classInstance = this;

        // When the user clicks anywhere outside of the modal, close it

        document.getElementById("backdrop").style.display = "none";
        // var mileStoneModal = document.getElementById("milestone-model");
        // var taskModal = document.getElementById("task-model");

        this.getAllJobs();

        this.fetchData();
    }

    fetchData = async () => {

        let reqData = {
            reqUserTypeId: 2,
        };
        let response = await ApiCall("getUserListByUserType", reqData);
        if (
            response.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            response.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let decodeData = Decoder.decode(response.data.payload);
            let listData = decodeData.data.userList;
            let arr = [];
            // consoleLog("Admin Staff List::", listData);
            listData.map((ll) => {
                arr.push({
                    label: ll.name,
                    value: ll.userId
                })
            })
            let mainData = this.props.location,
                preData = mainData.state;

            consoleLog("Internal Project ID", preData);
            this.setState({
                editId: preData
            })
            let obj = {
                actProjectId: preData
            }
            let res = await ApiCall("fetchInternalProject", obj);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let decodeData = Decoder.decode(res.data.payload);
                consoleLog("Fetch Internal Project Data:", decodeData);
                let respObject = decodeData.data.project;
                let milestoneArr = [];
                if (respObject.milestone.length > 0) {
                    respObject.milestone.map((data) => {
                        let task = [];

                        if (data.task.length > 0) {
                            data.task.map((tsk) => {
                                let selectedOwner = {},
                                    selectedStatus = {},
                                    selectedJob = {};
                                // ......Owner Data Check..........
                                arr.map((obj) => {
                                    if (obj.value == tsk.ownerId) {
                                        selectedOwner = {
                                            label: obj.label,
                                            value: obj.value,
                                        };
                                    }
                                });

                                //Job Data Check
                                this.state.allJobs.map((obj) => {
                                    if (obj.value === tsk.jobId) {
                                        selectedJob = {
                                            label: obj.label,
                                            value: obj.value
                                        }
                                    }
                                })

                                //....... Status Check..........
                                taskStatusArr.map((obj) => {
                                    if (obj.value == tsk.status) {
                                        selectedStatus = {
                                            label: obj.label,
                                            value: obj.value,
                                        };
                                    }
                                });
                                task.push({
                                    id: tsk.id,
                                    taskId: tsk.taskId,
                                    milestoneId: tsk.milestoneId,
                                    projectId: tsk.projectId,
                                    jobId: tsk.jobId,
                                    taskName: tsk.taskname,
                                    taskDescription: tsk.description,
                                    selectedOwner: selectedOwner,
                                    selectedJob: selectedJob,
                                    ownerId: tsk.ownerId,
                                    startDate: SetUSAdateFormat(tsk.startDate),
                                    endDate: SetUSAdateFormat(tsk.endDate),
                                    status: tsk.status,
                                    selectedStatus: selectedStatus,
                                    notes: tsk.adminNote,
                                    action: "-1"
                                })
                            })
                        }
                        milestoneArr.push({
                            id: data.id,
                            milestoneId: data.milestoneId,
                            milestoneName: data.name,
                            startDate: SetUSAdateFormat(data.startDate),
                            endDate: SetUSAdateFormat(data.endDate),
                            taskData: task,
                            action: "-1"
                        })
                    })
                }
                this.setState({
                    isLoad: false,
                    editId: respObject.id,
                    projectId: respObject.projectId,
                    projectTitle: respObject.title,
                    expectedEndDate: SetUSAdateFormat(respObject.dueDate),
                    projectDescription: respObject.description,
                    note: respObject.adminNote,
                    milestoneArr: milestoneArr
                })
            }

            this.setState({
                taskOwnerArr: arr
            });
        }

    }

    load = () => {

        this.getAllAdminStaffList();
    };

    getAllJobs = async () => {

        let reqData = {};
        let res = await ApiCall("getAllActiveJob", reqData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let decodeData = Decoder.decode(res.data.payload);
            let listData = decodeData.data.data;
            let arr = [];
            // consoleLog("All Job List Data >>>> ::", listData);
            listData.map((ll) => {
                let str = "";
                if (ll.serviceTypeId === 45) {
                    str = "Interpretation"
                } else if (ll.serviceTypeId === 46) {
                    str = "Translation"
                } else if (ll.serviceTypeId === 47) {
                    str = "Training"
                }
                arr.push({
                    label: ll.requestId + "  ( " + str + " )",
                    value: ll.id,
                    text: str
                })
            })

            this.setState({
                allJobs: arr
            });
        }
    }

    getAllAdminStaffList = async () => {

        let reqData = {
            reqUserTypeId: 2,
        };
        let res = await ApiCall("getUserListByUserType", reqData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let decodeData = Decoder.decode(res.data.payload);
            let listData = decodeData.data.userList;
            let arr = [];
            // consoleLog("Admin Staff List::", listData);
            listData.map((ll) => {
                arr.push({
                    label: ll.name,
                    value: ll.userId
                })
            })

            this.setState({
                taskOwnerArr: arr
            });
        }
    }

    onSaveProject = async () => {
        window.scrollTo(0, 0);
        // consoleLog("Show Milestone Array::", this.state.milestoneArr);
        let mileArr = [];
        this.state.milestoneArr.map((mile) => {
            let taskArr = [];
            consoleLog("Under MileStone Task Data:", mile.taskData)
            if (mile.taskData.length > 0) {
                mile.taskData.map((task) => {
                    taskArr.push({
                        id: task.id,
                        taskId: task.taskId,
                        taskName: task.taskName,
                        jobId: task.jobId,
                        taskDescription: task.taskDescription,
                        ownerId: task.ownerId,
                        startDate: SetDatabaseDateFormat(task.startDate),
                        endDate: SetDatabaseDateFormat(task.endDate),
                        status: task.status.toString(),
                        adminNote: task.notes,
                        action: task.action
                    })
                })
            }
            mileArr.push({
                id: mile.id,
                milestoneId: mile.milestoneId,
                milestoneName: mile.milestoneName,
                startDate: SetDatabaseDateFormat(mile.startDate),
                endDate: SetDatabaseDateFormat(mile.endDate),
                action: mile.action,
                task: taskArr
            })
        })

        let errorCount = 0;
        if (this.state.projectTitle === "") {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.EMPTY_TITLE);
            errorCount++;
            return false;
        } else if (this.state.expectedEndDate === "") {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.EMPTY_DEADLINE);
            errorCount++;
            return false;
        } else if (this.state.projectDescription === "") {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.EMPTY_DESCRIPTION);
            errorCount++;
            return false;
        }
        // .................Save internal project API....................................

        if (errorCount === 0) {



            let objData = {
                id: this.state.editId,
                projectId: this.state.projectId,
                title: this.state.projectTitle,
                dueDate: SetDatabaseDateFormat(this.state.expectedEndDate),
                projectDescription: this.state.projectDescription,
                adminNote: this.state.note,
                milestone: mileArr
            };

            consoleLog("Update Internal Project Api Request:", objData);

            let res = await ApiCall("updateInternalProject", objData);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                toast.success(AlertMessage.MESSAGE.INTERNAL_PROJECT.UPDATE_SUCCESS);
                setTimeout(() => {
                    return history.push("/adminInternalProjectList");
                }, 3000)


            } else {
                toast.error(res.message);
            }
        }
    };

    openMilestoneModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("milestone-model").style.display = "block";
        document.getElementById("milestone-model").classList.add("show");
    };

    closeMilestoneModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("milestone-model").style.display = "none";
        document.getElementById("milestone-model").classList.remove("show");
        this.resetMilestoneData();
    };
    openTaskModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("task-model").style.display = "block";
        document.getElementById("task-model").classList.add("show");
    };

    closeTaskModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("task-model").style.display = "none";
        document.getElementById("task-model").classList.remove("show");
        this.resetTaskData();
    };

    // .....................func for cancel btn......................

    onCancel = () => {
        window.scrollTo(0, 0);
        return history.push("/adminInternalProjectList");
    };

    //   ..................
    onProjectTitleChange = (value) => {
        this.setState({
            projectTitle: value,
        });
    };

    expectedDateChange = (date) => {
        this.setState({
            expectedEndDate: SetUSAdateFormat(date),
        });
    };
    onDescriptionChange = (e) => {
        this.setState({
            projectDescription: e.target.value,
        });
    };
    onNotesChange = (e) => {
        this.setState({
            note: e.target.value,
        });
    };

    onOpenMilestone = () => {
        this.setState({
            isAddMilestone: true,
        });
        this.openMilestoneModal();
    };

    //   ...............milestone modal,,,,,,,,,,,,,,,
    resetMilestoneData = () => {
        this.setState({
            mileStoneName: "",
            milestoneStartDate: "",
            milestoneEndDate: "",
        });
    };

    onMilestoneNameChange = (val) => {
        this.setState({
            mileStoneName: val,
        });
    };
    milestoneStartDateChange = (date) => {
        this.setState({
            milestoneStartDate: SetUSAdateFormat(date),
        });
    };
    milestoneEndDateChange = (date) => {
        this.setState({
            milestoneEndDate: SetUSAdateFormat(date),
        });
    };
    onAddMilestone = () => {
        let errorCount = 0;

        if (inputEmptyValidate(this.state.mileStoneName) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_MILESTONE_NAME
            );
            errorCount++;
        } else if (inputEmptyValidate(this.state.milestoneStartDate) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_START_DATE
            );
            errorCount++;
        } else if (inputEmptyValidate(this.state.milestoneEndDate) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_END_DATE
            );
            errorCount++;
        } else if (this.state.milestoneEndDate < this.state.milestoneStartDate) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.END_LESS_FROM
            );
            errorCount++;
        }

        if (errorCount === 0) {
            let objData = {
                milestoneName: this.state.mileStoneName,
                startDate:
                    this.state.milestoneStartDate == ""
                        ? ""
                        : SetDatabaseDateFormat(this.state.milestoneStartDate),
                endDate:
                    this.state.milestoneEndDate == ""
                        ? ""
                        : SetDatabaseDateFormat(this.state.milestoneEndDate),
            };
            consoleLog("data::", objData);

            this.closeMilestoneModal();
            // this.resetMilestoneData();
            // ........................................
            let milestoneArr = this.state.milestoneArr;

            let data = {
                id: "",
                milestoneId: "",
                milestoneName: this.state.mileStoneName,
                startDate: this.state.milestoneStartDate,
                endDate: this.state.milestoneEndDate,
                // taskData: [
                //   {
                //     taskId: 1,
                //     taskName: "",
                //     ownerId: "",
                //     startDate: SetDatabaseDateFormat(new Date()),
                //     endDate: SetDatabaseDateFormat(new Date()),
                //     status: "",
                //     selectedOwner: {},
                //     selectedStatus: {},
                //   },
                // ],
                taskData: [],
                action: "0"
            };
            milestoneArr.push(data);
            this.setState({
                milestoneArr: this.state.milestoneArr,
                milestoneName: "",
                milestoneStartDate: "",
                milestoneEndDate: ""
            });
        }
    };
    onAddMoreMilestone = () => {
        let errorCount = 0;

        if (inputEmptyValidate(this.state.mileStoneName) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_MILESTONE_NAME
            );
            errorCount++;
        } else if (inputEmptyValidate(this.state.milestoneStartDate) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_START_DATE
            );
            errorCount++;
        } else if (inputEmptyValidate(this.state.milestoneEndDate) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.EMPTY_END_DATE
            );
            errorCount++;
        } else if (this.state.milestoneEndDate < this.state.milestoneStartDate) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.MILESTONE.END_LESS_FROM
            );
            errorCount++;
        }

        if (errorCount === 0) {
            let objData = {
                milestoneName: this.state.mileStoneName,
                startDate:
                    this.state.milestoneStartDate == ""
                        ? ""
                        : SetDatabaseDateFormat(this.state.milestoneStartDate),
                startDate:
                    this.state.milestoneEndDate == ""
                        ? ""
                        : SetDatabaseDateFormat(this.state.milestoneEndDate),
            };
            consoleLog("data::", objData);


            // this.resetMilestoneData();
            // ........................................
            let milestoneArr = this.state.milestoneArr;

            let data = {
                id: "",
                milestoneId: "",
                milestoneName: this.state.mileStoneName,
                startDate: this.state.milestoneStartDate,
                endDate: this.state.milestoneEndDate,
                // taskData: [
                //   {
                //     taskId: 1,
                //     taskName: "",
                //     ownerId: "",
                //     startDate: SetDatabaseDateFormat(new Date()),
                //     endDate: SetDatabaseDateFormat(new Date()),
                //     status: "",
                //     selectedOwner: {},
                //     selectedStatus: {},
                //   },
                // ],
                taskData: [],
                action: "0"
            };
            milestoneArr.push(data);
            this.setState({
                milestoneArr: this.state.milestoneArr,
                mileStoneName: "",
                milestoneStartDate: "",
                milestoneEndDate: ""
            });
        }
    }

    //   ...............for task list ...............

    ontaskNameChange = (val, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].taskName = val;
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };
    onJobChange = (val, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].jobId = val.value;
        milestoneMainArr[key].taskData[key1].selectedJob = val;
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };
    onOwnerChange = (val, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].ownerId = val.value;
        milestoneMainArr[key].taskData[key1].selectedOwner = val;
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };
    onTaskStartDateChange = (date, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].startDate = SetUSAdateFormat(date);
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };
    onTaskEndDateChange = (date, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].endDate = SetUSAdateFormat(date);
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };
    onTaskStatusChange = (val, key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;

        milestoneMainArr[key].taskData[key1].status = val.value;
        milestoneMainArr[key].taskData[key1].selectedStatus = val;
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            milestoneArr: milestoneMainArr,
        });
    };

    onDeleteTask = (key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;
        milestoneMainArr[key].taskData[key1].action = "2";
        // milestoneMainArr[key].taskData.splice(key1, 1);
        this.setState({
            milestoneArr: milestoneMainArr,
        })

        // consoleLog("main arr::",milestoneMainArr)
    };

    onEditTask = (key, key1) => {
        let milestoneMainArr = this.state.milestoneArr;
        milestoneMainArr[key].taskData[key1].action = "1";
        this.setState({
            isUpdate: true,
            updateTaskId: milestoneMainArr[key].taskData[key1].id,
            milestoneTitle: milestoneMainArr[key].milestoneName,
            taskName: milestoneMainArr[key].taskData[key1].taskName,
            SelectedJob: milestoneMainArr[key].taskData[key1].selectedJob,
            taskDescription: milestoneMainArr[key].taskData[key1].taskDescription,
            selectedTaskOwner: milestoneMainArr[key].taskData[key1].selectedOwner,
            taskStartDate: milestoneMainArr[key].taskData[key1].startDate,
            taskEndDate: milestoneMainArr[key].taskData[key1].endDate,
            selectedTaskStatus: milestoneMainArr[key].taskData[key1].selectedStatus,
            taskNotes: milestoneMainArr[key].taskData[key1].notes,
            milestoneArr: milestoneMainArr
        });

        this.openTaskModal();
    }

    // ..................for add task modal,,,,,,,,,,,,,,,,,,,,
    onTaskNameChange_modal = (val) => {
        this.setState({
            taskName: val,
        });
    };

    onJobChange_modal = (val) => {
        this.setState({
            SelectedJob: val,
        });
    };

    onTaskDescriptionChange_modal = (e) => {
        this.setState({
            taskDescription: e.target.value,
        });
    };
    onTaskStartDateChange_modal = (date) => {
        this.setState({
            taskStartDate: SetUSAdateFormat(date),
        });
    };
    onTaskEndDateChange_modal = (date) => {
        this.setState({
            taskEndDate: SetUSAdateFormat(date),
        });
    };
    onTaskOwnerChange_modal = (val) => {
        this.setState({
            selectedTaskOwner: val,
        });
    };

    onTaskStatusChange_modal = (val) => {
        this.setState({
            selectedTaskStatus: val,
        });
    };
    onTaskNotesChange_modal = (e) => {
        this.setState({
            taskNotes: e.target.value,
        });
    };

    resetTaskData = () => {
        this.setState({
            updateTaskId: "",
            taskName: "",
            taskDescription: "",
            taskStartDate: "",
            taskEndDate: "",
            selectedTaskOwner: {},
            selectedTaskStatus: {},
            SelectedJob: {},
            taskNotes: "",
            milestoneTitle: "",
            isUpdate: false
        });
    };

    openTask = (item, index) => {
        this.openTaskModal();
        consoleLog("item::", item);
        this.setState({
            curIndex: index,
            milestoneTitle: item.milestoneName
        });
    };

    onAddTask = () => {
        let errorCount = 0;

        if (inputEmptyValidate(this.state.taskName) == false) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_TASK_NAME);
            errorCount++;
        }
        //  else if (
        //     inputEmptyValidate(this.state.SelectedJob.value) == false
        // ) {
        //     toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_JOB);
        //     errorCount++;
        // } 
        else if (inputEmptyValidate(this.state.taskDescription) == false) {
            toast.error(
                AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_TASK_DESCRIPTION
            );
            errorCount++;
        } else if (
            inputEmptyValidate(this.state.selectedTaskOwner.value) == false
        ) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_OWNER);
            errorCount++;
        } else if (inputEmptyValidate(this.state.taskStartDate) == false) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_START_DATE);
            errorCount++;
        } else if (inputEmptyValidate(this.state.taskEndDate) == false) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_END_DATE);
            errorCount++;
        } else if (inputEmptyValidate(this.state.taskEndDate) == false) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_END_DATE);
            errorCount++;
        } else if (this.state.taskStartDate > this.state.taskEndDate) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.END_LESS_FROM);
            errorCount++;
        } else if (
            inputEmptyValidate(this.state.selectedTaskStatus.value) == false
        ) {
            toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_STATUS);
            errorCount++;
        }

        if (errorCount === 0) {
            // consoleLog("index:::", this.state.curIndex);

            let milestoneArr = this.state.milestoneArr;

            // ......for owner dropdown in task list...................
            let ownerObj = {},
                statusObj = {},
                slectedJobObj = {};

            // this.state.allJobs.map((obj) => {
            //     if (obj.value == this.state.selectedTaskOwner.value) {
            //         ownerObj = {
            //             label: obj.label,
            //             value: obj.value,
            //         };
            //     }
            // });

            this.state.taskOwnerArr.map((obj) => {
                if (obj.value == this.state.selectedTaskOwner.value) {
                    ownerObj = {
                        label: obj.label,
                        value: obj.value,
                    };
                }
            });

            // ...........for status dropdown in task list,,,,,,,,,,,,,,,

            taskStatusArr.map((obj) => {
                if (obj.value == this.state.selectedTaskStatus.value) {
                    statusObj = {
                        label: obj.label,
                        value: obj.value,
                    };
                }
            });

            let objResData = {
                id: "",
                taskId: "",
                taskName: this.state.taskName,
                selectedJob: this.state.SelectedJob,
                taskDescription: this.state.taskDescription,
                ownerId: this.state.selectedTaskOwner.value,
                jobId: Object.keys(this.state.SelectedJob).length > 0 ? this.state.SelectedJob.value : "0",
                selectedOwner: ownerObj,
                startDate:
                    this.state.taskStartDate == ""
                        ? ""
                        : this.state.taskStartDate,
                endDate:
                    this.state.taskEndDate == ""
                        ? ""
                        : this.state.taskEndDate,
                status:
                    this.state.selectedTaskStatus.value == null ||
                        this.state.selectedTaskStatus.value == undefined
                        ? ""
                        : this.state.selectedTaskStatus.value,
                selectedStatus: statusObj,
                notes: this.state.taskNotes,
                milestoneId: milestoneArr[this.state.curIndex].milestoneId,
                action: "0"
            };
            // consoleLog("New Task Data::", objResData)
            milestoneArr[this.state.curIndex].taskData.push(objResData);
            this.setState({
                milestoneArr: milestoneArr,
            });

            // consoleLog("modified MileStone arr::", milestoneArr);

            this.closeTaskModal();
            this.resetTaskData();
        }
    };

    onUpdateTask = async () => {
        let errorCount = 0;
        try {

            if (inputEmptyValidate(this.state.taskName) == false) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_TASK_NAME);
                errorCount++;
            }
            else if (inputEmptyValidate(this.state.taskDescription) == false) {
                toast.error(
                    AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_TASK_DESCRIPTION
                );
                errorCount++;
            } else if (
                inputEmptyValidate(this.state.selectedTaskOwner.value) == false
            ) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_OWNER);
                errorCount++;
            } else if (inputEmptyValidate(this.state.taskStartDate) == false) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_START_DATE);
                errorCount++;
            } else if (inputEmptyValidate(this.state.taskEndDate) == false) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_END_DATE);
                errorCount++;
            } else if (inputEmptyValidate(this.state.taskEndDate) == false) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_END_DATE);
                errorCount++;
            } else if (this.state.taskStartDate > this.state.taskEndDate) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.END_LESS_FROM);
                errorCount++;
            } else if (
                inputEmptyValidate(this.state.selectedTaskStatus.value) == false
            ) {
                toast.error(AlertMessage.MESSAGE.INTERNAL_PROJECT.TASK.EMPTY_STATUS);
                errorCount++;
            }

            if (errorCount === 0) {
                // consoleLog("index:::", this.state.curIndex);

                let milestoneArr = this.state.milestoneArr;

                // ......for owner dropdown in task list...................
                let ownerObj = {},
                    statusObj = {},
                    slectedJobObj = {};

                this.state.taskOwnerArr.map((obj) => {
                    if (obj.value == this.state.selectedTaskOwner.value) {
                        ownerObj = {
                            label: obj.label,
                            value: obj.value,
                        };
                    }
                });

                // ...........for status dropdown in task list,,,,,,,,,,,,,,,

                taskStatusArr.map((obj) => {
                    if (obj.value == this.state.selectedTaskStatus.value) {
                        statusObj = {
                            label: obj.label,
                            value: obj.value,
                        };
                    }
                });

                let objData = {
                    id: this.state.updateTaskId,
                    taskName: this.state.taskName,
                    taskDescription: this.state.taskDescription,
                    ownerId: this.state.selectedTaskOwner.value,
                    jobId: Object.keys(this.state.SelectedJob).length > 0 ? this.state.SelectedJob.value : "0",
                    startDate:
                        this.state.taskStartDate == ""
                            ? ""
                            : SetDatabaseDateFormat(this.state.taskStartDate),
                    endDate:
                        this.state.taskEndDate == ""
                            ? ""
                            : SetDatabaseDateFormat(this.state.taskEndDate),
                    status:
                        this.state.selectedTaskStatus.value == null ||
                            this.state.selectedTaskStatus.value == undefined
                            ? ""
                            : this.state.selectedTaskStatus.value,
                    adminNote: this.state.taskNotes
                };

                consoleLog("Update Internal Task request :", objData);

                let res = await ApiCall("updatetask", objData);
                if (
                    res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                    res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                ) {
                    toast.success(AlertMessage.MESSAGE.INTERNAL_PROJECT.UPDATE_TASK_DATA);
                    this.fetchData();
                    this.closeTaskModal();
                    this.resetTaskData();
                } else {
                    toast.error(res.message);
                }
            }
        } catch (e) {
            console.log("Error in Update Task")
        }
    };

    onBack = () => {
        this.props.history.push("/adminInternalProjectList");
    };
    onSaveMilestone = () => {
        let objData = {
            milestoneArr: this.state.milestoneArr,
        };

        // consoleLog("main data:::", objData);
    };

    openEditMileStoneModal = (data, pos) => {
        consoleLog("Milestone Data::", data);
        this.setState({
            mileStoneEditPos: pos,
            mileStoneName: data.milestoneName,
            milestoneStartDate: data.startDate,
            milestoneEndDate: data.endDate,
            milestoneModal: true
        })
    }

    closeEditMilestoneModal = () => {
        this.setState({
            milestoneModal: false
        })
    }

    updateMileStone = async () => {

        let milestoneArr = this.state.milestoneArr;
        milestoneArr[this.state.mileStoneEditPos].milestoneName = this.state.mileStoneName;
        milestoneArr[this.state.mileStoneEditPos].startDate = this.state.milestoneStartDate;
        milestoneArr[this.state.mileStoneEditPos].endDate = this.state.milestoneEndDate;
        milestoneArr[this.state.mileStoneEditPos].action = "1";

        this.setState({
            milestoneArr: milestoneArr,
            mileStoneName: "",
            milestoneStartDate: "",
            milestoneEndDate: "",
            milestoneModal: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" />
                <div class="component-wrapper" hidden={!this.state.isLoad}>
                    {/* <ReactLoader /> */}
                    <LotteLoader />
                </div>

                <div className="component-wrapper" hidden={this.state.isLoad}>
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> /{" "}
                        <Link to="/adminInternalProjectList">Internal Projects</Link> /
                        Update Project
                    </div>
                    <h3 className="dcs">Update Project</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="department-component-app _fl sdw">
                                <h3>Project Details</h3>
                                {/* <div className="department-form">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_rbx">
                        {" "}
                        <span className="">Project ID</span>
                        <p style={{ fontSize: "16px" }}>12345</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                                <div className="department-form">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_rbx">
                                                {" "}
                                                <span className="">Project Title *</span>
                                                <div className="dropdwn">
                                                    <InputText
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.projectTitle}
                                                        onTextChange={(value) => {
                                                            this.onProjectTitleChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5">
                                            <div className="form_rbx">
                                                {" "}
                                                <span className="">Expected End Date *</span>
                                                <div
                                                    className="input-group"
                                                    style={{
                                                        width: "100%",
                                                        borderRadius: "9px",
                                                        height: "43px",
                                                        border: "1px solid #ced4da",
                                                        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                    }}
                                                >
                                                    <div style={{ width: "80%", padding: "8px" }}>
                                                        {this.state.expectedEndDate}
                                                    </div>
                                                    <div style={{ width: "20%" }}>
                                                        <a style={{ float: "right" }}>
                                                            <DatePicker
                                                                dropdownMode="select"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                adjustDateOnChange
                                                                minDate={new Date()}
                                                                onChange={(date) =>
                                                                    this.expectedDateChange(date)
                                                                }
                                                                customInput={<Schedule />}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="department-form">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_rbx">
                                                {" "}
                                                <span className="">Project Description</span>
                                                <textarea
                                                    rows="2"
                                                    className="in-textarea msg min"
                                                    value={this.state.projectDescription}
                                                    style={{
                                                        height: "100px",
                                                        color: "var(--grey)",
                                                        borderRadius: "10px",
                                                        boxShadow: "2px",
                                                        resize: "none",
                                                    }}
                                                    onChange={this.onDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="department-form">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form_rbx">
                                                <span className="">Notes</span>
                                                <textarea
                                                    rows="2"
                                                    className="in-textarea msg min"
                                                    value={this.state.note}
                                                    style={{
                                                        height: "100px",
                                                        color: "var(--grey)",
                                                        borderRadius: "10px",
                                                        boxShadow: "2px",
                                                        resize: "none",
                                                    }}
                                                    onChange={this.onNotesChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.milestoneArr.length === 0 ? <React.Fragment>
                                    <div className="_button-style m30 _fl text-center">
                                        <a
                                            href="javascript:void(0)"
                                            className="white-btn"
                                            style={{ textDecoration: "none" }}
                                            onClick={this.onCancel}
                                        >
                                            Back
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="blue-btn"
                                            style={{ textDecoration: "none", color: "#fff" }}
                                            onClick={this.onSaveProject}
                                        >
                                            Update
                                        </a>
                                    </div>
                                </React.Fragment> : <React.Fragment></React.Fragment>}

                                {/* ........... Milestone and Task Management ....................... */}

                                <h3>Milestones & Task[s] Management</h3>
                                <div className="row">
                                    <div className="col-md-10"></div>
                                    <div className="col-md-2" style={{ textAlign: "right" }}>
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.onOpenMilestone}
                                        >
                                            Add Milestone
                                        </button>
                                    </div>
                                </div>
                                {this.state.milestoneArr.map((obj, key) => (
                                    <React.Fragment key={key}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                Milestone : {obj.milestoneName} &nbsp; &nbsp;
                                                <FiEdit
                                                    onClick={() => this.openEditMileStoneModal(obj, key)}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginRight: '3px'
                                                    }}
                                                    size={20}
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive_mb">
                                            <div className="table-listing-app tblt">
                                                <div className="table-listing-app proj_tbl">
                                                    <div className="add_tsts">
                                                        <table
                                                            width="100%"
                                                            cellspacing="0"
                                                            cellpadding="0"
                                                            border="0"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ width: "5%" }}>Task ID</th>
                                                                    <th style={{ width: "15%" }}>Task</th>
                                                                    <th style={{ width: "15%" }}>Job or Project</th>
                                                                    <th style={{ width: "15%" }}>Owner</th>
                                                                    <th style={{ width: "15%" }}>Start Date</th>
                                                                    <th style={{ width: "15%" }}>End Date</th>
                                                                    <th style={{ width: "10%" }}>Status</th>
                                                                    <th style={{ width: "10%" }}></th>
                                                                </tr>
                                                            </tbody>
                                                            <tbody>
                                                                {obj.taskData.length > 0 ? <React.Fragment>
                                                                    {obj.taskData.map((obj1, key1) => (
                                                                        <React.Fragment key={key1}>
                                                                            {obj1.action !== "2" ? <React.Fragment>
                                                                                <tr>
                                                                                    <td style={{ width: "5%" }}>
                                                                                        {/* {obj1.taskId} */}{key1 + 1}
                                                                                    </td>
                                                                                    <td style={{ width: "15%" }}>
                                                                                        {/* <InputText
                                                                                            placeholder=""
                                                                                            className="in-field2"
                                                                                            value={obj1.taskName}
                                                                                            onTextChange={(value) => {
                                                                                                this.ontaskNameChange(
                                                                                                    value,
                                                                                                    key,
                                                                                                    key1
                                                                                                );
                                                                                            }}
                                                                                        /> */}
                                                                                        {obj1.taskName}
                                                                                    </td>
                                                                                    <td style={{ width: "15%" }}>
                                                                                        {/* <SelectBox
                                                                                            optionData={this.state.allJobs}
                                                                                            value={obj1.selectedJob}
                                                                                            onSelectChange={(value) =>
                                                                                                this.onJobChange(value, key, key1)
                                                                                            }
                                                                                        ></SelectBox> */}
                                                                                        {Object.keys(obj1.selectedJob).length > 0 ? obj1.selectedJob.label : "Not Assigned"}
                                                                                    </td>
                                                                                    <td style={{ width: "15%" }}>
                                                                                        {/* <SelectBox
                                                                                            optionData={this.state.taskOwnerArr}
                                                                                            value={obj1.selectedOwner}
                                                                                            onSelectChange={(value) =>
                                                                                                this.onOwnerChange(value, key, key1)
                                                                                            }
                                                                                        ></SelectBox> */}
                                                                                        {Object.keys(obj1.selectedOwner).length > 0 ? obj1.selectedOwner.label : "Not Assigned"}
                                                                                    </td>
                                                                                    <td style={{ width: "15%" }}>
                                                                                        {/* <div
                                                                                            className="input-group"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                borderRadius: "9px",
                                                                                                height: "43px",
                                                                                                border: "1px solid #ced4da",
                                                                                                boxShadow:
                                                                                                    "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                                                            }}
                                                                                        >
                                                                                            <div className="dateFieldTxt">
                                                                                                {SetUSAdateFormat(obj1.startDate)}
                                                                                            </div>
                                                                                            <div style={{ width: "20%" }}>
                                                                                                <a style={{ float: "right" }}>
                                                                                                    <DatePicker
                                                                                                        dropdownMode="select"
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        adjustDateOnChange
                                                                                                        minDate={new Date()}
                                                                                                        //   maxDate={new Date()}
                                                                                                        onChange={(date) =>
                                                                                                            this.onTaskStartDateChange(
                                                                                                                date,
                                                                                                                key,
                                                                                                                key1
                                                                                                            )
                                                                                                        }
                                                                                                        customInput={<Schedule />}
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {SetUSAdateFormat(obj1.startDate)}
                                                                                    </td>
                                                                                    <td style={{ width: "15%" }}>
                                                                                        {/* <div
                                                                                            className="input-group"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                borderRadius: "9px",
                                                                                                height: "43px",
                                                                                                border: "1px solid #ced4da",
                                                                                                boxShadow:
                                                                                                    "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                                                            }}
                                                                                        >
                                                                                            <div className="dateFieldTxt">
                                                                                                {SetUSAdateFormat(obj1.endDate)}
                                                                                            </div>
                                                                                            <div style={{ width: "20%" }}>
                                                                                                <a style={{ float: "right" }}>
                                                                                                    <DatePicker
                                                                                                        dropdownMode="select"
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        adjustDateOnChange
                                                                                                        minDate={new Date()}
                                                                                                        //   maxDate={new Date()}
                                                                                                        onChange={(date) =>
                                                                                                            this.onTaskEndDateChange(
                                                                                                                date,
                                                                                                                key,
                                                                                                                key1
                                                                                                            )
                                                                                                        }
                                                                                                        customInput={<Schedule />}
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {SetUSAdateFormat(obj1.endDate)}
                                                                                    </td>
                                                                                    <td style={{ width: "10%" }}>
                                                                                        {/* <SelectBox
                                                                                            optionData={this.state.taskStatusArr}
                                                                                            value={obj1.selectedStatus}
                                                                                            onSelectChange={(value) =>
                                                                                                this.onTaskStatusChange(
                                                                                                    value,
                                                                                                    key,
                                                                                                    key1
                                                                                                )
                                                                                            }
                                                                                        ></SelectBox> */}
                                                                                        {obj1.selectedStatus.label}
                                                                                    </td>
                                                                                    <td style={{ width: "10%" }}>
                                                                                        {this.state.milestoneArr.map((obj, index) =>
                                                                                        (
                                                                                            <React.Fragment key={index}>
                                                                                                {key == index ? (
                                                                                                    <React.Fragment>

                                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            {obj1.id !== "" ?
                                                                                                                <FiEdit
                                                                                                                    onClick={() => this.onEditTask(key, key1)}
                                                                                                                    style={{
                                                                                                                        cursor: "pointer",
                                                                                                                        marginRight: '3px'
                                                                                                                    }}
                                                                                                                    size={25}
                                                                                                                /> : <></>
                                                                                                            }
                                                                                                            {obj.taskData.length > 1 ?
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        ImageName.IMAGE_NAME
                                                                                                                            .CANCEL_BTN
                                                                                                                    }
                                                                                                                    className="delete-btn"
                                                                                                                    onClick={() =>
                                                                                                                        this.onDeleteTask(key, key1)
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        cursor: "pointer",
                                                                                                                    }}
                                                                                                                />
                                                                                                                : <></>
                                                                                                            }
                                                                                                        </div>

                                                                                                    </React.Fragment>
                                                                                                ) : (
                                                                                                    <React.Fragment />
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        )
                                                                                        )}
                                                                                    </td>
                                                                                </tr></React.Fragment> : <React.Fragment></React.Fragment>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}</React.Fragment> : <React.Fragment>
                                                                    <tr>
                                                                        <td colSpan={8}>No Task Found</td>
                                                                    </tr>
                                                                </React.Fragment>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.milestoneArr.length > 0 ? (
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-10"></div>
                                                        <div className="col-md-2">
                                                            <div className="addTaskBtn">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => this.openTask(obj, key)}
                                                                >
                                                                    Add Task
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                ))}

                                {this.state.milestoneArr.length > 0 ? (
                                    <React.Fragment>
                                        <div className="_button-style m30 _fl text-center">
                                            <a
                                                href="javascript:void(0)"
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.onBack}
                                            >
                                                Back
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="blue-btn"
                                                style={{ textDecoration: "none", color: "#fff" }}
                                                onClick={this.onSaveProject}
                                            >
                                                Update
                                            </a>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* .......................mileStone modal,,,,,,,,,,,,,,,,,,,,,,,,, */}
                <div
                    id="milestone-model"
                    className="modal fade modelwindow"
                    role="dialog"
                >
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content milestone-modal">
                            <div className="filter-head _fl mdfs taskHead">
                                <h3 className="milestoneTxt">New Milestone</h3>
                                <div className="modal-body">
                                    <div className="create-jeneral-wrap _fl">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">Milestone</span>
                                                    <InputText
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.mileStoneName}
                                                        onTextChange={(value) => {
                                                            this.onMilestoneNameChange(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Start Date</span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "43px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            {this.state.milestoneStartDate}
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    minDate={new Date()}
                                                                    onChange={(date) =>
                                                                        this.milestoneStartDateChange(date)
                                                                    }
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>End Start</span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "43px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            {this.state.milestoneEndDate}
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    minDate={new Date()}
                                                                    onChange={(date) =>
                                                                        this.milestoneEndDateChange(date)
                                                                    }
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="web-form-bx selct">
                                                    <div className="_button-style _fl text-center">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="blue-btn"
                                                            style={{ textDecoration: "none", color: "#fff" }}
                                                            onClick={this.onAddMilestone}
                                                        >
                                                            Add
                                                        </a>
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="blue-btn"
                                                            style={{ textDecoration: "none", color: "#fff" }}
                                                            onClick={this.onAddMoreMilestone}
                                                        >
                                                            Add More
                                                        </a>
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="white-btn"
                                                            onClick={this.closeMilestoneModal}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.milestoneModal} onHide={() => this.closeEditMilestoneModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Milestone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-md-5">
                                <div className="form_rbx">
                                    {" "}
                                    <span className="">Milestone</span>
                                    <InputText
                                        type="text"
                                        placeholder=""
                                        className="in-field2"
                                        value={this.state.mileStoneName}
                                        onTextChange={(value) => {
                                            this.onMilestoneNameChange(value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form_rbx">
                                    <span>Start Date</span>
                                    <div
                                        className="input-group"
                                        style={{
                                            width: "100%",
                                            borderRadius: "9px",
                                            height: "43px",
                                            border: "1px solid #ced4da",
                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                        }}
                                    >
                                        <div style={{ width: "80%", padding: "8px" }}>
                                            {this.state.milestoneStartDate}
                                        </div>
                                        <div style={{ width: "20%" }}>
                                            <a style={{ float: "right" }}>
                                                <DatePicker
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    adjustDateOnChange
                                                    minDate={new Date()}
                                                    onChange={(date) =>
                                                        this.milestoneStartDateChange(date)
                                                    }
                                                    customInput={<Schedule />}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-5">
                                <div className="form_rbx">
                                    <span>End Start</span>
                                    <div
                                        className="input-group"
                                        style={{
                                            width: "100%",
                                            borderRadius: "9px",
                                            height: "43px",
                                            border: "1px solid #ced4da",
                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                        }}
                                    >
                                        <div style={{ width: "80%", padding: "8px" }}>
                                            {this.state.milestoneEndDate}
                                        </div>
                                        <div style={{ width: "20%" }}>
                                            <a style={{ float: "right" }}>
                                                <DatePicker
                                                    dropdownMode="select"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    adjustDateOnChange
                                                    minDate={new Date()}
                                                    onChange={(date) =>
                                                        this.milestoneEndDateChange(date)
                                                    }
                                                    customInput={<Schedule />}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeEditMilestoneModal()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.updateMileStone()}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* ..............................add task,,,,,,,,,,,,,,,,,,,,,,,,,,, */}

                <div id="task-model" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="filter-head _fl mdfs taskHead">

                                <h3 className="milestoneTxt">{this.state.isUpdate ? "Update Task" : "New Task"} </h3>
                                <div className="modal-body">
                                    <div className="create-jeneral-wrap _fl">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    {" "}
                                                    <span className="">Milestone</span>

                                                    <p>{this.state.milestoneTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Task *</span>
                                                    <InputText
                                                        type="text"
                                                        placeholder=""
                                                        className="in-field2"
                                                        value={this.state.taskName}
                                                        onTextChange={(value) => {
                                                            this.onTaskNameChange_modal(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Job or Project</span>
                                                    <SelectBox
                                                        optionData={this.state.allJobs}
                                                        value={this.state.SelectedJob}
                                                        onSelectChange={(value) =>
                                                            this.onJobChange_modal(value)
                                                        }
                                                    ></SelectBox>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Task Description *</span>
                                                    <textarea
                                                        rows="2"
                                                        placeholder=""
                                                        className="in-textarea msg min"
                                                        value={this.state.taskDescription}
                                                        style={{
                                                            height: "100px",
                                                            color: "var(--grey)",
                                                            borderRadius: "10px",
                                                            boxShadow: "2px",
                                                            resize: "none",
                                                        }}
                                                        onChange={(e) =>
                                                            this.onTaskDescriptionChange_modal(e)
                                                        }
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Owner *</span>
                                                    <SelectBox
                                                        optionData={this.state.taskOwnerArr}
                                                        value={this.state.selectedTaskOwner}
                                                        onSelectChange={(value) =>
                                                            this.onTaskOwnerChange_modal(value)
                                                        }
                                                    ></SelectBox>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Start Date *</span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "43px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            {this.state.taskStartDate}
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    minDate={new Date()}
                                                                    onChange={(date) =>
                                                                        this.onTaskStartDateChange_modal(date)
                                                                    }
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>End Date *</span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "43px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            {this.state.taskEndDate}
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    minDate={new Date()}
                                                                    onChange={(date) =>
                                                                        this.onTaskEndDateChange_modal(date)
                                                                    }
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Status *</span>
                                                    <SelectBox
                                                        optionData={this.state.taskStatusArr}
                                                        value={this.state.selectedTaskStatus}
                                                        onSelectChange={(value) =>
                                                            this.onTaskStatusChange_modal(value)
                                                        }
                                                    ></SelectBox>
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <div className="form_rbx">
                                                    <span>Notes</span>
                                                    <textarea
                                                        rows="2"
                                                        placeholder=""
                                                        className="in-textarea msg min"
                                                        value={this.state.taskNotes}
                                                        style={{
                                                            height: "100px",
                                                            color: "var(--grey)",
                                                            borderRadius: "10px",
                                                            boxShadow: "2px",
                                                            resize: "none",
                                                        }}
                                                        onChange={(e) => this.onTaskNotesChange_modal(e)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="web-form-bx selct">
                                                    <div className="_button-style _fl text-center">
                                                        {this.state.isUpdate ? <React.Fragment>
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="blue-btn"
                                                                style={{ textDecoration: "none", color: "#fff" }}
                                                                onClick={this.onUpdateTask}
                                                            >
                                                                Update
                                                            </a></React.Fragment> :
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="blue-btn"
                                                                style={{ textDecoration: "none", color: "#fff" }}
                                                                onClick={this.onAddTask}
                                                            >
                                                                Add
                                                            </a>
                                                        }
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="white-btn"
                                                            onClick={this.closeTaskModal}
                                                            style={{ textDecoration: "none" }}
                                                        >
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal-backdrop fade show"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment >
        );
    }
}
class Schedule extends React.Component {
    render() {
        const { onClick } = this.props;
        return (
            <img
                style={{
                    width: "35px",
                    height: "37px",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
                src={ImageName.IMAGE_NAME.CALENDER4}
                onClick={onClick}
            />
        );
    }
}
