import React from 'react';
import { consoleLog } from '../../services/common-function';
import "./video.css";
// import { decode as base64_decode, encode as base64_encode } from "base-64";
import Room from "./Room";
import { ApiCall } from '../../services/middleware';
import { ErrorCode } from '../../services/constant';
import { CryptoDecoder, Decoder, Encoder } from '../../services/auth';
import Lottie from "lottie-react";
// import Loading from "./99947-loader.json";
import Loading from "./7clingo_animate.json";
import { LOCAL_HOST } from '../../services/config/api_url';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import TextField from '@material-ui/core/TextField';
import { toast, ToastContainer } from 'react-toastify';

const { connect } = require("twilio-video");


const style = {
    height: 400,
    width: 800,
    marginTop: "10%"
};

export default class VideoPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            identity: "",
            room: null,
            token: null,
            pic: "https://cquipsplus.ca/wp-content/themes/cera/assets/images/avatars/user-avatar.png",
            checked: true,
            isRequestSentModal: false,
            joiningName: '',
            errorJoiningName: false,
            userTypeId: '',
            hiddenJoinBtn: false,
            hideLoader: false,
            jobDetails: {}
        };
        this.inputRef = React.createRef();
        this.inputRef1 = React.createRef();
        this.returnToLobby = this.returnToLobby.bind(this);
        this.updateIdentity = this.updateIdentity.bind(this);
        this.removePlaceholderText = this.removePlaceholderText.bind(this);
        this.removePlaceholderImg = this.removePlaceholderImg.bind(this);
        this.updatePic = this.updatePic.bind(this);
        this.setChecked = this.setChecked.bind(this);

    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        let storageData = JSON.parse(localStorage.getItem("All_job_data"));
        // console.log("Storage Data >>>>>>>>>>>>>>>>>>>>>> ", storageData)
        if (storageData !== null) {
            // const authToken = localStorage.getItem("AuthToken");
            // const token = Decoder.decode(authToken);
            // consoleLog("Token Data:", token)
            // consoleLog("Get local Storage data:", storageData);
            let participantId = [
                Number(storageData.jobDetails.clientId),
                Number(storageData.jobDetails.requesterID),
                Number(storageData.jobDetails.leiID),
                Number(storageData.jobDetails.vendorID)
            ],
                participantMail = [
                    storageData.clientDetails.businessEmail,
                    storageData.jobDetails.requesterEmail,
                    storageData.jobDetails.leiEMAIL,
                    storageData.jobDetails.vendorEmail
                ],
                link = LOCAL_HOST + `/servicePage/${this.props.appointmentType}/${this.props.jobId}`,
                bidId = storageData.quoteInfo.bidId,
                id = storageData.jobDetails.id,
                clientId = storageData.jobDetails.clientId,
                reqdatetime = new Date();
            let obj = {
                id: id,
                clientId: clientId,
                jobId: this.props.jobId,
                participantId: participantId,
                participantMail: participantMail,
                uniqueLink: link,
                bidId: bidId,
                reqdatetime: reqdatetime
            }
            consoleLog("getAudioVideoIdentity Api request >>>>>>>>>>>>>>>", obj)
            let res = await ApiCall("getAudioVideoIdentity", obj);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let payload = Decoder.decode(res.data.payload);
                consoleLog("Get audio video identity response >>>>>>>", payload.data);
                if (payload.data.identityToken.token !== undefined) {
                    let roomObj = {
                        name: this.props.jobId,
                        audio: true,
                        video: false,
                        dominantSpeaker: true,
                    };
                    if (this.props.appointmentType == "VRI") {
                        roomObj.video = true;
                    }
                    const room = await connect(payload.data.identityToken.token, roomObj);
                    // console.log("Room Data",room);
                    if (payload.data.jobDetails !== undefined) {
                        // const encodeData = Encoder.encode(payload.data.jobDetails);
                        localStorage.setItem("callData", JSON.stringify(payload.data.jobDetails));
                    }
                    this.setState({
                        room: room,
                        token: payload.data.identityToken.token,
                        userTypeId: payload.data.userTypeId,
                        jobDetails: payload.data.jobDetails
                    });
                }
            } else {
                toast.error(res.message, { position: toast.POSITION.TOP_CENTER, autoClose: false })
            }
        } else {
            this.onOpenRequestSentModal();
        }
    }

    returnToLobby() {
        this.setState({ room: null });
        this.closeWindow();
    }
    removePlaceholderText() {
        this.inputRef.current.placeholder = "";
    }
    removePlaceholderImg() {
        this.inputRef1.current.placeholder = "";
    }
    updateIdentity(event) {
        this.setState({
            identity: event.target.value,
        });
    }
    updatePic(event) {
        this.setState({
            pic: event.target.value,
        });
    }
    setChecked(val) {
        this.setState({
            checked: val,
        });
    }

    onOpenRequestSentModal = () => {
        this.setState({
            isRequestSentModal: true
        })
    }

    onCloseRequestSentModal = () => {
        this.setState({
            isRequestSentModal: false
        });
    }

    onChangeJoiningName = (e) => {
        this.setState({
            joiningName: e.target.value
        })
    }

    sentJoiningRequest = async () => {
        if (this.state.joiningName === '') {
            this.setState({
                errorJoiningName: true
            })
        } else {
            this.setState({
                hiddenJoinBtn: true
            })
            let data = {
                jobId: this.props.jobId,
                email: this.props.email,
                identity: this.state.joiningName + ' (Guest)'
            }
            // consoleLog("Subscribe Data:", data);
            let res = await ApiCall("guestSubscribe", data);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let payload = Decoder.decode(res.data.payload);
                // console.log("Payload Data of Guest Subscribe>>> ", payload.data)
                this.onCloseRequestSentModal();
                if (payload.data.jobData !== undefined) {
                    let jobData = payload.data.jobData;
                    let jobDataObj = {
                        requestId: jobData.requestId,
                        jobTypeName: jobData.jobType,
                        clientName: jobData.requesterName,
                        sourceLanguageName: jobData.sourceLanguage,
                        targetLanguageName: jobData.targetLanguage
                    }
                    localStorage.setItem("callData", JSON.stringify(jobDataObj));
                }
                if (payload.data.identityToken.token !== undefined) {
                    let roomObj = {
                        name: this.props.jobId,
                        audio: true,
                        video: false,
                        dominantSpeaker: true,
                    };
                    if (this.props.appointmentType == "VRI") {
                        roomObj.video = true;
                    }
                    const room = await connect(payload.data.identityToken.token, roomObj);
                    this.setState({
                        room: room,
                        token: payload.data.identityToken.token,
                        userTypeId: payload.data.userTypeId,
                        hiddenJoinBtn: false
                    });
                }
            } else if (res.respondcode === 599) {
                this.onCloseRequestSentModal();
                // this.setState({
                //     hideLoader: true
                // })
                toast.error(res.message, { position: toast.POSITION.TOP_CENTER, autoClose: false })
            } else {
                toast.error(res.message);
                this.setState({
                    hiddenJoinBtn: false
                })
            }
        }
    }
    closeWindow = () => {

        localStorage.removeItem("callData");
        localStorage.removeItem("All_job_data");
        // Open the new window 
        // with the URL replacing the
        // current page using the
        // _self value
        const url = 'http://www.google.com/'
        let new_window =
            window.open(url, '_self');

        // Close this window
        new_window.close();
        return false;
    }
    render() {
        const disabled = this.state.identity === "" ? true : false;
        return (
            <div className="app">
                <ToastContainer hideProgressBar theme='colored' />
                {
                    this.state.room === null ? (<>
                        <center><Lottie animationData={Loading} loop={true} style={style}  className='res_load'/></center>
                    </>

                    ) : (
                        <>

                            <Room
                                returnToLobby={this.returnToLobby}
                                token={this.state.token}
                                room={this.state.room}
                                isVideoOn={this.props.appointmentType == "VRI" ? true : false}
                                userType={this.state.userTypeId}
                                jobId={this.props.jobId}
                            />
                        </>
                    )
                }

                <Modal show={this.state.isRequestSentModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Join Meeting</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <TextField id="outlined-basic" label="Enter your name" variant="outlined" /> */}
                        <div className="form-input-fields">
                            <input
                                type="text"
                                placeholder="Enter your name"
                                className="textbox4"
                                style={{
                                    borderRadius: "9px",
                                    boxShadow:
                                        "0px 0px 4px 0px rgb(0 0 0 / 20%)",
                                    width: "100%",
                                    height: "50px"
                                }}
                                value={this.state.joiningName}
                                onChange={this.onChangeJoiningName}
                            />
                            <span className='joiningFieldError' hidden={!this.state.errorJoiningName}>*Please enter name</span>
                        </div>

                    </Modal.Body>
                    <Modal.Footer hidden={this.state.hiddenJoinBtn}>
                        <Button variant="secondary" onClick={this.onCloseRequestSentModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.sentJoiningRequest}>
                            Join
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}