import React from 'react';
import { styled } from "@mui/system"; //imported for modal
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ApiCall } from '../../../../services/middleware';
import { ErrorCode } from '../../../../services/constant';
import { AlertMessage } from '../../../../enums';
import { passWordRandomGenerate } from '../../ManageClients/ClientList/function';
import { consoleLog } from '../../../../services/common-function';
import { toast } from 'react-toastify';
import { passwordValidator } from '../../../../validators';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 10,
        marginTop: 5,
        minWidth: 100,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
    },
}));

export default class AdminStaffMenuPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            resetPasswordData: '',
            isActive: false,
        }
    }

    componentDidMount() {
        var classInstance = this;

        document.getElementById("backdrop").style.display = "none";
        var statusModal = document.getElementById("status-model-ad");
        var resetModal = document.getElementById("reset-model-ad");
        window.onclick = function (event) {
            if (event.target === statusModal) {
                classInstance.closeStatusModal();
            } else if (event.target === resetModal) {
                classInstance.closeResetModal();
            }
        };

    }

    //................funct for menuBtn on click................
    menuBtnhandleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    //..............function for MenuButton close..............

    handleReset = () => {
        let mainPass = passWordRandomGenerate();
        consoleLog(mainPass)
        this.setState({
            resetPasswordData: mainPass
        })
        this.handleMenuClose();
        this.openResetModal();
    };
    openResetModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("reset-model-ad").style.display = "block";
        document.getElementById("reset-model-ad").classList.add("show");
    };

    closeResetModal = () => {
        document.getElementById("reset-model-ad").style.display = "none";
        document.getElementById("reset-model-ad").classList.remove("show");
        document.getElementById("backdrop").style.display = "none";
    };

    openStatusModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("status-model-ad").style.display = "block";
        document.getElementById("status-model-ad").classList.add("show");
    };

    closeStatusModal = () => {
        document.getElementById("status-model-ad").style.display = "none";
        document.getElementById("status-model-ad").classList.remove("show");
        document.getElementById("backdrop").style.display = "none";
    };

    onStatusModal = () => {
        if (this.props.value.status === 1) {
            this.setState({
                isActive: true,
            });
        } else {
            this.setState({
                isActive: false,
            });
        }

        // window.$("#status-model").modal("show");
        this.openStatusModal();
        this.handleMenuClose();
    };

    //........ No Delete .......

    cancelDelete = () => {
        this.closeResetModal();
    };
    cancelStatus = () => {
        this.closeStatusModal();
    };

    //........ Activate or Deactivate modal .......

    statusUpdate = async () => {
        // window.$("#status-model").modal("hide");
        this.closeStatusModal();

        let arrData = this.props.value;
        let stat = 0;
        if (arrData.status === 0) {
            stat = 1;
        } else {
            stat = 0;
        }

        let data = {
            staffid: arrData.userId,
            status: stat.toString(),
            staffusertypetd: arrData.userTypeId,
        };

        let status = await ApiCall("adminstaffstatuschange", data);
        if (
            status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.UPDATE_STATUS.SUCCESS);
            this.props.pageLoad();
        }
    };

    //............Reset Password...........


    //............Reset Password...........

    onResetPassword = async () => {
        let errorCount = 0;

        let validatePassword = passwordValidator(this.state.resetPasswordData)

        if (validatePassword.status === false) {
            toast.error(validatePassword.message, {
                hideProgressBar: true,
            });
            errorCount++;
        }

        this.closeResetModal();

        if (errorCount === 0) {
            let data = {
                staffid: this.props.value.userId,
                password: this.state.resetPasswordData,
            };

            consoleLog("req", data)

            let status = await ApiCall("userpasswordreset", data);
            if (
                status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                toast.success(AlertMessage.MESSAGE.PASSWORD.RESET_SUCCESS);
            } else {
                toast.error(status.message)
            }
        }
    };

    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        return (
            <React.Fragment>
                {/* <img
                    // src="/assets_temp/images/edit.png"
                    src={ImageName.IMAGE_NAME.MENU_VERTICAL}
                    style={{ cursor: "pointer" }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(key, e)
                    }
                /> */}
                <button
                    className='btn btn-primary'
                    style={{ cursor: "pointer" }}
                    id={"basic-button"}
                    aria-controls={"basic-menu"}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) =>
                        this.menuBtnhandleClick(e)
                    }
                >Go To</button>
                <StyledMenu
                    id="basic-menu"
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem onClick={this.handleReset}>
                        Reset Password
                    </MenuItem>
                    {/* <MenuItem>View Jobs</MenuItem>
              <MenuItem>View Projects</MenuItem> */}
                    <MenuItem
                        onClick={() => this.onStatusModal()}
                    >
                        De-Activate or Activate
                    </MenuItem>
                </StyledMenu>



                {/* ..................status modal................................. */}
                <div id="status-model-ad" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you really want to{" "}
                                            {this.state.isActive ? "Deactivate" : "Activate"} the
                                            record?{" "}
                                        </h6>
                                    </center>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "2%" }}
                                        >
                                            <a
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.cancelStatus}
                                            >
                                                No
                                            </a>
                                            <a
                                                className="blue-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.statusUpdate}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* ..................Reset Password modal................................. */}
                <div id="reset-model" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-lg">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you really want to reset the password for selected
                                            user?{" "}
                                        </h6>
                                    </center>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <center>
                                            <div className="col-md-8">
                                                <input

                                                    className="inputfield"
                                                    value={this.state.resetPasswordData}
                                                    onChange={(e) =>
                                                        this.onResetPassChange(e)
                                                    }
                                                />
                                            </div>
                                        </center>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "2%" }}
                                        >
                                            <a
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.cancelDelete}
                                            >
                                                No
                                            </a>
                                            <a
                                                className="blue-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.onResetPassword}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}