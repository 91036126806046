import React from 'react';
import { Decoder } from '../../services/auth';
import { consoleLog } from '../../services/common-function';
import { ErrorCode } from '../../services/constant';
import { ApiCall } from '../../services/middleware';
import ChatRoom from './ChatRoom';
import ReactLoader from '../Loader';
import { socket } from '../../services/config/socket';
import { SOCKET_PATH } from '../../services/config/api_url';
const { connect } = require("twilio-video");


export default class MainChatPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identity: "",
            room: null,
            token: null,
            pic: "https://cquipsplus.ca/wp-content/themes/cera/assets/images/avatars/user-avatar.png",
            checked: true,
            isRequestSentModal: false,
            joiningName: '',
            errorJoiningName: false,
            userTypeId: '',
            hiddenJoinBtn: false,
            hideLoader: true,
            currloginUserId: ""
        };
    }

    componentDidMount() {
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);
        if (authUser !== undefined) {
            this.setState({
                currloginUserId: authUser.data.userid
            })
        }
        this.load();
    }

    componentDidUpdate(prevProps) {
        // consoleLog("Line 44 >>> ", this.props)
        socket.connect(SOCKET_PATH);
        socket.on(`user/actLiveStatReq/${this.state.currloginUserId}`, (data) => {
            data['platform'] = 'web';
            if (data.groupId === this.props.jobId) {
                // console.log(" >>>>>>> Call data >>>>>>> ", data);
                socket.emit("user/liveStat", data);
            }

        });
        if (this.props.jobId !== prevProps.jobId) {
            this.load();
        }
    }

    load = async () => {
        this.setState({
            hideLoader: true
        })
        let obj = {
            jobId: this.props.jobId,
        }
        // consoleLog("Chat Api request:", obj)
        let res = await ApiCall("getChatOnlyIdentity", obj);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // consoleLog("video token>>", payload.data);
            if (payload.data.identityToken.token !== undefined) {
                let roomObj = {
                    name: this.props.jobId,
                    audio: false,
                    video: false,
                    dominantSpeaker: true,
                };
                const room = await connect(payload.data.identityToken.token, roomObj);
                // console.log("Room Data", room);
                this.setState({
                    room: room,
                    token: payload.data.identityToken.token,
                    userTypeId: payload.data.userTypeId,
                    hideLoader: false
                });
            }
        } else {
            // toast.error(res.message, { position: toast.POSITION.TOP_CENTER, autoClose: false })
        }
    }

    returnToLobby() {
        this.setState({ room: null });
    }

    render() {
        return (<>
            {this.state.hideLoader ? <><ReactLoader /></> : <>
                {
                    this.state.room === null ? <></> :
                        <ChatRoom
                            returnToLobby={this.returnToLobby}
                            token={this.state.token}
                            room={this.state.room}
                            isVideoOn={false}
                            userType={this.state.userTypeId}
                            jobId={this.props.jobId}
                            jobAlphaId={this.props.jobAlphaId}
                        />
                }</>
            }
        </>)
    }



}