import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { ImageName } from '../../../../enums';
import { timeValidator } from '../../../../validators';
import { toast } from 'react-toastify';

export default class TimeSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            hour: this.props.modHr,
            min: this.props.modMin,
            ampm: this.props.modAmpm,
        }
        console.log("Modal Props ....", props)
    }

    handleClose = () => {
        this.props.close()
    }

    hourChange = () => {
        let hr = parseInt(this.state.hour) + 1;
        if (parseInt(this.state.hour) + 1 > 12) {
            this.setState({
                hour: "01",
            });
        } else {
            if (parseInt(this.state.hour) + 1 > 9) {
                this.setState({
                    hour: hr,
                });
            } else {
                this.setState({
                    hour: "0" + hr,
                });
            }
        }
    };

    hourInputChange = (e) => {
        if (timeValidator(e.target.value)) {
            this.setState({
                hour: e.target.value,
            });
        }
    };

    hourChangeUp = () => {
        let hr = parseInt(this.state.hour) - 1;
        if (hr < 1) {
            this.setState({
                hour: "12",
            });
        } else {
            if (hr > 9) {
                this.setState({
                    hour: hr,
                });
            } else {
                this.setState({
                    hour: "0" + hr,
                });
            }
        }
    };

    minInputChange = (e) => {
        if (timeValidator(e.target.value)) {
            this.setState({
                min: e.target.value,
            });
        }
    };

    minChange = () => {
        let min = parseInt(this.state.min) + 1;
        if (parseInt(this.state.min) + 1 > 59) {
            this.setState({
                min: "00",
            });
        } else {
            if (parseInt(this.state.min) + 1 > 9) {
                this.setState({
                    min: min,
                });
            } else {
                this.setState({
                    min: "0" + min,
                });
            }
        }
    };

    minChangeUp = () => {
        let min = parseInt(this.state.min) - 1;
        if (min < 0) {
            this.setState({
                min: "59",
            });
        } else {
            if (min > 9) {
                this.setState({
                    min: min,
                });
            } else {
                this.setState({
                    min: "0" + min,
                });
            }
        }
    };

    ampmChange = () => {
        if (this.state.ampm === "AM") {
            this.setState({
                ampm: "PM",
            });
        } else {
            this.setState({
                ampm: "AM",
            });
        }
    };

    save = () => {
        // let modHour =
        //     (this.state.hour === "" ? Number("00") : this.state.hour) +
        //     ":" +
        //     (this.state.min === "" ? Number("00") : this.state.min) +
        //     " " +
        //     this.state.ampm;
        // var dt = moment(modHour, ["h:mm A"]).format("HH:mm");
        let errorCounter = 0;
        if (Number(this.state.hour) > 12) {
            toast.error("Hour cannot be set greater than 12 in appointment time");
            errorCounter++;
        } else if (Number(this.state.min) > 59) {
            toast.error("Minute cannot be set greater than 59 in appointment time");
            errorCounter++;
        }

        if (errorCounter === 0) {
            let dt = this.state.hour + ":" + this.state.min + " " + this.state.ampm;
            this.props.saveTime(dt);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal show={this.props.show} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Correct Time</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='timeModal'>
                            <div className="t-time">
                                <span className="t1">
                                    <small>
                                        <img
                                            src={ImageName.IMAGE_NAME.U_IMG}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.hourChange}
                                        />
                                        <br />
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={this.state.hour}
                                            className="tsd2"
                                            onChange={this.hourInputChange}
                                        // readonly
                                        />
                                        <br />
                                        <img
                                            src={ImageName.IMAGE_NAME.B_ARROW}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.hourChangeUp}
                                        />
                                    </small>
                                </span>
                                <span className="t2">
                                    <small>
                                        <img
                                            src={ImageName.IMAGE_NAME.U_IMG}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.minChange}
                                        />
                                        <br />
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={this.state.min}
                                            className="tsd2"
                                            onChange={this.minInputChange}
                                        // readonly
                                        />
                                        <br />
                                        <img
                                            src={ImageName.IMAGE_NAME.B_ARROW}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.minChangeUp}
                                        />
                                    </small>
                                </span>
                                <span
                                    className="t3"
                                    style={{ marginLeft: "2%" }}
                                >
                                    <small>
                                        <img
                                            src={ImageName.IMAGE_NAME.U_IMG}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.ampmChange}
                                        />
                                        <br />
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={this.state.ampm}
                                            className="tsd2"
                                            readonly
                                        />
                                        <br />
                                        <img
                                            src={ImageName.IMAGE_NAME.B_ARROW}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={this.ampmChange}
                                        />
                                    </small>
                                </span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="cn_btn"
                                onClick={() => this.handleClose()}
                            >
                                RESET
                            </button>
                            <button
                                type="submit"
                                className="sv_btn"
                                onClick={() => {
                                    this.save();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}