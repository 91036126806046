import "./video.css";
import React, { Component } from "react";
import { AiOutlineAudioMuted, AiOutlineAudio } from "react-icons/ai";
import { TbCaptureOff, TbCapture } from "react-icons/tb";
import { MdVideocam, MdVideocamOff, MdCallEnd } from "react-icons/md";
import { BsChatDotsFill, BsChatDots } from "react-icons/bs";
import { RiUserSharedLine } from "react-icons/ri";
import { FaMicrophone, FaShareAlt, FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

import {
  AppBar,
  Backdrop,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  List,
  TextField,
  Toolbar,
  Typography,
  Box,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import Participant from "./Participant";
import {
  LocalVideoTrack,
  connect,
  createLocalTracks,
  createLocalVideoTrack,
} from "twilio-video";
import ChatItem from "./ChatItem";
import { APP_URL } from "../../services/config";
import axios from "axios";
import { ErrorCode, UsersEnums } from "../../services/constant";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import { emailValidator } from "../../validators";
import { consoleLog } from "../../services/common-function";
import { ApiCall } from "../../services/middleware";
import { Decoder } from "../../services/auth";
import { toast, ToastContainer } from "react-toastify";
import CancelIcon from '@material-ui/icons/Cancel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Modal from 'react-bootstrap/Modal';
import { SelectBox } from "../Admin/SharedComponents/inputText";
import { COMMON } from "../../services/constant/connpmData";
import Table from 'react-bootstrap/Table';
import Checkbox from '@material-ui/core/Checkbox';
import { socket } from "../../services/config/socket";
import { MdContacts } from "react-icons/md";


const Chat = require("twilio-chat");

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values()),
      isMute: false,
      isVideo: true,
      screenTrack: null,
      isScreenTrack: false,
      screenCaptureRoom: null,
      dominantParticpant: null,
      messages: [],
      loading: false,
      channel: null,
      text: "",
      shareEmailBody: "",
      showChat: false,
      showShare: false,
      allEmailArr: [],
      shareEmailText: "",
      errorJoiningName: false,
      hideShareBtn: false,
      jobDetails: {},
      inviteAction: false,
      dialAudio: false,
      inviteText: "",
      allRole: COMMON.ROLE_ARR_FOR_AUDIO,
      selectedRole: {},

      isMultiSelect: false,
      allInviteeList: [],
      searchInviteeText: "",
      loader: false,
      showsms: false,

      allSmsContact: [],
      shareSmsText: "",
      smsBody: "",
      contactListPopUp: false,
      conatctListPopupPrimaryEmail: false,
      contactListPopupCCemail: false,

      allCcEmailArr: [],
      shareCcEmailText: "",
      // shareEmailBody: "",
      errorCcJoiningName: false,
      shareMailSubject: "",
      allEmailInviteList: [],
      allCcEmailInviteList: []
    };
    this.leaveRoom = this.leaveRoom.bind(this);
    this.screenCapture = this.screenCapture.bind(this);
    this.stopScreenCapture = this.stopScreenCapture.bind(this);
    this.audioMute = this.audioMute.bind(this);
    this.audioUnMute = this.audioUnMute.bind(this);
    this.videoStop = this.videoStop.bind(this);
    this.videoResume = this.videoResume.bind(this);
    this.showChat = this.showChat.bind(this);
    this.hideChat = this.hideChat.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.scrollDiv = React.createRef();
    this.getToken = this.getToken.bind(this);
  }


  isMobile = () => {
    const userAgent = window.navigator.userAgent;
    const mobileKeywords = [
      'Android', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'IEMobile', 'Opera Mini',
      'webOS', 'Windows Phone', 'Kindle', 'touch', 'Mobile'
    ];
    return mobileKeywords.some(keyword => userAgent.includes(keyword));
  };

  async componentDidMount() {

    this.setState({
      jobDetails: JSON.parse(localStorage.getItem("callData"))
    })
    // Add event listeners for future remote participants coming or going
    this.props.room.on("participantConnected", (participant) =>
      this.addParticipant(participant)
    );
    this.props.room.on("participantDisconnected", (participant) => {
      this.removeParticipant(participant);
      if (this.state.dominantParticpant === participant) {
        this.setState({ dominantParticpant: null });
      }
    });
    this.props.room.on("dominantSpeakerChanged", (participant) => {
      console.log("The new dominant speaker in the Room is:", participant);
      //this.dominantSpeakerChanged(participant);
    });
    const client = await Chat.Client.create(this.props.token);

    client.on("tokenAboutToExpire", async () => {
      const token = await this.getToken(
        this.props.room.localParticipant.identity
      );
      client.updateToken(token);
    });

    client.on("tokenExpired", async () => {
      const token = await this.getToken(
        this.props.room.localParticipant.identity
      );
      client.updateToken(token);
    });
    client.on("channelJoined", async (channel) => {
      // getting list of all messages since this is an existing channel
      const messages = await channel.getMessages();
      // console.log("Get message:", messages)
      this.setState({ messages: messages.items || [] });
      this.scrollToBottom();
    });

    try {
      const channel = await client.getChannelByUniqueName(this.props.room.name);
      this.joinChannel(channel);
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: this.props.room.name,
          friendlyName: this.props.room.name,
        });

        this.joinChannel(channel);
      } catch {
        throw new Error("Unable to create channel, please reload this page");
      }
    }
    window.addEventListener("beforeunload", this.leaveRoom);
  }
  scrollToBottom() {
    /* const scrollHeight = this.scrollDiv.current.scrollHeight;
    const height = this.scrollDiv.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;*/
  }
  getToken = async (identity) => {
    let token = "";
    let obj = {
      identity: identity,
      jobId: this.props.room.name
    }
    // console.log("Get Token request >>>>>>>>>>>> ", obj);
    let res = await ApiCall("getTokenByIdentity", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // console.log("Get Token By Identity response >>>> :::", payload.data);
      token = payload.data.token;
      return token;
    }
    // const response1 = await fetch(
    //   `https://demo.lykstage.com:5000/token/${email}`
    // );
    // const data1 = await response1.json();
    // console.log("Token Response >>>>>>>>>>>>>> ", data1)

    // return data1.token;
  };
  componentWillUnmount() {
    this.leaveRoom();
  }
  dominantSpeakerChanged(participant) {
    this.setState({
      dominantParticpant: participant,
    });
  }
  joinChannel = async (channel) => {
    if (channel.channelState.status !== "joined") {
      await channel.join();
    }

    this.setState({
      channel: channel,
      loading: false,
    });

    channel.on("messageAdded", this.handleMessageAdded);
    this.scrollToBottom();
  };

  handleMessageAdded = (message) => {
    const { messages } = this.state;
    this.setState(
      {
        messages: [...messages, message],
      },
      this.scrollToBottom
    );
  };
  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);
    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
  }
  showChat() {
    this.setState({
      showChat: true,
    });
  }
  hideChat() {
    this.setState({
      showChat: false,
    });
  }
  leaveRoom() {
    if (this.state.screenCaptureRoom !== null) {
      this.state.screenCaptureRoom.disconnect();
    }
    this.props.room.disconnect();
    this.props.returnToLobby();
  }
  async screenCapture() {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: { frameRate: 15 },
    });

    const screenTrack = new LocalVideoTrack(stream.getTracks()[0], {
      name: "myscreenshare",
    });
    const token = await this.getToken(
      this.props.room.localParticipant.identity + "-Copy"
    );
    const room1 = await connect(token, {
      name: this.props.room.localParticipant.identity + "-Copy",
      tracks: [screenTrack],
    });

    screenTrack.once("stopped", () => {
      room1.localParticipant.unpublishTrack(screenTrack);
    });
    //this.props.room.localParticipant.publishTrack(screenTrack);
    //room1.localParticipant.publishTrack(screenTrack);
    this.setState({
      screenCaptureRoom: room1,
      screenTrack: screenTrack,
      isScreenTrack: true,
    });
    //console.log(JSON.stringify(this.props.room));
  }

  async stopScreenCapture() {
    this.state.screenCaptureRoom.localParticipant.unpublishTrack(
      this.state.screenTrack
    );
    this.state.screenTrack.stop();
    // const tracks = await createLocalTracks({
    //   video: { facingMode: "user" },
    //   audio: true,
    // });
    //const track = new LocalVideoTrack();
    // Join the Room with the pre-acquired LocalTracks.
    // const room = await connect(this.props.token, {
    //   name: this.props.room.name,
    //   tracks,
    // });

    // const cameraTrack = tracks.find((track) => track.kind === "video");

    // // Switch to the back facing camera.
    // cameraTrack.restart({ facingMode: "environment" });
    // //this.props.room.localParticipant.publishTrack(track);
    this.state.screenCaptureRoom.disconnect();
    this.setState({
      screenTrack: null,
      isScreenTrack: false,
      screenCaptureRoom: null,
    });
  }
  async audioMute() {
    this.props.room.localParticipant.audioTracks.forEach((audioTrack) => {
      audioTrack.track.disable();
    });
    this.setState({
      isMute: true,
    });
  }
  async audioUnMute() {
    this.props.room.localParticipant.audioTracks.forEach((audioTrack) => {
      audioTrack.track.enable();
    });
    this.setState({
      isMute: false,
    });
  }

  async videoStop() {
    this.props.room.localParticipant.videoTracks.forEach((videoTrack) => {
      videoTrack.track.disable();
      //videoTrack.track.stop();
      //videoTrack.unpublish();
    });
    this.setState({
      isVideo: false,
    });
  }
  async videoResume() {
    this.props.room.localParticipant.videoTracks.forEach((videoTrack) => {
      videoTrack.track.enable();
    });
    /*createLocalVideoTrack()
      .then((localVideoTrack) => {
        return this.props.room.localParticipant.publishTrack(localVideoTrack);
      })
      .then((publication) => {
        console.log("Successfully unmuted your video:", publication);
      });*/
    this.setState({
      isVideo: true,
    });
  }
  sendMessage = async() => {
    let text = this.state.text.trim();
    if (text) {
      // console.log("Send message:",this.state.text);
      this.setState({ loading: true });
      let req = {
          meetingId: this.props.room.name,
          sentBy: this.props.room.localParticipant.identity,
          message: this.state.text,
          createdOn: new Date()
      };
      consoleLog("Request For Add Chat Message >>> ", req)
      let res = await ApiCall("addChatMessages", req);
      if (
          res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
          res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
          this.state.channel.sendMessage(String(this.state.text).trim());
          this.setState({ text: "", loading: false });
      }
    }
  };


  // ...............document....................
  onUploadDocument = (e) => {
    // console.log("position:", i)
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios.post(APP_URL.IMAGE_URL, formData).then((res) => {
      // console.log("doc res>>>", res.data.data);
      // this.setState({
      //     //   imagePath: res.data.data.url,
      //     uploadName: res.data.data.path + res.data.data.filename,
      // });

      if (res.data.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR) {
        this.setState({ loading: true });
        this.state.channel.sendMessage(String(res.data.data.url).trim());
        this.setState({ text: "", loading: false });
      }
    });
  }

  handleOpenShareModal = async () => {
    let content = await this.getContentOfCall("mail");
    await this.loaderChange(true);

    this.setState({
      inviteAction: false,
      showShare: true,
      shareMailSubject: content.subject,
      shareEmailBody: content.content
    })
    await this.loaderChange(false);
  }

  handleCloseShareModal = () => {
    this.setState({
      showShare: false
    })
  }

  deleteEmail = (pos) => {
    let arr = this.state.allEmailArr,
      brr = [];
    arr.map((data, i) => {
      if (i != pos) {
        brr.push(data)
      }
    });
    // console.log("Length>>", brr.length)
    this.setState({
      allEmailArr: brr
    })
  }

  onChangeEmail = (e) => {

    if (e.charCode == 13 || e.charCode == 32) {
      // alert('Enter... (KeyPress, use charCode)');
      // if(emailValidator(e.target.value)){

      // }
      // console.log("ASD>>", emailValidator(e.target.value));
      let validateEmail = emailValidator(e.target.value);

      if (validateEmail.status) {
        let arr = this.state.allEmailArr;
        arr.push(e.target.value);
        this.setState({
          allEmailArr: arr,
          shareEmailText: ''
        })
      } else {
        this.setState({
          errorJoiningName: true
        })
      }
    } else {
      this.setState({
        shareEmailText: e.target.value
      })
    }
  }

  changeEmailText = (e) => {
    let val = e.target.value;
    let temp = val.split(',');
    if (temp.length > 1) {
      let arr = this.state.allEmailArr;
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i] != "") {
          arr.push(temp[i]);

        }
      }
      this.setState({
        allEmailArr: arr,
      })
    }
    this.setState({
      shareEmailText: temp[temp.length - 1],
      errorJoiningName: false
    })
  }

  shareCallLink = async () => {

    this.setState({
      hideShareBtn: true
    })
    let data = {};
    if (this.state.shareEmailText === '') {
      // data = {
      //   jobId: this.props.jobId,
      //   emails: this.state.allEmailArr,
      //   emailBody: this.state.shareEmailBody
      // }
      data = {
        jobId: this.props.jobId,
        body: this.state.shareEmailBody,
        to: this.state.allEmailArr,
        cc: this.state.allCcEmailArr,
        subject: this.state.shareMailSubject,
        fromApp: "no"
      }
      this.shareLinkApi(data);
    }
    else {
      let validateEmail = emailValidator(this.state.shareEmailText);
      if (validateEmail.status) {
        let arr = this.state.allEmailArr;
        arr.push(this.state.shareEmailText);
        this.setState({
          allEmailArr: arr
        })
        // data = {
        //   jobId: this.props.jobId,
        //   emails: this.state.allEmailArr,
        //   emailBody: this.state.shareEmailBody
        // }
        data = {
          jobId: this.props.jobId,
          body: this.state.shareEmailBody,
          to: this.state.allEmailArr,
          cc: this.state.allCcEmailArr,
          subject: this.state.shareMailSubject,
          fromApp: "no"
        }
        this.shareLinkApi(data);
      } else {
        this.setState({
          errorJoiningName: true,
          hideShareBtn: false
        })
      }
    }
  }

  shareLinkApi = async (data) => {
    consoleLog("Share Call Link Request:", data);
    let res = await ApiCall("emailShare", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success('Request sent successfully');
      this.handleCloseShareModal();
      this.setState({
        hideShareBtn: false,
        allEmailArr: [],
        shareEmailText: ''
      })
    } else {
      toast.error(res.message);
      this.setState({
        hideShareBtn: false
      })
    }
  }

  getInviteeListData = async () => {
    let req = {
      jobId: this.props.room.name,
      search: this.state.searchInviteeText,
      userType: Object.keys(this.state.selectedRole).length > 0 ? this.state.selectedRole.value : ""
    }

    consoleLog("Get Invitee List req >>> ", req)

    let res = await ApiCall("getIviteeList", req);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // console.log("Get job invitee response >>>> :::", payload.data);
      let arr = [];
      if (payload.data.list.length > 0) {
        for (let list of payload.data.list) {
          list['isCheck'] = 0;
          arr.push(list);
        }
      }
      consoleLog("Get job invitee response >>>> :::", arr)
      this.setState({
        allInviteeList: arr,
        allEmailInviteList: arr,
        allCcEmailInviteList: arr
      })
    }
  }

  openInviteAction = async () => {
    this.getInviteeListData();
    this.setState({
      inviteAction: true
    })
  }

  closeInviteAction = () => {
    this.setState({
      inviteAction: false
    })
  }

  openDialAudio = () => {
    this.setState({
      inviteAction: false,
      dialAudio: true
    })
  }

  closeDialAudio = () => {
    this.setState({
      dialAudio: false
    })
  }

  onSelectedRoleChange = async (value) => {
    await this.loaderChange(true);
    this.setState({
      selectedRole: value
    })
    await this.loaderChange(false);
    this.getInviteeListData();
  }

  setMulti = () => {
    this.setState({
      isMultiSelect: !this.state.isMultiSelect
    })
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  changeInviteeSearchText = async (e) => {
    console.log(e.target.value)
    await this.loaderChange(true);
    this.setState({
      searchInviteeText: e.target.value
    })
    await this.loaderChange(false);

    this.getInviteeListData();
  }

  clickCall = (id) => {
    let users = [];
    users.push(id);
    socket.emit("ondemand/inCallRequest", { "jobId": this.props.room.name, "users": users });
    console.log(">>>>>>>  ondemand/inCallRequest  >>>>>>>>>>>>>", socket);
    this.closeDialAudio();
  }

  submitMultiCall = async () => {
    let users = [],
      selectedUserCounter = 0;
    this.state.allInviteeList.map((data) => {
      if (data.isCheck === 1) {
        users.push(data.userId);
        selectedUserCounter++;
      }
    })

    if (selectedUserCounter > 0) {
      socket.emit("ondemand/inCallRequest", { "jobId": this.props.room.name, "users": users });
      console.log(">>>>>>>  ondemand/inCallRequest  >>>>>>>>>>>>>", socket);
      this.closeDialAudio();
      let arr = this.state.allInviteeList;
      arr.map((data) => {
        data.isCheck = 0;
      })
      await this.loaderChange(true);
      this.setState({
        allInviteeList: arr
      })
      await this.loaderChange(false);
    }
  }

  openSms = async () => {
    let content = await this.getContentOfCall("sms");
    await this.loaderChange(true);
    this.setState({
      inviteAction: false,
      showsms: true,
      smsBody: content.content,
      shareMailSubject: content.subject
    })
    await this.loaderChange(false);
  }

  closeSms = () => {
    this.setState({
      showsms: false
    })
  }

  selectEmail = (value) => {

  }

  changeSmsText = (e) => {
    console.log(e.target.value);
    this.setState({
      shareSmsText: e.target.value
    })
  }

  deleteSms = async (pos) => {
    let arr = this.state.allSmsContact,
      crr = this.state.allInviteeList,
      brr = [];

    crr[pos].isCheck = 0;
    arr.map((data, i) => {
      if (i != pos) {
        brr.push(data)
      }
    });
    // console.log("Length>>", brr.length)
    await this.loaderChange(true);
    this.setState({
      allSmsContact: brr,
      allInviteeList: crr
    })
    await this.loaderChange(false);
  }

  openContactListPopup = () => {
    this.setState({
      contactListPopUp: true
    })
  }

  closeContactListPopup = () => {
    this.setState({
      contactListPopUp: false
    })
  }

  checkInvite = async (e, pos) => {
    let arr = this.state.allInviteeList;
    if (e.target.checked) {
      arr[pos].isCheck = 1;
    } else {
      arr[pos].isCheck = 0;
    }
    // console.log(arr);
    await this.loaderChange(true);
    this.setState({
      allInviteeList: arr
    })
    await this.loaderChange(false);
  }

  submitCheckInviteForSms = async () => {
    let arr = [];
    this.state.allInviteeList.map((data) => {
      if (data.isCheck === 1) {
        let temp = data.countryCode + "" + data.mobile;
        arr.push(temp);
      }
    })
    await this.loaderChange(true);
    this.setState({
      allSmsContact: arr,
      contactListPopUp: false
    })
    await this.loaderChange(false);
  }

  submitForSendSms = async () => {
    let data = {};
    if (this.state.shareSmsText === "" && this.state.allSmsContact.length === 0) {
      toast.error("Please enter a number for send sms")
    } else {
      if (this.state.shareSmsText === "" && this.state.allSmsContact.length > 0) {

        data = {
          jobId: this.props.jobId,
          body: this.state.smsBody,
          to: this.state.allSmsContact,
          fromApp: "no"
        }
        this.shareLinkApiForSms(data);
      } else {
        let arr = this.state.allSmsContact;
        arr.push(this.state.shareSmsText);
        data = {
          jobId: this.props.jobId,
          body: this.state.smsBody,
          to: arr,
          fromApp: "no"
        }
        this.shareLinkApiForSms(data);
      }
    }
    let arr = this.state.allInviteeList;
    arr.map((data) => {
      data.isCheck = 0;
    })
    await this.loaderChange(true);
    this.setState({
      allInviteeList: arr,
      showsms: false
    })
    await this.loaderChange(false);
  }

  shareLinkApiForSms = async (data) => {
    consoleLog("Share smsShare Link Request:", data);
    let res = await ApiCall("smsShare", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success('Request sent successfully');
      this.handleCloseShareModal();
      this.setState({
        hideShareBtn: false,
        allSmsContact: [],
        shareSmsText: ''
      })
    } else {
      toast.error(res.message);
      this.setState({
        hideShareBtn: false
      })
    }
  }


  deleteCcEmail = (pos) => {
    let arr = this.state.allCcEmailArr,
      brr = [];
    arr.map((data, i) => {
      if (i != pos) {
        brr.push(data)
      }
    });
    // console.log("Length>>", brr.length)
    this.setState({
      allCcEmailArr: brr
    })
  }

  onChangeCcEmail = (e) => {

    if (e.charCode == 13 || e.charCode == 32) {
      // alert('Enter... (KeyPress, use charCode)');
      // if(emailValidator(e.target.value)){

      // }
      // console.log("ASD>>", emailValidator(e.target.value));
      let validateEmail = emailValidator(e.target.value);

      if (validateEmail.status) {
        let arr = this.state.allCcEmailArr;
        arr.push(e.target.value);
        this.setState({
          allCcEmailArr: arr,
          shareCcEmailText: ''
        })
      } else {
        this.setState({
          errorCcJoiningName: true
        })
      }
    } else {
      this.setState({
        shareCcEmailText: e.target.value
      })
    }
  }

  changeCcEmailText = (e) => {
    let val = e.target.value;
    let temp = val.split(',');
    if (temp.length > 1) {
      let arr = this.state.allCcEmailArr;
      for (let i = 0; i < temp.length - 1; i++) {
        if (temp[i] != "") {
          arr.push(temp[i]);

        }
      }
      this.setState({
        allCcEmailArr: arr,
      })
    }
    this.setState({
      shareCcEmailText: temp[temp.length - 1],
      errorCcJoiningName: false
    })
  }

  changeEmailSubject = (e) => {
    this.setState({
      shareMailSubject: e.target.value
    })
  }

  openContactListPopupPrimaryEmail = () => {
    this.setState({
      conatctListPopupPrimaryEmail: true
    })
  }

  closeContactListPopupPrimaryEmail = () => {
    this.setState({
      conatctListPopupPrimaryEmail: false
    })
  }

  submitCheckInviteForPrimaryEmail = async () => {
    let arr = [];
    this.state.allEmailInviteList.map((data) => {
      if (data.isCheck === 1) {
        arr.push(data.email)
      }
    })
    await this.loaderChange(true);
    this.setState({
      allEmailArr: arr,
      conatctListPopupPrimaryEmail: false
    })
    await this.loaderChange(false);
  }

  checkInvitePrimaryEmail = async (e, pos) => {
    let arr = this.state.allEmailInviteList;
    if (e.target.checked) {
      arr[pos].isCheck = 1;
    } else {
      arr[pos].isCheck = 0;
    }
    // console.log(arr);
    await this.loaderChange(true);
    this.setState({
      allEmailInviteList: arr
    })
    await this.loaderChange(false);
  }

  /* cc Email Contact Modal Functionality start */

  openContactListPopupCcEmail = () => {
    this.setState({
      contactListPopupCCemail: true
    })
  }

  closeContactListPopupCcEmail = () => {
    this.setState({
      contactListPopupCCemail: false
    })
  }

  submitCheckInviteForCcEmail = async () => {
    let arr = [];
    this.state.allCcEmailInviteList.map((data) => {
      if (data.isCheck === 1) {
        arr.push(data.email)
      }
    })
    await this.loaderChange(true);
    this.setState({
      allCcEmailArr: arr,
      contactListPopupCCemail: false
    })
    await this.loaderChange(false);
  }

  checkInviteCcEmail = async (e, pos) => {
    let arr = this.state.allCcEmailInviteList;
    if (e.target.checked) {
      arr[pos].isCheck = 1;
    } else {
      arr[pos].isCheck = 0;
    }
    // console.log(arr);
    await this.loaderChange(true);
    this.setState({
      allCcEmailInviteList: arr
    })
    await this.loaderChange(false);
  }

  onChangeSms = (e) => {
    if (e.target.value != "") {
      const inputText = e.target.value;
      if (/^\d*$/.test(inputText)) {
        if (e.charCode == 13 || e.charCode == 32) {
          let arr = this.state.allSmsContact;
          arr.push(e.target.value);
          this.setState({
            allSmsContact: arr,
            shareSmsText: ''
          })
        } else {
          this.setState({
            shareSmsText: e.target.value
          })
        }
      }
    }
  }

  changeSmsText = (e) => {
    let val = e.target.value;
    if (/^\d*$/.test(val)) {

      let temp = val.split(',');
      if (temp.length > 1) {
        let arr = this.state.allSmsContact;
        for (let i = 0; i < temp.length - 1; i++) {
          if (temp[i] != "") {
            arr.push(temp[i]);
          }
        }
        this.setState({
          allSmsContact: arr,
        })
      }
      this.setState({
        shareSmsText: temp[temp.length - 1]
      })
    }
  }

  getContentOfCall = async (type) => {
    let content = {
      content: "",
      subject: ""
    };
    let obj = {
      type: type,
      fromApp: "no",
      jobId: this.props.room.name
    }
    // consoleLog("Get Content Data Req >>>>>>>>>>>> ", obj);
    let res = await ApiCall("getMeetingContent", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // console.log("Get Meeting Content response >>>> :::", payload.data);
      content = payload.data;
    }

    return content;
  };

  render() {
    return (
      <div className="room">
        <ToastContainer hideProgressBar theme="colored" />

        {(this.state.jobDetails !== undefined && this.state.jobDetails !== null) ?
          <div className="details_job_box">
            <h6>Job ID : {this.state.jobDetails.requestId}</h6>
            <h6>Job Type : {this.state.jobDetails.jobTypeName}</h6>
            {/* <h6>Client Name : {this.state.jobDetails.clientName}</h6> */}
            <h6>Source Language : {this.state.jobDetails.sourceLanguageName}</h6>
            <h6>Target Language : {this.state.jobDetails.targetLanguageName}</h6>
          </div> :
          <></>
        }
        <div id="dominant-participant">
          {this.state.dominantParticpant === null ? (
            <>
              <Participant
                key={this.props.room.localParticipant.identity}
                localParticipant="true"
                dominantParticpant="true"
                participant={this.props.room.localParticipant}
              />
            </>
          ) : (
            <>
              <Participant
                key={this.state.dominantParticpant.identity}
                dominantParticpant="true"
                participant={this.state.dominantParticpant}
              />
            </>
          )}
          {/* <div className="video_thumb">
            <Participant
              key={this.props.room.localParticipant.identity}
              localParticipant="true"
              participant={this.props.room.localParticipant}
              getParticipant={() => this.dominantSpeakerChanged(null)}
              isVideoOn={this.props.isVideoOn}
            />

            {this.state.remoteParticipants.map((participant) => (
              <Participant
                key={participant.identity}
                participant={participant}
                getParticipant={() => this.dominantSpeakerChanged(participant)}
                isVideoOn={this.props.isVideoOn}
              />
            ))}
          </div> */}
        </div>

        {/* ................... Share functionality................... */}
        <div>
          {/* <Button variant="outlined" color="primary" onClick={this.handleOpenShareModal}>
            Share
          </Button> */}
          <Modal show={this.state.inviteAction} onHide={this.closeInviteAction} centered>
            <Modal.Header closeButton>
              <Modal.Title>INVITE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 pt_15_iam" >
                  <span className="inviteActionMod_pt" onClick={() => this.openDialAudio()}>
                    <FaMicrophone /> DIAL FOR AUDIO
                  </span>
                </div>
                <div className="col-md-12 pt_15_iam">
                  <span className="inviteActionMod_pt" onClick={() => this.handleOpenShareModal()}>
                    <IoIosMail /> INVITE VIA MAIL
                  </span>
                </div>
                <div className="col-md-12 pt_15_iam">
                  <span className="inviteActionMod_pt" onClick={() => this.openSms()}>
                    <FaShareAlt /> SHARE MEETING LINK
                  </span>
                </div>
              </div>
            </Modal.Body>
          </Modal>


          <Modal
            show={this.state.dialAudio}
            onHide={this.closeDialAudio}
            dialogClassName="audio-modal"
            centered>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 head">
                  Contacts
                </div>
                <div className="col-md-6">
                  <div className="t-right">
                    <button className={this.state.isMultiSelect ? "mul-active" : "multi-btn"} onClick={() => this.setMulti()}>Select Multiple</button>
                    {this.state.isMultiSelect ? <button className="call_mul_btn" onClick={() => this.submitMultiCall()}>Call</button> : ""}
                  </div>
                </div>
              </div>
              <div className="row pt-15">
                <div className="col-md-6">
                  <div className="search-container">
                    <input
                      type="text"
                      className="search-box"
                      placeholder="Contact Name"
                      // value={this.state.searchInviteeText}
                      onChange={this.changeInviteeSearchText}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <SelectBox
                    optionData={this.state.allRole}
                    value={this.state.selectedRole}
                    onSelectChange={(value) =>
                      this.onSelectedRoleChange(value)
                    }
                  // isDisabled = {true}
                  ></SelectBox>
                </div>
              </div>
              <div className="row pt-15">
                <div className="col-md-12">
                  <Table striped hover >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.allInviteeList.length > 0 ?
                        this.state.allInviteeList.map((item, i) =>
                          <tr>
                            <td>{item.userId}</td>
                            <td>{item.name}</td>
                            <td>{item.userTypeName}</td>
                            <td>
                              {this.state.isMultiSelect ?
                                <Checkbox
                                  color="primary"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  checked={item.isCheck === 1}
                                  onChange={(e) => this.checkInvite(e, i)}
                                /> :
                                <FaPhoneAlt size={30} color="green" onClick={() => this.clickCall(item.userId)} style={{ cursor: "pointer" }} />}
                            </td>
                          </tr>
                        )
                        : <></>}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.showShare} onHide={this.handleCloseShareModal} aria-labelledby="form-dialog-title"
            dialogClassName="audio-modal">
            <Modal.Header id="form-dialog-title">Share Your Link to external users</Modal.Header>
            <Modal.Body>
              <div>
                <DialogContentText>
                  To
                </DialogContentText>
                {/* <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  value={this.state.shareEmailText}
                  onKeyPress={this.onChangeEmail}
                  onChange={
                    this.changeEmailText
                  }
                /> */}
                <input
                  type='text'
                  placeholder="Email Address"
                  className="sms_numberInput"
                  value={this.state.shareEmailText}
                  onKeyPress={this.onChangeEmail}
                  onChange={
                    this.changeEmailText
                  }
                />
                <span className="contact_sms_icon" onClick={() => this.openContactListPopupPrimaryEmail()}>
                  <MdContacts size={30} />
                </span>
                <span className='joiningFieldError' hidden={!this.state.errorJoiningName}>*Please enter valid email</span>
                <div className="row">
                  {this.state.allEmailArr.map((em, i) =>
                    <div className="col-md-4" style={{ paddingTop: '10px' }}>
                      <Card>
                        <Card.Body className="share_card_body">
                          {em}
                          <CloseButton style={{ float: 'right' }} onClick={() => this.deleteEmail(i)} />
                        </Card.Body>
                      </Card>

                    </div>)}
                </div>
              </div>
              <div className="pt-15">
                <DialogContentText>
                  Cc
                </DialogContentText>
                {/* <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={this.state.shareCcEmailText}
                onKeyPress={this.onChangeCcEmail}
                onChange={
                  this.changeCcEmailText
                }
              /> */}
                <input
                  type='text'
                  placeholder="Email Address"
                  className="sms_numberInput"
                  value={this.state.shareCcEmailText}
                  onKeyPress={this.onChangeCcEmail}
                  onChange={
                    this.changeCcEmailText
                  }
                />
                <span className="contact_sms_icon" onClick={() => this.openContactListPopupCcEmail()}>
                  <MdContacts size={30} />
                </span>
                <span className='joiningFieldError' hidden={!this.state.errorCcJoiningName}>*Please enter valid email</span>
                <div className="row">
                  {this.state.allCcEmailArr.map((em, i) =>
                    <div className="col-md-4" style={{ paddingTop: '10px' }}>
                      <Card>
                        <Card.Body className="share_card_body">
                          {em}
                          <CloseButton style={{ float: 'right' }} onClick={() => this.deleteCcEmail(i)} />
                        </Card.Body>
                      </Card>

                    </div>)}
                </div>
              </div>
              <div className="pt-15">
                <DialogContentText>
                  Subject
                </DialogContentText>
                <input
                  type='text'
                  placeholder="Subject"
                  className="sms_numberInput"
                  value={this.state.shareMailSubject}
                  onChange={
                    this.changeEmailSubject
                  }
                />
              </div>

              <div className="editor_pad-vri">

                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.shareEmailBody}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({
                      shareEmailBody: data
                    })
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer hidden={this.state.hideShareBtn}>
              <Button onClick={this.handleCloseShareModal} color="primary">
                Cancel
              </Button>
              <Button onClick={this.shareCallLink} color="primary">
                Send
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.showsms}
            onHide={this.closeSms}
            dialogClassName="audio-modal"
            centered
          >
            <Modal.Header id="form-dialog-title">Send Meeting invite via SMS</Modal.Header>
            <Modal.Body>
              <DialogContentText>
                To
              </DialogContentText>
              <input
                type='text'
                placeholder="Type Phone Number With Country code"
                className="sms_numberInput"
                value={this.state.shareSmsText}
                onKeyPress={this.onChangeSms}
                onChange={this.changeSmsText} />
              <span className="contact_sms_icon" onClick={() => this.openContactListPopup()}><MdContacts size={30} /></span>

              <div className="row">
                {this.state.allSmsContact.map((item, i) =>
                  <div className="col-md-4" style={{ paddingTop: '10px' }}>
                    <Card>
                      <Card.Body className="share_card_body">
                        {"+" + item}
                        <CloseButton style={{ float: 'right' }} onClick={() => this.deleteSms(i)} />
                      </Card.Body>
                    </Card>

                  </div>)}
              </div>

              <div className="editor_pad-vri">
                <DialogContentText>
                  Message
                </DialogContentText>

                <textarea className="smsBody" value={this.state.smsBody}></textarea>
              </div>
            </Modal.Body>
            <Modal.Footer hidden={this.state.hideShareBtn}>
              <Button onClick={this.closeSms} color="primary">
                Cancel
              </Button>
              <Button onClick={this.submitForSendSms} color="primary">
                Send
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.contactListPopUp} onHide={this.closeContactListPopup} dialogClassName="contact-mod" centered>
            <Modal.Header closeButton>
              <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {this.state.allInviteeList.map((data, i) => <>
                  <div className="col-md-1">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={data.isCheck === 1}
                      onChange={(e) => this.checkInvite(e, i)}
                    />
                  </div>
                  <div className="col-md-11 name_con" >
                    {data.name + " ( " + data.userTypeName + " )"}
                  </div>
                </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer >
              {/* <Button onClick={this.closeContactListPopup} color="primary">
                Reset
              </Button> */}
              <Button onClick={() => this.submitCheckInviteForSms()} color="primary">
                Done
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.conatctListPopupPrimaryEmail} onHide={this.closeContactListPopupPrimaryEmail} dialogClassName="contact-mod" centered>
            <Modal.Header closeButton>
              <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {this.state.allEmailInviteList.map((data, i) => <div className="pt-10">
                  <div className="col-md-1">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      // checked={data.isCheck === 1}
                      onChange={(e) => this.checkInvitePrimaryEmail(e, i)}
                    />
                  </div>
                  <div className="col-md-11 name_con" >
                    {data.name + " ( " + data.userTypeName + " )"} <br />
                    {data.email}
                  </div>
                </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer >
              <Button onClick={() => this.submitCheckInviteForPrimaryEmail()} color="primary">
                Done
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.contactListPopupCCemail} onHide={this.closeContactListPopupCcEmail} dialogClassName="contact-mod" centered>
            <Modal.Header closeButton>
              <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {this.state.allCcEmailInviteList.map((data, i) => <div className="pt-10">
                  <div className="col-md-1">
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      // checked={data.isCheck === 1}
                      onChange={(e) => this.checkInviteCcEmail(e, i)}
                    />
                  </div>
                  <div className="col-md-11 name_con" >
                    {data.name + " ( " + data.userTypeName + " )"} <br />
                    {data.email}
                  </div>
                </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer >
              <Button onClick={() => this.submitCheckInviteForCcEmail()} color="primary">
                Done
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* 
        <div id="participant-bar"> */}
        <div className="video_thumb">
          <Participant
            key={this.props.room.localParticipant.identity}
            localParticipant="true"
            participant={this.props.room.localParticipant}
            getParticipant={() => this.dominantSpeakerChanged(null)}
            isVideoOn={this.props.isVideoOn}
          />

          {this.state.remoteParticipants.map((participant) => (
            <Participant
              key={participant.identity}
              participant={participant}
              getParticipant={() => this.dominantSpeakerChanged(participant)}
              isVideoOn={this.props.isVideoOn}
            />
          ))}
        </div>

        <div id="chat-bar" hidden={!this.state.showChat}>
          <Box sx={{ flexGrow: 1 }}>
            <Backdrop open={this.state.loading} style={{ zIndex: 99999 }}>
              <CircularProgress style={{ color: "white" }} />
            </Backdrop>

            <AppBar position="static" className="header_ch">
              <Toolbar style={styles.toolFlex}>
                <Typography variant="h6">
                  {`Job ID: ${this.props.room.name}`}
                </Typography>

                <Button color="inherit" onClick={this.hideChat}><CancelIcon /></Button>

              </Toolbar>
            </AppBar>

            <CssBaseline />

            <Grid container direction="column" style={styles.mainGrid}>
              <Grid item style={styles.gridItemChatList} ref={this.scrollDiv}>
                <List dense={true}>
                  {this.state.messages &&
                    this.state.messages.map((message) => (
                      <ChatItem
                        key={message.index}
                        message={message}
                        email={this.props.room.localParticipant.identity}
                      />
                    ))}
                </List>
              </Grid>

              <Grid item style={styles.gridItemMessage}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item style={styles.textFieldContainer}>
                    <TextField
                      required
                      style={styles.textField}
                      placeholder="Enter message"
                      variant="outlined"
                      multiline
                      rows={2}
                      value={this.state.text}
                      onChange={(event) =>
                        this.setState({ text: event.target.value })
                      }
                    />
                  </Grid>

                  <Grid item style={styles.uploadButtonContainer}>

                    <IconButton
                      style={styles.uploadButton}
                    // onClick={this.sendMessage}
                    >
                      <label
                        for={"profile_image"}
                        // data-toggle="tooltip"
                        // data-placement="top"
                        // title={data.uploadName}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          id={"profile_image"}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            this.onUploadDocument(e);
                          }}
                        />
                        <AttachFileRoundedIcon style={styles.uploadIcon} />
                      </label>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={styles.sendButton}
                      onClick={this.sendMessage}
                    >
                      <Send style={styles.sendIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div id="button-bar">
          <ul>
            <li id="leaveRoom" onClick={() => this.leaveRoom()}>
              <MdCallEnd></MdCallEnd>
            </li>
            {this.state.isMute === false ? (
              <>
                <li id="mute" onClick={this.audioMute}>
                  <AiOutlineAudio></AiOutlineAudio>
                </li>
              </>
            ) : (
              <>
                <li id="unmute" onClick={this.audioUnMute}>
                  <AiOutlineAudioMuted></AiOutlineAudioMuted>
                </li>
              </>
            )}
            {this.props.isVideoOn === true ? (
              <>
                {this.isMobile() ? <></> : <>
                  {this.state.isScreenTrack === false ? (
                    <>
                      <li
                        id="screenCapture"
                        className="bb"
                        onClick={this.screenCapture}
                      >
                        <TbCapture></TbCapture>
                      </li>
                    </>
                  ) : (
                    <>
                      <li id="stopScreenCapture" onClick={this.stopScreenCapture}>
                        <TbCaptureOff></TbCaptureOff>
                      </li>
                    </>
                  )} </>}
                {this.state.isVideo === true ? (
                  <>
                    <li id="videoStop" onClick={this.videoStop}>
                      <MdVideocam></MdVideocam>
                    </li>
                  </>
                ) : (
                  <>
                    <li id="videoResume" onClick={this.videoResume}>
                      <MdVideocamOff></MdVideocamOff>
                    </li>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {this.state.showChat === false ? (
              <>
                <li id="showChat" onClick={this.showChat}>
                  <BsChatDotsFill></BsChatDotsFill>
                </li>
              </>
            ) : (
              <>
                <li id="hideChat" onClick={this.hideChat}>
                  <BsChatDots></BsChatDots>
                </li>
              </>
            )}
            {this.props.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN ||
              this.props.userType === UsersEnums.APPLICATION_ROLE.CLIENT ||
              this.props.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF ||
              this.props.userType === UsersEnums.APPLICATION_ROLE.CLIENT_CONTACT ||
              this.props.userType === UsersEnums.APPLICATION_ROLE.REQUESTER ||
              this.props.userType === UsersEnums.APPLICATION_ROLE.SUPERVISOR ?
              <li onClick={this.openInviteAction}>
                <RiUserSharedLine></RiUserSharedLine>
              </li> :
              <></>
            }
          </ul>
        </div>
      </div>
    );
  }
}
const styles = {
  textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
  textFieldContainer: { flex: 1, marginRight: 12 },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemChatList: { overflow: "auto", height: "40vh" },
  gridItemMessage: { marginTop: 12, marginBottom: 12, paddingLeft: 20, paddingRight: 10 },
  sendButton: { backgroundColor: "#00B6FF" },
  sendIcon: { color: "white" },
  uploadButtonContainer: { marginRight: 10 },
  uploadButton: { width: "50px", height: "50px", backgroundColor: "#00B6FF" },
  uploadIcon: { color: "white" },
  mainGrid: { paddingTop: 0, borderWidth: 1 },
  toolFlex: { justifyContent: "space-between" }
};
export default Room;
