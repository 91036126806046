import React, { Component } from "react";
import "./video.css";
import Track from "./Track";
import { Decoder } from "../../services/auth";

class Participant extends Component {
  constructor(props) {
    super(props);

    const existingPublications = Array.from(
      this.props.participant.tracks.values()
    );
    const existingTracks = existingPublications.map(
      (publication) => publication.track
    );
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    this.state = {
      tracks: nonNullTracks,
      jobDetails: {}
    };

    // this.eventClick = this.eventClick.bind(this);
  }

  componentDidMount() {
    if (!this.props.localParticipant) {
      this.props.participant.on("trackSubscribed", (track) =>
        this.addTrack(track)
      );
    }
    this.setState({
      jobDetails: JSON.parse(localStorage.getItem("callData"))
    })
  }
  eventuClick() {
    this.props.getParticipant();
  }
  addTrack(track) {
    this.setState({
      tracks: [...this.state.tracks, track],
    });
  }

  render() {
    return (
      <>
        {this.props.dominantParticpant === "true" ? (
          <>
            {/* <div className="participant" id={this.props.participant.identity}> */}
            <div className="video_sec_head" id={this.props.participant.identity}>
              {/* <div className="identity-text">
                {this.props.participant.identity}
              </div> */}
              <h4>{this.props.participant.identity}</h4><br />
              {(this.state.jobDetails !== undefined && this.state.jobDetails !== null) ?
                <div style={{ color: "#fff" }}>
                  <h6>Job ID : {this.state.jobDetails.requestId}</h6>
                  <h6>Job Type : {this.state.jobDetails.jobTypeName}</h6>
                  {/* <h6>Client Name : {this.state.jobDetails.clientName}</h6> */}
                  <h6>Source Language : {this.state.jobDetails.sourceLanguageName}</h6>
                  <h6>Target Language : {this.state.jobDetails.targetLanguageName}</h6>
                </div> :
                <></>
              }

              <div class="main_video">
                <div class="main_video_non">
                  {this.state.tracks.map((track) => (
                    <Track key={track} filter={this.state.filter} track={track}
                      isVideoOn={this.props.isVideoOn} />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <div
              className="participant-each"
              id={this.props.participant.identity}
              onClick={() => this.eventuClick()}
            > */}
            <div
              className="sml_vdo"
              id={this.props.participant.identity}
              onClick={() => this.eventuClick()}
            >
              {/* <div className="identity-text">
                {this.props.participant.identity}
              </div> */}

              {this.state.tracks.map((track) => (
                <Track
                  key={track}
                  filter={this.state.filter}
                  track={track}
                  identity={this.props.participant.identity}

                  isVideoOn={this.props.isVideoOn}
                />
              ))}
              <div className="video_id_blo">
                <strong>{this.props.participant.identity}</strong>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Participant;
