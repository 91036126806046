
import { toast } from "react-toastify";
export const valueChange = (path) => {
  // console.log("from action>>>>>", path);
  return {
    type: "CHANGE_VALUE",
    payload: path
  };
};

export const viewToastSuccess = (message) => {
  return function(dispatch) {
    dispatch({
      type: "TOAST"
    });
    toast.success(message);
  };
}

export const viewToastError = (message) => {
  return function(dispatch) {
    dispatch({
      type: "TOAST"
    });
    toast.error(message);
  };
}

/* For Manage Reports */

export const getAllReports = (payload) =>{
  return function(dispatch){
    dispatch({
      type : "GET_REPORT",
      payload : ["1","2","3"]
    })
  }
}
