import {
  MemberDescriptor,
  Member,
  MemberUpdatedEventArgs,
  MemberUpdateReason
} from '../member';
import { Logger } from '../logger';

import { Channel } from '../channel';

import { SyncMap, SyncClient } from 'twilio-sync';
import { Users } from './users';
import { CommandExecutor } from '../commandexecutor';
import { JoinChannelRequest, JoinChannelResponse } from '../interfaces/commands/joinchannel';
import { Configuration } from '../configuration';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type MembersEvents = {
  memberJoined: (member: Member) => void;
  memberLeft: (member: Member) => void;
  memberUpdated: (data: {
    member: Member;
    updateReasons: MemberUpdateReason[];
  }) => void;
};

const log = Logger.scope('Members');

export interface MembersServices {
  syncClient: SyncClient;
  users: Users;
  commandExecutor: CommandExecutor;
}

interface MembersLinks {
  participants: string;
}

/**
 * @classdesc Represents the collection of members for the channel
 * @fires Members#memberJoined
 * @fires Members#memberLeft
 * @fires Members#memberUpdated
 */
class Members extends ReplayEventEmitter<MembersEvents> {

  rosterEntityPromise: Promise<SyncMap>;

  constructor(
    public readonly channel: Channel,
    public readonly members: Map<string, Member>,
    private readonly links: MembersLinks,
    private readonly configuration: Configuration,
    private readonly services: MembersServices
  ) {
    super();
  }

  public async unsubscribe(): Promise<void> {
    if (this.rosterEntityPromise) {
      let entity = await this.rosterEntityPromise;
      entity.close();
      this.rosterEntityPromise = null;
    }
  }

  public async subscribe(rosterObjectName: string) {
    return this.rosterEntityPromise = this.rosterEntityPromise
      || this.services.syncClient.map({ id: rosterObjectName, mode: 'open_existing' })
             .then(rosterMap => {
               rosterMap.on('itemAdded', args => {
                 log.debug(this.channel.sid + ' itemAdded: ' + args.item.key);
                 this.upsertMember(args.item.key, args.item.data)
                     .then(member => {
                       this.emit('memberJoined', member);
                     });
               });

               rosterMap.on('itemRemoved', args => {
                 log.debug(this.channel.sid + ' itemRemoved: ' + args.key);
                 let memberSid = args.key;
                 if (!this.members.has(memberSid)) {
                   return;
                 }
                 let leftMember = this.members.get(memberSid);
                 this.members.delete(memberSid);
                 this.emit('memberLeft', leftMember);
               });

               rosterMap.on('itemUpdated', args => {
                 log.debug(this.channel.sid + ' itemUpdated: ' + args.item.key);
                 this.upsertMember(args.item.key, args.item.data);
               });

               let membersPromises = [];
               let that = this;
               const rosterMapHandler = function(paginator) {
                 paginator.items.forEach(item => { membersPromises.push(that.upsertMember(item.key, item.data)); });
                 return paginator.hasNextPage ? paginator.nextPage().then(rosterMapHandler) : null;
               };

               return rosterMap
                 .getItems()
                 .then(rosterMapHandler)
                 .then(() => Promise.all(membersPromises))
                 .then(() => rosterMap);
             })
             .catch(err => {
               this.rosterEntityPromise = null;
               if (this.services.syncClient.connectionState != 'disconnected') {
                 log.error('Failed to get roster object for channel', this.channel.sid, err);
               }
               log.debug('ERROR: Failed to get roster object for channel', this.channel.sid, err);
               throw err;
             });
  }

  public async upsertMember(memberSid: string, data: MemberDescriptor): Promise<Member> {
    let member = this.members.get(memberSid);
    if (member) {
      return member._update(data);
    }

    const links = {
      self: `${this.links.participants}/${memberSid}`
    };

    member = new Member(data, memberSid, this.channel, links, this.services);
    this.members.set(memberSid, member);
    member.on('updated', (args: MemberUpdatedEventArgs) => this.emit('memberUpdated', args));
    return member;
  }

  /**
   * @returns {Promise<Array<Member>>} returns list of members {@see Member}
   */
  public async getMembers(): Promise<Array<Member>> {
    return this.rosterEntityPromise.then(() => {
      let members = [];
      this.members.forEach((member) => members.push(member));
      return members;
    });
  }

  /**
   * Get member by SID from channel
   * @returns {Promise<Member>}
   */
  public async getMemberBySid(memberSid: string): Promise<Member> {
    return this.rosterEntityPromise.then(() => {
      let member = this.members.get(memberSid);
      if (!member) {
        throw new Error('Member with SID ' + memberSid + ' was not found');
      }
      return member;
    });
  }

  /**
   * Get member by identity from channel
   * @returns {Promise<Member>}
   */
  public async getMemberByIdentity(identity: string): Promise<Member> {
    let foundMember = null;
    return this.rosterEntityPromise.then(() => {
      this.members.forEach((member) => {
        if (member.identity === identity) {
          foundMember = member;
        }
      });
      if (!foundMember) {
        throw new Error('Member with identity ' + identity + ' was not found');
      }
      return foundMember;
    });
  }

  /**
   * Add user to the channel
   * @returns {Promise<any>}
   */
  public async add(identity: string): Promise<JoinChannelResponse> {
    return await this.services.commandExecutor.mutateResource<JoinChannelRequest, JoinChannelResponse>(
      'post',
      this.links.participants,
      {
        identity
      }
    );
  }

  /**
   * Invites user to the channel
   * User can choose either to join or not
   * @returns {Promise<any>}
   */
  public async invite(identity: string): Promise<any> {
    return await this.services.commandExecutor.mutateResource(
      'post',
      this.channel.links.invites,
      {
        identity
      }
    );
  }

  /**
   * Remove member from channel
   * @returns {Promise<any>}
   */
  public async remove(identity: string): Promise<void> {
    return await this.services.commandExecutor.mutateResource(
      'delete',
      `${this.links.participants}/${identity}`,
    );
  }
}

export { Members };

/**
 * Fired when member joined channel
 * @event Members#memberJoined
 * @type {Member}
 */

/**
 * Fired when member left channel
 * @event Members#memberLeft
 * @type {Member}
 */

/**
 * Fired when member updated
 * @event Members#memberUpdated
 * @type {Object}
 * @property {Member} member - Updated Member
 * @property {Member#UpdateReason[]} updateReasons - Array of Member's updated event reasons
 */
