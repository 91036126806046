import React from "react";
import {
  consoleLog,
  SetDateFormat,
  textTruncate,
} from "../../../../../services/common-function";
import { Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system"; //imported for modal
import { ImageName } from "../../../../../enums";
import StatusList from "./StatusList";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    marginTop: 5,
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

export default class HistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      anchorEl: null, //menu button
      anchorEl1: null,
      curIndex: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        listData: this.props.value,
      });
    }
  }

  //................funct for menuBtn on click................
  menuBtnhandleClick = (index, event) => {
    this.setState({
      curIndex: index,
      anchorEl: event.currentTarget,
    });
  };
  menuBtnhandleClick_b = (index, event) => {
    this.setState({
      curIndex: index,
      anchorEl1: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      anchorEl1: null,
    });
  };

  goEdit = () => {
    this.props.onViewClick(this.state.curIndex);
  };

  render() {
    const open = Boolean(this.state.anchorEl); //used in MenuButton open
    const open1 = Boolean(this.state.anchorEl1);
    return (
      <React.Fragment>
        <div className="table-listing-app">
          <div className="table-responsive_cus table-style-a">
            <table width="100%" border="0" cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <th style={{ width: "15%" }}>Project#</th>
                  <th style={{ width: "10%" }}>Task#</th>
                  <th style={{ width: "15%" }}>Service Type</th>
                  <th style={{ width: "15%" }}>Date</th>
                  <th style={{ width: "20%" }}>Language </th>
                  <th style={{ width: "12%" }}>Status</th>
                  <th style={{ width: "8%" }}>Action</th>
                </tr>
                {this.state.listData.length > 0 ? (
                  <React.Fragment>
                    {this.state.listData.map((item, key) => (
                      <tr key={key}>
                        <td colSpan="11">
                          <div className="tble-row">
                            <table
                              width="100%"
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                            >
                              <tbody>
                                <tr>
                                  <td style={{ width: "15%" }}>{item.jobId}</td>
                                  <td style={{ width: "10%" }}>{item.taskNo}.{item.subTaskNo} </td>
                                  <td style={{ width: "15%" }}>{item.servname}</td>
                                  <td style={{ width: "15%" }}>
                                    {item.expectedDeadline}
                                  </td>
                                  <td
                                    style={{ width: "20%" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                      item.sourceLanguage +
                                      " > " +
                                      item.targetLanguage
                                    }
                                  >
                                    {textTruncate(
                                      item.sourceLanguage +
                                      " > " +
                                      item.targetLanguage,
                                      25
                                    )}
                                  </td>
                                  {/* <td style={{ width: "8%" }}></td> */}
                                  <td style={{ width: "12%" }}>
                                    <StatusList value={item} />
                                  </td>
                                  <td style={{ width: "8%" }}>
                                    {item.status === 2 ? (
                                      <React.Fragment>
                                        <img
                                          src={
                                            ImageName.IMAGE_NAME.MENU_VERTICAL
                                          }
                                          style={{ cursor: "pointer" }}
                                          id={"basic-button" + key}
                                          aria-controls={"basic-menu" + key}
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open ? "true" : undefined
                                          }
                                          onClick={(e) =>
                                            this.menuBtnhandleClick(key, e)
                                          }
                                        />
                                        <StyledMenu
                                          id={"basic-menu" + key}
                                          anchorEl={this.state.anchorEl}
                                          open={open}
                                          onClose={this.handleMenuClose}
                                          MenuListProps={{
                                            "aria-labelledby":
                                              "basic-button" + key,
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => this.goEdit()}
                                          >
                                            View Details
                                          </MenuItem>
                                          {/* <MenuItem
                                          // onClick={this.declineModal}
                                          >
                                            Cancel
                                          </MenuItem> */}
                                        </StyledMenu>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <img
                                          src={
                                            ImageName.IMAGE_NAME.MENU_VERTICAL
                                          }
                                          style={{ cursor: "pointer" }}
                                          id={"basic-button" + key}
                                          aria-controls={"basic-menu" + key}
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open1 ? "true" : undefined
                                          }
                                          onClick={(e) =>
                                            this.menuBtnhandleClick_b(key, e)
                                          }
                                        />
                                        <StyledMenu
                                          id={"basic-menu" + key}
                                          anchorEl={this.state.anchorEl1}
                                          open={open1}
                                          onClose={this.handleMenuClose}
                                          MenuListProps={{
                                            "aria-labelledby":
                                              "basic-button" + key,
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() => this.goEdit()}
                                          >
                                            View Details
                                          </MenuItem>
                                          {/* <MenuItem onClick={this.declineModal}>
                                            Cancel
                                          </MenuItem> */}
                                        </StyledMenu>
                                      </React.Fragment>
                                    )}
                                    {/* </div> */}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="7">
                        <center style={{ fontSize: "20px" }}>
                          No data found !!!
                        </center>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
