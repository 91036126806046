import React from "react";
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import axios from "axios";
import { APP_URL } from "../../services/config";
import { ErrorCode } from "../../services/constant";
import ChatItemView from "./ChatItemView";
import { ApiCall } from "../../services/middleware";
import { consoleLog } from "../../services/common-function";
import { Decoder } from "../../services/auth";
import { socket } from "../../services/config/socket";
import { SOCKET_PATH } from "../../services/config/api_url";

const Chat = require("twilio-chat");

const styles = {
    textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
    textFieldContainer: { flex: 1, marginRight: 12 },
    gridItem: { paddingTop: 12, paddingBottom: 12 },
    gridItemChatList: { overflow: "auto", height: "43vh" },
    gridItemMessage: { marginTop: 12, marginBottom: 12 },
    sendButton: { backgroundColor: "#3f51b5" },
    sendIcon: { color: "white" },
    uploadButtonContainer: { marginRight: 10 },
    uploadButton: { width: "50px", height: "50px", backgroundColor: "#00a0df", position: "absolute", borderRadius: "50%", marginLeft: "10px" },
    uploadIcon: { color: "white" },
    mainGrid: { paddingTop: 0, borderWidth: 1 },
};
export default class ChatRoom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            remoteParticipants: Array.from(this.props.room.participants.values()),
            isMute: false,
            isVideo: true,
            screenTrack: null,
            isScreenTrack: false,
            screenCaptureRoom: null,
            dominantParticpant: null,
            messages: [],
            loading: false,
            channel: null,
            text: "",
            showChat: false,
            showShare: false,
            allEmailArr: [],
            shareEmailText: "",
            errorJoiningName: false,
            hideShareBtn: false,
            userTypeId: "",
            userId: ""
        };
    }
    componentDidMount() {
        this.load();
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);
        // console.log("Auth User Data >>> ", authUser)
        this.setState({
            userTypeId: authUser.data.usertypeid,
            userId: authUser.data.userid
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.token !== prevProps.token) {
            prevProps.room.disconnect();
            this.load();
        }
    }

    load = async () => {


        const client = await Chat.Client.create(this.props.token);
        this.fetchChatData(this.props.room.name)

        // client.on("tokenAboutToExpire", async () => {
        //     const token = await this.getToken(
        //         this.props.room.localParticipant.identity
        //     );
        //     client.updateToken(token);
        // });

        // client.on("tokenExpired", async () => {
        //     const token = await this.getToken(
        //         this.props.room.localParticipant.identity
        //     );
        //     client.updateToken(token);
        // });
        client.on("channelJoined", async (channel) => {
            // getting list of all messages since this is an existing channel
            // const messages = await channel.getMessages();
            // console.log("Get message:", messages)
            // this.setState({ messages: messages.items || [] });
            // this.scrollToBottom();
        });

        try {
            const channel = await client.getChannelByUniqueName(this.props.room.name);
            this.joinChannel(channel);
        } catch (err) {
            try {
                const channel = await client.createChannel({
                    uniqueName: this.props.room.name,
                    friendlyName: this.props.room.name,
                });

                this.joinChannel(channel);
            } catch {
                throw new Error("Unable to create channel, please reload this page");
            }
        }
        window.addEventListener("beforeunload", this.leaveRoom);
    }
    scrollToBottom() {
        /* const scrollHeight = this.scrollDiv.current.scrollHeight;
        const height = this.scrollDiv.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.scrollDiv.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;*/
    }
    getToken = async (email) => {
        const response1 = await fetch(
            `https://demo.lykstage.com:5000/token/${email}`
        );
        const data1 = await response1.json();

        return data1.token;
    };
    joinChannel = async (channel) => {
        if (channel.channelState.status !== "joined") {
            await channel.join();
        }

        this.setState({
            channel: channel,
            loading: false,
        });

        channel.on("messageAdded", this.handleMessageAdded);
        this.scrollToBottom();
    };

    handleMessageAdded = (message) => {
        const { messages } = this.state;
        this.setState(
            {
                messages: [...messages, message],
            },
            this.scrollToBottom
        );
    };
    sendMessage = async () => {
        let text = this.state.text.trim();
        if (text) {

            // console.log("Send message:",this.state.text);
            this.setState({ loading: true });
            let req = {
                meetingId: this.props.room.name,
                sentBy: this.props.room.localParticipant.identity,
                message: this.state.text,
                createdOn: new Date()
            };
            consoleLog("Request For Add Chat Message >>> ", req)
            let res = await ApiCall("addChatMessages", req);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let socObj = {
                    jobId: this.props.jobAlphaId,
                    groupId: this.props.jobId,
                    userId: this.state.userId,
                    userTypeId: this.state.userTypeId
                }
                // console.log("Send message socket obj >>> ", socObj)
                socket.connect(SOCKET_PATH);
                socket.emit("user/chkReqIsLive", socObj);
                this.state.channel.sendMessage(String(this.state.text).trim());
                this.setState({ text: "", loading: false });
            }
        }
    };

    fetchChatData = async (id) => {
        let req = {
            meetingId: id
        };
        let res = await ApiCall("fetchChatMessages", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            consoleLog("All Chat Messages", payload.data);
            let arr = [];
            payload.data.map((item, i) => {
                arr.push({
                    index: i,
                    author: item.sentBy,
                    body: item.message,
                    timestamp: item.createdOn
                })
            })
            this.setState({ messages: arr || [] });
            this.scrollToBottom();
        }
    }


    // ...............document....................
    onUploadDocument = (e) => {
        // console.log("position:", i)
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        axios.post(APP_URL.IMAGE_URL, formData).then(async (res) => {
            // console.log("doc res>>>", res.data.data);
            // this.setState({
            //     //   imagePath: res.data.data.url,
            //     uploadName: res.data.data.path + res.data.data.filename,
            // });

            if (res.data.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR) {
                this.setState({ loading: true });
                let req = {
                    meetingId: this.props.room.name,
                    sentBy: this.props.room.localParticipant.identity,
                    message: String(res.data.data.url).trim(),
                    createdOn: new Date()
                };
                consoleLog("Request For Add Chat Message >>> ", req)
                let resAdd = await ApiCall("addChatMessages", req);
                if (
                    resAdd.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                    resAdd.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                ) {
                    this.state.channel.sendMessage(String(res.data.data.url).trim());
                    this.setState({ text: "", loading: false });
                }
            }
        });
    }
    leaveRoom() {
        console.log("Leave");
        this.props.room.disconnect();
        this.props.returnToLobby();
    }
    render() {
        return (
            <>
                <div className="chat-app-information-component">

                    <div className="chat-app-component">
                        {/* <Grid container direction="column" style={styles.mainGrid}> */}
                        {/* <Grid item style={styles.gridItemChatList} ref={this.scrollDiv}>
                        <List dense={true}> */}
                        {this.state.messages &&
                            this.state.messages.map((message) => (
                                <ChatItemView
                                    key={message.index}
                                    message={message}
                                    email={this.props.room.localParticipant.identity}
                                />
                            ))}
                        {/* </List>
                    </Grid> */}

                        {/* </Grid> */}

                    </div>
                </div>
                <div className="chat-app-type-area" style={{ width: "95%" }}>
                    <input
                        type="text"
                        value={this.state.text}
                        onChange={(event) =>
                            this.setState({ text: event.target.value })
                        }
                        name=""
                        className="chat-field-bx"
                    />
                    <button type="submit" style={styles.uploadButton}>
                        <label
                            for={"profile_image"}
                            style={{ cursor: "pointer" }}
                        >
                            <input
                                type="file"
                                id={"profile_image"}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    this.onUploadDocument(e);
                                }}
                            />
                            <AttachFileRoundedIcon style={styles.uploadIcon} />
                        </label>
                    </button>
                    <button type="submit" className="send-btn-app" onClick={this.sendMessage}>
                        send
                    </button>
                </div>
                {/* <button type="submit" onClick={this.sendMessage}>
                        <AttachFileRoundedIcon style={styles.uploadIcon} />
                    </button> */}
                {/* <Grid item style={styles.gridItemMessage}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item style={styles.textFieldContainer}>
                            <TextField
                                required
                                style={styles.textField}
                                placeholder="Enter message"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={this.state.text}
                                onChange={(event) =>
                                    this.setState({ text: event.target.value })
                                }
                            />
                        </Grid>

                        <Grid item style={styles.uploadButtonContainer}>

                            <IconButton style={styles.uploadButton} >
                                <label
                                    for={"profile_image"}
                                    style={{ cursor: "pointer" }}
                                >
                                    <input
                                        type="file"
                                        id={"profile_image"}
                                        style={{ display: "none" }}
                                        onChange={(e) => {
                                            this.onUploadDocument(e);
                                        }}
                                    />
                                    <AttachFileRoundedIcon style={styles.uploadIcon} />
                                </label>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                style={styles.sendButton}
                                onClick={this.sendMessage}
                            >
                                <Send style={styles.sendIcon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid> */}
            </>
        )
    }
}