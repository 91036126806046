import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ApiCall } from "../../../../services/middleware";
import { toast, ToastContainer } from "react-toastify";
import { passwordValidator } from "../../../../validators";
import { passWordRandomGenerate } from "../Vendor List/function";
import { AlertMessage } from "../../../../enums";
import { ErrorCode } from "../../../../services/constant";
import { consoleLog } from "../../../../services/common-function";
import history from "../../../../history";

//Style for option button................
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default class VendorMenuPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            resetPasswordData: "",
            isActive: false
        }
    }

    //................funct for menuBtn on click................
    menuBtnhandleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    openResetModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("reset-password-vendor").style.display = "block";
        document.getElementById("reset-password-vendor").classList.add("show");
    };

    closeResetModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("reset-password-vendor").style.display = "none";
        document.getElementById("reset-password-vendor").classList.remove("show");
    };

    handleReset = () => {
        let mainPass = passWordRandomGenerate();
        // consoleLog("automated password::", mainPass)
        this.setState({
            resetPasswordData: mainPass
        })
        this.handleMenuClose();
        this.openResetModal();
    };
    cancelDelete = () => {
        // window.$("#status-model").modal("hide");
        this.closeResetModal();
    };
    //............Reset Password...........

    onResetPassword = async () => {


        let errorCount = 0;

        let validatePassword = passwordValidator(this.state.resetPasswordData)

        if (validatePassword.status === false) {
            toast.error(validatePassword.message, {
                hideProgressBar: true,
            });
            errorCount++;
        }
        // let pass = this.randomString(10, "aA#!");

        let data = {
            staffid: this.props.value,
            password: this.state.resetPasswordData,
        };

        consoleLog("Prev Data request:", data)

        if (errorCount === 0) {

            let data = {
                staffid: this.props.value,
                password: this.state.resetPasswordData,
            };

            consoleLog("Data request:", data)

            let status = await ApiCall("userpasswordreset", data);
            if (
                status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                this.closeResetModal();
                toast.success(AlertMessage.MESSAGE.PASSWORD.RESET_SUCCESS);
            }
        }

    };

    onResetPassChange = (e) => {
        this.setState({
            resetPasswordData: e.target.value,
        });
    };

    onDocument = () => {
        return history.push({
            pathname: "/adminVendorDocs",
            state: this.props.value,
        });
    };

    vendorRateCards = () => {

        let obj = {
            id: this.props.value,
            fName: this.props.profileData.fName,
            lName: this.props.profileData.lName
        }
        return history.push({
            pathname: "/adminVendorRateCard",
            state: obj
        });
    };

    onStatusModal = () => {
        if (
            this.props.profileData.status === 1 ||
            this.props.profileData.status === "1"
        ) {
            this.setState({
                isActive: true,
            });
        } else {
            this.setState({
                isActive: false,
            });
        }

        this.openStatusModal();
        this.handleMenuClose();
    };

    openStatusModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("profile-status-modal").style.display = "block";
        document.getElementById("profile-status-modal").classList.add("show");
    };
    closeStatusModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("profile-status-modal").style.display = "none";
        document.getElementById("profile-status-modal").classList.remove("show");
    };
    // ................status func for modal ...................
    onModalStatusChange = async () => {
        consoleLog("Vendor Profile DAta:", this.props.profileData);
        let stat = "0";
        if (this.props.profileData.status === 0) {
            stat = "1";
        } else {
            stat = "0";
        }
        let data = {
            vendorid: this.props.value,
            status: stat,
        };
        let status = await ApiCall("deletevendorAcccount", data);

        if (
            status.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            status.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.UPDATE_STATUS.SUCCESS);
            // this.load();
            this.closeStatusModal();
            return history.push("/adminVendorList")
        }
    };

    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" />
                <Button
                    aria-controls="op-btn"
                    id="op-btn"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.menuBtnhandleClick(e)}
                >
                    Go to
                </Button>
                <StyledMenu
                    id="op-btn"
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.handleMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "op-btn",
                    }}
                >
                    <MenuItem onClick={this.handleReset}>
                        Reset Password
                    </MenuItem>
                    <MenuItem
                        onClick={() => this.onDocument()}
                    >
                        Verification Docs
                    </MenuItem>
                    <MenuItem>View Jobs</MenuItem>
                    <MenuItem>View Projects</MenuItem>
                    <MenuItem
                        onClick={() => this.vendorRateCards()}
                    >
                        Rate Cards
                    </MenuItem>
                    <MenuItem>Invoices</MenuItem>
                    <MenuItem
                        onClick={() => this.onStatusModal()}
                    >
                        {this.props.profileData.status === 0 ? "Activate" : "De-Activate"}
                        {/* De-Activate or Activate */}
                    </MenuItem>
                </StyledMenu>


                {/* ..................Reset Password modal................................. */}
                <div
                    id="reset-password-vendor"
                    className="modal fade modelwindow"
                    role="dialog"
                >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you really want to reset the password for selected
                                            user?{" "}
                                        </h6>
                                    </center>
                                    <div className="row" style={{ marginTop: "20px" }}>
                                        <center>
                                            <div className="col-md-6">
                                                <input
                                                    className="inputfield"
                                                    value={this.state.resetPasswordData}
                                                    onChange={(e) =>
                                                        this.onResetPassChange(e)
                                                    }
                                                />
                                            </div>
                                        </center>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "2%" }}
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.cancelDelete}
                                            >
                                                No
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="blue-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.onResetPassword}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ..................DeActivate modal................................. */}
                <div id="profile-status-modal" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="model-info f-model"> */}
                                <div className="form-search-app">
                                    <center>
                                        <h6>
                                            Are you sure you want to{" "}
                                            {this.state.isActive ? "Deactivate" : "Activate"} this vendor?{" "}
                                        </h6>
                                    </center>
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div
                                            class="_button-style _fl text-center"
                                            style={{ marginTop: "5%" }}
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                className="white-btn"
                                                style={{ textDecoration: "none" }}
                                                onClick={this.closeStatusModal}
                                            >
                                                No
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="blue-btn"
                                                style={{ textDecoration: "none", color: "#fff" }}
                                                onClick={this.onModalStatusChange}
                                            >
                                                Yes
                                            </a>
                                        </div>
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}