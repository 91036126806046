import { Menu, MenuItem } from "@mui/material";
import React, { Component } from "react";
import { AlertMessage, ImageName } from "../../../../enums";
import { Decoder } from "../../../../services/auth";
import { CommonData, ErrorCode } from "../../../../services/constant";
import { ApiCall, ApiCallClient, ApiCallVendor } from "../../../../services/middleware";
import { PaginationDropdown, SelectBox } from "../../SharedComponents/inputText";
import Select, { components } from "react-select";
import $ from "jquery";
// import "./viewAllJobs.css";
import {
    consoleLog,
    SetDateFormat,
    SetTimeFormat,
} from "../../../../services/common-function";
import { toast, ToastContainer } from "react-toastify";
import ReactLoader from "../../../Loader";
import { inputEmptyValidate } from "../../../../validators";
import ViewCalender from "../../../ReactBigCalender/ViewCalender";
import { Link } from "react-router-dom";

const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
        borderRadius: "6px",
        height: 50,
        minHeight: 50,
        textAlign: "center",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "yellow";
        return {
            ...styles,
            color: isFocused ? "grey" : "white",
            color: "#000",
            cursor: isDisabled ? "not-allowed" : "default",
        };
    },
};
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img
                src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
                style={{ width: "17px" }}
            />
        </components.DropdownIndicator>
    );
};

const cancelationArr = [
    {
        label: "Last minute reschedule ",
        value: 1,
    },
    {
        label: "Duplicate/Error ",
        value: 2,
    },
    {
        label: "Consumer No Show",
        value: 3,
    },
    {
        label: "Interpreter No Show",
        value: 4,
    },
    {
        label: "Other Service being utilized",
        value: 5,
    },
    {
        label: "Other ",
        value: 6,
    },
];

const reqData = {
    limit: "",
    offset: "",
    status: "",
    clientId: "",
    serviceType: "",
    orderby: "",
    direc: "",
    searchFrom: "",
    searchTo: "",
    rfqId: "",
    clientid: ""
};

export default class VendorViewJobs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoad: true,
            isAlljobs: true,
            isHistory: false,
            current_page: 1,
            total_page: 10,
            limit: 20,
            anchorEl: null, //menu button
            anchorEl1: null,
            cancellationData: {},
            rescheduledCheck: null,
            isSelected: null,
            clientArr: [],
            clientData: {},
            appointmentTypeArr: [],
            appointmentTypeData: {},
            targetLangData: {},
            sourceLangData: {},
            languageArr: [],
            statusArr: [],
            statusData: {},
            leiArr: [],
            leiData: {},
            industryArr: [],
            industryData: {},
            otherReason: "",
            formDate: "",
            toDate: "",
            selectedDisplayData: {
                label: "20",
                value: "20"
            },
            listData: [],
            historyList: [],
            historyTotalPage: "",
            appointmentDate: "",
            hour: "08",
            min: "00",
            ampm: "AM",
            isOtherReason: false,
            rescheduleNote: "",
            isCalender: false,
            calenderData: [],
            vendorName: ""
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();

        var classInstance = this;
        // When the user clicks anywhere outside of the modal, close it

        document.getElementById("backdrop").style.display = "none";
        var modal = document.getElementById("decline-model");
        var filterModal = document.getElementById("filter-model");
        window.onclick = function (event) {
            if (event.target == modal) {
                classInstance.closeDeleteModal();
            } else if (event.target == filterModal) {
                classInstance.closeModal();
            }
        };

        window.$(".verificaiton-doc-tab ul li").on("click", function () {
            $(".verificaiton-doc-tab ul li").removeClass("active");
            $(this).addClass("active");
            $("div").removeClass("activeLnk");
            $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk");
        });
    }

    load = async () => {

        let mainData = this.props.location,
            preData = mainData.state;

        if (preData) {
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: JSON.stringify((this.state.current_page - 1) * this.state.limit),
                vendorId: preData.id
            };
            this.setState({
                vendorName: preData.fName + " " + preData.lName
            })
            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);
        }
    };


    listApi = async (data) => {
        //...... For All jobs listing.............
        const res = await ApiCallVendor("getJobListByVendorId", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            try {
                const decodeData = Decoder.decode(res.data.payload);
                consoleLog("Vendor Interpretation List >>> ", decodeData.data)
                let listDetails = [];
                if (decodeData.data.interpretationList.length > 0) {
                    listDetails = decodeData.data.interpretationList;
                }
                let totalPage = Math.ceil(
                    decodeData.data.count / parseInt(this.state.limit)
                );
                this.setState({
                    listData: listDetails,
                    total_page: totalPage,
                    isLoad: false
                });
            } catch (e) {
                console.log(e)
            }
        }
    };
    //........Page show Limit.........

    onChangeLimit = (value) => {
        this.setState({
            limit: parseInt(value.value),
            selectedDisplayData: value
        });

        let limit = value.value;

        let data = {
            limit: limit,
            offset: JSON.stringify(
                (this.state.current_page - 1) * parseInt(value.value)
            ),
        };
        let returnData = Object.assign(reqData, data);

        this.listApi(returnData);
    };

    //........... Export File...............

    onExport = async () => {
        let data = {
            // name: this.state.uname,
            // email: this.state.emailId,
            // mobile: this.state.mobileNo,
            orderby: "",
            direc: "",
            searchto: "",
            searchfrom: "",
        };
        // let res = await ApiCall("exportadminstaff", data);
        // const decodeData = Decoder.decode(res.data.payload);
        // if (
        //   res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        //   res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        // ) {
        //   window.open(decodeData.data.fileUrl, "_blank");
        // }
    };
    //................funct for menuBtn on click................
    menuBtnhandleClick = (index, event) => {
        this.setState({
            curIndex: index,
            anchorEl: event.currentTarget,
        });
    };
    menuBtnhandleClick_b = (index, event) => {
        this.setState({
            curIndex: index,
            anchorEl1: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            anchorEl1: null,
        });
    };

    // .............pagination function..........

    clickChange = (e) => {
        this.setState({
            current_page: e.target.value,
        });
    };

    // This is goes to the previous page
    exLeft = () => {
        this.setState({
            current_page: 1,
        });
        let fetchData = {
            limit: JSON.stringify(this.state.limit),
            offset: "0",
        };
        let returnData = Object.assign(reqData, fetchData);
        this.listApi(returnData);
    };

    // This is goes to the last page
    exRigth = () => {
        let totalPage = this.state.total_page;
        this.setState({
            current_page: totalPage,
        });
        let fetchData = {
            limit: JSON.stringify(this.state.limit),
            offset: JSON.stringify((totalPage - 1) * this.state.limit),
        };
        let returnData = Object.assign(reqData, fetchData);
        this.listApi(returnData);
    };

    // This is goes to the first page
    prev = () => {
        let currentPage = this.state.current_page;
        if (currentPage > 1) {
            currentPage--;
            this.setState({
                current_page: currentPage,
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: JSON.stringify((currentPage - 1) * this.state.limit),
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);
        }
    };

    // This is goes to the next page
    next = () => {
        let currentPage = this.state.current_page;
        let totalPage = this.state.total_page;
        if (currentPage < totalPage) {
            currentPage++;
            this.setState({
                current_page: currentPage,
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: JSON.stringify((currentPage - 1) * this.state.limit),
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);
        }
    };

    goEdit = () => {
        this.props.history.push({
            pathname: "/adminJobDetails",
            state: this.state.listData[this.state.curIndex].id,
        });
    };

    openTable = () => {
        this.setState({
            isCalender: false
        })
    }

    openCalender = async () => {

        let mainData = this.props.location,
            preData = mainData.state;

        // consoleLog("preData::", preData)

        if (preData) {
            let fetchData = {
                status: "",
                clientId: "",
                serviceType: "",
                orderby: "",
                direc: "",
                searchFrom: "",
                searchTo: "",
                rfqId: "",
                clientid: preData.userId
            };



            //...... For All jobs listing for calender.............
            const res = await ApiCallVendor("getJobListByVendorId", fetchData);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                const decodeData = Decoder.decode(res.data.payload);
                let listDetails = [];
                if (decodeData.data.projectList.length > 0) {
                    listDetails = decodeData.data.projectList;
                }
                consoleLog("CalenderDAta get job by vendor >>>  : ", listDetails)
                this.setState({
                    calenderData: listDetails,
                    isCalender: true
                });
            }

        }

    }

    detailJob = (value) => {
        // consoleLog("Calender Click", value);
        this.setState({
            curIndex: value.curIndex
        });

        this.props.history.push({
            pathname: "/adminJobDetails",
            state: this.state.calenderData[value.curIndex].id,
        });

    }

    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        const open1 = Boolean(this.state.anchorEl1);
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" />
                <div className="component-wrapper vewaljobs">
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> / <Link to="/adminVendorList">Vendor</Link> / View Jobs
                    </div>
                    <div className="vendor-info p-10 _fl sdw">
                        <div className="vn-form _fl">

                            <div className="row"></div>
                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col-md-6">
                                    <p>Vendor Name : {this.state.vendorName}</p>
                                </div>
                                <div className="col-md-6 rateList">
                                    <div className="_fl verificaiton-doc-tab">
                                        <ul>
                                            <li
                                                className="active"
                                                data-related="tble-data-a"
                                                onClick={() => {
                                                    this.onTabClick("interpretation");
                                                }}
                                                style={{ width: "100%" }}
                                            >
                                                InterPretation
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-app">
                        {!this.state.isCalender ? <React.Fragment>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="cus-filter-btn">
                                        <button className="button">
                                            <img src={ImageName.IMAGE_NAME.MENU_BTN} onclick={this.openTable} />
                                        </button>
                                        <button className="button">
                                            <img src={ImageName.IMAGE_NAME.MENU_BTN_TWO} onClick={this.openCalender} />
                                        </button>

                                        <div className="filter-pagination">
                                            <button className="prev_btn" onClick={this.exLeft}></button>
                                            <button className="prv_btn" onClick={this.prev}>
                                                {" "}
                                                {"<"}
                                            </button>
                                            <span className="num" onChange={(e) => this.clickChange(e)}>
                                                {this.state.current_page}
                                            </span>
                                            <button className="nxt_btn" onClick={this.next}>
                                                {">"}
                                            </button>
                                            <button
                                                className="next_btn"
                                                onClick={this.exRigth}
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-filter-box">
                                        <div className="tble-short">
                                            {" "}
                                            <span className="lbl">Display</span>
                                            <div
                                                class="dropdwn"
                                                style={{
                                                    width: "70px",
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <PaginationDropdown
                                                    optionData={CommonData.COMMON.DISPLAY_ARR}
                                                    value={this.state.selectedDisplayData}
                                                    placeholder="Select"
                                                    onSelectChange={(value) => {
                                                        this.onChangeLimit(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : <React.Fragment>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="cus-filter-btn">
                                        <button className="button" onClick={this.openTable}>
                                            <img src={ImageName.IMAGE_NAME.GREY_HAMBURGER} style={{ width: "20px", marginTop: "15px" }} />
                                        </button>
                                        <button className="button" onClick={this.openCalender}>
                                            <img src={ImageName.IMAGE_NAME.BLUE_CALENDER} style={{ width: "20px", marginTop: "15px" }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </div>

                    {this.state.isCalender ? <React.Fragment>
                        <div className="table-listing-app">
                            <ViewCalender id={this.state.calenderData} detailClick={(value) => { this.detailJob(value) }} type="job" />
                        </div>
                    </React.Fragment> :
                        <React.Fragment>

                            <div
                                className="tab-app-information activeLnk"
                                id="tble-data-a"
                                hidden={!this.state.isAlljobs}
                            >
                                <div className="table-listing-app">
                                    <div className="table-responsive_cus table-style-a">
                                        <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "10%" }}>Job#</th>
                                                    <th style={{ width: "10%" }}>Job Type</th>
                                                    <th style={{ width: "10%" }}>Appointment Type</th>
                                                    <th style={{ width: "15%" }}>Date & Time</th>
                                                    <th style={{ width: "10%" }}>Duration</th>
                                                    <th style={{ width: "15%" }}>Location</th>
                                                    <th style={{ width: "20%" }}>
                                                        Language{" "}
                                                    </th>
                                                    <th style={{ width: "10%" }}>Status</th>
                                                </tr>
                                                {this.state.listData.length > 0 ? (<React.Fragment>
                                                    {this.state.listData.map((item, key) => (
                                                        <tr>
                                                            <td colspan="8">
                                                                <div className="tble-row">
                                                                    <table
                                                                        width="100%"
                                                                        border="0"
                                                                        cellpadding="0"
                                                                        cellspacing="0"
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ width: "10%" }}>
                                                                                    {item.jobId}
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>
                                                                                    {item.jobType}
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>
                                                                                    <div className="f2f">
                                                                                        {item.appointmentType}
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{ width: "15%" }}>
                                                                                    {item.scheduleDate}
                                                                                    {" | "}
                                                                                    {item.scheduleTime}
                                                                                </td>
                                                                                <td style={{ width: "10%" }}>
                                                                                    {item.duration}
                                                                                </td>
                                                                                <td style={{ width: "15%" }}>
                                                                                    {item.location === "" ? "N/A" : item.location}
                                                                                </td>
                                                                                <td style={{ width: "20%" }}>
                                                                                    {item.sourceLanguage} {">"}
                                                                                    {<br />}
                                                                                    {item.targetLanguage}
                                                                                </td>
                                                                                {/* <td style={{ width: "8%" }}></td> */}
                                                                                <td style={{ width: "10%" }}>
                                                                                    {item.status === 0 ? (
                                                                                        <React.Fragment>
                                                                                            <span className="progress-btn yellow">
                                                                                                Offer Sent
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 1 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn sky"
                                                                                            >
                                                                                                Bid Sent
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 2 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn green"
                                                                                            >
                                                                                                Assigned
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 3 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn sky"
                                                                                            >
                                                                                                In Progress
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 4 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn green"
                                                                                            >
                                                                                                Completed
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 5 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn red"
                                                                                            >
                                                                                                Offer Rejected
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 6 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn yellow"
                                                                                            >
                                                                                                Under Review
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 7 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn red"
                                                                                            >
                                                                                                Cancelled
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 8 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn green"
                                                                                            >
                                                                                                Assigned
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 9 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn yellow"
                                                                                            >
                                                                                                In Progress
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 10 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn green"
                                                                                            >
                                                                                                completed
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : item.status === 11 ? (
                                                                                        <React.Fragment>
                                                                                            <span
                                                                                                href="#"
                                                                                                className="progress-btn red"
                                                                                            >
                                                                                                cancelled
                                                                                            </span>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment></React.Fragment>
                                                                                    )}
                                                                                </td>
                                                                                {/* <td style={{ width: "4%" }}>
                                                                                    {item.status === 2 ? (
                                                                                        <React.Fragment>
                                                                                            <img
                                                                                                src={
                                                                                                    ImageName.IMAGE_NAME.MENU_VERTICAL
                                                                                                }
                                                                                                style={{ cursor: "pointer" }}
                                                                                                id="basic-button"
                                                                                                aria-controls="basic-menu"
                                                                                                aria-haspopup="true"
                                                                                                aria-expanded={
                                                                                                    open ? "true" : undefined
                                                                                                }
                                                                                                onClick={(e) =>
                                                                                                    this.menuBtnhandleClick(key, e)
                                                                                                }
                                                                                            />
                                                                                            <Menu
                                                                                                id="basic-menu"
                                                                                                anchorEl={this.state.anchorEl}
                                                                                                open={open}
                                                                                                onClose={this.handleMenuClose}
                                                                                                MenuListProps={{
                                                                                                    "aria-labelledby": "basic-button",
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem onClick={this.goEdit}>
                                                                                                    View Details
                                                                                                </MenuItem>
                                                                                            </Menu>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <img
                                                                                                src={
                                                                                                    ImageName.IMAGE_NAME.MENU_VERTICAL
                                                                                                }
                                                                                                style={{ cursor: "pointer" }}
                                                                                                id="basic-button"
                                                                                                aria-controls="basic-menu"
                                                                                                aria-haspopup="true"
                                                                                                aria-expanded={
                                                                                                    open1 ? "true" : undefined
                                                                                                }
                                                                                                onClick={(e) =>
                                                                                                    this.menuBtnhandleClick_b(key, e)
                                                                                                }
                                                                                            />
                                                                                            <Menu
                                                                                                id="basic-menu"
                                                                                                anchorEl={this.state.anchorEl1}
                                                                                                open={open1}
                                                                                                onClose={this.handleMenuClose}
                                                                                                MenuListProps={{
                                                                                                    "aria-labelledby": "basic-button",
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem onClick={this.goEdit}>
                                                                                                    View Details
                                                                                                </MenuItem>
                                                                                            </Menu>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </td> */}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>) : (<React.Fragment>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <td colSpan="11">
                                                            <center style={{ fontSize: "20px" }}>
                                                                No data found !!!
                                                            </center>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>)}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </React.Fragment>
                    }
                    {this.state.isCalender ? <React.Fragment></React.Fragment> : <React.Fragment>
                        <div className="table-filter-app">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="cus-filter-btn">
                                        <button className="button">
                                            <img src={ImageName.IMAGE_NAME.MENU_BTN} />
                                        </button>
                                        <button className="button">
                                            <img src={ImageName.IMAGE_NAME.MENU_BTN_TWO} />
                                        </button>

                                        <div className="filter-pagination">
                                            <button className="prev_btn" onClick={this.exLeft}></button>
                                            <button className="prv_btn" onClick={this.prev}>
                                                {" "}
                                                {"<"}
                                            </button>
                                            <span className="num" onChange={(e) => this.clickChange(e)}>
                                                {this.state.current_page}
                                            </span>
                                            <button className="nxt_btn" onClick={this.next}>
                                                {">"}
                                            </button>
                                            <button
                                                className="next_btn"
                                                onClick={this.exRigth}
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-filter-box">
                                        <div className="tble-short">
                                            {" "}
                                            <span className="lbl">Display</span>
                                            <div className="dropdwn">
                                                <div
                                                    class="dropdwn"
                                                    style={{
                                                        width: "70px",
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <PaginationDropdown
                                                        optionData={CommonData.COMMON.DISPLAY_ARR}
                                                        value={this.state.selectedDisplayData}
                                                        placeholder="Select"
                                                        onSelectChange={(value) => {
                                                            this.onChangeLimit(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}
