import { Paginator } from './interfaces/paginator';

interface PaginatorState {
  source: any;
  nextToken: any;
  prevToken: any;
  items: any;
}

/**
 * Pagination helper class.
 */
class RestPaginator<T> implements Paginator<T> {
  private state: PaginatorState;

  /**
   * Indicates the existence of the next page.
   */
  public get hasNextPage(): boolean { return !!this.state.nextToken; }

  /**
   * Indicates the existence of the previous page
   */
  public get hasPrevPage(): boolean { return !!this.state.prevToken; }

  /**
   * Array of elements on the current page.
   */
  public get items(): Array<T> { return this.state.items; }

  /**
   * @internal
   */
  constructor(items, source, prevToken, nextToken) {
    this.state = {
      prevToken,
      nextToken,
      source,
      items
    };
  }

  /**
   * Request the next page. Does not modify the existing object.
   */
  public async nextPage(): Promise<RestPaginator<T>> {
    return this.hasNextPage ? this.state.source(this.state.nextToken) : Promise.reject(new Error('No next page'));
  }

  /**
   * Request the previous page. Does not modify the existing object.
   */
  public async prevPage(): Promise<RestPaginator<T>> {
    return this.hasPrevPage ? this.state.source(this.state.prevToken) : Promise.reject(new Error('No previous page'));
  }
}

export { RestPaginator };
