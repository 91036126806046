// import history from './history';
import axios from "axios";
import moment from "moment";
import { Decoder } from "../auth";
import { PROD } from "../config/api_url";
import { ErrorCode } from "../constant";
import { ApiCall } from "../middleware";

// const sessionCheck =() =>{
//     var date = new Date();
//     var time = date.getTime();
//     // console.log(time);
//     var sessionTime = localStorage.getItem('sessionTime');
//     //console.log(sessionTime);
//     if(time<sessionTime){
//         localStorage.setItem("sessionTime",time+(3600*1000));

//         //console.log("in");
//     } else {
//         //console.log("out");
//         localStorage.clear();
//         return history.push('/');
//     }
// }

const consoleLog = (str, val) => {
  let con = "";
  if (str === undefined || str === null || str === "") {
    con = "Console>>>";
  } else {
    con = str;
  }

  if (!PROD) {
    return console.log(con + ">>>", val);
  }
};

const phoneNumberCheck = (number) => {
  if (number.length >= 3) {
    return number;
  }
};

const SetDateFormat = (value) => {
  let day = moment(value).format("DD");
  let Month = moment(value).format("MMMM");
  let Year = moment(value).format("YYYY");

  let finalDate = day + " " + Month + " " + Year;
  return finalDate;
};

const SetTimeFormat = (value) => {
  let time = moment(value).format("LT");
  return time;
};

const getLookUpDataFromAPI = async () => {
  let lookupres = await ApiCall("getLookUpData");
  if (
    lookupres.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    lookupres.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  ) {
    let payload = Decoder.decode(lookupres.data.payload);
    return payload.data.lookupdata;
  }
};

const getCountryList = async () => {
  let res = await ApiCall("getcountrylist");
  if (
    res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  ) {
    let payload = Decoder.decode(res.data.payload);
    return payload.data.countryInfo;
  }
};

const SetRequestViewTimeFormat = (value) => {
  let time = moment(value).format("lll");
  return time;
};
const SetDOBFormat = (value) => {
  let day = moment(value).format("DD");
  let Month = moment(value).format("MM");
  let Year = moment(value).format("YYYY");

  let finalDate = Year + "-" + Month + "-" + day;
  return finalDate;
};

const textTruncate = (str, length, ending) => {
  if (str !== null) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  } else {
    return "---";
  }
};

const getClientInfo = async () => {
  let clientinfoRes = await ApiCall("getallclinetinfo");
  if (
    clientinfoRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    clientinfoRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  ) {
    let clientPayload = Decoder.decode(clientinfoRes.data.payload);
    let clientResData = clientPayload.data.clientlist;
    // console.log("Client payload::::::::::", clientResData);
    let clientDataArr = [];
    for (let i = 0; i < clientResData.length; i++) {
      clientDataArr.push({
        label: clientResData[i].clientName,
        value: clientResData[i].clientid,
        adminName: clientResData[i].clientAdminName
      });
    }
    return clientDataArr;
  }
};

const getVendorInfo = async () => {
  let vendorinfoRes = await ApiCall("allVendorList");
  if (
    vendorinfoRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
    vendorinfoRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  ) {
    let vendorPayload = Decoder.decode(vendorinfoRes.data.payload);
    let vendorResData = vendorPayload.data.list;
    // console.log("Client payload::::::::::", clientResData);
    let vendorDataArr = [];
    for (let i = 0; i < vendorResData.length; i++) {
      vendorDataArr.push({
        label: vendorResData[i].name,
        value: vendorResData[i].userId,
      });
    }
    return vendorDataArr;
  }
};

const getLanguageArray = async () => {
  let languageResData = await ApiCall("getlanguagelist");
  let languagePayload = Decoder.decode(languageResData.data.payload);
  let languageResArrData = languagePayload.data.languagelist;
  // console.log("language Arr>>>", this.state.languageArr);
  let languageArrData = [];
  for (let n = 0; n < languageResArrData.length; n++) {
    languageArrData.push({
      label: languageResArrData[n].language,
      value: languageResArrData[n].id,
    });
  }
  return languageArrData;
};

const SetTimeMinSecFormat = (value) => {
  let hh = moment(value).format("HH");
  let mn = moment(value).format("mm");
  let ss = moment(value).format("ss");
  let time = hh + ":" + mn + ":" + ss;
  return time;
};

const SetScheduleDate = (value) => {
  let day = moment(value).format("DD");
  let Month = moment(value).format("MM");
  let Year = moment(value).format("YYYY");
  let finalDate = Year + "-" + Month + "-" + day;
  return finalDate;
};

const decimalValue = (text) => {
  const validated = text.match(/^(\d*\.{0,1}\d{0,3}$)/);
  return validated;
};

const SetUSAdateFormat = (value) => {
  // consoleLog("usa Dtae Format Value::", value);
  // let date = new Date(value);
  // consoleLog("new date::", value);
  // let aa = date.getDate();
  // aa = aa <= 9 ? "0" + aa : aa;
  // let bb = date.getMonth() + 1;
  // bb = bb <= 9 ? "0" + bb : bb;
  // let cc = date.getFullYear();
  // consoleLog("Full new USA DAte ::", bb + "-" + aa + "-" + cc)

  let day = moment(value).format("DD");
  let Month = moment(value).format("MM");
  let Year = moment(value).format("YYYY");

  let finalDate = Month + "-" + day + "-" + Year;
  return finalDate;
};

const SetDueDate = (value) => {
  var currentDate = moment(value);
  var futureMonth = moment(currentDate).add(1, "M");
  var futureMonthEnd = moment(futureMonth).endOf("month");

  if (
    currentDate.date() != futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
  ) {
    futureMonth = futureMonth.add(1, "d");
  }
  return futureMonth;
};

const SetDatabaseDateFormat = (value) => {
  // consoleLog("Date::", value)
  let day = moment(value, "MM-DD-YYYY").format("DD");
  let Month = moment(value, "MM-DD-YYYY").format("MM");
  let Year = moment(value, "MM-DD-YYYY").format("YYYY");

  let finalDate = Year + "-" + Month + "-" + day;
  return finalDate;
  // ====================================================
  // var dt = new Date(value);
  // var day = dt.getDate();
  // day = day <= 9 ? "0" + day : day;
  // var month = dt.getMonth() + 1;
  // month = month <= 9 ? "0" + month : month;
  // var year = dt.getFullYear();

  // var databaseDate = year + "-" + month + "-" + day;
  // return databaseDate;
};

const SetUSAdateFormatV2 = (value) => {
  consoleLog("usa Dtae Format Value::", value);
  let date = new Date(value);
  // consoleLog("new date::", value);
  let aa = date.getDate();
  aa = aa <= 9 ? "0" + aa : aa;
  let bb = date.getMonth() + 1;
  bb = bb <= 9 ? "0" + bb : bb;
  let cc = date.getFullYear();
  consoleLog("Full new USA DAte ::", bb + "-" + aa + "-" + cc)

  let finalDate = bb + "-" + aa + "-" + cc;
  return finalDate;
};

const SetNextMonthDate = (value) => {
  // console.log("Request Date Value >>> ", value);
  // let date = new Date(value);
  // let day = date.getDate();
  // day = day <= 9 ? "0" + day : day;
  // let month = date.getMonth() + 2;
  // month = month <= 9 ? "0" + month : month;
  // let year = date.getFullYear();

  // let fullDate = year + "-" + month + "-" + day;
  // console.log("Full Date >>> ", fullDate)
  // return fullDate;

  // Get the current datetime
  var currentDatetime = new Date(value);

  // Calculate the next 30-day date
  var next30DaysDate = new Date(currentDatetime);
  next30DaysDate.setDate(currentDatetime.getDate() + 30);

  // Check if the next date exceeds the current month's last day
  if (next30DaysDate.getMonth() !== currentDatetime.getMonth() + 1) {
    // Set the next date to the last day of the current month
    next30DaysDate = new Date(currentDatetime.getFullYear(), currentDatetime.getMonth() + 1, 0);
  }

  // Format the next 30-day date as a string (YYYY-MM-DD)
  var next30DaysDateString = next30DaysDate.toISOString().split('T')[0];

  // console.log(next30DaysDateString);

  return next30DaysDateString;
}

const getCurrentMonthDate = () => {
  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  // var lastDay = new Date(y, m + 1, 0);
  // console.log("First Date >>> ", firstDay, " >>> Last Date >>>> ", lastDay);
  let day1 = SetUSAdateFormatV2(firstDay);
  let dayCurr = SetUSAdateFormatV2(new Date());
  let str = {
    firstDate: day1,
    currDate: dayCurr
  }
  return str;

}

const isDateDifferenceLessThanOrEqualTo14Days = (date2) => {
  // Convert USA format dates to JavaScript Date objects
  var jsDate1 = new Date();
  var jsDate2 = new Date(date2);

  // console.log("JSDAte 1>>>>>>>>>>>>>>> ", jsDate1);
  // console.log("JS Date 2 >>>>>>>>>>>>>>>>>.  ", jsDate2);

  // Calculate the difference in milliseconds
  var differenceInMilliseconds = Math.abs(jsDate2 - jsDate1);
  // consoleLog("difference In Milliseconds>>>>>>>>>>>>>>>>>>>>  " , differenceInMilliseconds)

  // Convert milliseconds to days
  var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  // consoleLog("difference In Days>>>>>>>>>>>>>>>>>>>>  " , differenceInDays)

  // Check if the difference is less than or equal to 14 days
  // console.log("Date difference >>> ", differenceInDays)
  return differenceInDays <= 14;
}

const getTimeZoneOffset = () => {
  const d = new Date();

  let diff = d.getTimezoneOffset();
  // consoleLog("Time Zone Offset >>>> ", diff)
  return diff;
}

function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

const getDateWithMonthName = (value) => {

  const inputDateString = value;
  const inputDate = new Date(inputDateString);
  let day = inputDate.getDate();
  day = day > 9 ? day : "0" + day;
  const month = inputDate.getMonth();
  const year = inputDate.getFullYear();

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const formattedDate = `${day} ${monthNames[month]} ${year}`;
  return formattedDate;
  // console.log(formattedDate); // Output: "27th February 2023"
}

const getUtcDateWithMonthName = (value) => {

  const inputDateString = value;
  const inputDate = new Date(inputDateString);
  let day = inputDate.getUTCDate();
  day = day > 9 ? day : "0" + day;
  const month = inputDate.getUTCMonth();
  const year = inputDate.getUTCFullYear();

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const formattedDate = `${day} ${monthNames[month]} ${year}`;
  return formattedDate;
  // console.log(formattedDate); // Output: "27th February 2023"
}

const convertTo12HourFormat = (time24) => {
  let [hours, minutes, seconds] = time24.split(':').map(Number);

  let period = 'AM';

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      hours -= 12;
    }
  }

  // Ensure leading zero for single-digit hours
  let formattedHours = hours < 10 ? '0' + hours : hours;

  // Ensure leading zero for single-digit minutes
  let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

const set12hourTimeFormatUtc = (value) => {
  const utcTimestamp = value;
  // console.log("Current Time Zone >>>> ", Intl.DateTimeFormat().resolvedOptions().timeZone)
  const dateUTC = new Date(utcTimestamp);
  const options = {
    timeZone: "UTC", // Replace with the desired time zone
    hour12: true,
    hour: "2-digit",
    minute: "2-digit"
  };

  const formattedTime = dateUTC.toLocaleTimeString("en-US", options);

  // console.log("Converted time in 12-hour format:", formattedTime);

  // console.log("Current Time >>>> ", dateUTC.getUTCHours());
  return formattedTime;
}

const set12hourTimeFormatLocal = (value) => {
  const utcTimestamp = value;
  // console.log("Current Time Zone >>>> ", Intl.DateTimeFormat().resolvedOptions().timeZone)
  const dateUTC = new Date(utcTimestamp);
  const options = {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit"
  };

  const formattedTime = dateUTC.toLocaleTimeString("en-US", options);

  // console.log("Converted time in 12-hour format:", formattedTime);

  // console.log("Current Time >>>> ", dateUTC.getUTCHours());
  return formattedTime;
}

/* get local time */
const getLocalTime = (value) => {
  const utcTimestamp = value;
  const dateUTC = new Date(utcTimestamp);
  const options = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  const localTime = dateUTC.toLocaleTimeString('en-US', options);
  return localTime;
}

const getCallDurationData = (value) => {
  let duration = value.split(" ");
  let durationVal = Number(duration[0]) === 0 ? "1" : duration[0];
  let durationString = durationVal + " " + duration[1];
  return durationString;
}

const SetDBFormat = (value) => {
  const d = new Date(value);
  let day = d.getDate();
  day = day > 9 ? day : "0" + day;
  let month = d.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  let year = d.getFullYear();
  let str = year + "-" + month + "-" + day;
  return str;
}
export {
  // sessionCheck,
  phoneNumberCheck,
  SetDateFormat,
  SetTimeFormat,
  getLookUpDataFromAPI,
  getCountryList,
  SetRequestViewTimeFormat,
  SetDOBFormat,
  textTruncate,
  getClientInfo,
  getLanguageArray,
  SetTimeMinSecFormat,
  consoleLog,
  SetScheduleDate,
  decimalValue,
  SetUSAdateFormat,
  SetDueDate,
  SetDatabaseDateFormat,
  SetUSAdateFormatV2,
  getVendorInfo,
  SetNextMonthDate,
  getCurrentMonthDate,
  isDateDifferenceLessThanOrEqualTo14Days,
  getTimeZoneOffset,
  getDateWithMonthName,
  convertTo12HourFormat,
  set12hourTimeFormatUtc,
  getUtcDateWithMonthName,
  getCallDurationData,
  SetDBFormat,
  getLocalTime,
  set12hourTimeFormatLocal
};
