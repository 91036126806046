import { Menu, MenuItem } from "@mui/material";
import React, { Component } from "react";
import { AlertMessage, ImageName } from "../../../../enums";
import { Decoder } from "../../../../services/auth";
import { CommonData, ErrorCode } from "../../../../services/constant";
import { ApiCall } from "../../../../services/middleware";
import "../InternalProjectList/InternalProjectList.css";
import {
    InputText,
    MultiSelectBox,
    PaginationDropdown,
    SelectBox,
} from "../../SharedComponents/inputText";
import Select, { components } from "react-select";
import $ from "jquery";
import {
    consoleLog,
    SetDatabaseDateFormat,
    SetDateFormat,
    SetDOBFormat,
    SetUSAdateFormat,
} from "../../../../services/common-function";
import { toast, ToastContainer } from "react-toastify";
import { inputEmptyValidate } from "../../../../validators";
import ViewCalender from "../../../ReactBigCalender/ViewCalender";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import moment from "moment";
import { styled } from "@mui/system"; //imported for modal
import LotteLoader from "../../../Loader/LotteLoader";
import { IMAGE_PATH_ONLY } from "../../../../services/config/api_url";

const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
        borderRadius: "6px",
        height: 50,
        minHeight: 50,
        textAlign: "center",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = "yellow";
        return {
            ...styles,
            color: isFocused ? "grey" : "white",
            color: "#000",
            cursor: isDisabled ? "not-allowed" : "default",
        };
    },
};
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <img
                src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
                style={{ width: "17px" }}
            />
        </components.DropdownIndicator>
    );
};

const cancelationArr = [
    {
        label: "Last minute reschedule ",
        value: 1,
    },
    {
        label: "Duplicate/Error ",
        value: 2,
    },
    {
        label: "Consumer No Show",
        value: 3,
    },
    {
        label: "Interpreter No Show",
        value: 4,
    },
    {
        label: "Other Service being utilized",
        value: 5,
    },
    {
        label: "Other ",
        value: 6,
    },
];

const allStatusArr = [
    {
        label: "Open",
        value: "0",
    },
    {
        label: "Closed",
        value: "1",
    }
];

const reqData = {
    limit: "",
    offset: "",
    searchfrom: "",
    searchto: "",
    searchText: ""
};

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 10,
        marginTop: 5,
        minWidth: 100,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
    },
}));

export default class InternalProjectAdminStaff extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoad: true,
            isAlljobs: true,
            isHistory: false,
            current_page: 1,
            total_page: 10,
            limit: 20,
            offset: 0,
            // ..............
            current_page_history: 1,
            total_page_history: 10,
            limit_history: 20,
            offset_history: 0,
            anchorEl: null, //menu button
            anchorEl1: null,
            cancellationData: {},
            isSelected: "",
            clientArr: [],
            clientData: {},
            appointmentTypeArr: [],
            appointmentTypeData: {},
            targetLangData: [],
            sourceLangData: [],
            sourceLangId: [],
            targetLangId: [],
            languageArr: [],
            statusArr: [],
            statusData: {},
            leiArr: [],
            leiData: {},
            industryArr: [],
            industryData: {},
            otherReason: "",
            formDate: "",
            toDate: "",
            selectedDisplayData: {
                label: "20",
                value: "20",
            },
            selectedDisplayData_history: {
                label: "20",
                value: "20",
            },
            listData: [],
            historyList: [],

            appointmentDate: "",
            hour: "08",
            min: "00",
            ampm: "AM",
            hour_fiter: "08",
            min_filter: "00",
            ampm_filter: "AM",
            isOtherReason: false,
            rescheduleNote: "",
            isCalender: false,
            calenderData: [],

            id: "",

            //   for task modal,,,,,,,,,,,,,,,,,
            taskId: "",
            mileStone: "",
            task: "",
            taskDescription: "",
            owner: "",
            taskStartDate: "",
            taskDueDate: "",
            selectedTaskStatus: {},
            notesByAdmin: "",
            addTaskNote: "",
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.load();

        var classInstance = this;
        // When the user clicks anywhere outside of the modal, close it

        document.getElementById("backdrop").style.display = "none";
        var filterModal = document.getElementById("filter-model");
        window.addEventListener("click", function (event) {
            if (event.target == filterModal) {
                classInstance.closeModal();
            }
        });

        window.$(".verificaiton-doc-tab ul li").on("click", function () {
            $(".verificaiton-doc-tab ul li").removeClass("active");
            $(this).addClass("active");
            $("div").removeClass("activeLnk");
            $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk");
        });
        // this.load();
        window.$(".filterTab ul li").on("click", function () {
            $(".filterTab ul li").removeClass("active");
            $(this).addClass("active");
            $("div").removeClass("activeLnk1");
            $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk1");
        });
    }

    load = () => {

        // ....................For List Data..........................................

        let fetchData = {
            limit: JSON.stringify(this.state.limit),
            offset: JSON.stringify((this.state.current_page - 1) * this.state.limit),
            searchfrom: "",
            searchto: "",
            tabType: "0"
        };

        let returnData = Object.assign(reqData, fetchData);
        this.listApi(returnData);
    };

    //...... For All jobs listing.............
    listApi = async (data) => {
        this.setState({
            isLoad: true
        })
        const res = await ApiCall("internalTasklist", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            const decodeData = Decoder.decode(res.data.payload);
            consoleLog("Internal Task List:::", decodeData.data);
            let listDetails = [];
            if (decodeData.data.projectList.length > 0) {
                listDetails = decodeData.data.projectList;
            }
            let totalPage = Math.ceil(decodeData.data.count / parseInt(this.state.limit));
            this.setState({
                listData: listDetails,
                total_page: totalPage,
                isLoad: false
            });
        }

        //..............for history interpretation listing...............
    };
    listApi_history = async (data) => {
        this.setState({
            isLoad: true
        })
        const resHistory = await ApiCall("internalTasklist", data);
        if (
            resHistory.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            resHistory.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            const decodeData1 = Decoder.decode(resHistory.data.payload);
            // consoleLog("Payload history data>>>", decodeData1);
            let historyDetails = [];
            if (decodeData1.data.projectList.length > 0) {
                historyDetails = decodeData1.data.projectList;
            }
            let totalPage1 = Math.ceil(
                decodeData1.data.count / parseInt(this.state.limit_history)
            );
            this.setState({
                historyList: historyDetails,
                total_page_history: totalPage1,
                isLoad: false
            });
        }
    };

    // .............filter modal function...................
    onOtherReasonChange = (e) => {
        this.setState({
            otherReason: e.target.value,
        });
    };
    //........Page show Limit.........

    onChangeLimit = (value) => {
        if (this.state.isAlljobs) {
            this.setState({
                limit: parseInt(value.value),
                selectedDisplayData: value,
                current_page: 1,
            });

            let limit = value.value;

            let data = {
                limit: limit,
                offset: "0",
                tabType: "0",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, data);

            this.listApi(returnData);
        } else if (this.state.isHistory) {
            this.setState({
                limit_history: parseInt(value.value),
                selectedDisplayData_history: value,
                current_page_history: 1,
            });

            let limit = value.value;

            let data = {
                limit: limit,
                offset: "0",
                tabType: "1",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, data);

            this.listApi_history(returnData);
        }
    };

    //........... Export File...............

    onExport = async () => {
        let data = {};
        let res = await ApiCall("exportInternalTasks", data);
        let decodeData = Decoder.decode(res.data.payload);
        consoleLog("File of export File:", decodeData)
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            // window.open(decodeData.data.fileUrl, "_blank");
            var file_path = decodeData.data.fileUrl;
            var a = document.createElement('a');
            a.href = file_path;
            a.download = "Internal_project_List";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    openModal = () => {
        document.getElementById("backdrop").style.display = "block";
        document.getElementById("filter-model").style.display = "block";
        document.getElementById("filter-model").classList.add("show");
    };
    closeModal = () => {
        document.getElementById("backdrop").style.display = "none";
        document.getElementById("filter-model").style.display = "none";
        document.getElementById("filter-model").classList.remove("show");
    };

    closeFilterModal = () => {
        this.closeModal();
    };

    filterModal = () => {
        this.openModal();
        this.handleMenuClose();
    };
    declineModal = () => {
        this.openDeclineModal();
        this.handleMenuClose();
    };
    historyModal = () => {
        this.openHistoryModal();
        this.handleMenuClose();
    };
    declineClose = () => {
        this.resetDeclineData();
        this.closeDeleteModal();
    };

    formDateChange = (date) => {
        this.setState({
            formDate: SetUSAdateFormat(date),
        });
    };

    toDateChange = (date) => {
        this.setState({
            toDate: SetUSAdateFormat(date),
        });
    };
    //................funct for menuBtn on click................
    menuBtnhandleClick = (index, event) => {
        this.setState({
            curIndex: index,
            anchorEl: event.currentTarget,
        });
    };
    menuBtnhandleClick_b = (index, event) => {
        this.setState({
            curIndex: index,
            anchorEl1: event.currentTarget,
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            anchorEl1: null,
        });
    };

    // .............pagination function..........

    clickChange = (e) => {
        this.setState({
            current_page: e.target.value,
        });
    };

    // This is goes to the previous page
    exLeft = () => {
        if (this.state.isAlljobs) {
            this.setState({
                current_page: 1,
                offset: "0",
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: "0",
                tabType: "0",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);
        } else if (this.state.isHistory) {
            this.setState({
                current_page_history: 1,
                offset: "0",
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit_history),
                offset: "0",
                tabType: "1",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi_history(returnData);
        }
    };

    // This is goes to the last page
    exRigth = () => {
        if (this.state.isAlljobs) {
            let totalPage = this.state.total_page;
            this.setState({
                current_page: totalPage,
                offset: JSON.stringify((totalPage - 1) * this.state.limit),
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: JSON.stringify((totalPage - 1) * this.state.limit),
                tabType: "0",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);
        } else if (this.state.isHistory) {
            let totalPage = this.state.total_page_history;
            this.setState({
                current_page_history: totalPage,
            });
            let fetchData = {
                limit: JSON.stringify(this.state.limit_history),
                offset: JSON.stringify((totalPage - 1) * this.state.limit_history),
                tabType: "1",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi_history(returnData);
        }
    };

    // This is goes to the first page
    prev = () => {
        if (this.state.isAlljobs) {
            let currentPage = this.state.current_page;
            if (currentPage > 1) {
                currentPage--;
                this.setState({
                    current_page: currentPage,
                    offset: JSON.stringify((currentPage - 1) * this.state.limit),
                });
                let fetchData = {
                    limit: JSON.stringify(this.state.limit),
                    offset: JSON.stringify((currentPage - 1) * this.state.limit),
                    tabType: "0",
                    searchfrom: "",
                    searchto: "",
                };
                let returnData = Object.assign(reqData, fetchData);
                this.listApi(returnData);
            }
        } else if (this.state.isHistory) {
            let currentPage = this.state.current_page_history;
            if (currentPage > 1) {
                currentPage--;
                this.setState({
                    current_page_history: currentPage,
                });
                let fetchData = {
                    limit: JSON.stringify(this.state.limit_history),
                    offset: JSON.stringify((currentPage - 1) * this.state.limit_history),
                    tabType: "1",
                    searchfrom: "",
                    searchto: "",
                };
                let returnData = Object.assign(reqData, fetchData);
                this.listApi_history(returnData);
            }
        }
    };

    // This is goes to the next page
    next = () => {
        if (this.state.isAlljobs) {
            let currentPage = this.state.current_page;
            let totalPage = this.state.total_page;
            if (currentPage < totalPage) {
                currentPage++;
                this.setState({
                    current_page: currentPage,
                    offset: JSON.stringify((currentPage - 1) * this.state.limit),
                });
                let fetchData = {
                    limit: JSON.stringify(this.state.limit),
                    offset: JSON.stringify((currentPage - 1) * this.state.limit),
                    tabType: "0",
                    searchfrom: "",
                    searchto: "",
                };
                let returnData = Object.assign(reqData, fetchData);
                this.listApi(returnData);
            }
        } else if (this.state.isHistory) {
            let currentPage = this.state.current_page_history;
            let totalPage = this.state.total_page_history;
            if (currentPage < totalPage) {
                currentPage++;
                this.setState({
                    current_page_history: currentPage,
                });
                let fetchData = {
                    limit: JSON.stringify(this.state.limit_history),
                    offset: JSON.stringify((currentPage - 1) * this.state.limit_history),
                    tabType: "1",
                    searchfrom: "",
                    searchto: "",
                };
                let returnData = Object.assign(reqData, fetchData);

                this.listApi_history(returnData);
            }
        }
    };

    onFilterApply = () => {
        if (this.state.isAlljobs) {
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: "0",
                tabType: "0",
                searchfrom: this.state.formDate!== "" ? SetDatabaseDateFormat(this.state.formDate) : "",
                searchto: this.state.toDate!== "" ? SetDatabaseDateFormat(this.state.toDate) : ""
            };

            let returnData = Object.assign(reqData, fetchData);
            this.listApi(returnData);

            this.closeFilterModal();

            this.setState({
                current_page: 1,
            });
        } else {
            let fetchData = {
                limit: JSON.stringify(this.state.limit_history),
                offset: "0",
                tabType: "1",
                searchfrom: this.state.formDate!== "" ? SetDatabaseDateFormat(this.state.formDate) : "",
                searchto: this.state.toDate!== "" ? SetDatabaseDateFormat(this.state.toDate) : ""
            };

            // consoleLog("req filter data::", fetchData);
            let returnData = Object.assign(reqData, fetchData);
            this.listApi_history(returnData);

            this.closeFilterModal();

            this.setState({
                current_page_history: 1,
            });
        }
    };

    onResetFilter = () => {
        if (this.state.isAlljobs) {
            this.resetData();
            this.setState({
                current_page: 1,
            });

            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: "0",
                tabType: "0",
                searchfrom: "",
                searchto: "",
            };

            // consoleLog("req filter data::", fetchData);
            this.listApi(fetchData);
        } else {
            this.resetData();
            this.setState({
                current_page: 1,
            });

            let fetchData = {
                limit: JSON.stringify(this.state.limit_history),
                offset: "0",
                tabType: "1",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, fetchData);
            this.listApi_history(returnData);
        }

        this.closeFilterModal();
    };

    resetData = () => {
        this.setState({
            formDate: "",
            toDate: "",
        });
    };


    onTabClick = (value) => {
        if (value === "alljobs") {
            this.resetData();
            this.setState({
                // interpretationModal: true,
                // translationModal: false,
                // trainingModal: false,
                isAlljobs: true,
                isHistory: false,
                // isTraining: false,
            });

            let res = {
                limit: this.state.limit,
                offset: "0",
                tabType: "0",
                searchfrom: "",
                searchto: "",
            };
            let returnData = Object.assign(reqData, res);
            this.listApi(returnData);
        } else if (value === "history") {
            this.resetData();
            this.setState({
                isAlljobs: false,
                isHistory: true,
            });
            let res = {
                limit: this.state.limit_history,
                offset: "0",
                searchfrom: "",
                searchto: "",
                tabType: "1",
            };
            let returnData = Object.assign(reqData, res);
            this.listApi_history(returnData);
        }
    };


    goEdit = (id) => {
        if (this.state.isAlljobs) {
            this.props.history.push({
                pathname: "/internalTaskDetails",
                state: this.state.listData[this.state.curIndex].pId,
                taskId : this.state.listData[this.state.curIndex].id,
                type: id
            });
        } else if (this.state.isHistory) {
            // consoleLog("History list edit project Id:", this.state.historyList[this.state.curIndex].pId)
            this.props.history.push({
                pathname: "/internalTaskDetails",
                state: this.state.historyList[this.state.curIndex].pId,
                taskId : this.state.historyList[this.state.curIndex].id,
                type: id
            });

        }
    };

    viewTaskDetails = (id) => {
        if (this.state.isAlljobs) {
            this.props.history.push({
                pathname: "/internalTaskDetails",
                state: this.state.listData[this.state.curIndex].pId,
                taskId : this.state.listData[this.state.curIndex].id,
                type: id
            });
        } else if (this.state.isHistory) {
            this.props.history.push({
                pathname: "/internalTaskDetails",
                state: this.state.historyList[this.state.curIndex].pId,
                taskId : this.state.historyList[this.state.curIndex].id,
                type: id
            });

        }

    }

    openTable = () => {
        this.setState({
            isCalender: false,
        });
    };

    openCalender = async () => {
        let reqData = {
            status: "",
            clientId: "",
            serviceType: "",
            orderby: "",
            direc: "",
            searchFrom: "",
            searchTo: "",
            rfqId: "",
        };
        //...... For All jobs listing for calender.............
        const res = await ApiCall("getApprovedInterpretationRFQList", reqData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            const decodeData = Decoder.decode(res.data.payload);
            let listDetails = [];
            if (decodeData.data.projectList.length > 0) {
                listDetails = decodeData.data.projectList;
            }
            // consoleLog("CalenderDAta : ", listDetails)
            this.setState({
                calenderData: listDetails,
                isCalender: true,
            });
        }
    };

    detailJob = (value) => {
        // consoleLog("Calender Click", value);
        this.setState({
            curIndex: value.curIndex,
        });

        this.props.history.push({
            pathname: "/adminJobDetails",
            state: this.state.calenderData[value.curIndex].id,
        });
    };

    resetDeclineData = () => {
        this.setState({
            rescheduleNote: "",
            appointmentDate: "",
            cancellationData: {},
            otherReason: "",
            hour: "08",
            min: "00",
            ampm: "AM",
        });
    };

    onProjectIdChange = (val) => {
        if (this.state.isAlljobs) {
            let fetchData = {
                limit: JSON.stringify(this.state.limit),
                offset: JSON.stringify(
                    (this.state.current_page - 1) * this.state.limit
                ),
                tabType: "0",
                searchfrom: "",
                searchto: "",
                searchText: val
            };

            this.listApi(fetchData);
        } else {
            let fetchData = {
                limit: JSON.stringify(this.state.limit_history),
                offset: JSON.stringify(
                    (this.state.current_page_history - 1) * this.state.limit_history
                ),
                searchfrom: "",
                searchto: "",
                searchText: val
            };

            this.listApi_history(fetchData);
        }

        this.setState({
            id: val,
        });
    };

    render() {
        const open = Boolean(this.state.anchorEl); //used in MenuButton open
        const open1 = Boolean(this.state.anchorEl1);
        const customStylesStatus = {
            control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                borderRadius: "10px",
                height: 38,
                minHeight: 38,
                width: 80,
                fontSize: 13
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = "yellow";
                return {
                    ...styles,
                    color: isFocused ? "grey" : "white",
                    color: "#000",
                    fontSize: 13,
                    cursor: isDisabled ? "not-allowed" : "default",
                };
            },
        };
        return (
            <React.Fragment>
                <ToastContainer hideProgressBar theme="colored" />
                <div className="component-wrapper vewaljobs">
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> / Internal Projects
                    </div>
                    <div className="vendor-info p-10 _fl sdw">
                        <div className="vn-form _fl">
                            <div className="row">
                                {/* <div className="col-md-3">
                                    <div className="vn_frm">
                                        <span
                                        // style={{
                                        //     width: "30%",
                                        //     paddingLeft: "5px",
                                        //     fontSize: "14px",
                                        // }}
                                        >
                                            Task Title
                                        </span>
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.id}
                                            onTextChange={(value) => {
                                                this.onProjectIdChange(value);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="vn_frm">
                                        <span
                                        >
                                            Task Id
                                        </span>
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.id}
                                            onTextChange={(value) => {
                                                this.onProjectIdChange(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="vn_frm">
                                        <span
                                        // style={{
                                        //     width: "30%",
                                        //     paddingLeft: "5px",
                                        //     fontSize: "14px",
                                        // }}
                                        >
                                            Project Title
                                        </span>
                                        <InputText
                                            placeholder="Search"
                                            className="inputfield"
                                            value={this.state.id}
                                            onTextChange={(value) => {
                                                this.onProjectIdChange(value);
                                            }}
                                        />
                                    </div>
                                </div> */}
                                <div className="col-md-8"></div>

                                <div className="col-md-4">
                                    <div className="vn_frm">
                                        <span></span>
                                        <InputText
                                            placeholder="Search by Task Id & Task Name"
                                            className="inputfield"
                                            value={this.state.id}
                                            onTextChange={(value) => {
                                                this.onProjectIdChange(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: "25px" }}>
                                <div className="col-md-4">
                                    <div className="_fl verificaiton-doc-tab ven">
                                        <ul>
                                            <li
                                                className="active"
                                                data-related="tble-data-a"
                                                onClick={() => {
                                                    this.onTabClick("alljobs");
                                                }}
                                            >
                                                All Projects
                                            </li>
                                            <li
                                                data-related="tble-data-b"
                                                onClick={() => {
                                                    this.onTabClick("history");
                                                }}
                                            >
                                                History
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-filter-app">
                        {!this.state.isCalender ? (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="cus-filter-btn" style={{ alignItems: "center" }}>
                                            {/* <button className="button">
                                                <img
                                                    src={ImageName.IMAGE_NAME.MENU_BTN}
                                                    onClick={this.openTable}
                                                />
                                            </button> */}
                                            {/* <button className="button">
                                                <img
                                                    src={ImageName.IMAGE_NAME.MENU_BTN_TWO}
                                                    onClick={this.openCalender}
                                                />
                                            </button> */}

                                            <div
                                                class="filter-btn"
                                                style={{ float: "none", paddingLeft: "10px" }}
                                            >
                                                <a href="#" onClick={this.filterModal}>
                                                    Filter
                                                </a>
                                            </div>

                                            <div className="filter-pagination">
                                                <button
                                                    className="prev_btn"
                                                    onClick={this.exLeft}
                                                ></button>
                                                <button className="prv_btn" onClick={this.prev}>
                                                    {" "}
                                                    {"<"}
                                                </button>
                                                <span
                                                    className="num"
                                                    onChange={(e) => this.clickChange(e)}
                                                >
                                                    {this.state.isAlljobs
                                                        ? this.state.current_page
                                                        : this.state.current_page_history}
                                                </span>
                                                <button className="nxt_btn" onClick={this.next}>
                                                    {">"}
                                                </button>
                                                <button
                                                    className="next_btn"
                                                    onClick={this.exRigth}
                                                ></button>
                                            </div>
                                            {/* <div className="statusTxt">Status</div>
                                            <div>
                                                <Select
                                                    components={{
                                                        DropdownIndicator,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    styles={customStylesStatus}
                                                    options={allStatusArr}
                                                ></Select>

                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="table-filter-box">
                                            <div className="export-btn" onClick={this.onExport}>
                                                <a href="javascript:void(0)">Export</a>
                                            </div>
                                            {/* <div className="addnew">
                    <a href="#">Add New</a>
                  </div> */}
                                            <div className="tble-short">
                                                {" "}
                                                <span className="lbl">Display</span>
                                                <div
                                                    class="dropdwn"
                                                    style={{
                                                        width: "70px",
                                                        fontSize: "12px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <PaginationDropdown
                                                        optionData={CommonData.COMMON.DISPLAY_ARR}
                                                        value={
                                                            this.state.isAlljobs
                                                                ? this.state.selectedDisplayData
                                                                : this.state.selectedDisplayData_history
                                                        }
                                                        placeholder="Select"
                                                        onSelectChange={(value) => {
                                                            this.onChangeLimit(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="cus-filter-btn">
                                            <button className="button" onClick={this.openTable}>
                                                <img
                                                    src={ImageName.IMAGE_NAME.GREY_HAMBURGER}
                                                    style={{ width: "20px", marginTop: "15px" }}
                                                />
                                            </button>
                                            <button className="button" onClick={this.openCalender}>
                                                <img
                                                    src={ImageName.IMAGE_NAME.BLUE_CALENDER}
                                                    style={{ width: "20px", marginTop: "15px" }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {this.state.isCalender ? (
                        <React.Fragment>
                            <div className="table-listing-app">
                                <ViewCalender
                                    id={this.state.calenderData}
                                    detailClick={(value) => {
                                        this.detailJob(value);
                                    }}
                                    type="job"
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div
                                className="tab-app-information activeLnk"
                                id="tble-data-a"
                                hidden={!this.state.isAlljobs}
                            >
                                <div hidden={!this.state.isLoad}>
                                    <LotteLoader />
                                </div>
                                <div hidden={this.state.isLoad}>
                                    <div className="table-listing-app">
                                        <div className="table-responsive_cus table-style-a">
                                            <table
                                                width="100%"
                                                border="0"
                                                cellPadding="0"
                                                cellSpacing="0"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "10%" }}>Task ID</th>
                                                        <th style={{ width: "15%" }}>Task</th>
                                                        <th style={{ width: "10%" }}>Project Id</th>
                                                        <th style={{ width: "10%" }}>Assign Job/Project #</th>
                                                        <th style={{ width: "15%" }}>Project Title</th>
                                                        <th style={{ width: "10%" }}>Start Date</th>
                                                        <th style={{ width: "10%" }}>Due Date</th>
                                                        <th style={{ width: "10%" }}>Status</th>
                                                        <th style={{ width: "5%" }}>Action</th>
                                                    </tr>
                                                    {this.state.listData.length > 0 ? (
                                                        <React.Fragment>
                                                            {this.state.listData.map((item, key) => (
                                                                <tr>
                                                                    <td colspan="11">
                                                                        <div className="tble-row">
                                                                            <table
                                                                                width="100%"
                                                                                border="0"
                                                                                cellPadding="0"
                                                                                cellSpacing="0"
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.taskId}
                                                                                        </td>
                                                                                        <td style={{ width: "15%" }}>
                                                                                            {item.taskname}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.projectId}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.relationId}
                                                                                        </td>
                                                                                        <td style={{ width: "15%" }}>
                                                                                            {item.projectName}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {SetDateFormat(item.startDate)}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {SetDateFormat(item.endDate)}
                                                                                        </td>

                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.status === 0 ? (
                                                                                                <React.Fragment>
                                                                                                    <span className="progress-btn yellow">
                                                                                                        Open
                                                                                                    </span>
                                                                                                </React.Fragment>
                                                                                            ) : item.status === 1 ? (
                                                                                                <React.Fragment>
                                                                                                    <span
                                                                                                        className="progress-btn green"
                                                                                                    >
                                                                                                        Closed
                                                                                                    </span>
                                                                                                </React.Fragment>
                                                                                            ) : (
                                                                                                <React.Fragment></React.Fragment>
                                                                                            )}
                                                                                        </td>
                                                                                        <td style={{ width: "5%" }}>
                                                                                            <React.Fragment>
                                                                                                <img
                                                                                                    src={
                                                                                                        ImageName.IMAGE_NAME
                                                                                                            .MENU_VERTICAL
                                                                                                    }
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    id={"basic-button" + key}
                                                                                                    aria-controls={
                                                                                                        "basic-menu" + key
                                                                                                    }
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded={
                                                                                                        open ? "true" : undefined
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        this.menuBtnhandleClick(
                                                                                                            key,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <StyledMenu
                                                                                                    id={"basic-menu" + key}
                                                                                                    anchorEl={this.state.anchorEl}
                                                                                                    open={open}
                                                                                                    onClose={this.handleMenuClose}
                                                                                                    MenuListProps={{
                                                                                                        "aria-labelledby":
                                                                                                            "basic-button" + key,
                                                                                                    }}
                                                                                                >
                                                                                                    <MenuItem onClick={() => this.goEdit("1")}>
                                                                                                        View Project Details
                                                                                                    </MenuItem>

                                                                                                    <MenuItem
                                                                                                        onClick={() => this.viewTaskDetails("2")}
                                                                                                    >
                                                                                                        View Task Details
                                                                                                    </MenuItem>
                                                                                                </StyledMenu>
                                                                                            </React.Fragment>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <tr style={{ textAlign: "center" }}>
                                                                <td colSpan="11">
                                                                    <center style={{ fontSize: "20px" }}>
                                                                        No data found !!!
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="tab-app-information"
                                id="tble-data-b"
                                hidden={!this.state.isHistory}
                            >
                                <div hidden={!this.state.isLoad}>
                                    <LotteLoader />
                                </div>
                                <div hidden={this.state.isLoad}>
                                    <div className="table-listing-app">
                                        <div className="table-responsive_cus table-style-a">
                                            <table
                                                width="100%"
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "10%" }}>Task ID</th>
                                                        <th style={{ width: "20%" }}>Task</th>
                                                        <th style={{ width: "10%" }}>Project Id</th>
                                                        <th style={{ width: "20%" }}>Project Title</th>
                                                        <th style={{ width: "12%" }}>Start Date</th>
                                                        <th style={{ width: "12%" }}>Due Date</th>
                                                        <th style={{ width: "10%" }}>Status</th>
                                                        <th style={{ width: "5%" }}>Action</th>
                                                    </tr>
                                                    {this.state.historyList.length > 0 ? (
                                                        <React.Fragment>
                                                            {consoleLog("History list on list data::", this.state.historyList)}
                                                            {this.state.historyList.map((item, key) => (
                                                                <tr>
                                                                    <td colspan="11">
                                                                        <div className="tble-row">
                                                                            <table
                                                                                width="100%"
                                                                                border="0"
                                                                                cellPadding="0"
                                                                                cellSpacing="0"
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.taskId}
                                                                                        </td>
                                                                                        <td style={{ width: "20%" }}>
                                                                                            {item.taskname}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.projectId}
                                                                                        </td>
                                                                                        <td style={{ width: "20%" }}>
                                                                                            {item.projectName}
                                                                                        </td>
                                                                                        <td style={{ width: "12%" }}>
                                                                                            {SetDateFormat(item.startDate)}
                                                                                        </td>
                                                                                        <td style={{ width: "12%" }}>
                                                                                            {SetDateFormat(item.endDate)}
                                                                                        </td>
                                                                                        <td style={{ width: "10%" }}>
                                                                                            {item.status === 0 ? (
                                                                                                <React.Fragment>
                                                                                                    <span className="progress-btn yellow">
                                                                                                        Open
                                                                                                    </span>
                                                                                                </React.Fragment>
                                                                                            ) : item.status === 1 ? (
                                                                                                <React.Fragment>
                                                                                                    <span
                                                                                                        className="progress-btn green"
                                                                                                    >
                                                                                                        Closed
                                                                                                    </span>
                                                                                                </React.Fragment>
                                                                                            ) : (
                                                                                                <React.Fragment></React.Fragment>
                                                                                            )}
                                                                                        </td>
                                                                                        <td style={{ width: "5%" }}>
                                                                                            <React.Fragment>
                                                                                                <img
                                                                                                    src={
                                                                                                        ImageName.IMAGE_NAME
                                                                                                            .MENU_VERTICAL
                                                                                                    }
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    id={"basic-button" + key}
                                                                                                    aria-controls={
                                                                                                        "basic-menu" + key
                                                                                                    }
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded={
                                                                                                        open ? "true" : undefined
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        this.menuBtnhandleClick(
                                                                                                            key,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <StyledMenu
                                                                                                    id={"basic-menu" + key}
                                                                                                    anchorEl={this.state.anchorEl}
                                                                                                    open={open}
                                                                                                    onClose={this.handleMenuClose}
                                                                                                    MenuListProps={{
                                                                                                        "aria-labelledby":
                                                                                                            "basic-button" + key,
                                                                                                    }}
                                                                                                >
                                                                                                    <MenuItem onClick={() => this.goEdit("1")}>
                                                                                                        View Project Details
                                                                                                    </MenuItem>

                                                                                                    <MenuItem
                                                                                                        onClick={() => this.viewTaskDetails("2")}
                                                                                                    >
                                                                                                        View Task Details
                                                                                                    </MenuItem>
                                                                                                </StyledMenu>
                                                                                            </React.Fragment>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <tr style={{ textAlign: "center" }}>
                                                                <td colSpan="11">
                                                                    <center style={{ fontSize: "20px" }}>
                                                                        No data found !!!
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {this.state.isCalender ? (
                        <React.Fragment></React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="table-filter-app">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="cus-filter-btn">
                                            {/* <button className="button">
                                                <img src={ImageName.IMAGE_NAME.MENU_BTN} />
                                            </button> */}
                                            {/* <button className="button">
                      <img src={ImageName.IMAGE_NAME.MENU_BTN_ONE} />
                    </button> */}
                                            {/* <button className="button">
                                                <img src={ImageName.IMAGE_NAME.MENU_BTN_TWO}
                                                    onClick={this.openCalender} />
                                            </button> */}

                                            {/* <div
                    class="filter-btn"
                    style={{ float: "none", paddingLeft: "10px" }}
                  >
                    <a href="#" onClick={this.filterModal}>
                      Filter
                    </a>
                  </div> */}

                                            <div className="filter-pagination">
                                                <button
                                                    className="prev_btn"
                                                    onClick={this.exLeft}
                                                ></button>
                                                <button className="prv_btn" onClick={this.prev}>
                                                    {" "}
                                                    {"<"}
                                                </button>
                                                <span
                                                    className="num"
                                                    onChange={(e) => this.clickChange(e)}
                                                >
                                                    {this.state.current_page}
                                                </span>
                                                <button className="nxt_btn" onClick={this.next}>
                                                    {">"}
                                                </button>
                                                <button
                                                    className="next_btn"
                                                    onClick={this.exRigth}
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="table-filter-box">
                                            {/* <div className="export-btn" onClick={this.onExport}>
                    <a href="">Export</a>
                  </div>
                  <div className="addnew">
                    <a href="#">Add New</a>
                  </div> */}
                                            <div className="tble-short">
                                                {" "}
                                                <span className="lbl">Display</span>
                                                <div className="dropdwn">
                                                    <div
                                                        class="dropdwn"
                                                        style={{
                                                            width: "70px",
                                                            fontSize: "12px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <PaginationDropdown
                                                            optionData={CommonData.COMMON.DISPLAY_ARR}
                                                            value={this.state.selectedDisplayData}
                                                            placeholder="Select"
                                                            onSelectChange={(value) => {
                                                                this.onChangeLimit(value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {/* Filter Section= */}
                </div>



                {/* ..................Filter modal................................. */}
                <div id="filter-model" className="modal fade modelwindow" role="dialog">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        {/* <!-- Modal content--> */}
                        <div className="modal-content">
                            <div className="filter-head _fl mdf">
                                <h3>Filter by</h3>
                                <div class="reset-btn-dp">
                                    <button class="reset">
                                        <img
                                            src={ImageName.IMAGE_NAME.RESET_BTN}
                                            onClick={this.onResetFilter}
                                        />
                                        Reset
                                    </button>
                                    <button class="apply" data-dismiss="modal">
                                        <img
                                            src={ImageName.IMAGE_NAME.BLUE_TICK}
                                            onClick={this.onFilterApply}
                                        />
                                        Apply
                                    </button>
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className="model-info f-model">
                                    <div className="form-search-app">
                                        <div className="lable-text">requested on</div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-field-app">
                                                    <span></span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "41px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            <span>FROM {this.state.formDate}</span>
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    // minDate={new Date()}
                                                                    onChange={(date) => this.formDateChange(date)}
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-field-app">
                                                    <span></span>
                                                    <div
                                                        className="input-group"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "9px",
                                                            height: "41px",
                                                            border: "1px solid #ced4da",
                                                            boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                    >
                                                        <div style={{ width: "80%", padding: "8px" }}>
                                                            <span>To {this.state.toDate}</span>
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                            <a style={{ float: "right" }}>
                                                                <DatePicker
                                                                    dropdownMode="select"
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    adjustDateOnChange
                                                                    minDate={new Date(this.state.formDate)}
                                                                    onChange={(date) => this.toDateChange(date)}
                                                                    customInput={<Schedule />}
                                                                />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    className="modal-backdrop fade show"
                    id="backdrop"
                    style={{ display: "none" }}
                ></div>
            </React.Fragment>
        );
    }
}

class Schedule extends React.Component {
    render() {
        const { onClick } = this.props;
        return (
            <img
                style={{
                    width: "35px",
                    height: "37px",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
                src={ImageName.IMAGE_NAME.CALENDER4}
                onClick={onClick}
            />
        );
    }
}
