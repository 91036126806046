import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AlertMessage, ImageName } from "../../../../../enums";
import { Decoder } from "../../../../../services/auth";
import {
  consoleLog,
  SetDateFormat,
  SetTimeFormat,
  textTruncate,
} from "../../../../../services/common-function";
import { CommonData, ErrorCode } from "../../../../../services/constant";
import { ApiCall } from "../../../../../services/middleware";
import {
  InputText,
  PaginationDropdown,
  SelectBox,
} from "../../../SharedComponents/inputText";
// import "./InterpretationInvoicePage.css";
import $ from "jquery";
import Select, { components } from "react-select";
import Menu from "@mui/material/Menu";
import { styled, Box } from "@mui/system"; //imported for modal
import axios from "axios";
import { IMAGE_URL } from "../../../../../services/config/api_url";
import Switch from "@mui/material/Switch";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import { FiEdit } from "react-icons/fi";
import { FcInfo } from "react-icons/fc";

// ................mui switch DesignServices...............
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 28,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#335b7b",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#993921",
    boxSizing: "border-box",
  },
}));

// ..........................style for react select........................

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
    borderRadius: "6px",
    // width: "120%",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "yellow";

    return {
      ...styles,
      color: isFocused ? "grey" : "white",
      //   backgroundColor: isDisabled ? "red" : "white",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    marginTop: 5,
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 2%) 0px 0px 0px 1px, rgba(0, 0, 0, 0%) 0px 10px 15px -3px, rgba(0, 0, 0, 0%) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

// .......................for react select icon.............................................

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
        style={{ width: "17px" }}
      />
    </components.DropdownIndicator>
  );
};

const reqData = {
  limit: "",
  offset: "",
  jobId: "",
  search: "",
  searchto: "",
  searchfrom: "",
  status: "",
  serviceTypeId: "46",
};

export default class TranslationInvoicePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      isPayable: false,
      isReceivable: true,
      current_page: 1,
      total_page: 10,
      limit: 20,
      offset: 0,
      selectedDisplayData: {
        label: "20",
        value: "20",
      },
      // ...payable..........
      current_page_payable: 1,
      total_page_payable: 10,
      limit_payable: 20,
      offset_payable: 0,
      selectedDisplayData_payable: {
        label: "20",
        value: "20",
      },
      formDate_payable: "",
      toDate_payable: "",
      listData: [],
      payableData: [],
      curIndex: 0,
      formDate: "",
      toDate: "",
      selectedVendorData: "",
      vendorArr: [],
      totalCount: "",
      input: "",
      show: true,
      anchorEl: null,

      // ...for invoice modal......
      invoiceId: "12345",
      invoiceDate: "25-12-2021",
      dueDate: "25-01-2022",
      invoiceStatusArr: [],
      invoiceStatusData: "",
      invoicePeriodFromDate: "",
      invoicePeriodToDate: "",
      clientName: "",
      billingAddress: "",
      phoneNumber: "",
      invoiceEmail: "",
      invoiceNote: "",

      imagePath: "images/profile-pic.png",
      rejectModal: false,
      rejectReason: "",
      selectedRejectData: {},
      viewNoteModal: false,
      viewNote: "",
      updatedAmount: "",
      updateDetails: false,
      updateBillId: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",
      loader: false

    };
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  componentDidMount() {
    consoleLog("Translation")
    window.scrollTo(0, 0);
    this.load();
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("rec").classList.add("active");
    var classInstance = this;

    var filterModal = document.getElementById("filter-model");
    var invoiceModal = document.getElementById("invoice-model");
    var adminInfoModal = document.getElementById("adminInfo-model");
    var createTemplateModal = document.getElementById("createTemplate-model");
    var chooseTemplateModal = document.getElementById("chooseTemplate-model");

    window.onclick = function (event) {
      if (event.target === filterModal) {
        classInstance.closeFilterModal();
      } else if (event.target === invoiceModal) {
        classInstance.closeInvoiceModal();
      } else if (event.target === adminInfoModal) {
        classInstance.closeAdminInfoModal();
      } else if (event.target === createTemplateModal) {
        classInstance.closeCreateTemplateModal();
      } else if (event.target === chooseTemplateModal) {
        classInstance.closeChooseTemplateModal();
      }
    };

    window.$(".verificaiton-doc-tab ul li").on("click", function () {
      $(".verificaiton-doc-tab ul li").removeClass("active");
      $(this).addClass("active");
      $("div").removeClass("activeLnk");
      $("div[id=" + $(this).attr("data-related") + "]").addClass("activeLnk");
    });
  }

  load = async () => {
    let resData = {
      limit: this.state.limit,
      offset: this.state.offset.toString(),
    };

    let MainData = Object.assign(reqData, resData);

    this.getListDetails(MainData);

    // ...............vendor list.........................

    let vendorArrMainData = [];

    let vendorRes = await ApiCall("fetchAllVendorList", {});

    if (
      vendorRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      vendorRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(vendorRes.data.payload);

      // consoleLog("*****", payload.data);
      let vendorArrData = payload.data.vendorList;
      vendorArrData.map((obj) => {
        vendorArrMainData.push({
          label: obj.name,
          value: obj.userId,
        });
      });
      this.setState({
        vendorArr: vendorArrMainData,
      });
    }
  };

  getListDetails = async (data) => {
    // .............................................
    let modifyData = { isSelected: false };

    // let res = await ApiCall("fetchAccountPayable", data);
    let res = await ApiCall("fetchBillsUnderVerificationReceivable", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload),
        allBillInvoice = [];
      allBillInvoice = payload.data.details;

      allBillInvoice.map((obj) => {
        Object.assign(obj, modifyData);
      });
      consoleLog("Bill Payload:", allBillInvoice);
      let totalPage = Math.ceil(payload.data.count / this.state.limit);
      if (
        allBillInvoice == null ||
        allBillInvoice == [] ||
        allBillInvoice == undefined
      ) {
        this.setState({
          listData: [],
        });
      } else {
        this.setState({
          listData: allBillInvoice,
          totalCount: payload.data.count,
          total_page: totalPage,
        });
      }
    }
  };
  getPayableDetails = async (data) => {
    // .............................................
    let modifyData = { isSelected: false };
    let res = await ApiCall("fetchBillsUnderVerificationPayable", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload),
        allBillInvoice = [];
      allBillInvoice = payload.data.details;

      allBillInvoice.map((obj) => {
        Object.assign(obj, modifyData);
      });
      consoleLog("Bill Payload payload payable:", allBillInvoice);
      let totalPage = Math.ceil(payload.data.count / this.state.limit_payable);
      if (
        allBillInvoice == null ||
        allBillInvoice == [] ||
        allBillInvoice == undefined
      ) {
        this.setState({
          payableData: [],
        });
      } else {
        this.setState({
          payableData: allBillInvoice,
          total_page_payable: totalPage,
        });
      }
    }
  };
  // .............pagination function..........
  clickChange = (e) => {
    this.setState({
      current_page: e.target.value,
    });
  };
  exLeft = () => {
    this.setState({
      current_page: 1,
    });

    let resData = {
      limit: this.state.limit,
      offset: this.state.offset.toString(),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);
  };
  exRigth = () => {
    let currentPage = this.state.current_page;
    let totalPage = this.state.total_page;
    this.setState({
      current_page: totalPage,
    });
    let resData = {
      limit: JSON.stringify(this.state.limit),
      offset: JSON.stringify((currentPage - 1) * this.state.limit),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);
  };
  prev = () => {
    let currentPage = this.state.current_page;
    if (currentPage > 1) {
      currentPage--;
      this.setState({
        current_page: currentPage,
      });
    }
    let resData = {
      limit: JSON.stringify(this.state.limit),
      offset: JSON.stringify((currentPage - 1) * this.state.limit),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);
  };
  next = () => {
    let currentPage = this.state.current_page;
    let totalPage = this.state.total_page;

    if (currentPage < totalPage) {
      currentPage++;
      this.setState({
        current_page: currentPage,
      });
    }
    let resData = {
      limit: JSON.stringify(this.state.limit),
      offset: JSON.stringify((currentPage - 1) * this.state.limit),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);
  };

  // .............pagination function for payable..........
  clickChange_payable = (e) => {
    this.setState({
      current_page_payable: e.target.value,
    });
  };
  exLeft_payable = () => {
    this.setState({
      current_page_payable: 1,
    });

    let resData = {
      limit: this.state.limit_payable,
      offset: this.state.offset_payable.toString(),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getPayableDetails(mainData);
  };
  exRigth_payable = () => {
    let currentPage = this.state.current_page_payable;
    let totalPage = this.state.total_page_payable;
    this.setState({
      current_page_payable: totalPage,
    });
    let resData = {
      limit: JSON.stringify(this.state.limit_payable),
      offset: JSON.stringify((currentPage - 1) * this.state.limit_payable),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getPayableDetails(mainData);
  };
  prev_payable = () => {
    let currentPage = this.state.current_page_payable;
    if (currentPage > 1) {
      currentPage--;
      this.setState({
        current_page_payable: currentPage,
      });
    }
    let resData = {
      limit: JSON.stringify(this.state.limit_payable),
      offset: JSON.stringify((currentPage - 1) * this.state.limit_payable),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getPayableDetails(mainData);
  };
  next_payable = () => {
    let currentPage = this.state.current_page_payable;
    let totalPage = this.state.total_page_payable;

    if (currentPage < totalPage) {
      currentPage++;
      this.setState({
        current_page_payable: currentPage,
      });
    }
    let resData = {
      limit: JSON.stringify(this.state.limit_payable),
      offset: JSON.stringify((currentPage - 1) * this.state.limit_payable),
      jobId: this.state.id,
      search: this.state.input,
    };
    let mainData = Object.assign(reqData, resData);

    this.getPayableDetails(mainData);
  };

  //   ......................func for filter modal open...............
  openFilterModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("filter-model").style.display = "block";
    document.getElementById("filter-model").classList.add("show");
  };

  closeFilterModal = () => {
    document.getElementById("filter-model").style.display = "none";
    document.getElementById("filter-model").classList.remove("show");
    document.getElementById("backdrop").style.display = "none";
  };

  openInvoiceModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("invoice-model").style.display = "block";
    document.getElementById("invoice-model").classList.add("show");
  };

  closeInvoiceModal = () => {
    document.getElementById("invoice-model").style.display = "none";
    document.getElementById("invoice-model").classList.remove("show");
    document.getElementById("backdrop").style.display = "none";
  };
  openAdminInfoModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("adminInfo-model").style.display = "block";
    document.getElementById("adminInfo-model").classList.add("show");
  };
  closeAdminInfoModal = () => {
    document.getElementById("adminInfo-model").style.display = "none";
    document.getElementById("adminInfo-model").classList.remove("show");
    document.getElementById("backdrop").style.display = "none";
  };
  openCreateTemplateModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("createTemplate-model").style.display = "block";
    document.getElementById("createTemplate-model").classList.add("show");
  };
  closeCreateTemplateModal = () => {
    document.getElementById("createTemplate-model").style.display = "none";
    document.getElementById("createTemplate-model").classList.remove("show");
    document.getElementById("backdrop").style.display = "none";
  };
  openChooseTemplateModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("chooseTemplate-model").style.display = "block";
    document.getElementById("chooseTemplate-model").classList.add("show");
  };
  closeChooseTemplateModal = () => {
    document.getElementById("chooseTemplate-model").style.display = "none";
    document.getElementById("chooseTemplate-model").classList.remove("show");
    document.getElementById("backdrop").style.display = "none";
  };
  // .............filter modal function...................

  openInfoModal = () => {
    this.openAdminInfoModal();
    this.handleMenuClose();
  };

  openTemplateModal = () => {
    this.openCreateTemplateModal();
    this.handleMenuClose();
  };

  openSelectTemplateModal = () => {
    this.openChooseTemplateModal();
    this.chooseTemplateApi();
    this.handleMenuClose();
  };

  formDateChange = (e) => {
    this.setState({
      formDate: e.target.value,
    });
  };

  toDateChange = (e) => {
    this.setState({
      toDate: e.target.value,
    });
  };
  // ........payable.........
  formDateChange_payable = (e) => {
    this.setState({
      formDate_payable: e.target.value,
    });
  };

  toDateChange_payable = (e) => {
    this.setState({
      toDate_payable: e.target.value,
    });
  };

  invoiceFormDateChange = (e) => {
    consoleLog("val::", e.target.value);
    this.setState({
      invoicePeriodFromDate: e.target.value,
    });
  };

  invoiceToDateChange = (e) => {
    consoleLog("val::", e.target.value);
    this.setState({
      invoicePeriodToDate: e.target.value,
    });
  };

  onFilterApply = () => {
    if (this.state.isReceivable == true) {
      let resData = {
        limit: this.state.limit,
        offset: this.state.offset.toString(),
        search: this.state.input,
        jobId: this.state.id,
        searchto: this.state.toDate,
        searchfrom: this.state.formDate,
      };

      let mainData = Object.assign(reqData, resData);

      this.getListDetails(mainData);

      this.closeFilterModal();
    } else {
      let resData = {
        limit: this.state.limit_payable,
        offset: this.state.offset_payable.toString(),
        search: this.state.input,
        jobId: this.state.id,
        searchto: this.state.toDate_payable,
        searchfrom: this.state.formDate_payable,
      };

      let mainData = Object.assign(reqData, resData);

      this.getPayableDetails(mainData);

      // this.setState({
      //   formDate: "",
      //   toDate: "",
      // });
      this.closeFilterModal();
    }
  };

  onResetFilter = () => {
    this.load();
    this.setState({
      formDate: "",
      toDate: "",
      current_page: 1,
      selectedDisplayData: {
        label: "20",
        value: "20",
      },
    });

    this.closeFilterModal();
  };
  onVendorChange = async (dat) => {
    let obj = { label: dat.label, value: dat.value };

    let resData = {
      limit: this.state.limit,
      offset: this.state.offset.toString(),
      jobId: dat.value,
    };

    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);
    this.setState({
      selectedVendorData: obj,
    });
  };

  onIdChange = (val) => {
    let resData = {
      limit: this.state.limit,
      offset: this.state.offset.toString(),
      search: this.state.input,
      jobId: val,
    };

    let MainData = Object.assign(reqData, resData);

    this.getListDetails(MainData);
    this.setState({
      id: val,
    });
  };

  inputChange = (e) => {
    let resData = {
      limit: this.state.limit,
      offset: this.state.offset.toString(),
      jobId: this.state.id,
      search: e.target.value,
    };

    let mainData = Object.assign(reqData, resData);

    this.getListDetails(mainData);

    this.setState({
      input: e.target.value,
    });
  };

  onTick = async (item, id) => {
    // consoleLog("%%%%%%", id);
    // if (item == 0) {
    //   item = 1;
    // } else {
    //   item = 0;
    // }
    this.closeRejectModal();

    let res = await ApiCall("changeStatusAccountPayable", {
      id: id,
      status: item.toString(),
      reason: this.state.rejectReason
    });

    // consoleLog("resssss", res)
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      if (item === "1") {
        toast.success(AlertMessage.MESSAGE.PAYABALES.SUCCESS);
      } else if (item === "3") {
        toast.success(AlertMessage.MESSAGE.PAYABALES.REJECT);
      }

      this.load();
      let resDataPayable = {
        limit: this.state.limit_payable,
        offset: this.state.offset_payable.toString(),
      };

      let MainDataPayable = Object.assign(reqData, resDataPayable);

      this.getPayableDetails(MainDataPayable);
    } else {
      toast.error(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR);
    }
  };

  onReject = async (item, id, index) => {
    let arr = this.state.listData;

    // arr

    let res = await ApiCall("changeStatusAccountPayable", {
      id: id,
      status: "5",
    });
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.PAYABALES.REJECT);

      this.load();
    } else {
      toast.error(AlertMessage.MESSAGE.SERVER.INTERNAL_SERVER_ERROR);
    }
  };

  showDetails = (val, index) => {
    let TranslationData = this.props.translationData
    if (val.serviceTypeId === 45) {
      TranslationData.history.push({
        pathname: "/adminJobDetailsFromBillVerification",
        state: val.requestId,
        from: "bills"
      });
    } else if (val.serviceTypeId === 46) {
      TranslationData.history.push({
        pathname: "/adminTranslationDetailsFromBillVerification",
        state: val.requestId,
        from: "bills"
      });
    } else if (val.serviceTypeId === 47) {
      TranslationData.history.push({
        pathname: "/adminTrainingDetailsFromBillVerification",
        state: val.requestId,
        from: "bills"
      });
    }
  };
  goEdit = () => {
    this.props.history.push({
      pathname: "/adminTranslationDetails",
      state: this.state.listData[this.state.curIndex].requestId,
    });
  };

  // ..................for invoice modal.........................

  onInvoiceStatusChange = (value) => {
    let obj = { label: value.label, value: value.value };
    this.setState({
      invoiceStatusData: obj,
    });
  };

  onInvoiceBillingChange = (e) => {
    this.setState({
      billingAddress: e.target.value,
    });
  };
  onInvoicePhoneChange = (value) => {
    this.setState({
      phoneNumber: value,
    });
  };
  onInvoiceEmailChange = (value) => {
    this.setState({
      invoiceEmail: value,
    });
  };

  menuBtnhandleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  onProfileImage = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios.post(IMAGE_URL, formData).then((res) => {
      // console.log(res.data.data);
      this.setState({
        imagePath: res.data.data.url,
        adminPhoto: res.data.data.path + res.data.data.filename,
      });
      this.onUpdate();
    });
  };

  // ..................admin info function.....................

  onStreetChange = (e) => {
    this.setState({
      adminStreet: e.target.value,
    });
  };
  onCityChange = (e) => {
    this.setState({
      adminCity: e.target.value,
    });
  };
  onStateChange = (e) => {
    this.setState({
      stateData: e.target.value,
    });
  };
  onZipChange = (e) => {
    this.setState({
      adminZipCode: e.target.value,
    });
  };
  onPhoneChange = (e) => {
    this.setState({
      adminPhone: e.target.value,
    });
  };
  onFaxChange = (e) => {
    this.setState({
      adminFax: e.target.value,
    });
  };
  onWebsiteChange = (e) => {
    this.setState({
      adminWebsite: e.target.value,
    });
  };

  onAdminInfoCancel = (e) => {
    this.closeAdminInfoModal();
    this.setState({
      adminStreet: "",
      adminCity: "",
      stateData: {},
      adminZipCode: "",
      adminPhone: "",
      adminFax: "",
      adminWebsite: "",
    });
  };

  onSubmitAdminInfo = () => {
    consoleLog("submit::");
  };

  // ................create templete modal function...............

  onTemplateNameChange = (val) => {
    this.setState({
      templateName: val,
    });
  };

  // onSearchData = (val) => {
  //   this.setState({
  //     searchColumnData: val,
  //   });
  // };

  onCreateTemplateCancel = () => {
    this.closeCreateTemplateModal();
  };

  onCreateTemplate = async () => {
    let data = {
      templateName: this.state.templateName,
      content: this.state.selectedColumnList,
    };

    let res = await ApiCall("insertInvoiceTemplate", data);
    consoleLog("rsponse", res);

    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.TEMPLATE.ADD_SUCCESS);
      this.closeCreateTemplateModal();
      this.setState({
        isEditable: true,
      });
    }

    consoleLog("data:::", data);
  };

  //..............function for MenuButton close..............
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  subTypeChange = (id) => (e) => {
    let arr = this.state.availableColumnsList;
    if (e.target.checked) {
      arr[id].isSelected = true;
    } else {
      arr[id].isSelected = false;
    }

    this.setState({
      availableColumnsList: arr,
    });
  };

  selectedSubTypeChange = (id) => (e) => {
    let arr = this.state.selectedColumnList;
    if (e.target.checked) {
      arr[id].isSelected = true;
    } else {
      arr[id].isSelected = false;
    }

    this.setState({
      selectedColumnList: arr,
    });
  };

  onAddColumnData = () => {
    let arr = this.state.selectedColumnList,
      brr = [];

    this.state.availableColumnsList.map((data) => {
      if (data.isSelected) {
        arr.push({
          isSelected: false,
          name: data.name,
        });
      } else {
        brr.push({
          isSelected: false,
          name: data.name,
        });
      }
    });
    // consoleLog("brr::", brr);

    this.setState({
      selectedColumnList: arr,
      availableColumnsList: brr,
    });
  };

  onDeleteColumnData = () => {
    let arr = this.state.availableColumnsList,
      brr = [];

    this.state.selectedColumnList.map((data) => {
      if (data.isSelected) {
        arr.push({
          isSelected: false,
          name: data.name,
        });
      } else {
        brr.push({
          isSelected: false,
          name: data.name,
        });
      }
    });

    this.setState({
      selectedColumnList: brr,
      availableColumnsList: arr,
    });
  };

  //.............choose template..........
  onTemplateChange = (val) => {
    this.setState({
      selectedTemplateData: val,
    });
  };

  closeSelectTemplateModal = () => {
    this.closeChooseTemplateModal();
  };

  chooseTemplateApi = async () => {
    let arr = [];
    let res = await ApiCall("fetchInvoiceTemplateList");

    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let decodeData = Decoder.decode(res.data.payload);

      consoleLog("decodeData::", decodeData.data);
      decodeData.data.map((obj) => {
        arr.push({
          label: obj.templateName,
          value: obj.id,
        });
      });
      this.setState({
        templateArr: arr,
        templateId: this.state.selectedTemplateData.value,
      });
    }
  };

  fetchTemplateApi = async () => {
    let res = await ApiCall("getInvoiceTemplateById", {
      id: this.state.selectedTemplateData.value,
    });

    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let decodeData = Decoder.decode(res.data.payload);
      consoleLog("decodeData:::", decodeData);
      let templateData = JSON.parse(decodeData.data[0].content);
      consoleLog("templateData:::", templateData);

      if (templateData.length > 0) {
        let showHideObj = {
          dueDateTemplate: false,
          invoicePeriodTemplate: false,
          billingAddressTemplate: false,
          phoneNumberTemplate: false,
          emailTemplate: false,
          invoiceNotesTemplate: false,
          payableItemsTemplate: false,
          invoiceIdTemplate: false,
          invoiceDateTemplate: false,
        };
        templateData.map((obj) => {
          if (obj.name === "Due Date") {
            showHideObj.dueDateTemplate = true;
          } else if (obj.name === "Invoice Period") {
            showHideObj.invoicePeriodTemplate = true;
          } else if (obj.name === "Billing Address") {
            showHideObj.billingAddressTemplate = true;
          } else if (obj.name === "Phone Number") {
            showHideObj.phoneNumberTemplate = true;
          } else if (obj.name === "Email") {
            showHideObj.emailTemplate = true;
          } else if (obj.name === "Invoice Notes") {
            showHideObj.invoiceNotesTemplate = true;
          } else if (obj.name === "Payable Item[s]") {
            showHideObj.payableItemsTemplate = true;
          } else if (obj.name === "Invoice #") {
            showHideObj.invoiceIdTemplate = true;
          } else if (obj.name === "Invoice Date") {
            showHideObj.invoiceDateTemplate = true;
          }
        });
        this.setState({
          showHide: showHideObj,
        });
      }
    }
  };

  onSelectTemplate = () => {
    this.fetchTemplateApi();
    this.closeSelectTemplateModal();
  };

  onSearchData = (val) => {
    let display = this.state.displayAvailableColumnsList.filter((item) =>
      item.name.toUpperCase().includes(val.toUpperCase())
    );
    this.setState({
      searchColumnData: val,
      availableColumnsList: display,
    });
  };

  // ................status func...................
  onStatusChange = async () => {
    let flag = false;
    if (this.state.primaryCheck === true) {
      flag = false;
    } else {
      flag = true;
    }
    //  consoleLog("check::",flag)
    this.setState({
      primaryCheck: flag,
    });


  };

  onTabClick = (value) => {
    if (value === "receivables") {
      let resData = {
        limit: this.state.limit,
        offset: this.state.offset.toString(),
      };

      let MainData = Object.assign(reqData, resData);

      this.getListDetails(MainData);
      this.setState({
        isPayable: false,
        isReceivable: true,
      });
    } else if (value === "payables") {
      let resDataPayable = {
        limit: this.state.limit_payable,
        offset: this.state.offset_payable.toString(),
      };

      let MainDataPayable = Object.assign(reqData, resDataPayable);

      this.getPayableDetails(MainDataPayable);
      this.setState({
        isPayable: true,
        isReceivable: false,
      });
    }
  };
  receivableTypeChange = (val, id) => (e) => {
    consoleLog("val:", val);
    if (val == "receivable") {
      let selectedArr = this.state.selectedListData;
      let brr = [];

      let arr = this.state.listData;
      if (e.target.checked) {
        arr[id].isSelected = true;
      } else {
        arr[id].isSelected = false;
      }

      this.setState({
        listData: arr,
        isChecked: arr[id].isSelected,
        selectedListData: selectedArr,
      });
    } else {
      // let selectedArr = this.state.selectedListData;
      let brr = [];

      let arr = this.state.payableData;
      if (e.target.checked) {
        arr[id].isSelected = true;
      } else {
        arr[id].isSelected = false;
      }

      this.setState({
        payableData: arr,
        isChecked: arr[id].isSelected,
        // selectedListData: selectedArr,
      });
    }


  };

  onExport = async () => {
    let brr = [],
      arrId = [],
      clientID = "",
      jobID = "",
      invoiceId = "",
      mainInvoiceId = "",
      clientName = "";
    for (let i = 0; i < this.state.listData.length; i++) {
      if (this.state.listData[i].isSelected === true) {
        brr.push(this.state.listData[i]);

      }
    }
    consoleLog("%%%%", brr)

    brr.map((obj) => {
      arrId.push(obj.id);
      // clientID = obj.clientId;
      // jobID.push(obj.jobId);
      // clientName = obj.clientName;
      // invoiceId = obj.id;
    });

    if (brr.length < 1) {
      toast.error("Please select one row");
    } else {
      let obj = { id: arrId };

      let res = await ApiCall("exportInvoiceReceivables", obj);
      consoleLog("resss", res)
      const decodeData = Decoder.decode(res.data.payload);
      consoleLog("payload", decodeData)
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        window.open(decodeData.data.url, "_blank");
      }

    }
  }

  onExportPayable = async () => {
    let brr = [],
      arrId = [],
      clientID = "",
      jobID = "",
      invoiceId = "",
      mainInvoiceId = "",
      clientName = "";
    for (let i = 0; i < this.state.payableData.length; i++) {
      if (this.state.payableData[i].isSelected === true) {
        brr.push(this.state.payableData[i]);

      }
    }
    consoleLog("%%%%", brr)

    brr.map((obj) => {
      arrId.push(obj.id);
      // clientID = obj.clientId;
      // jobID.push(obj.jobId);
      // clientName = obj.clientName;
      // invoiceId = obj.id;
    });

    if (brr.length < 1) {
      toast.error("Please select one row");
    } else {
      let obj = { id: arrId };

      let res = await ApiCall("exportInvoicePayables", obj);
      consoleLog("resss", res)
      const decodeData = Decoder.decode(res.data.payload);
      consoleLog("payload", decodeData)
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        window.open(decodeData.data.url, "_blank");
      }
    }
  }

  openRejectModal = (data) => {
    this.setState({
      selectedRejectData: data,
      rejectModal: true
    })
  }

  closeRejectModal = () => {
    this.setState({
      selectedRejectData: {},
      rejectReason: "",
      rejectModal: false
    })
  }

  rejectReasonChange = (e) => {
    this.setState({
      rejectReason: e.target.value
    })
  }

  submitReject = () => {
    this.onTick("3", this.state.selectedRejectData.id)
  }

  openViewNoteModal = (data) => {
    this.setState({
      updateBillId: data.id,
      viewNote: data.description,
      updatedAmount: data.totalAmount,
      billQty: data.quantity,
      billUnitPrice: data.unitPrice,
      viewNoteModal: true
    })
  }

  closeViewNoteModal = () => {
    this.setState({
      viewNote: "",
      updatedAmount: "",
      billQty: "",
      billUnitPrice: "",
      billRemarks: "",
      viewNoteModal: false,
      updateDetails: false
    })
  }

  changeUpdateAmount = (value) => {
    value = value.replace(/[^0-9]/g, '');
    this.setState({
      updatedAmount: value
    })
  }

  changeViewDescription = (e) => {
    this.setState({
      viewNote: e.target.value
    })
  }

  changeUpdateDetails = () => {
    this.setState({
      updateDetails: true
    })
  }

  changeBillQty = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billQty: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billUnitPrice) > 0) {
            amt = newValue * Number(this.state.billUnitPrice);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billQty: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeUnitPrice = async (e) => {

    const newValue = e.target.value;
    let amt = 0;
    const regex = /^[+-]?\d*\.?\d{0,3}$/;
    if (regex.test(newValue)) {
      if (newValue === "" || newValue === "0") {
        await this.loaderChange(true);
        this.setState({
          billUnitPrice: 0,
          updatedAmount: 0
        })
        await this.loaderChange(false);
      } else {
        const parsedValue = parseFloat(newValue);
        if (!isNaN(parsedValue)) {
          if (Number(this.state.billQty) > 0) {
            amt = newValue * Number(this.state.billQty);
          } else {
            amt = 0;
          }
          await this.loaderChange(true);
          this.setState({
            billUnitPrice: parsedValue,
            updatedAmount: amt
          })
          await this.loaderChange(false);
        }
      }
    }
  }

  changeBillRemarks = (e) => {
    this.setState({
      billRemarks: e.target.value
    })
  }

  updateBillDetails = async () => {
    let errorCount = 0;
    if (this.state.billQty == 0 || this.state.billQty === "") {
      toast.error("Quantity cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billUnitPrice == 0 || this.state.billUnitPrice === "") {
      toast.error("Unit price cannot be 0 or blank");
      errorCount++;
    } else if (this.state.updatedAmount == 0 || this.state.updatedAmount === "") {
      toast.error("Total amount cannot be 0 or blank");
      errorCount++;
    } else if (this.state.billRemarks === "") {
      toast.error("Please enter remarks");
      errorCount++;
    }
    if (errorCount == 0) {
      let reqObj = {
        id: this.state.updateBillId,
        totalAmount: this.state.updatedAmount,
        quantity: this.state.billQty,
        unitPrice: this.state.billUnitPrice,
        description: this.state.viewNote,
        remarks: this.state.billRemarks
      }

      // consoleLog("Updated BillDetails >>>> ", reqObj);
      let res = await ApiCall("updateExistingBill", reqObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Bill Updated Successfully");
        this.closeViewNoteModal();
        this.load();
      } else {
        toast.error(res.message)
      }
    }
  }

  render() {
    const open = Boolean(this.state.anchorEl); //used in MenuButton open

    const checkStatus = (status) => {
      if (status === 0) {
        return <span className="progress-btn yellow">Pending</span>;
      } else if (status === 1) {
        return (
          <a href="javascript:void(0)" className="progress-btn sky">
            Verified
          </a>
        );
      } else if (status === 5) {
        return (
          <a href="javascript:void(0)" className="progress-btn red">
            Rejected
          </a>
        );
      }
    };

    const checkAction = (data, index) => {
      return (
        <React.Fragment>
          <a href="javascript:void(0)">
            <img
              src={ImageName.IMAGE_NAME.EYE_BTN}
              onClick={() => this.showDetails(data, index)}
              style={{ marginRight: "3px" }}
            />
          </a>
          {data.status === 0 || data.status === 3 ?
            <a href="javascript:void(0)">
              <img
                src={ImageName.IMAGE_NAME.BLUE_TICK_JPG}
                onClick={() => this.onTick("1", data.id)}
              />
            </a> : <></>}
          {data.status === 0 ?
            <a href="javascript:void(0)" >
              <img
                src={ImageName.IMAGE_NAME.CANCEL_BTN}
                onClick={() => this.openRejectModal(data)}
                style={{ marginLeft: "3px" }}
              />
            </a> : <></>}
        </React.Fragment>
      );
    };


    return (
      <React.Fragment>
        <ToastContainer hideProgressBar theme="colored" />

        {/* <div className="listing-component-app"> */}
        <div className="vendor-info _fl sdw">
          <div className="vn-form _fl">
            <div className="row">
              <div className="col-md-4">
                <div className="vn_frm">
                  <span
                    style={{
                      width: "30%",
                      paddingLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Project ID
                  </span>
                  <InputText
                    placeholder="Search"
                    className="inputfield"
                    value={this.state.id}
                    onTextChange={(value) => {
                      this.onIdChange(value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-3"></div>
              <div className="col-md-4">
                {/* <div className="vn_frm rt">
                  <input
                    type="text"
                    value={this.state.input}
                    name=""
                    placeholder="Search"
                    className="inputfield"
                    onChange={this.inputChange}
                    style={{ width: "140%" }}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="vn-form _fl" style={{ marginTop: "50px" }}>
            <div className="row">
              <div className="col-md-4">
                <div className="_fl verificaiton-doc-tab ven">
                  <ul>
                    <li
                      className="active"
                      data-related="transactionReceivable"
                      onClick={() => {
                        this.onTabClick("receivables");
                      }}
                      id="rec"
                    >
                      Receivables
                    </li>
                    <li
                      data-related="transactionPayable"
                      onClick={() => {
                        this.onTabClick("payables");
                      }}
                    >
                      Payables
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-2"></div>
              <div className="col-md-2">
                {this.state.isReceivable ? <React.Fragment>
                  <a
                    href="javascript:void(0)"
                    className="progress-btn sky"
                    style={{
                      textAlign: "center",
                      textDecoration: "none",
                      fontSize: "12px",
                      // pointerEvents:this.state.isChecked ? "" : "none"
                    }}
                    onClick={this.onExport}
                  >
                    Export
                  </a>
                </React.Fragment> : <React.Fragment>
                  <a
                    href="javascript:void(0)"
                    className="progress-btn sky"
                    style={{
                      textAlign: "center",
                      textDecoration: "none",
                      fontSize: "12px",
                      // pointerEvents:this.state.isChecked ? "" : "none"
                    }}
                    onClick={this.onExportPayable}
                  >
                    Export
                  </a>
                </React.Fragment>}

              </div>
            </div>
          </div>
        </div>

        {this.state.isReceivable ? (
          <React.Fragment>
            <div className="table-filter-app-b">
              <div className="filter-btn">
                <a
                  href={"javascript:void(0)"}
                  onClick={this.openFilterModal}
                >
                  Filter
                </a>
              </div>
              <div className="filter-pagination">
                <button className="prev_btn" onClick={this.exLeft}></button>
                <button className="prv_btn" onClick={this.prev}>
                  {" "}
                  {"<"}
                </button>
                <span className="num" onChange={(e) => this.clickChange(e)}>
                  {this.state.current_page}
                </span>
                <button className="nxt_btn" onClick={this.next}>
                  {">"}
                </button>
                <button
                  className="next_btn"
                  onClick={this.exRigth}
                ></button>
              </div>
              <div className="table-filter-box">
                <div className="tble-short">
                  <span className="lbl">Display</span>
                  <div
                    className="dropdwn"
                    style={{
                      width: "70px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    <PaginationDropdown
                      optionData={CommonData.COMMON.DISPLAY_ARR}
                      value={this.state.selectedDisplayData}
                      placeholder="Select"
                      onSelectChange={(value) => {
                        this.onChangeLimit(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="table-filter-app-b">
              <div className="filter-btn">
                <a
                  href={"javascript:void(0)"}
                  onClick={this.openFilterModal}
                >
                  Filter
                </a>
              </div>
              <div className="filter-pagination">
                <button
                  className="prev_btn"
                  onClick={this.exLeft_payable}
                ></button>
                <button className="prv_btn" onClick={this.prev_payable}>
                  {" "}
                  {"<"}
                </button>
                <span
                  className="num"
                  onChange={(e) => this.clickChange_payable(e)}
                >
                  {this.state.current_page_payable}
                </span>
                <button className="nxt_btn" onClick={this.next_payable}>
                  {">"}
                </button>
                <button
                  className="next_btn"
                  onClick={this.exRigth_payable}
                ></button>
              </div>
              <div className="table-filter-box">
                <div className="tble-short">
                  <span className="lbl">Display</span>
                  <div
                    className="dropdwn"
                    style={{
                      width: "70px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    <PaginationDropdown
                      optionData={CommonData.COMMON.DISPLAY_ARR}
                      value={this.state.selectedDisplayData_payable}
                      placeholder="Select"
                      onSelectChange={(value) => {
                        this.onChangeLimit_payable(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="tab-app-information activeLnk" id="transactionReceivable">
          <div className="table-listing-app">
            <div className="table-responsive">
              <table
                width="100%"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody>
                  <tr>
                    <th style={{ width: "8%" }}>
                      <strong>Project ID</strong>
                    </th>
                    <th style={{ width: "10%" }}>Service</th>
                    <th style={{ width: "10%" }}>Client(Bill To)</th>
                    {/* <th style={{ width: "10%" }}>Vendor</th>
                        <th style={{ width: "10%" }}>Vendor Type</th> */}
                    <th style={{ width: "12%" }}>Actual Date</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "11%" }}>Total Amount</th>
                    <th style={{ width: "6%" }}>Action</th>
                  </tr>
                </tbody>
                <tbody>
                  {this.state.listData.length > 0 ? (
                    <React.Fragment>
                      {this.state.listData.map((data, i) => (
                        <React.Fragment>
                          <tr>
                            <td
                              style={{ width: "8%" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={data.jobId}
                            >
                              <label className="custom_check2">
                                <input
                                  type="checkbox"
                                  name={i}
                                  checked={data.isSelected}
                                  onChange={this.receivableTypeChange(
                                    "receivable",
                                    i
                                  )}
                                />
                                <span
                                  className="checkmark2"
                                  style={{ padding: "10px" }}
                                ></span>
                                <strong>
                                  <div
                                    onClick={() =>
                                      this.showDetails(data, i)
                                    }
                                    style={{
                                      paddingLeft: "5px",
                                      paddingTop: "4px",
                                    }}
                                  >
                                    {data.jobId.length > 15
                                      ? textTruncate(data.jobId, 15)
                                      : data.jobId}
                                    {/* // {textTruncate(data.jobId, 15)} */}
                                  </div>
                                </strong>
                              </label>
                            </td>
                            <td style={{ width: "10%" }}>
                              {data.serviceType}
                            </td>
                            <td
                              style={{ width: "10%" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={data.email}
                            >
                              {data.clientName.length > 20
                                ? textTruncate(data.clientName, 20)
                                : data.clientName}
                            </td>
                            {/* <td style={{ width: "10%" }}>
                                  {data.vendorName}
                                </td>
                                <td style={{ width: "10%" }}>
                                  {" "}
                                  {data.vendotType}
                                </td> */}
                            <td style={{ width: "12%" }}>
                              {SetDateFormat(data.scheduleDate)}
                            </td>
                            <td style={{ width: "10%" }}>
                              {data.status === 0 ? (
                                <React.Fragment>
                                  <span className="progress-btn yellow">
                                    Pending
                                  </span>
                                </React.Fragment>
                              ) : data.status === 1 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn sky"
                                  >
                                    Verified
                                  </span>
                                </React.Fragment>
                              ) : data.status === 2 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn blue"
                                  >
                                    Invoice Created
                                  </span>
                                </React.Fragment>
                              ) : data.status === 3 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn red"
                                  >
                                    Rejected
                                  </span>
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}

                              {/* <a href="#" className="progress-btn sky">Verified</a> */}
                            </td>
                            <td style={{ width: "11%" }}>
                              $ {data.totalAmount}
                            </td>
                            <td style={{ width: "6%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {/* {checkAction(data.status, data.id, i)} */}
                                {/* <FcInfo size={30} onClick={() => this.openViewNoteModal(data)} /> */}
                                <FiEdit size={30} onClick={() => this.openViewNoteModal(data)} />
                                {checkAction(data, i)}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="7">
                          <center style={{ fontSize: "20px" }}>
                            No data found !!!
                          </center>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tab-app-information" id="transactionPayable">
          <div className="table-listing-app">
            <div className="table-responsive">
              <table
                width="100%"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody>
                  <tr>
                    <th style={{ width: "9%" }}>
                      <strong>Project ID</strong>
                    </th>
                    <th style={{ width: "10%" }}>Service</th>
                    {/* <th style={{ width: "10%" }}>Client(Bill To)</th> */}
                    <th style={{ width: "10%" }}>Vendor</th>
                    <th style={{ width: "10%" }}>Vendor Type</th>
                    <th style={{ width: "12%" }}>Actual Date</th>
                    <th style={{ width: "10%" }}>Status</th>
                    <th style={{ width: "9%" }}>Total Amount</th>
                    <th style={{ width: "6%" }}>Action</th>
                  </tr>
                </tbody>
                <tbody>
                  {this.state.payableData.length > 0 ? (
                    <React.Fragment>
                      {this.state.payableData.map((data, i) => (
                        <React.Fragment>
                          <tr>
                            <td
                              style={{ width: "9%" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={data.jobId}
                            >
                              <label className="custom_check2">
                                <input
                                  type="checkbox"
                                  name={i}
                                  checked={data.isSelected}
                                  onChange={this.receivableTypeChange(
                                    "payable",
                                    i
                                  )}
                                />
                                <span
                                  className="checkmark2"
                                  style={{ padding: "10px" }}
                                ></span>
                                <strong>
                                  <div
                                    onClick={() =>
                                      this.showDetails(data, i)
                                    }
                                    style={{
                                      paddingLeft: "5px",
                                      paddingTop: "4px",
                                    }}
                                  >
                                    {data.jobId.length > 15
                                      ? textTruncate(data.jobId, 15)
                                      : data.jobId}
                                    {/* {data.jobId} */}
                                  </div>
                                </strong>
                              </label>
                            </td>
                            <td style={{ width: "10%" }}>
                              {data.serviceType}
                            </td>
                            {/* <td style={{ width: "10%" }}>{data.clientName}</td> */}
                            <td
                              style={{ width: "10%" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={data.vendorName}
                            >
                              {data.vendorName.length > 20
                                ? textTruncate(data.vendorName, 20)
                                : data.vendorName}
                            </td>
                            <td style={{ width: "10%" }}>
                              {" "}
                              {data.vendorType}
                            </td>
                            <td style={{ width: "12%" }}>
                              {SetDateFormat(data.scheduleDate)}
                            </td>
                            <td style={{ width: "10%" }}>
                              {data.status === 0 ? (
                                <React.Fragment>
                                  <span className="progress-btn yellow">
                                    Pending
                                  </span>
                                </React.Fragment>
                              ) : data.status === 1 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn sky"
                                  >
                                    Verified
                                  </span>
                                </React.Fragment>
                              ) : data.status === 2 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn blue"
                                  >
                                    Invoice Created
                                  </span>
                                </React.Fragment>
                              ) : data.status === 3 ? (
                                <React.Fragment>
                                  <span
                                    href="#"
                                    className="progress-btn red"
                                  >
                                    Rejected
                                  </span>
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )}

                              {/* <a href="#" className="progress-btn sky">Verified</a> */}
                            </td>
                            <td style={{ width: "9%" }}>
                              $ {data.totalAmount}
                            </td>
                            <td style={{ width: "6%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {/* <FcInfo size={30} onClick={() => this.openViewNoteModal(data)} /> */}
                                <FiEdit size={30} onClick={() => this.openViewNoteModal(data)} />
                                {checkAction(data, i)}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="7">
                          <center style={{ fontSize: "20px" }}>
                            No data found !!!
                          </center>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}

        {/* ..................modal................................. */}
        <div id="filter-model" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-lg">
            {/* <!-- Modal content--> */}
            <div className="modal-content">
              <div className="filter-head _fl mdf">
                <h3>Filter by</h3>
                <div className="reset-btn-dp">
                  <button className="reset" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.RESET_BTN}
                      onClick={this.onResetFilter}
                    />
                    Reset
                  </button>
                  <button className="apply" data-dismiss="modal">
                    <img
                      src={ImageName.IMAGE_NAME.BLUE_TICK}
                      onClick={this.onFilterApply}
                    />
                    Apply
                  </button>
                </div>
              </div>
              {this.state.isReceivable ? (
                <React.Fragment>
                  <div className="modal-body">
                    <div className="model-info f-model">
                      <div className="form-search-app">
                        <div className="lable-text">requested on</div>
                        <div className="form-field-app">
                          <span>from</span>
                          <input
                            type="date"
                            className="datefield bd"
                            placeholder="10/25/2021"
                            value={this.state.formDate}
                            onChange={this.formDateChange}
                          />
                        </div>
                        <div className="form-field-app">
                          <span>to</span>
                          <input
                            type="date"
                            className="datefield bd"
                            placeholder="10/25/2021"
                            value={this.state.toDate}
                            onChange={this.toDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="modal-body">
                    <div className="model-info f-model">
                      <div className="form-search-app">
                        <div className="lable-text">requested on</div>
                        <div className="form-field-app">
                          <span>from</span>
                          <input
                            type="date"
                            className="datefield bd"
                            placeholder="10/25/2021"
                            value={this.state.formDate_payable}
                            onChange={this.formDateChange_payable}
                          />
                        </div>
                        <div className="form-field-app">
                          <span>to</span>
                          <input
                            type="date"
                            className="datefield bd"
                            placeholder="10/25/2021"
                            value={this.state.toDate_payable}
                            onChange={this.toDateChange_payable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal-backdrop fade show"
          id="backdrop"
          style={{ display: "none" }}
        ></div>



        <Modal
          show={this.state.viewNoteModal}
          onHide={this.closeViewNoteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Body>
            {/* <Box sx={styleCancelModal}> */}
            <div className="doc_action_modal">
              <div className="edit_details" hidden={this.state.updateDetails} >
                <FiEdit size={25} onClick={this.changeUpdateDetails} />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Quantity
                </span>
                <input
                  placeholder="Quantity"
                  className="inputfield"
                  type="number"
                  value={this.state.billQty}
                  onChange={(e) =>
                    this.changeBillQty(e)
                  }
                  disabled={!this.state.updateDetails}
                />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Unit Price
                </span>
                <input
                  placeholder="unitPrice"
                  className="inputfield"
                  type="number"
                  value={this.state.billUnitPrice}
                  onChange={(e) =>
                    this.changeUnitPrice(e)
                  }
                  disabled={!this.state.updateDetails}
                />
              </div>
              <div className="vn_frm">
                <span
                  style={{
                    width: "35%",
                    fontSize: "15px",
                  }}
                >
                  Total Amount
                </span>
                <InputText
                  placeholder="amount"
                  className="inputfield"
                  value={this.state.updatedAmount}
                  onTextChange={(value) => {
                    this.changeUpdateAmount(value);
                  }}
                  disabled={true}
                />
              </div>
              <span className="tr_nl">
                <label>Description</label>
                <textarea
                  rows="2"
                  placeholder="Enter Descriptions ..."
                  className="in-textarea msg min"
                  value={this.state.viewNote}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={this.changeViewDescription}
                  disabled={!this.state.updateDetails}
                ></textarea>
              </span>

              <div>
                <label>Remarks</label>
                <textarea
                  rows="2"
                  placeholder="Enter Remarks ..."
                  className="in-textarea msg min"
                  value={this.state.billRemarks}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={this.changeBillRemarks}
                  disabled={!this.state.updateDetails}
                ></textarea>
                {/* {this.state.viewNote} */}
              </div>
              <div className="edit_details_btn_sec" hidden={!this.state.updateDetails}>
                <Button onClick={this.closeViewNoteModal} color="primary">
                  Close
                </Button>
                <Button onClick={this.updateBillDetails} color="primary">
                  Update
                </Button>
              </div>
            </div>
            {/* </Box> */}

          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.rejectModal}
          onHide={this.closeRejectModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Modal.Header className="reject_payables_details" closeButton>
            <Modal.Title>Are you sure want to reject ? </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="">
              <span className="tr_nl">
                <label>Enter Reject Reason</label>
                <textarea
                  rows="2"
                  placeholder="Please type anything..."
                  className="in-textarea msg min"
                  value={this.state.rejectReason}
                  style={{
                    height: "100px",
                    color: "var(--grey)",
                    borderRadius: "10px",
                    boxShadow: "2px",
                    resize: "none",
                  }}
                  onChange={(e) => this.rejectReasonChange(e)}
                ></textarea>
                {/* {this.state.viewNote} */}
              </span>
              <Button onClick={this.closeRejectModal} color="primary">
                Close
              </Button>
              <Button onClick={this.submitReject} color="primary">
                Reject
              </Button>

            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
