import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { emailValidator } from "../../../../validators";
import { MdContacts } from "react-icons/md";
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContentText from '@material-ui/core/DialogContentText';
import { toast, ToastContainer } from "react-toastify";
import { consoleLog } from "../../../../services/common-function";
import { ApiCall } from "../../../../services/middleware";
import { ErrorCode } from "../../../../services/constant";
import { Decoder } from "../../../../services/auth";

let message = "";

message += "<br/><br/><br/><p>Thanks & Regards, </p>";
message += "<p><strong>7C Lingo Team </strong></p>";
message += "<p><strong>Email: 7c@7clingo.com</strong></p>"
message += "<p><strong>Call or Text: 810-328-3570</strong></p></body>";

export default class EmailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            allInviteeList: [],
            allEmailInviteList: [],
            allCcEmailInviteList: [],
            shareEmailText: "",
            allEmailArr: [],
            errorJoiningName: false,
            conatctListPopupPrimaryEmail: false,
            errorCcJoiningName: false,
            allCcEmailArr: [],
            shareCcEmailText: "",
            shareMailSubject: "",
            shareEmailBody: message,
            hideShareBtn: false,
            contactListPopupCCemail: false,
        }
    }

    componentDidMount() {
        this.getInviteeListData();
        this.getCcInviteeListData();
    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    getInviteeListData = async () => {
        let req = {
            jobId: this.props.jobId,
            search: '',
            userType: ""
        }

        // consoleLog("Get Invitee List req >>> ", req)

        let res = await ApiCall("getIviteeList", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // console.log("Get job invitee response >>>> :::", payload.data);
            let arr = [],
                arr2 = [],
                arr3 = [];
            if (payload.data.list.length > 0) {
                for (let list of payload.data.list) {
                    list['isCheck'] = 0;
                    arr.push(list);
                }
                for (let list2 of payload.data.list) {
                    list2['isCheck'] = 0;
                    arr2.push(list2);
                }
            }
            // consoleLog("Get job invitee response >>>> :::", arr)
            this.setState({
                allInviteeList: arr,
                allEmailInviteList: arr2
            })
        }
    }

    getCcInviteeListData = async () => {
        let req = {
            jobId: this.props.jobId,
            search: '',
            userType: ""
        }

        // consoleLog("Get Invitee List req >>> ", req)

        let res = await ApiCall("getIviteeList", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // console.log("Get job invitee response >>>> :::", payload.data);
            let arr = [];
            if (payload.data.list.length > 0) {
                for (let list of payload.data.list) {
                    list['isCheck'] = 0;
                    arr.push(list);
                }
            }
            // consoleLog("Get job invitee response >>>> :::", arr)
            this.setState({
                allCcEmailInviteList: arr
            })
        }
    }

    handleCloseShareModal = () => {
        this.props.closeMailModal();
    }

    onChangeEmail = (e) => {

        if (e.charCode == 13 || e.charCode == 32) {
            // alert('Enter... (KeyPress, use charCode)');
            // if(emailValidator(e.target.value)){

            // }
            // console.log("ASD>>", emailValidator(e.target.value));
            let validateEmail = emailValidator(e.target.value);

            if (validateEmail.status) {
                let arr = this.state.allEmailArr;
                arr.push(e.target.value);
                this.setState({
                    allEmailArr: arr,
                    shareEmailText: ''
                })
            } else {
                this.setState({
                    errorJoiningName: true
                })
            }
        } else {
            this.setState({
                shareEmailText: e.target.value
            })
        }
    }

    changeEmailText = (e) => {
        let val = e.target.value;
        let temp = val.split(',');
        if (temp.length > 1) {
            let arr = this.state.allEmailArr;
            for (let i = 0; i < temp.length - 1; i++) {
                if (temp[i] != "") {
                    arr.push(temp[i]);

                }
            }
            this.setState({
                allEmailArr: arr,
            })
        }
        this.setState({
            shareEmailText: temp[temp.length - 1],
            errorJoiningName: false
        })
    }

    openContactListPopupPrimaryEmail = () => {
        this.setState({
            conatctListPopupPrimaryEmail: true
        })
    }

    closeContactListPopupPrimaryEmail = () => {
        this.setState({
            conatctListPopupPrimaryEmail: false
        })
    }

    checkInvitePrimaryEmail = async (e, pos) => {
        let temp = this.state.allEmailInviteList;
        if (e.target.checked) {
            temp[pos].isCheck = 1;
        } else {
            temp[pos].isCheck = 0;
        }
        // console.log(arr);
        await this.loaderChange(true);
        this.setState({
            allEmailInviteList: temp
        })
        await this.loaderChange(false);
    }

    submitCheckInviteForPrimaryEmail = async () => {
        let arr = [];
        this.state.allEmailInviteList.map((data) => {
            if (data.isCheck === 1) {
                arr.push(data.email)
            }
        })
        await this.loaderChange(true);
        this.setState({
            allEmailArr: arr,
            conatctListPopupPrimaryEmail: false
        })
        await this.loaderChange(false);
    }

    onChangeCcEmail = (e) => {

        if (e.charCode == 13 || e.charCode == 32) {
            // alert('Enter... (KeyPress, use charCode)');
            // if(emailValidator(e.target.value)){

            // }
            // console.log("ASD>>", emailValidator(e.target.value));
            let validateEmail = emailValidator(e.target.value);

            if (validateEmail.status) {
                let arr = this.state.allCcEmailArr;
                arr.push(e.target.value);
                this.setState({
                    allCcEmailArr: arr,
                    shareCcEmailText: ''
                })
            } else {
                this.setState({
                    errorCcJoiningName: true
                })
            }
        } else {
            this.setState({
                shareCcEmailText: e.target.value
            })
        }
    }

    changeCcEmailText = (e) => {
        let val = e.target.value;
        let temp = val.split(',');
        if (temp.length > 1) {
            let arr = this.state.allCcEmailArr;
            for (let i = 0; i < temp.length - 1; i++) {
                if (temp[i] != "") {
                    arr.push(temp[i]);

                }
            }
            this.setState({
                allCcEmailArr: arr,
            })
        }
        this.setState({
            shareCcEmailText: temp[temp.length - 1],
            errorCcJoiningName: false
        })
    }

    /* cc Email Contact Modal Functionality start */

    openContactListPopupCcEmail = () => {
        this.setState({
            contactListPopupCCemail: true
        })
    }

    closeContactListPopupCcEmail = () => {
        this.setState({
            contactListPopupCCemail: false
        })
    }

    submitCheckInviteForCcEmail = async () => {
        let arr = [];
        this.state.allCcEmailInviteList.map((data) => {
            if (data.isCheck === 1) {
                arr.push(data.email)
            }
        })
        await this.loaderChange(true);
        console.log("CC EMAIL >>> ", arr)
        this.setState({
            allCcEmailArr: arr,
            contactListPopupCCemail: false
        })
        await this.loaderChange(false);
    }

    checkInviteCcEmail = async (e, pos) => {
        let arr = this.state.allCcEmailInviteList;
        if (e.target.checked) {
            arr[pos].isCheck = 1;
        } else {
            arr[pos].isCheck = 0;
        }
        // console.log(arr);
        await this.loaderChange(true);
        this.setState({
            allCcEmailInviteList: arr
        })
        await this.loaderChange(false);
    }


    deleteCcEmail = (pos) => {
        let arr = this.state.allCcEmailArr,
            brr = [];
        arr.map((data, i) => {
            if (i != pos) {
                brr.push(data)
            }
        });
        // console.log("Length>>", brr.length)
        this.setState({
            allCcEmailArr: brr
        })
    }

    deleteEmail = (pos) => {
        let arr = this.state.allEmailArr,
            brr = [];
        arr.map((data, i) => {
            if (i != pos) {
                brr.push(data)
            }
        });
        // console.log("Length>>", brr.length)
        this.setState({
            allEmailArr: brr
        })
    }

    changeEmailSubject = (e) => {
        this.setState({
            shareMailSubject: e.target.value
        })
    }

    shareCallLink = async () => {
        let errorCounter = 0;
        if (this.state.shareEmailText === '' && this.state.allEmailArr.length == 0) {
            toast.error('Please enter an email');
            errorCounter++;
            return false;
        } else if (this.state.shareMailSubject === '') {
            toast.error('Please enter email subject');
            errorCounter++;
            return false;
        } else if (this.state.shareEmailBody === '') {
            toast.error('Please enter email body');
            errorCounter++;
            return false;
        }

        if (errorCounter === 0) {
            this.setState({
                hideShareBtn: true
            })
            let data = {};
            if (this.state.shareEmailText === '') {
                // data = {
                //   jobId: this.props.jobId,
                //   emails: this.state.allEmailArr,
                //   emailBody: this.state.shareEmailBody
                // }

                data = {
                    jobId: this.props.jobId,
                    body: this.state.shareEmailBody,
                    to: this.state.allEmailArr,
                    cc: this.state.allCcEmailArr,
                    subject: this.state.shareMailSubject,
                    fromApp: "no"
                }
                this.shareLinkApi(data);
            }
            else {
                let validateEmail = emailValidator(this.state.shareEmailText);
                if (validateEmail.status) {
                    let arr = this.state.allEmailArr;
                    arr.push(this.state.shareEmailText);
                    this.setState({
                        allEmailArr: arr
                    });
                    data = {
                        jobId: this.props.jobId,
                        body: this.state.shareEmailBody,
                        to: this.state.allEmailArr,
                        cc: this.state.allCcEmailArr,
                        subject: this.state.shareMailSubject,
                        fromApp: "no"
                    }
                    this.shareLinkApi(data);
                } else {
                    this.setState({
                        errorJoiningName: true,
                        hideShareBtn: false
                    })
                }
            }
        }
    }

    shareLinkApi = async (data) => {
        consoleLog("Share Call Link Request:", data);
        let res = await ApiCall("jobEmailSentWithFooter", data);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success('Request sent successfully');
            this.handleCloseShareModal();
            this.setState({
                hideShareBtn: false,
                allEmailArr: [],
                shareEmailText: ''
            })
        } else {
            toast.error(res.message);
            this.setState({
                hideShareBtn: false
            })
        }
    }

    render() {
        return (<>

            <Modal show={this.props.showShare} onHide={this.handleCloseShareModal} aria-labelledby="form-dialog-title"
                dialogClassName="audio-modal">
                <Modal.Header id="form-dialog-title">Job Email Sent</Modal.Header>
                <Modal.Body>
                    <div>
                        <DialogContentText>
                            To
                        </DialogContentText>
                        {/* <input
                            type='text'
                            placeholder="Email Address"
                            className="sms_numberInput"
                            value={this.state.shareEmailText}
                            onKeyPress={this.onChangeEmail}
                            onChange={
                                this.changeEmailText
                            }
                            readOnly={true}
                        /> */}
                        <span className="contact_sms_icon" onClick={() => this.openContactListPopupPrimaryEmail()}>
                            <MdContacts size={30} />
                        </span>
                        <span className='joiningFieldError' hidden={!this.state.errorJoiningName}>*Please enter valid email</span>
                        <div className="row">
                            {this.state.allEmailArr.map((em, i) =>
                                <div className="col-md-4" style={{ paddingTop: '10px' }}>
                                    <Card>
                                        <Card.Body className="share_card_body">
                                            {em}
                                            <CloseButton style={{ float: 'right' }} onClick={() => this.deleteEmail(i)} />
                                        </Card.Body>
                                    </Card>

                                </div>)}
                        </div>
                    </div>
                    <div className="pt-15">
                        <DialogContentText>
                            Cc
                        </DialogContentText>
                        {/* <input
                            type='text'
                            placeholder="Email Address"
                            className="sms_numberInput"
                            value={this.state.shareCcEmailText}
                            onKeyPress={this.onChangeCcEmail}
                            onChange={
                                this.changeCcEmailText
                            }
                            readOnly={true}
                        /> */}
                        <span className="contact_sms_icon" onClick={() => this.openContactListPopupCcEmail()}>
                            <MdContacts size={30} />
                        </span>
                        <span className='joiningFieldError' hidden={!this.state.errorCcJoiningName}>*Please enter valid email</span>
                        <div className="row">
                            {this.state.allCcEmailArr.map((em, i) =>
                                <div className="col-md-4" style={{ paddingTop: '10px' }}>
                                    <Card>
                                        <Card.Body className="share_card_body">
                                            {em}
                                            <CloseButton style={{ float: 'right' }} onClick={() => this.deleteCcEmail(i)} />
                                        </Card.Body>
                                    </Card>

                                </div>)}
                        </div>
                    </div>
                    <div className="pt-15">
                        <DialogContentText>
                            Subject
                        </DialogContentText>
                        <input
                            type='text'
                            placeholder="Subject"
                            className="sms_numberInput"
                            value={this.state.shareMailSubject}
                            onChange={
                                this.changeEmailSubject
                            }
                        />
                    </div>

                    <div className="editor_pad-vri">

                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.shareEmailBody}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                this.setState({
                                    shareEmailBody: data
                                })
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer hidden={this.state.hideShareBtn}>
                    <Button onClick={this.handleCloseShareModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.shareCallLink} color="primary">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.conatctListPopupPrimaryEmail} onHide={this.closeContactListPopupPrimaryEmail} dialogClassName="contact-mod" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {this.state.allEmailInviteList.map((data, i) => <div className="pt-10">
                            <div className="col-md-1">
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    // checked={data.isCheck === 1}
                                    onChange={(e) => this.checkInvitePrimaryEmail(e, i)}
                                />
                            </div>
                            <div className="col-md-11 name_con" >
                                {data.name + " ( " + data.userTypeName + " )"} <br />
                                {data.email}
                            </div>
                        </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button onClick={() => this.submitCheckInviteForPrimaryEmail()} color="primary">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.contactListPopupCCemail} onHide={this.closeContactListPopupCcEmail} dialogClassName="contact-mod" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {this.state.allCcEmailInviteList.map((data, i) => <div className="pt-10">
                            <div className="col-md-1">
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    // checked={data.isCheck === 1}
                                    onChange={(e) => this.checkInviteCcEmail(e, i)}
                                />
                            </div>
                            <div className="col-md-11 name_con" >
                                {data.name + " ( " + data.userTypeName + " )"} <br />
                                {data.email}
                            </div>
                        </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button onClick={() => this.submitCheckInviteForCcEmail()} color="primary">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    }
}