import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./store/CombineReducer/index";

// const store = createStore(
//   RootReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );


const store = createStore(RootReducer, applyMiddleware(thunk));
export default store;
