const INITIAL_STATE = {
    allReport: [],
    success : true
  };
  
  const ReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_REPORT": {
        return {
          ...state,
          // header: action.payload,
          allReport : action.payload
        };
      }
      case "CREATE_REPORT": {
        return {
          ...state
        };
      }
      default:
        return state;
    }
  };
  
  export default ReportReducer;
  