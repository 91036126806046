import { Logger } from './logger';
import { Channel, NotificationLevel, ChannelStatus, ChannelType } from './channel';
import { Client } from './client';
import { parseAttributes, parseTime } from './util';

const log = Logger.scope('ChannelDescriptor');

/**
 * Contains channel information.
 * Unlike {@link Channel}, this information won't be updated in realtime.
 * To have fresh data, user should query for channel descriptors again.
 */
class ChannelDescriptor {
  public client: Client;
  public descriptor: any;

  /**
   * Unique system identifier of the channel.
   */
  public readonly sid: string;

  /**
   * Custom attributes of the channel.
   */
  public readonly channel: string;

  /**
   * Unique name of the channel.
   */
  public readonly uniqueName: string;

  /**
   * Name of the channel.
   */
  public readonly friendlyName: string;

  /**
   * Custom attributes of the channel.
   */
  public readonly attributes: any;

  /**
   * Identity of the user that created this channel.
   */
  public readonly createdBy: string;

  /**
   * Date this channel was created on.
   */
  public readonly dateCreated: Date;

  /**
   * Date this channel was last updated on.
   */
  public readonly dateUpdated: Date;

  /**
   * Amount of messages in the channel.
   */
  public readonly messagesCount: number;

  /**
   * Amount of members in the channel.
   */
  public readonly membersCount: number;

  /**
   * Index of the last message the user has read in this channel.
   */
  public readonly lastConsumedMessageIndex: number;

  /**
   * Delivery status of the channel.
   */
  public readonly status: ChannelStatus;

  /**
   * MemberType of the channel.
   */
  public readonly type: ChannelType;

  /**
   * Signifies whether the channel is private.
   */
  public readonly isPrivate: boolean;

  /**
   * User notification level for this channel.
   */
  public readonly notificationLevel: NotificationLevel;

  /**
   * @internal
   */
  constructor(client: Client, descriptor: any) {
    this.client = client;
    this.descriptor = descriptor;

    this.sid = descriptor.sid || descriptor.conversation_sid;
    this.channel = `${this.sid}.channel`;
    this.uniqueName = descriptor.unique_name;
    this.friendlyName = descriptor.friendly_name;
    this.attributes = parseAttributes(descriptor.attributes, 'Failed to parse channel attributes', log);
    this.createdBy = descriptor.created_by;
    this.dateCreated = parseTime(descriptor.date_created);
    this.dateUpdated = parseTime(descriptor.date_updated);
    this.messagesCount = descriptor.messages_count;
    this.membersCount = descriptor.participants_count;
    this.type = descriptor.type;
    this.isPrivate = descriptor.type === 'private';
    this.lastConsumedMessageIndex = descriptor.last_consumed_message_index;
    this.notificationLevel = descriptor.notification_level || undefined;
    this.status = descriptor.status || 'unknown';
  }

  /**
   * Get the channel object from the descriptor.
   */
  getChannel(): Promise<Channel> {
    return this.client.getChannelBySid(this.sid);
  }
}

export { ChannelDescriptor };
