import React from "react";
import { consoleLog } from "../../../../services/common-function";

export default class TableDynamicReports extends React.Component {
    constructor(props) {
        super(props);
    }

    getColspanNumber = () => {
        let counter = 10;
        if (this.props.data.fields) {
            this.props.data.fields.map((data) => {
                if (data.display) {
                    counter++;
                }
            });
        }
        return counter;
    }

    render() {
        return (
            <table width="100%" border="0" cellpadding="0" cellspacing="0">
                <tr>
                    {Object.keys(this.props.data).length > 0 ? <>
                        {this.props.data.fields.map((data, i) =>
                            <>
                                <th key={i} hidden={!data.display}>
                                    <span style={{ fontSize: "13px" }}>{data.label}</span>
                                </th>
                            </>
                        )} </> : <></>
                    }
                </tr>
                {this.props.list.length > 0 ? <>
                    {this.props.list.map((data, i) =>
                        <tr key={i}>
                            {/* <td > */}
                                {/* <div className="tble-row">
                                    <table
                                        width="100%"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                    >
                                        <tr> */}
                                {this.props.data.fields.map((item, j) =>
                                    <td key={j} hidden={!item.display}>{data[item.name]}</td>
                                )}
                                {/* <td style={{ width: "20%" }}>Report 1</td>

                                            <td style={{ width: "20%" }}>Dummy Report Type</td>
                                            <td style={{ width: "20%" }}>Dummy Data</td>
                                            <td style={{ width: "10%" }}>Last 6 Months</td>
                                            <td style={{ width: "10%" }}></td> */}
                                {/* </tr>
                                    </table>
                                </div> */}
                            {/* </td> */}
                        </tr>
                    )}
                </> : <>
                    <td colSpan={this.getColspanNumber()}>
                        No Data Found !
                    </td>
                </>
                }
            </table>
        )
    }
}