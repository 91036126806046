import { Logger } from './logger';

import { Members } from './data/members';
import { Member, MemberUpdatedEventArgs, MemberUpdateReason } from './member';
import { Messages } from './data/messages';
import { Message, MessageUpdatedEventArgs, MessageUpdateReason } from './message';

import { UriBuilder, parseToNumber } from './util';
import { UserDescriptor } from './userdescriptor';
import { Users } from './data/users';
import { Paginator } from './interfaces/paginator';
import { Channels } from './data/channels';
import { McsClient } from '@twilio/mcs-client';

import { SyncClient } from 'twilio-sync';
import { TypingIndicator } from './services/typingindicator';
import { Network } from './services/network';
import { validateTypesAsync, custom, literal, nonEmptyString, nonNegativeInteger, objectSchema } from '@twilio/declarative-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { JoinChannelRequest, JoinChannelResponse } from './interfaces/commands/joinchannel';
import { EditChannelRequest } from './interfaces/commands/editchannel';
import { ChannelResponse } from './interfaces/commands/channel';
import { EditNotificationLevelRequest } from './interfaces/commands/editnotificationlevel';
import { EditLastConsumedMessageIndexRequest, EditLastConsumedMessageIndexResponse } from './interfaces/commands/editlastconsumedmessageindex';
import isEqual from 'lodash.isequal';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';

type ChannelEvents = {
  memberJoined: (member: Member) => void;
  memberInvited: (member: Member) => void;
  memberLeft: (member: Member) => void;
  memberUpdated: (data: {
    member: Member;
    updateReasons: MemberUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  typingEnded: (member: Member) => void;
  typingStarted: (member: Member) => void;
  updated: (data: {
    channel: Channel;
    updateReasons: ChannelUpdateReason[];
  }) => void;
  removed: (channel: Channel) => void;
};

const log = Logger.scope('Channel');

const fieldMappings = {
  lastMessage: 'lastMessage',
  attributes: 'attributes',
  createdBy: 'createdBy',
  dateCreated: 'dateCreated',
  dateUpdated: 'dateUpdated',
  friendlyName: 'friendlyName',
  lastConsumedMessageIndex: 'lastConsumedMessageIndex',
  notificationLevel: 'notificationLevel',
  sid: 'sid',
  status: 'status',
  type: 'type',
  uniqueName: 'uniqueName',
  state: 'state'
};

function parseTime(timeString) {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

interface ChannelServices {
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

interface ChannelInternalState {
  uniqueName: string;
  status: ChannelStatus;
  type: ChannelType;
  attributes: any;
  createdBy?: string;
  dateCreated: Date;
  dateUpdated: Date;
  friendlyName: string;
  lastConsumedMessageIndex: number | null;
  lastMessage?: LastMessage;
  notificationLevel?: NotificationLevel;
  state?: ChannelState;
}

interface ChannelDescriptor {
  channel: string;
  entityName: string;
  uniqueName: string;
  attributes: any;
  createdBy?: string;
  friendlyName: string;
  lastConsumedMessageIndex: number;
  dateCreated: any;
  dateUpdated: any;
  type: ChannelType;
  notificationLevel?: NotificationLevel;
}

interface ChannelLinks {
  self: string;
  messages: string;
  participants: string;
  invites: string;
}

/**
 * The reason for the `updated` event being emitted by a channel.
 */
type ChannelUpdateReason =
  | 'attributes'
  | 'createdBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'friendlyName'
  | 'lastConsumedMessageIndex'
  | 'state'
  | 'status'
  | 'uniqueName'
  | 'lastMessage'
  | 'notificationLevel';

/**
 * The status of the channel, relative to the client: whether
 * the channel has been `joined` or the client is
 * `notParticipating` in the channel or has been `invited` to
 * the channel.
 */
type ChannelStatus = 'unknown' | 'notParticipating' | 'invited' | 'joined';

/**
 * Channel type.
 */
type ChannelType = 'public' | 'private';

/**
 * The user's notification level for the channel. Determines
 * whether the currently logged-in user will receive pushes for events
 * in this channel. Can be either `muted` or `default`, where
 * `default` defers to the global service push configuration.
 */
type NotificationLevel = 'default' | 'muted';

/**
 * The conversational state of the channel.
 */
type ChannelState = {
  /**
   * The current state.
   */
  current: 'active' | 'inactive' | 'closed',

  /**
   * Date at which the latest channel state update happened.
   */
  dateUpdated: Date
};

interface ChannelUpdatedEventArgs {
  channel: Channel;
  updateReasons: ChannelUpdateReason[];
}

/**
 * These options can be passed to {@link Channel.sendMessage}.
 */
interface SendMediaOptions {
  /**
   * Content type of media.
   */
  contentType: string;

  /**
   * Content to post.
   */
  media: string | Buffer;
}

/**
 * Information about the last message of a channel.
 */
interface LastMessage {
  /**
   * Message's index.
   */
  index?: number;

  /**
   *  Message's creation date.
   */
  dateCreated?: Date;
}

/**
 * A channel represents a remote channel of communication between multiple Programmable Chat clients.
 */

class Channel extends ReplayEventEmitter<ChannelEvents> {
  private readonly channelState: ChannelInternalState;
  private statusSource: Channels.DataSource;

  private entityPromise: Promise<any>;
  private entityName: string;
  private entity: any;
  private messagesEntity: any;
  private membersEntity: Members;
  private readonly members: any;

  /**
   * Unique system identifier of the channel.
   */
  public readonly sid: string;
  public readonly links: ChannelLinks;
  private readonly configuration: Configuration;
  private readonly services: ChannelServices;

  /**
   * @internal
   */
  constructor(
    descriptor: ChannelDescriptor,
    sid: string,
    links: ChannelLinks,
    configuration: Configuration,
    services: ChannelServices
  ) {
    super();

    this.sid = sid;
    this.links = links;
    this.configuration = configuration;
    this.services = services;

    let attributes = descriptor.attributes || {};
    let createdBy = descriptor.createdBy;
    let dateCreated = parseTime(descriptor.dateCreated);
    let dateUpdated = parseTime(descriptor.dateUpdated);
    let friendlyName = descriptor.friendlyName || null;
    let lastConsumedMessageIndex =
      Number.isInteger(descriptor.lastConsumedMessageIndex) ? descriptor.lastConsumedMessageIndex : null;
    let uniqueName = descriptor.uniqueName || null;

    try {
      JSON.stringify(attributes);
    } catch (e) {
      throw new Error('Attributes must be a valid JSON object.');
    }

    this.entityName = descriptor.channel;
    this.channelState = {
      uniqueName,
      status: 'notParticipating',
      type: descriptor.type,
      attributes,
      createdBy,
      dateCreated,
      dateUpdated,
      friendlyName,
      lastConsumedMessageIndex
    };

    if (descriptor.notificationLevel) {
      this.channelState.notificationLevel = descriptor.notificationLevel;
    }

    const membersLinks = {
      participants: this.links.participants
    };

    this.members = new Map();
    this.membersEntity = new Members(this, this.members, membersLinks, this.configuration, this.services);
    this.membersEntity.on('memberJoined', this.emit.bind(this, 'memberJoined'));
    this.membersEntity.on('memberLeft', this.emit.bind(this, 'memberLeft'));
    this.membersEntity.on('memberUpdated',
      (args: MemberUpdatedEventArgs) => this.emit('memberUpdated', args));

    this.messagesEntity = new Messages(this, this.configuration, services);
    this.messagesEntity.on('messageAdded', message => this._onMessageAdded(message));
    this.messagesEntity.on('messageUpdated',
      (args: MessageUpdatedEventArgs) => this.emit('messageUpdated', args));
    this.messagesEntity.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
  }

  /**
   * Fired when a member has joined the channel.
   *
   * Parameters:
   * 1. {@link Member} `member` - member that joined the channel
   * @event
   */
  static readonly memberJoined = 'memberJoined';

  /**
   * Fired when a member has left the channel.
   *
   * Parameters:
   * 1. {@link Member} `member` - member that left the channel
   * @event
   */
  static readonly memberLeft = 'memberLeft';

  /**
   * Fired when data of a member has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Member} `member` - member that has received the update
   *     * {@link MemberUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly memberUpdated = 'memberUpdated';

  /**
   * Fired when a new message has been added to the channel.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been added
   * @event
   */
  static readonly messageAdded = 'messageAdded';

  /**
   * Fired when message is removed from the channel's message list.
   *
   * Parameters:
   * 1. {@link Message} `message` - message that has been removed
   * @event
   */
  static readonly messageRemoved = 'messageRemoved';

  /**
   * Fired when data of a message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Message} `message` - message that has received the update
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly messageUpdated = 'messageUpdated';

  /**
   * Fired when a member has stopped typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member that has stopped typing
   * @event
   */
  static readonly typingEnded = 'typingEnded';

  /**
   * Fired when a member has started typing.
   *
   * Parameters:
   * 1. {@link Member} `member` - the member that has started typing
   * @event
   */
  static readonly typingStarted = 'typingStarted';

  /**
   * Fired when the data of the message has been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the following properties:
   *     * {@link Channel} `channel` - channel that has received the update
   *     * {@link ChannelUpdateReason}[] `updateReasons` - array of reasons for update
   * @event
   */
  static readonly updated = 'updated';

  /**
   * Fired when the channel was destroyed or the currently-logged-in user has left private channel.
   *
   * Parameters:
   * 1. {@link Channel} `channel` - channel that has been removed
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Delivery status of the channel.
   */
  public get status(): ChannelStatus { return this.channelState.status; }

  /**
   * MemberType of the channel.
   */
  public get type(): ChannelType { return this.channelState.type; }

  /**
   * Unique name of the channel.
   */
  public get uniqueName(): string { return this.channelState.uniqueName; }

  /**
   * Signifies whether the channel is private.
   */
  public get isPrivate(): boolean { return this.channelState.type === 'private'; }

  /**
   * Name of the channel.
   */
  public get friendlyName(): string { return this.channelState.friendlyName; }

  /**
   * Date this channel was last updated on.
   */
  public get dateUpdated(): any { return this.channelState.dateUpdated; }

  /**
   * Date this channel was created on.
   */
  public get dateCreated(): any { return this.channelState.dateCreated; }

  /**
   * Identity of the user that created this channel.
   */
  public get createdBy(): string { return this.channelState.createdBy; }

  /**
   * Custom attributes of the channel.
   */
  public get attributes(): Object { return this.channelState.attributes; }

  /**
   * Index of the last message the user has read in this channel.
   */
  public get lastConsumedMessageIndex(): number | null { return this.channelState.lastConsumedMessageIndex; }

  /**
   * Last message sent to this channel.
   */
  public get lastMessage(): LastMessage { return this.channelState.lastMessage; }

  /**
   * User notification level for this channel.
   */
  public get notificationLevel(): NotificationLevel { return this.channelState.notificationLevel; }

  /**
   * State of the channel.
   */
  public get state(): ChannelState | undefined { return this.channelState.state; }

  /**
   * Load and subscribe to this channel and do not subscribe to its members and messages.
   * This or _subscribeStreams will need to be called before any events on channel will fire.
   * @internal
   */
  async _subscribe() { // protected? private? used by others in module
    if (this.entityPromise) { return this.entityPromise; }

    return this.entityPromise = this.entityPromise ||
      this.services.syncClient.document({ id: this.entityName, mode: 'open_existing' })
        .then(entity => {
          this.entity = entity;
          this.entity.on('updated', args => { this._update(args.data); });
          this.entity.on('removed', () => this.emit('removed', this));
          this._update(this.entity.data);
          return entity;
        })
        .catch(err => {
          this.entity = null;
          this.entityPromise = null;
          if (this.services.syncClient.connectionState != 'disconnected') {
            log.error('Failed to get channel object', err);
          }
          log.debug('ERROR: Failed to get channel object', err);
          throw err;
        });
  }

  /**
   * Load the attributes of this channel and instantiate its members and messages.
   * This or _subscribe will need to be called before any events on the channel will fire.
   * This will need to be called before any events on members or messages will fire
   * @internal
   */
  private async _subscribeStreams() {
    try {
      await this._subscribe();
      log.trace('_subscribeStreams, this.entity.data=', this.entity.data);
      const messagesObjectName = this.entity.data.messages;
      const rosterObjectName = this.entity.data.roster;
      await Promise.all([
        this.messagesEntity.subscribe(messagesObjectName),
        this.membersEntity.subscribe(rosterObjectName)
      ]);
    } catch (err) {
      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error('Failed to subscribe on channel objects', this.sid, err);
      }
      log.debug('ERROR: Failed to subscribe on channel objects', this.sid, err);
      throw err;
    }
  }

  /**
   * Stop listening for and firing events on this channel.
   * @internal
   */
  private async _unsubscribe() {
    // Keep our subscription to public channels objects
    if (this.isPrivate && this.entity) {
      await this.entity.close();
      this.entity = null;
      this.entityPromise = null;
    }

    return Promise.all([
      this.membersEntity.unsubscribe(),
      this.messagesEntity.unsubscribe()
    ]);
  }

  /**
   * Set channel status.
   * @internal
   */
  _setStatus(status: ChannelStatus, source: Channels.DataSource) {
    this.statusSource = source;

    if (this.channelState.status === status) { return; }

    this.channelState.status = status;

    if (status === 'joined') {
      this._subscribeStreams()
          .catch(err => {
            log.debug('ERROR while setting channel status ' + status, err);
            if (this.services.syncClient.connectionState !== 'disconnected') {
              throw err;
            }
          });
    } else if (status === 'invited') {
      this._subscribe()
          .catch(err => {
            log.debug('ERROR while setting channel status ' + status, err);
            if (this.services.syncClient.connectionState !== 'disconnected') {
              throw err;
            }
          });
    } else if (this.entityPromise) {
      this._unsubscribe().catch(err => {
        log.debug('ERROR while setting channel status ' + status, err);
        if (this.services.syncClient.connectionState !== 'disconnected') {
          throw err;
        }
      });
    }
  }

  /**
   * Update source of the channel.
   * @internal
   */
  _statusSource(): Channels.DataSource {
    return this.statusSource;
  }

  private static preprocessUpdate(update, channelSid) {
    try {
      if (typeof update.attributes === 'string') {
        update.attributes = JSON.parse(update.attributes);
      } else if (update.attributes) {
        JSON.stringify(update.attributes);
      }
    } catch (e) {
      log.warn('Retrieved malformed attributes from the server for channel: ' + channelSid);
      update.attributes = {};
    }

    try {
      if (update.dateCreated) {
        update.dateCreated = new Date(update.dateCreated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateCreated from the server for channel: ' + channelSid);
      delete update.dateCreated;
    }

    try {
      if (update.dateUpdated) {
        update.dateUpdated = new Date(update.dateUpdated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateUpdated from the server for channel: ' + channelSid);
      delete update.dateUpdated;
    }

    try {
      if (update.lastMessage && update.lastMessage.timestamp) {
        update.lastMessage.timestamp = new Date(update.lastMessage.timestamp);
      }
    } catch (e) {
      log.warn('Retrieved malformed lastMessage.timestamp from the server for channel: ' + channelSid);
      delete update.lastMessage.timestamp;
    }
  }

  /**
   * Update the local channel object with new values.
   * @internal
   */
  _update(update) {
    log.trace('_update', update);

    Channel.preprocessUpdate(update, this.sid);
    const updateReasons = new Set<ChannelUpdateReason>();

    for (const key of Object.keys(update)) {
      const localKey = fieldMappings[key];

      if (!localKey) {
        continue;
      }

      switch (localKey) {
        case fieldMappings.status:
          if (!update.status || update.status === 'unknown'
            || this.channelState.status === update.status) {
            break;
          }

          this.channelState.status = update.status;
          updateReasons.add(localKey);

          break;
        case fieldMappings.attributes:
          if (isEqual(this.channelState.attributes, update.attributes)) {
            break;
          }

          this.channelState.attributes = update.attributes;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastConsumedMessageIndex:
          if (update.lastConsumedMessageIndex === undefined
            || update.lastConsumedMessageIndex === this.channelState.lastConsumedMessageIndex) {
            break;
          }

          this.channelState.lastConsumedMessageIndex = update.lastConsumedMessageIndex;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastMessage:
          if (this.channelState.lastMessage && !update.lastMessage) {
            delete this.channelState.lastMessage;
            updateReasons.add(localKey);

            break;
          }

          this.channelState.lastMessage = this.channelState.lastMessage || {};

          if (update.lastMessage?.index !== undefined
            && update.lastMessage.index !== this.channelState.lastMessage.index) {
            this.channelState.lastMessage.index = update.lastMessage.index;
            updateReasons.add(localKey);
          }

          if (update.lastMessage?.timestamp !== undefined
            && this.channelState.lastMessage?.dateCreated?.getTime() !== update.lastMessage.timestamp.getTime()) {
            this.channelState.lastMessage.dateCreated = update.lastMessage.timestamp;
            updateReasons.add(localKey);
          }

          if (isEqual(this.channelState.lastMessage, {})) {
            delete this.channelState.lastMessage;
          }

          break;
        case fieldMappings.state:
          const state = update.state || undefined;

          if (state !== undefined) {
            state.dateUpdated = new Date(state.dateUpdated);
          }

          if (isEqual(this.channelState.state, state)) {
            break;
          }

          this.channelState.state = state;
          updateReasons.add(localKey);

          break;
        default:
          const isDate = update[key] instanceof Date;
          const keysMatchAsDates = isDate && this.channelState[localKey]?.getTime() === update[key].getTime();
          const keysMatchAsNonDates = !isDate && this[localKey] === update[key];

          if (keysMatchAsDates || keysMatchAsNonDates) {
            break;
          }

          this.channelState[localKey] = update[key];
          updateReasons.add(localKey);
      }
    }

    if (updateReasons.size > 0) {
      this.emit('updated', { channel: this, updateReasons: [...updateReasons] });
    }
  }

  /**
   * @internal
   */
  private _onMessageAdded(message) {
    for (let member of this.members.values()) {
      if (member.identity === message.author) {
        member._endTyping();
        break;
      }
    }
    this.emit('messageAdded', message);
  }

  private async _setLastConsumedMessageIndex(index: number | null): Promise<number> {
    const result = await this.services.commandExecutor.mutateResource<
      EditLastConsumedMessageIndexRequest,
      EditLastConsumedMessageIndexResponse
    >(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        last_consumed_message_index: index
      }
    );

    return result.unread_messages_count;
  }

  /**
   * Add a member to the channel by its identity.
   * @param identity Identity of the Client to add.
   */
  @validateTypesAsync(nonEmptyString)
  public async add(identity: string): Promise<void> {
    await this.membersEntity.add(identity);
  }

  /**
   * Advance the channel's last consumed message index to the current read horizon.
   * Rejects if the user is not a member of the channel.
   * Last consumed message index is updated only if the new index value is higher than the previous.
   * @param index Message index to advance to.
   * @return Resulting unread messages count in the channel.
   */
  @validateTypesAsync(nonNegativeInteger)
  public async advanceLastConsumedMessageIndex(index: number): Promise<number> {
    await this._subscribeStreams();

    if (index < this.lastConsumedMessageIndex) {
      return await this._setLastConsumedMessageIndex(this.lastConsumedMessageIndex);
    }

    return await this._setLastConsumedMessageIndex(index);
  }

  /**
   * Decline an invitation to the channel and unsubscribe from its events.
   */
  public async decline(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      `${this.links.invites}/${this.configuration.userIdentity}`
    );

    return this;
  }

  /**
   * Delete the channel and unsubscribe from its events.
   */
  public async delete(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      this.links.self,
    );

    return this;
  }

  /**
   * Get the custom attributes of this Channel.
   *
   * *Note: {@link Channel.attributes} will be empty for public channels until this function is called.*
   */
  public async getAttributes(): Promise<any> {
    await this._subscribe();
    return this.attributes;
  }

  /**
   * Return messages from the channel using the paginator interface.
   * @param pageSize Number of messages to return in a single chunk. Default is 30.
   * @param anchor Index of the newest message to fetch. Default is from the end.
   * @param direction Query direction. By default it queries backwards
   * from newer to older. The `"forward"` value will query in the opposite direction.
   * @return A page of messages.
   */
  @validateTypesAsync(
    ['undefined', nonNegativeInteger],
    ['undefined', nonNegativeInteger],
    ['undefined', literal('backwards', 'forward')]
  )
  public async getMessages(pageSize?: number, anchor?: number, direction?: 'backwards' | 'forward'): Promise<Paginator<Message>> {
    await this._subscribeStreams();
    return await this.messagesEntity.getMessages(pageSize, anchor, direction);
  }

  /**
   * Get a list of all the members who are joined to this channel.
   */
  public async getMembers(): Promise<Member[]> {
    await this._subscribeStreams();
    return await this.membersEntity.getMembers();
  }

  /**
   * Get channel members count.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Programmable Chat system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  public async getMembersCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    return response.body.participants_count;
  }

  /**
   * Get a member by its SID.
   * @param memberSid Member SID.
   */
  @validateTypesAsync(nonEmptyString)
  public async getMemberBySid(memberSid: string): Promise<Member> {
    return await this.membersEntity.getMemberBySid(memberSid);
  }

  /**
   * Get a member by its identity.
   * @param identity Member identity.
   */
  @validateTypesAsync(nonEmptyString)
  public async getMemberByIdentity(identity: string): Promise<Member> {
    return await this.membersEntity.getMemberByIdentity(identity);
  }

  /**
   * Get the total message count in the channel.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Programmable Chat system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  public async getMessagesCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    return response.body.messages_count;
  }

  /**
   * Get unread messages count for the user if they are a member of this channel.
   * Rejects if the user is not a member of the channel.
   *
   * Use this method to obtain the number of unread messages together with
   * {@link Channel.updateLastConsumedMessageIndex} instead of relying on the
   * message indices which may have gaps. See {@link Message.index} for details.
   *
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also be possibly incorrect for a few seconds. The Programmable Chat system does not
   * provide real time events for counter values changes.
   *
   * This is useful for any UI badges, but it is not recommended to build any core application
   * logic based on these counters being accurate in real time.
   */
  public async getUnconsumedMessagesCount(): Promise<number | null> {
    const url = new UriBuilder(this.configuration.links.myConversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    if (response.body.conversation_sid !== this.sid) {
      throw new Error('Channel was not found in the user channels list');
    }

    const unreadMessageCount = response.body.unread_messages_count;

    if (typeof unreadMessageCount === 'number') {
      return unreadMessageCount;
    }

    return null;
  }

  /**
   * Invite a user to the channel by their identity.
   * @param identity Identity of the user.
   */
  @validateTypesAsync(nonEmptyString)
  public async invite(identity: string): Promise<void> {
    await this.membersEntity.invite(identity);
  }

  /**
   * Join the channel and subscribe to its events.
   */
  public async join(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource<JoinChannelRequest, JoinChannelResponse>(
      'post',
      this.links.participants,
      {
        identity: this.configuration.userIdentity
      }
    );

    return this;
  }

  /**
   * Leave the channel.
   */
  public async leave(): Promise<Channel> {
    if (this.channelState.status === 'joined') {
      await this.services.commandExecutor.mutateResource(
        'delete',
        `${this.links.participants}/${this.configuration.userIdentity}`,
      );
    }

    return this;
  }

  /**
   * Remove a member from the channel. When a string is passed as the argument, it will assume that the string is an identity.
   * @param member Identity or the member object to remove.
   */
  @validateTypesAsync([nonEmptyString, Member])
  public async removeMember(member: string | Member): Promise<void> {
    await this.membersEntity.remove(typeof member === 'string' ? member : member.sid);
  }

  /**
   * Send a message to the channel.
   * @param message Message body for the text message,
   * `FormData` or {@link Channel.MediaOptions) for media content. Sending FormData is supported only with the browser engine.
   * @param messageAttributes Attributes for the message.
   * @return Index of the new message.
   */
  @validateTypesAsync(
    [
      'string',
      literal(null),
      // Wrapping it into a custom rule is necessary because the FormData class is not available on initialization.
      custom((value) => [value instanceof FormData, 'an instance of FormData']),
      objectSchema('media options', {
        contentType: [nonEmptyString, 'undefined'],
        media: custom((value) => {
          let isValid = (typeof value === 'string' && value.length > 0) || value instanceof Uint8Array || value instanceof ArrayBuffer;

          if (typeof Blob === 'function') {
            isValid = isValid || value instanceof Blob;
          }

          return [
            isValid,
            'a non-empty string, an instance of Buffer or an instance of Blob'
          ];
        })
      })
    ],
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)]
  )
  public async sendMessage(message: string | FormData | SendMediaOptions | null, messageAttributes?: any): Promise<number> {
    if (typeof message === 'string' || message === null) {
      const response = await this.messagesEntity.send(message, messageAttributes);
      return parseToNumber(response.index);
    }

    const response = await this.messagesEntity.sendMedia(message, messageAttributes);
    return parseToNumber(response.index);
  }

  /**
   * Set last consumed message index of the channel to the index of the last known message.
   * @return Resulting unread messages count in the channel.
   */
  public async setAllMessagesConsumed(): Promise<number> {
    await this._subscribeStreams();
    const messagesPage = await this.getMessages(1);
    if (messagesPage.items.length > 0) {
      return this.advanceLastConsumedMessageIndex(messagesPage.items[0].index);
    }
    return Promise.resolve(0);
  }

  /**
   * Set all messages in the channel unread.
   * @return Resulting unread messages count in the channel.
   */
  public async setNoMessagesConsumed(): Promise<number> {
    await this._subscribeStreams();
    return await this._setLastConsumedMessageIndex(null);
  }

  /**
   * Set user notification level for this channel.
   * @param notificationLevel New user notification level.
   */
  @validateTypesAsync(literal('default', 'muted'))
  public async setUserNotificationLevel(notificationLevel: NotificationLevel): Promise<void> {
    await this.services.commandExecutor.mutateResource<EditNotificationLevelRequest>(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        notification_level: notificationLevel
      }
    );
  }

  /**
   * Send a notification to the server indicating that this client is currently typing in this channel.
   * Typing ended notification is sent after a while automatically, but by calling this method again you ensure that typing ended is not received.
   */
  public typing(): Promise<void> {
    return this.services.typingIndicator.send(this.sid);
  }

  /**
   * Update the attributes of the channel.
   * @param attributes New attributes.
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<Channel> {
    await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
      'post',
      this.links.self,
      { attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined }
    );

    return this;
  }

  /**
   * Update the friendly name of the channel.
   * @param friendlyName New friendly name.
   */
  @validateTypesAsync('string')
  public async updateFriendlyName(friendlyName: string): Promise<Channel> {
    if (this.channelState.friendlyName !== friendlyName) {
      await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
        'post',
        this.links.self,
        { friendly_name: friendlyName }
      );
    }

    return this;
  }

  /**
   * Set the last consumed message index to the current read horizon.
   * @param index Message index to set as last consumed.
   * If null is provided, then the behavior is identical to {@link Channel.setNoMessagesConsumed}.
   * @returns Resulting unread messages count in the channel.
   */
  @validateTypesAsync([literal(null), nonNegativeInteger])
  public async updateLastConsumedMessageIndex(index: number | null): Promise<number> {
    await this._subscribeStreams();
    return await this._setLastConsumedMessageIndex(index);
  }

  /**
   * Update the unique name of the channel.
   * @param uniqueName New unique name for the channel. Setting unique name to null removes it.
   */
  @validateTypesAsync(['string', literal(null)])
  public async updateUniqueName(uniqueName: string | null): Promise<Channel> {
    if (this.channelState.uniqueName !== uniqueName) {
      if (!uniqueName) {
        uniqueName = '';
      }

      await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
        'post',
        this.links.self,
        { unique_name: uniqueName }
      );
    }
    return this;
  }

  /**
   * Get user descriptors of this channel.
   */
  public async getUserDescriptors(): Promise<Paginator<UserDescriptor>> {
    return await this.services.users.getChannelUserDescriptors(this.sid);
  }
}

export {
  ChannelDescriptor,
  Channel,
  ChannelType,
  ChannelUpdateReason,
  ChannelStatus,
  NotificationLevel,
  ChannelState,
  ChannelUpdatedEventArgs,
  SendMediaOptions,
  LastMessage
};
